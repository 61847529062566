import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export const TYPE_TRUCK = 'truck';
export const TYPE_TRAILER = 'trailer';
export const TYPE_CARGO = 'cargo';
export const TYPE_THIRD_PARTY = 'third party';

export const TYPES = [TYPE_TRUCK, TYPE_TRAILER, TYPE_CARGO, TYPE_THIRD_PARTY];

export class Change extends Model {
    @observable field = '';
    @observable diff = null;
    @observable model = '';
    @observable oid = null;
    @observable before = '';
    @observable after = '';
}

export class ChangeStore extends Store {
    Model = Change;
}
