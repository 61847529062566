import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { CalculationRule } from './CalculationRule';

export const DESCRIPTION = 'description';
export const LEVEL = 'level';
export const OPTIONAL_FIELDS = [DESCRIPTION, LEVEL];

export class CalculationRuleDescription extends Model {
    static backendResourceName = 'calculation_rule_description';

    @observable id = null;
    @observable description = '';
    @observable rate = 0;
    @observable deleted = false;

    relations() {
        return {
            calculationRule: CalculationRule
        };
    }
}

export class CalculationRuleDescriptionStore extends Store {
    Model = CalculationRuleDescription;
    static backendResourceName = 'calculation_rule_description';
}
