import { observable } from 'mobx';
import { Casts, Model, Store } from './../Base';
import { Activity } from '../Activity';

export const BOOKING_EUROTUNNEL_DRIVER_LANGUAGES = ['GB', 'FR', 'PL', 'NL', 'DE', 'ES', 'HU', 'CZ', 'IT', 'RO', 'XX'];
export const BOOKING_EUROTUNNEL_VEHICLE_LENGTHS = ['LowerThan7m5', 'EqualOrGreaterThan7m5_LowerThan13m', 'EqualOrGreaterThan13m'];

export class BookingEurotunnel extends Model {
    static backendResourceName = 'tariff_km_rate';

    @observable id = null;
    @observable date = null;
    @observable driverLanguage = '';
    @observable driverName = '';
    @observable truckPlate = '';
    @observable trailerPlate = '';
    @observable vehicleLength = ''; // one of the VEHICLE_LENGTHS above
    @observable vehicleEmpty = false; // always false
    @observable hasAdr = false;
    @observable isRefrigerated = false;
    @observable externalReference = null; // Set by Eurotunnel API (readonly)
    @observable requestorName = ''; // dispatcher name?
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            activity: Activity,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class BookingEurotunnelStore extends Store {
    Model = BookingEurotunnel;
    static backendResourceName = 'booking_eurotunnel';
}
