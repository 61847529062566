import React from 'react';
import PropTypes from 'prop-types';

const IconTrailerChange = props => {
    return (
        <svg viewBox="0 0 30 30" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M24.379 19l-2.621 2.667h1.965c0 2.206-1.762 4-3.931 4a3.795 3.795 0 0 1-1.835-.467l-.957.973c.806.52 1.763.827 2.792.827 2.896 0 5.242-2.387 5.242-5.333H27L24.379 19zm-9.102 2.333c0-2.206 1.762-4 3.931-4 .662 0 1.291.167 1.835.467l.957-.973A5.133 5.133 0 0 0 19.208 16c-2.896 0-5.242 2.387-5.242 5.333H12L14.621 24l2.621-2.667h-1.965z"
                    fill={props.color}
                    fillRule="nonzero"
                />
                <path d="M2-1h24v24H2z" />
                <path
                    d="M27 3H3v13h1c0 1.66 2.008 3 3 3s2.5-.5 2.5-2c0 1.5 1.537 2 2.5 2 .963 0 3-1.34 3-3 1.333-1.011 2.666-1.511 4-1.5 1.345.012 3.012.512 5 1.5h3V3zM7 17.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm5 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
                    fill={props.color}
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
};
IconTrailerChange.propTypes = {
    color: PropTypes.string,
};

export default IconTrailerChange;
