import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
`;

@observer
export default class TextOverflow extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.containerRef = this.containerRef.bind(this);
    }

    @observable open = false;

    @action onOpen() {
        // only open if it has overflow
        this.open = this.containerNode.scrollWidth > this.containerNode.clientWidth;
    }

    @action onClose() {
        this.open = false;
    }

    containerNode = null;

    containerRef(node) {
        this.containerNode = node;
    }

    render() {
        const { text, ...props } = this.props;
        return (
            <Popup
                open={this.open}
                onOpen={this.onOpen}
                onClose={this.onClose}
                trigger={<Container innerRef={this.containerRef} {...props}>{text}</Container>}
                content={text}
            />
        );
    }
}
