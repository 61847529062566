import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { User } from './User';
import { BEPTicket } from './BEPTicket';

export class BEPFollowupActionCategory extends Model {
    static backendResourceName = 'bepfollowup_action_category';

    @observable id = null;
    @observable name = '';
    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
};

export class BEPFollowupActionCategoryStore extends Store {
    Model = BEPFollowupActionCategory;
    static backendResourceName = 'bepfollowup_action_category';
};


export class BEPFollowup extends Model {
    static backendResourceName = 'bepfollowup';

    @observable id = null;
    @observable proaReference = '';

    @observable description = '';
    @observable performedAt = null;

    @observable createdAt = null;
    @observable updatedAt = null;

    @observable _isProa = null;

    relations() {
        return {
            bepTicket: BEPTicket,

            performedActionCategory: BEPFollowupActionCategory,

            performedBy: User,
            createdBy: User,
        };
    }

    casts() {
        return {
            performedAt: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    @computed get isProa() {
        if (this._isProa !== null) {
            return this._isProa;
        }

        return !!this.proaReference;
    }
};

export class BEPFollowupStore extends Store {
    Model = BEPFollowup;
    static backendResourceName = 'bepfollowup';
};
