import React from 'react';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';
import { divIcon } from 'leaflet';
import TypeIcon from 'component/Activity/TypeIcon';
import styled from 'styled-components';
import { COLOR_PRIMARY } from 'styles';
import markerGreenIcon from '../../image/marker-icon-green-empty.png';
import markerGreyIcon from '../../image/marker-icon-grey-empty.png';
import markerBlueIcon from '../../image/marker-icon-blue.png';
import markerDarkblueIcon from '../../image/marker-icon-darkblue.png'

const OPACITY_PRIMARY = 1

const StyledSpan = styled.span`
    background-color: ${props => props.color || COLOR_PRIMARY};
    opacity: ${props => props.opacity || OPACITY_PRIMARY};

    position: relative;

    display: block;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;

    ::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        top: 24px;
        left: 4px;
        border: 10px solid transparent;
        border-top: 1.1rem solid ${props => props.color || COLOR_PRIMARY};
    }

    /*width: 2rem;
    height: 2rem;
    display: block;
    position: relative;
    border-radius: 2rem 2rem 0;
    transform: rotate(45deg);*/

    text-align: center;
`

function backgroundGenerator(props) {
   if (props.kind === 'boek stop') {
        return props.selected ? markerGreenIcon : markerBlueIcon;
    }
     if (props.kind !== 'activity') {
        return props.selected ? markerGreenIcon : markerDarkblueIcon;
    }
    return props.selected ? markerGreenIcon : markerGreyIcon;
}

const BackgroundIcon = styled.div`
    background: url(${backgroundGenerator});
    background-repeat: no-repeat;
    width: 25px;
    height: 41px;
    padding-top: 5px;
    display: flex;
    justify-content: center;
`;

const IconMarker = ({ type, kind, selected, price = 0,...rest }) => {
    return (
        <BackgroundIcon selected={selected} kind={kind} {...rest}>
            <TypeIcon type={type} kind={kind} price={price} color="#fff" width="20px" height="25px" />
        </BackgroundIcon>
    );
};

IconMarker.propTypes = {
    type: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    price: PropTypes.string,
};

export default function({ type, kind, price = 0, color, certification, unbookable, ...rest }, selected) {
    let html = renderToString(
        <IconMarker {...rest}
            data-test-icon-marker
            type={type}
            kind={kind}
            selected={selected}
        />
    );

    // Render tanking a bit differently, to allow changing colors.
    if (type === 'tanking') {
        html = renderToString(
            <StyledSpan color={color} {...rest}>
                <TypeIcon type={type} kind={kind} price={price} color="#fff" width="20px" height="25px" />
            </StyledSpan>
        );
    }

    // Render parking a bit differently, to allow changing colors.
    if (type === 'parking' && color !== 'red') {
        html = renderToString(
            <StyledSpan opacity={unbookable ? '0.3' : null} color={color} {...rest}>
                <TypeIcon type={type} kind={kind} certification={certification} color="#fff" width="20px" height="25px" />
            </StyledSpan>
        );
    } else if (type === 'parking' && color === 'red') {
        html = renderToString(
            <StyledSpan opacity={unbookable ? '0.3' : null} color={color} {...rest}>
                <TypeIcon type={type} kind={kind} banned={true} certification={certification} color="#fff" width="20px" height="25px" />
            </StyledSpan>
        );
    }

    return divIcon({
        html,
        className: 'my-custom-pin',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
    });
}
