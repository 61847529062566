import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { VerificationFormVersion } from '../Verification/FormVersion';
import { FormAnswerStore } from './Answer';
import { Truck } from '../Truck';
import { Driver } from '../Driver';
import { User } from '../User';

export class FormResponse extends Model {
    static backendResourceName = 'form_response';

    @observable id = null;
    @observable checkIn = false;
    @observable driver1Signature = null;
    @observable driver2Signature = null;
    @observable verifiedAt = null;

    relations() {
        return {
            truck: Truck,
            formVersion: VerificationFormVersion,
            answers: FormAnswerStore,
            driver1: Driver,
            driver2: Driver,
            verifiedBy: User,
        };
    }
}

export class FormQuestionStore extends Store {
    Model = FormResponse;
    static backendResourceName = 'form_response';
}
