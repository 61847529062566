import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Driver } from './Driver';

export function deleteDietDay(dietDay, extraData) {
    const p = dietDay.save({ relations: ['driver'] })

    return p
        .then(() => dietDay.fetch());
}

export function saveDietDay(dietDay, extraData) {
    const p = dietDay.save({ relations: ['driver'] })

    return p
        .then(() => dietDay.fetch());
}

export class DietDay extends Model {
    static backendResourceName = 'diet_day';

    @observable id = null;
    @observable workSince = null;
    @observable workTill = null;
    @observable manuallyDeleted = false;

    @observable _wasWorkSince = false;
    @observable _wasWorkTill = false;

    parse(data) {
        const result = super.parse(data);

        this._wasWorkSince = !!this.workSince;
        this._wasWorkTill = !!this.workTill;

        return result;
    }

    @computed get isWorkSinceChanged() {
        return this.__changes.includes('workSince') &&
            this._wasWorkSince !== !!this.workSince;
    }

    @computed get isWorkTillChanged() {
        return this.__changes.includes('workTill') &&
            this._wasWorkTill !== !!this.workTill;
    }

    relations() {
        return {
            driver: Driver,
        };
    }

    casts() {
        return {
            workSince: Casts.date,
            workTill: Casts.date,
        };
    }
}

export class DietDayStore extends Store {
    Model = DietDay;
    static backendResourceName = 'diet_day';
    getByIsoWeek(isoWeek) {
        return this.models.find(m => m.workSince.format('GGGG-WW') <= isoWeek && isoWeek <= m.workTill.format('GGGG-WW'));
    }
}
