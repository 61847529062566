import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { THead, Tr, Th } from 'component/Invoice/Table';
import { SortIconGroup, SortIcon } from 'component/AdminOverview';
import { Icon } from 'semantic-ui-react';
import { OWN_CURRENCY_SYMBOL } from 'helpers';

export class ClickableTh extends Component {
    static propTypes = {
        name: PropTypes.string,
        onClick: PropTypes.func,
        currentSortOrder: PropTypes.string,
        label: PropTypes.string,
    };

    render() {
        const { onClick, name, currentSortOrder, ...rest } = this.props;

        return (
            <React.Fragment>
                <Th  {...rest} onClick={onClick ? event => onClick(event, name) : undefined}>

                    {onClick && <SortIconGroup>
                            {/* First Icon is positioned weirdly for some reason so dummy icon */}
                            <Icon />
                            <SortIcon
                                name="sort ascending"
                                active={currentSortOrder === 'asc'}
                            />
                            <SortIcon
                                name="sort descending"
                                active={currentSortOrder === 'desc'}
                            />
                        </SortIconGroup>}
                        {this.props.label}
                </Th>
            </React.Fragment>
        );
    }
}

@observer
export default class ActivityHeader extends Component {
    static propTypes = {
        // The first few columns are details about the activiy. This component
        // is also used in the activity finalize view, so it would show again
        // the activity details.
        showActivityColumns: PropTypes.bool,

        /**
         * Prepend with some sort of filler header.
         */
        filler: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
        showColumnVat: PropTypes.bool.isRequired,
        showCustomAmount: PropTypes.bool.isRequired,
        sticky: PropTypes.bool.isRequired,
        mapThProps: PropTypes.func.isRequired,
        currencySymbol: PropTypes.string.isRequired
    };

    static defaultProps = {
        showActivityColumns: true,
        showColumnVat: true,
        showCustomAmount: false,
        sticky: true,
        currencySymbol: OWN_CURRENCY_SYMBOL,
        mapThProps: props => props,
    };

    render() {
        const { mapThProps, showActivityColumns, currencySymbol, showColumnVat, showCustomAmount, filler, sticky, ...rest } = this.props;
        let actualFiller = null;
        const thProps = { sticky, ...rest };

        if (filler === true) {
            actualFiller = <ClickableTh {...thProps} />;
        } else if (typeof filler === 'function') {
            actualFiller = filler(thProps);
        }

        return (
            <THead>
                <Tr>
                    {actualFiller}
                    {showActivityColumns && (
                        <React.Fragment>
                            <ClickableTh {...mapThProps({ ...thProps, name: 'from', label: t('invoice.editActivity.from'), alignLeft: true })}></ClickableTh>
                            <ClickableTh {...mapThProps({ ...thProps, name: 'to', label: t('invoice.editActivity.to'), alignLeft: true })}></ClickableTh>
                        </React.Fragment>
                    )}
                    <ClickableTh {...mapThProps({ ...thProps, name: 'givenKm', label: t('activity.field.givenKm.label') })}></ClickableTh>
                    <ClickableTh {...mapThProps({ ...thProps, name: 'tariffKm', label: t('tariffKmRate.field.priceFc.label', { currencySymbol: currencySymbol }) })}></ClickableTh>
                    <ClickableTh {...mapThProps({ ...thProps, name: 'tariffFixed', label: t('tariffFixedRate.field.price.label') })}></ClickableTh>
                    <ClickableTh {...mapThProps({ ...thProps, name: 'tariffWaiting', label: t('tariffWaitingRate.field.priceFc.label', { currencySymbol: currencySymbol }) })}></ClickableTh>
                    <ClickableTh {...mapThProps({ ...thProps, name: 'secondDriver', label: t('tariffSecondDriverRate.field.price.short') })}></ClickableTh>
                    <ClickableTh {...mapThProps({ ...thProps, name: 'tollCosts', label: t('activity.field.tollCosts.label') })}></ClickableTh>
                    <ClickableTh {...mapThProps({ ...thProps, name: 'fuel', label: t('activity.field.expectedKmSurcharge.label') })}></ClickableTh>
                    {/*
                    <ClickableTh sticky={sticky}>
                        {t('activity.field.expectedFixedSurcharge.label')}
                    </ClickableTh>
                    */}
                    <ClickableTh {...mapThProps({ ...thProps, name: 'otherCosts', label: t('activity.field.otherCosts.label') })}></ClickableTh>
                    {showColumnVat
                        ? <ClickableTh {...mapThProps({ ...thProps, name: 'vat', label: t('activity.field.vat.label') })}></ClickableTh>
                        : <ClickableTh {...mapThProps({ ...thProps, name: 'custom', label: showCustomAmount ? t('activity.field.custom.label') : '' })}></ClickableTh>
                    }
                    <ClickableTh {...mapThProps({ ...thProps, name: 'total', label: t('invoiceLine.field.amount.label') })}></ClickableTh>
                </Tr>
            </THead>
        );
    }
}
