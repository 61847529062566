import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Row, Col, SingleDatePicker, TimeInput } from 're-cy-cle';
import { snakeToCamel } from 'helpers';
import { Form } from 'semantic-ui-react';

@observer
export default class DatetimePicker extends Component {
    static propTypes = {
        model: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        afterChange: PropTypes.func,
        label: PropTypes.string,
        showValue: PropTypes.bool,
        helpText: PropTypes.string,
        required: PropTypes.bool,
    };

    static defaultProps = {
        required: false,
    };

    setInput = (n, value) => {
        const { name, model, afterChange } = this.props;

        model.setInput(name, value || null);

        if (afterChange) {
            afterChange(model);
        }
    };

    render() {
        const { model, name, onChange, label, showValue, helpText, required, ...rest } = this.props;

        // Weird name, but we still need the original `showValue` to show or hide the `required` prop.
        const showMyValue = showValue === undefined ? true : showValue;
        const labelContent = 'label' in this.props ? label : t(`${snakeToCamel(model.constructor.backendResourceName)}.field.${name}.label`);

        return (
            <Form.Field {...rest}
                error={model.backendValidationErrors[name]}
                required={showValue || required}
                helpText={helpText}
            >
                <label>{labelContent}</label>
                <Row>
                    <Col xs={7}>
                        <SingleDatePicker
                            name={name}
                            value={showMyValue ? model[name] : null}
                            disabled={!showMyValue}
                            onChange={onChange || this.setInput}
                            autoComplete="off"
                        />
                    </Col>
                    <Col xs={5}>
                        <TimeInput
                            name={name}
                            value={showMyValue ? model[name] : null}
                            disabled={!showMyValue}
                            onChange={onChange || this.setInput}
                            autoComplete="off"
                        />
                    </Col>
                </Row>
            </Form.Field>
        );
    }
}
