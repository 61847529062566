import React from 'react';
import PropTypes from 'prop-types';

const IconDriverChange = props => {
    return (
        <svg viewBox="0 0 30 30" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M24.379 19l-2.621 2.667h1.965c0 2.206-1.762 4-3.931 4a3.795 3.795 0 0 1-1.835-.467l-.957.973c.806.52 1.763.827 2.792.827 2.896 0 5.242-2.387 5.242-5.333H27L24.379 19zm-9.102 2.333c0-2.206 1.762-4 3.931-4 .662 0 1.291.167 1.835.467l.957-.973A5.133 5.133 0 0 0 19.208 16c-2.896 0-5.242 2.387-5.242 5.333H12L14.621 24l2.621-2.667h-1.965zM11 11c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm5.729 3.327C14.953 13.444 12.536 13 11 13c-2.67 0-8 1.34-8 4v2h8.582a8.02 8.02 0 0 1 5.147-4.673z"
                    fill={props.color}
                    fillRule="nonzero"
                />
                <path d="M-1-1h24v24H-1z" />
            </g>
        </svg>
    );
};

IconDriverChange.propTypes = {
    color: PropTypes.string,
};

export default IconDriverChange;
