import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Model } from 'store/Base';
import { observer } from 'mobx-react';
import { FormField, RadioButtons } from 're-cy-cle';
import { snakeToCamel } from '../helpers';

@observer
export default class FormRadioButtons extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
        onChange: PropTypes.func,
        afterChange: PropTypes.func,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        afterChange: () => {},
    }

    setInput(name, value) {
        const { model } = this.props;

        model.setInput(name, value);
    };

    onChange(...args) {
        const { onChange, afterChange } = this.props;
        const fn = onChange || this.setInput.bind(this);
        fn(...args);
        afterChange(...args);
    }

    render() {
        const { model, label, name, options, disabled } = this.props;

        return (
            <FormField
                label={label || t(`${snakeToCamel(model.constructor.backendResourceName)}.field.${name}.label`)}
                error={model.backendValidationErrors && model.backendValidationErrors[name]}
            >
                <RadioButtons
                    disabled={disabled}
                    name={name}
                    value={model[name]}
                    onChange={this.onChange.bind(this)}
                    options={options}
                />
            </FormField>
        );
    }
}
