import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Activity } from 'store/Activity';
import { Icon } from 'semantic-ui-react';
import { formatMoney } from 'helpers';

@observer
export default class ActivityInvoiceStatus extends Component {
    static propTypes = {
        activity: PropTypes.instanceOf(Activity).isRequired,
    };

    render() {
        const { activity } = this.props;
        let icon = <Icon name="close" color="red"/>;
        let title = '';

        if (activity.invoiced) {
            icon = activity.invoiceSummary.invoiced_total_amount === activity.invoiceSummary.agreed_total ? <Icon name="check" color="green"/> : '≠';
            title = `Agreed: ${formatMoney(activity.invoiceSummary.agreed_total)}\nInvoiced: ${formatMoney(activity.invoiceSummary.invoiced_total_amount)}`;
        }

        return (
            <span title={title} data-test-invoice>
                {icon}
            </span>
        );
    }
}
