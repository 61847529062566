import { observable } from 'mobx';
import { Model, Store } from '../Base';

export class DriverAvailabilityDefault extends Model {
    static backendResourceName = 'driver_availability_default';

    @observable id = null;

    @observable travelToWorkDeparturePlace = '';

    @observable arrivalTravelMethod = '';
    @observable arrivalPlace = '';

    @observable departureTravelMethod = '';
    @observable departurePlace = '';

    @observable travelToHomeArrivalPlace = '';
}

export class DriverAvailabilityDefaultStore extends Store {
    Model = DriverAvailabilityDefault;
    static backendResourceName = 'driver_availability_default';
}
