import { observable } from 'mobx';
import { each, get } from 'lodash';
import { getMoneyInputProps } from 'spider/helpers';
import moment from 'moment-timezone';

export let FRONTEND_API_BASE_URL = process.env.REACT_APP_CY_FRONTEND_API_BASE_URL || '/api/';
export let FRONTEND_SSO_LOGIN_URL = process.env.REACT_APP_SSO_LOGIN_URL || '/api/oauth2/signin/';
export let FRONTEND_SSO_LOGOUT_URL = process.env.REACT_APP_SSO_LOGOUT_URL || '/api/oauth2/signout/';

// Config set by bootstrap.
export let MAPS_API_KEY = '';
export let MAPS_API_URL = '';

export const BUILD_INFO = observable({
    version: 'dev',
});

export function configOverride(bootstrap) {
    MAPS_API_KEY = bootstrap.google_maps_api_key;
    MAPS_API_URL = bootstrap.google_maps_api_url;
    Object.assign(BUILD_INFO, bootstrap.build_info);
}

export function formatNumberWithCommas(number) {
    return number.toLocaleString('en-US');
}

/**
 * Converts given moment to the same time in timezone. For example,
 *
 * - User enters 10:29
 * - User enters London
 *
 * This means 10:29 in London
 */
export function convertMomentToTimezone(aMoment, timezone) {
    if (!aMoment) {
        return aMoment;
    }

    return moment.tz(aMoment, timezone);

    // Saves time correctly, but displays in user local time.
    // return moment.tz(aMoment.format(), timezone);
}


// Stolen from re-cy-cle
// lodash's `camelCase` method removes dots from the string; this breaks mobx-spine
export function snakeToCamel(s) {
    if (s.startsWith('_')) {
        return s;
    }
    return s.replace(/_\w/g, m => m[1].toUpperCase());
}

// lodash's `snakeCase` method removes dots from the string; this breaks mobx-spine
export function camelToSnake(s) {
    return s.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
}

// TODO: make separate helper files categorized by theme, e.g. "money" and "date"
// This is insane at the moment, sorry man.

export const PUBLIC_URL =
    process.env.NODE_ENV !== 'production' ? process.env.PUBLIC_URL : undefined;

// While in debug mode, customer ids can be filtered here. It speeds up page
// loading and is automatically disabled on production to prevent goldplated ids
// going live.
export const ALLOCATION_IDS = []; //[410, 414];
export const DEFAULT_TIMEZONE = 'Europe/Amsterdam';
export const USER_TIMEZONE = moment.tz.guess();

export const IS_DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.REACT_APP_CY_BUILDING_FOR_CI === 'true';
export const IS_STAGE = window.location.href.includes('staging') || window.location.href.includes('uat');

// Feature flags
export const FLAG_ACTIVITY_ISSUES = IS_DEBUG || IS_STAGE;
export const FLAG_FUEL = true;

// Some features depend on tracking own currency vs foreign currency, like
// road / fuel costs.
export const OWN_CURRENCY = 'EUR';
export const OWN_CURRENCY_SYMBOL = '€';

export function floatToDecimal(value) {
    return value.toFixed(2).replace('.', ',');
}

// Stolen from https://gist.github.com/penguinboy/762197#gistcomment-2380871
const flatten = (object, prefix = '') => {
    return Object.keys(object).reduce((prev, element) => {
        return typeof object[element] === 'object'
            ? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
            : { ...prev, ...{ [`${prefix}${element}`]: object[element] } }
    }, {});
}

/**
 * Get list of error messages from the backend response. Typical usage:
 *
 * model.save().catch(response =>
 *     parseBackendErrorMessages(response.response.data.errors)
 * )
 */
export function parseBackendErrorMessages(errors) {
    if (!errors) {
        return [];
    }

    const messages = [];
    const flat = flatten(errors);

    Object.keys(flat).forEach(key => {
        if (key.includes('.message')) {
            messages.push(flat[key]);
        }
    });

    return messages;
}

export function parseBackendErrorCodes(errors) {
    const codes = [];
    const flat = flatten(errors);

    Object.keys(flat).forEach(key => {
        if (key.includes('.code')) {
            codes.push(flat[key]);
        }
    });

    return codes;
}

export function decimalToFloat(value) {
    if (typeof value !== 'string') {
        return null;
    }
    return parseFloat(value.replace(/\./g, '').replace(',', '.'));
}

export const MOBILITY_PACKAGE_START_DATE = '2022-02-22';
export const SCREEN_WIDTH_PX = '1280px';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ssZZ';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_SHORT_FORMAT = 'DD-MM-YY';
export const DATE_MINI_FORMAT = 'DD-MM';
export const DATE_CUSTOM_FORMAT = 'DD MMM YYYY';
export const DATETIME_FORMAT = 'DD-MM-YYYY HH:mm';
export const DATETIME_LONG_FORMAT = 'DD-MM-YY HH:mm:ss';
export const DATETIME_SHORT_FORMAT = 'DD-MM-YY HH:mm';
export const DATETIME_MINI_FORMAT = 'DD-MM HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const DATE_RANGE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ssZZ';
export const ACTION_DELAY = 400;

export const BOEK_LIGHT_GREEN = 'rgb(200, 228, 200)';

export function setMoneyForBackend(value, decimals=2) {
    if (typeof value !== 'string') {
        return 0;
    }

    const parsed = decimalToFloat(value);

    return isFinite(parsed) ? Math.round(parsed * Math.pow(10, decimals)) : 0;
}

export function setPercentageFloatForBackend(value) {
    if (typeof value !== 'string') {
        return 0;
    }

    const parsed = decimalToFloat(value);

    return isFinite(parsed) ? parsed : 0;
}

//const moneyFormat = new Intl.NumberFormat('nl-NL', {
//    style: 'currency',
//    currency: 'EUR',
//    currencyDisplay: 'symbol'
//});

export function formatMoney(value, decimals = 2, currency=OWN_CURRENCY) {
    // Better money formatter, which prefixed the euro symbol. We're not yet
    // ready for this...

    const moneyFormat = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: currency ? currency : OWN_CURRENCY,
        currencyDisplay: 'symbol',
    })

    return moneyFormat.format(value / Math.pow(10, decimals)).replace(/\s/g, '');
}

export function formatMoneyWithoutCurrency(value, decimals = 2) {
    return formatMoney(value, decimals).replace('€', ''); // Keep the money formatting but remove the currency symbol.
}

export function getMoneyForUser(value, decimals=2) {
    if (typeof value !== 'number') {
        return null;
    }

    return (value / Math.pow(10, decimals)).toFixed(decimals).replace('.', ',');

}

const currencySymbols = {
    null: OWN_CURRENCY_SYMBOL,
    GBP: '£',
    PLN: 'PLN',
    SEK: 'SEK',
    EUR: '€',
    RON: 'RON',
    undefined: '¤',
}

export function getCurrencySymbolFromCode(currencyCode) {
    return currencySymbols[currencyCode]
}

// Found it weird to use money helpers on fuel surcharge, so simply
// wrap them.
export function setFuelSurchargeForBackend(value) {
    return setMoneyForBackend(value);
}

export function getFuelSurchargeForUser(value) {
    return getMoneyForUser(value);
}

export function setFactorForBackend(value) {
    return setMoneyForBackend(value);
}

export function setPercentageForBackend(value) {
    return setPercentageFloatForBackend(value);
}

export function getFactorForUser(value) {
    return getMoneyForUser(value);
}

export function getPercentageForUser(value) {
    return getMoneyForUser(value, 0);
}

export function getFactorInputProps() {
    return Object.assign(getMoneyInputProps(), {
        prefix: undefined,
        suffix: '%',
    });
}

export function getPercentageInputProps() {
    return Object.assign(getMoneyInputProps(), {
        prefix: undefined,
        suffix: '%',
        allowDecimal: false,
    });
}

// It is possible that in a <select> component, the currently selected model
// is not present in the list; either it is deleted, or the store has pagination, etc.
export function addSelectedModelInOptions(models, selectedModel) {
    const newModels = models.filter();
    if (selectedModel.id && !newModels.find(m => m.id === selectedModel.id)) {
        newModels.push(selectedModel);
    }
    return newModels;
}

// Accepts a request error, and transforms it into an array
// of notification messages.
export function formatCustomValidationErrors(err) {
    let output = [];

    each(get(err, 'response.data.errors'), (errors, resource) => {
        output = output.concat(
            errors.map((e, i) => {
                return {
                    key: `${resource}${i}`,
                    message: e.message,
                    dismissAfter: 4000,
                };
            })
        );
    });
    return output;
}

// Accepts a request validation error, and transforms it into an array
// of notification messages.
export function formatValidationErrors(err) {
    const output = [];

    each(get(err, 'response.data.errors'), (errors, resource) => {
        Object.values(errors).forEach(moreNesting => {
            Object.values(moreNesting).forEach(moreNesting2 => {
                output.push({
                    type: 'error',
                    message: moreNesting2[0].message,
                    dismissAfter: 4000,
                });
            });
        });
    });

    return output;
}
