import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestion } from './Question';

export class FormQuestionText extends Model {
    static backendResourceName = 'form_question_text';

    @observable id = null;
    @observable multiLine = false;
    @observable autoFill = '';

    relations() {
        return {
            formQuestion: FormQuestion,
        };
    }
}

export class FormQuestionTextStore extends Store {
    Model = FormQuestionText;
    static backendResourceName = 'form_question_text';
}
