import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export class TariffSecondDriverRate extends Model {
    static backendResourceName = 'tariff_second_driver_rate';

    @observable id = null;
    @observable price = 0;

    calcPriceForActivity(activity) {
        if (activity.assignment.driver2.id) {
            activity._tariffSecondDriverRateExplaination = 'Found second driver';
            return this.price;
        }

        activity._tariffSecondDriverRateExplaination = 'No second driver';
        return 0;
    }

    explainForActivity(activity) {
        if (!activity._tariffSecondDriverRateExplaination) {
            this.calcPriceForActivity(activity);
        }

        return activity._tariffSecondDriverRateExplaination;
    }
}

export class TariffSecondDriverRateStore extends Store {
    Model = TariffSecondDriverRate;
    static backendResourceName = 'tariff_second_driver_rate';
}
