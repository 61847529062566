import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'semantic-ui-react';
import { getMoneyForUser, DATE_SHORT_FORMAT, getCurrencySymbolFromCode } from 'helpers';

@observer
export default class ActivityFinalizeWaivers extends Component {
    static propTypes = {
        activity: PropTypes.object.isRequired,
    };

    render() {
        const { activity } = this.props;
        const isFc = activity.allocation.contract.isFc
        const currencySymbol = getCurrencySymbolFromCode(activity.allocation.contract.fcCode);

        return (
            <Table data-test-waivers celled compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell singleLine>{t('planning.activityFinalizeModal.waivers.waiversBy')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('planning.activityFinalizeModal.waivers.waiversAt')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('planning.activityFinalizeModal.waivers.reason')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('planning.activityFinalizeModal.waivers.remarks')}</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign="right">{t('planning.activityFinalizeModal.waivers.waiversAmount')}</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign="right">{t('planning.activityFinalizeModal.invoice.activityAmount')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {activity.invoiceLineItems.map((invoiceLineItem) => {
                        return invoiceLineItem.invoiceLine.disputes.map(dispute => dispute.waivers.map((waiver) => {
                            return (
                                <Table.Row key={waiver.id}>
                                    <Table.Cell collapsing>{waiver.isAutoCreated ? t('waiver.autoCreatedUser') : waiver.createdBy.fullName}</Table.Cell>
                                    <Table.Cell collapsing>{waiver.createdAt.format(DATE_SHORT_FORMAT)}</Table.Cell>
                                    <Table.Cell data-test-message>{waiver.message}</Table.Cell>
                                    <Table.Cell data-test-remarks>{waiver.remarks}</Table.Cell>
                                    <Table.Cell data-test-amount textAlign="right">{currencySymbol}{getMoneyForUser(isFc ? waiver.waivedTotalAmountFc : waiver.waivedTotalAmount)}</Table.Cell>
                                    <Table.Cell data-test-activity-amount textAlign="right">{currencySymbol}{getMoneyForUser(invoiceLineItem.calcWaivedAmount(waiver))}</Table.Cell>
                                </Table.Row>
                            );
                        }));
                    })}
                </Table.Body>
            </Table>
        );
    }
}
