import { computed, observable } from 'mobx';
import { Model, Store, OrderedStore } from 'store/Base';
import { VerificationFormVersion } from '../Verification/FormVersion';
import { FormQuestionText } from './QuestionText';
import { FormQuestionInteger } from './QuestionInteger';
import { FormQuestionFile } from './QuestionFile';
import { FormQuestionChoice } from './QuestionChoice';
import { FormQuestionChoiceOption } from './QuestionChoiceOption';
import { FormQuestionDate } from './QuestionDate';
import { omit } from 'lodash';

export const TYPE_TEXT = 'text';
export const TYPE_INTEGER = 'integer';
export const TYPE_FILE = 'file';
export const TYPE_CHOICE = 'choice';
export const TYPE_DATE = 'date';
export const TYPES = [TYPE_TEXT, TYPE_INTEGER, TYPE_FILE, TYPE_CHOICE, TYPE_DATE];
export const REQUIRED_RELATIONS = [
    'unlockedByOption',
    'text',
    'integer',
    'file',
    'choice.options',
    'date',
];

export class FormQuestion extends Model {
    static backendResourceName = 'form_question';

    @observable id = null;
    @observable body = '';
    @observable ordering = 0;
    @observable type = '';
    @observable _isUnlockedByOptionSet = false;

    @computed get typed() {
        return this[this.type];
    }

    relations() {
        return {
            formVersion: VerificationFormVersion,
            unlockedByOption: FormQuestionChoiceOption,

            text: FormQuestionText,
            integer: FormQuestionInteger,
            file: FormQuestionFile,
            choice: FormQuestionChoice,
            date: FormQuestionDate,
        };
    }

    toBackend(options) {
        const data = super.toBackend(options);

        return omit(data, TYPES.filter(type => type !== this.type));
    }

    @computed
    get hasUnlockedByOption() {
        if (!this.unlockedByOption.isNew) {
            return true;
        }

        return this._isUnlockedByOptionSet;
    }

    setUnlockedByOption(option) {
        this.markChanged('unlockedByOption');

        this.unlockedByOption = option;
        this._isUnlockedByOptionSet = true;
    }

    unsetUnlockedByOption() {
        this.markChanged('unlockedByOption');

        this.unlockedByOption.setInput('id', null);
        this._isUnlockedByOptionSet = false;
    }
}

class UnorderedFormQuestionStore extends Store {
    Model = FormQuestion;
    static backendResourceName = 'form_question';
}

export const FormQuestionStore = OrderedStore(UnorderedFormQuestionStore, 'ordering');
