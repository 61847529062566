import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestionChoiceOption } from './QuestionChoiceOption';

export class FormAnswerChoiceOption extends Model {
    static backendResourceName = 'form_answer_choice_option';

    @observable id = null;

    relations() {
        return {
            formQuestionChoiceOption: FormQuestionChoiceOption,
        };
    }
}

export class FormAnswerChoiceOptionStore extends Store {
    Model = FormAnswerChoiceOption;
    static backendResourceName = 'form_answer_choice_option';
}
