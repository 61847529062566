export default `
{{customerId}} / {{trailerPlate}} waiting for unload {{currentLocation}}



{{customerId}} / {{trailerPlate}} waiting for unload {{currentLocation}}
{{reason}}

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
