import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Entity } from 'store/Entity';
import { CostSupplier } from 'store/CostSupplier';
import { FuelCostStore } from 'store/FuelCost';
import { RoadCostStore } from 'store/RoadCost';
import { CostImportStore } from 'store/CostImport';
import { get } from 'lodash';
import moment from 'moment';

export const TOTALS_ANNOTATION = 'total_fuel_costs_net_fc,total_fuel_costs_gross_fc,total_fuel_costs_vat_fc,total_road_costs_net_fc,total_road_costs_gross_fc,total_road_costs_vat_fc';

export class CostInvoice extends Model {
    static backendResourceName = 'cost_invoice';
    static omitFields = [
        'totalFuelCostsGrossFc',
        'totalFuelCostsNetFc',
        'totalFuelCostsVatFc',
        'totalRoadCostsGrossFc',
        'totalRoadCostsNetFc',
        'totalRoadCostsVatFc',
    ];

    @observable id = null;
    @observable invoiceNumber = '';
    @observable invoiceSummary = '';
    @observable invoiceDate = null;
    @observable invoiceDue = null;
    @observable paidBy = '';

    @observable netAmount = null;
    @observable grossAmount = null;
    @observable vatAmount = null;
    @observable fcCode = '';
    @observable finalizedAt = null;

    @observable totalFuelCostsGrossFc = 0;
    @observable totalFuelCostsNetFc = 0;
    @observable totalFuelCostsVatFc = 0;
    @observable totalRoadCostsGrossFc = 0;
    @observable totalRoadCostsNetFc = 0;
    @observable totalRoadCostsVatFc = 0;

    // Hack to render finalize errors on separate fuel / road cost stores.
    @observable _cachedValidationErrors = {};

    relations() {
        return {
            entity: Entity,
            costSupplier: CostSupplier,
            fuelCosts: FuelCostStore,
            roadCosts: RoadCostStore,
            costImports: CostImportStore,
        }
    }

    casts() {
        return {
            invoiceDate: Casts.date,
            invoiceDue: Casts.date,
            finalizedAt: Casts.datetime,
        };
    }

    fetchTotals() {
        const copyCostInvoice = new CostInvoice({ id: this.id });
        copyCostInvoice.setFetchParams({
            'include_annotations': TOTALS_ANNOTATION,
        });

        return copyCostInvoice.fetch().then(() => {
            this.totalFuelCostsGrossFc = copyCostInvoice.totalFuelCostsGrossFc;
            this.totalFuelCostsNetFc = copyCostInvoice.totalFuelCostsNetFc;
            this.totalFuelCostsVatFc = copyCostInvoice.totalFuelCostsVatFc;
            this.totalRoadCostsGrossFc = copyCostInvoice.totalRoadCostsGrossFc;
            this.totalRoadCostsNetFc = copyCostInvoice.totalRoadCostsNetFc;
            this.totalRoadCostsVatFc = copyCostInvoice.totalRoadCostsVatFc;
        });
    }

    @computed
    get fuelCostNetAmount() {
        return this.fuelCosts.models.reduce((result, fc) =>
            result + fc.netFc
        , 0);
    }

    @computed
    get roadCostNetAmount() {
        return this.roadCosts.models.reduce((result, rc) =>
            result + rc.netFc
        , 0);
    }

    @computed
    get summedNetAmount() {
        return this.fuelCostNetAmount + this.roadCostNetAmount;
    }

    @computed
    get fuelCostGrossAmount() {
        return this.fuelCosts.models.reduce((result, fc) =>
            result + fc.grossFc
        , 0);
    }

    @computed
    get roadCostGrossAmount() {
        return this.roadCosts.models.reduce((result, rc) =>
            result + rc.grossFc
        , 0);
    }

    @computed
    get summedGrossAmount() {
        return this.fuelCostGrossAmount + this.roadCostGrossAmount;
    }

    @computed
    get fuelCostVatAmount() {
        return this.fuelCosts.models.reduce((result, fc) =>
            result + fc.vatFc
        , 0);
    }

    @computed
    get roadCostVatAmount() {
        return this.roadCosts.models.reduce((result, rc) =>
            result + rc.vatFc
        , 0);
    }

    @computed
    get summedVatAmount() {
        return this.fuelCostVatAmount + this.roadCostVatAmount;
    }

    calculateVatAmount() {
        this.setInput('vatAmount', this.grossAmount - this.netAmount);
    }

    finalize() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}finalize/`)
                .then(() => {
                    this.setInput('finalizedAt', moment());
                })
                .catch(e => {
                    if (get(e, 'response.data.errors')) {
                        // Backend return non standard validation response. Lets
                        // fix here:
                        e.response.data.errors['road_cost'] = {};
                        e.response.data.errors['fuel_cost'] = {};
                        get(e, `response.data.errors.cost_invoice.${this.id}.road_cost`, []).forEach(rc => {
                            if (!e.response.data.errors['road_cost'][rc.params.id]) {
                                e.response.data.errors['road_cost'][rc.params.id] = {};
                            }

                            if (!e.response.data.errors['road_cost'][rc.params.id][rc.params.field]) {
                                e.response.data.errors['road_cost'][rc.params.id][rc.params.field] = [];
                            }

                            e.response.data.errors['road_cost'][rc.params.id][rc.params.field].push(rc);
                        });
                        get(e, `response.data.errors.cost_invoice.${this.id}.fuel_cost`, []).forEach(rc => {
                            if (!e.response.data.errors['fuel_cost'][rc.params.id]) {
                                e.response.data.errors['fuel_cost'][rc.params.id] = {};
                            }

                            if (!e.response.data.errors['fuel_cost'][rc.params.id][rc.params.field]) {
                                e.response.data.errors['fuel_cost'][rc.params.id][rc.params.field] = [];
                            }

                            e.response.data.errors['fuel_cost'][rc.params.id][rc.params.field].push(rc);
                        });

                        this.parseValidationErrors(e.response.data.errors);
                    }
                    throw e;
                })
        );
    }

    unfinalize() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}unfinalize/`)
                .then(() => {
                    this.setInput('finalizedAt', null);
                })
                .catch(e => {
                    if (get(e, 'response.data.errors')) {
                        this.parseValidationErrors(e.response.data.errors);
                    }
                    throw e;
                })
        );
    }
}

export class CostInvoiceStore extends Store {
    static backendResourceName = 'cost_invoice';

    Model = CostInvoice;
}
