import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, IconSave, Loader } from 're-cy-cle';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    ${props => props.form && `
        margin-top: 19px;
    `}
`;

export default class SaveButton extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        disabled: PropTypes.bool,
        children: PropTypes.node,
        className: PropTypes.string,
        form: PropTypes.bool,
        title: PropTypes.string,
    };

    render() {
        const { children, loading, disabled, title, className, ...props } = this.props;
        return (
            <StyledButton
                type="submit"
                title={title}
                disabled={disabled || loading}
                className={className}
                {...props}
            >
                {loading ? <Loader show /> : <IconSave />}
                {children || t('form.saveButton')}
            </StyledButton>
        );
    }
}
