export default `
{{customerId}} / {{trailerPlate}} ETA to POI {{currentLocation}}



{{customerId}} / {{trailerPlate}} his ETA at POI {{currentLocation}} is: {{currentCommunicatedTime}}
Reason:

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
