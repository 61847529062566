import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Location } from '../Location';

export class PointOfInterest extends Model {
    static backendResourceName = 'points_of_interest';

    urlRoot() {
        return 'location/points_of_interest/'
    }

    @observable name = '';
    @observable type = '';
    @observable point: {
        lng: null,
        lat: null,
    }

    toLocation() {
        return new Promise(resolve => {
            const location = new Location({
                address: `${this.point.lat}, ${this.point.lng}`,
                point: {
                    lat: this.point.lat,
                    lng: this.point.lng,
                }
            });

            location.geocode().then(() => resolve(location));
        });
    }
}

export class PointOfInterestStore extends Store {
    Model = PointOfInterest;
    static backendResourceName = 'points_of_interest';

    url() {
        return 'location/points_of_interest/'
    }
}
