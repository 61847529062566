import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { CostProduct } from './CostProduct';
import { CostSupplier } from './CostSupplier';

export class CostProductSupplierCode extends Model {
    static backendResourceName = 'cost_product_supplier_code';

    @observable id = null;
    @observable supplierCode = '';
    @observable costType = '';

    relations() {
        return {
            costProduct: CostProduct,
            costSupplier: CostSupplier,
        };
    }
}

export class CostProductSupplierCodeStore extends Store {
    static backendResourceName = 'cost_product_supplier_code';

    Model = CostProductSupplierCode;
}
