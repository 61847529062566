import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { StatusUpdate } from './StatusUpdate';


export class StatusUpdateAttachment extends Model {
    static backendResourceName = 'status_update_attachment';

    @observable id = null;
    @observable file = null;

    relations() {
        return {
            statusUpdate: StatusUpdate,
        };
    }
}

export class StatusUpdateAttachmentStore extends Store {
    Model = StatusUpdateAttachment;
    static backendResourceName = 'status_update_attachment';
}

