import { observable, computed } from 'mobx';
import { Model, Store } from './Base';
import { RouteImportErrorStore } from './RouteImportError';
import { ProposedActivityStore } from './ProposedActivity';
import { Allocation } from './Allocation';
import { uniq } from 'lodash';
import { KIND_ACTIVITY_TYPES, CONDITION_ADR, CONDITION_TSR1, CONDITION_TSR1_PLUS, CONDITION_TSR2, CONDITION_SENT_GEO } from './Activity';
export const STATUS_NEW = 'new';
export const STATUS_EDITED = 'edited';
export const STATUS_ACCEPTED = 'accepted';
export const STATUS_REJECTED = 'rejected';

export class ProposedRoute extends Model {
    static backendResourceName = 'proposed_route';

    @observable id = null;
    @observable status = STATUS_NEW;
    @observable orderReference = '';
    @observable remarks = '';
    @observable acceptanceRejectionRemarks = '';
    @observable freightKms = null;
    @observable emptyKms = null;
    @observable kmAmount = null;
    @observable precalculatedKmAmount = null;
    @observable origin = 'lis';
    @observable _rejectionMailSubject = '';

    relations() {
        return {
            proposedActivities: ProposedActivityStore,
            allocation: Allocation,
            importErrors: RouteImportErrorStore,
        };
    }

    reject() {
        return this.api.patch(`${this.url}reject/`, {
            acceptance_rejection_remarks: this.acceptanceRejectionRemarks,
            rejection_mail_subject: this._rejectionMailSubject,
        });
    }

    /**
     * If one of the activities has special conditions, apply to all.
     *
     * See T23759
     */
    injectConditions() {
        let conditions = [];

        if (this.origin === 'dpg2') {
            // We want to skip this because DPG2 is pretty good at managing conditions.
            // Also this code sometimes removed Temperature. Due to lack of time to debug it
            // we decided to disable it for routes with dpg2 origin
            return
        }

        this.proposedActivities.forEach(pa => {
            conditions = uniq([...pa.conditions, ...conditions]);
        });

        conditions = conditions.filter(condition =>
            [CONDITION_ADR, CONDITION_TSR1, CONDITION_TSR1_PLUS, CONDITION_TSR2, CONDITION_SENT_GEO].includes(condition)
        );

        if (conditions.length > 0) {
            this.proposedActivities.forEach(pa => {
                pa.setInput('conditions', conditions);
                pa.convertedActivity.setInput('conditions', conditions);
            });
        }
    }

    isInvoiceable() {
        if (this.origin === 'dfds') {
            return true;
        } else if(this.origin === 'kta') {
            return true;
        }

        return this.proposedActivities.filter(pa => KIND_ACTIVITY_TYPES.includes(pa.type)).filter(pa => !pa.convertedActivity.finalizedAt && !pa.externallyDeleted).length === 0;
    }

    @computed get cleanRemarks() {
        return this.remarks.replace(/%n/g, ' ');
    }
}

export class ProposedRouteStore extends Store {
    static backendResourceName = 'proposed_route';

    Model = ProposedRoute;
}
