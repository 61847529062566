export default `
{{customerId}} / {{trailerPlate}} ETA to {{currentLocation}}



{{customerId}} / {{trailerPlate}} ETA to {{currentLocation}} is: {{currentCommunicatedTime}}

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;