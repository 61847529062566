import { observable, action, computed } from 'mobx';
import { Model, Store } from 'store/Base';
import { Invoice } from './Invoice';
import { InvoiceLineItemStore } from './InvoiceLineItem';
import { TariffKmRate } from './Tariff/KmRate';
import { getMoneyForUser } from 'helpers';
import { flatten } from 'lodash';
import { OrderedStore } from 'store/Base';
import { DisputeStore } from './Dispute';
import { Currency } from 'store/Currency';
import { OWN_CURRENCY, getCurrencySymbolFromCode } from 'helpers';
/**
 * Distribute amount over individual items. It will look at percentage of
 * activityExpected / totalExpected and use that to calculate which fraction
 * of totalInvoiced should be attributed per item.
 */
export function distributeAmount(tariff, items, field, totalExpected, totalInvoiced, activityExpectedCallback) {
    let summed = 0;
    let firstNonZeroItem = null;

    items.forEach(item => {
        const activityExpected = activityExpectedCallback(item);
        let amount = Math.round(tariff.calcFractionalAmount(activityExpected, totalExpected, totalInvoiced));

        // If all items are 0, just distribute equally over all items.
        if (totalExpected === 0) {
            amount = Math.round(totalInvoiced / items.length);
        }

        item.setInput(field, amount);
        summed += amount;

        if ((amount !== 0 || activityExpected !== 0) && firstNonZeroItem === null) {
            firstNonZeroItem = item;
        }
    });

    // Give rounding errors to first non-zero activity.
    const roundingErrors = totalInvoiced - summed;

    if (roundingErrors && firstNonZeroItem) {
        firstNonZeroItem.setInput(field, firstNonZeroItem[field] + roundingErrors);
    }
}

/**
 * Each amount is stored in cents, but any in between calculations should not
 * do any rounding. This means that:
 *
 * - Functions that start with calcExpected returns float.
 * - Setting any amounts on invoiceLine requires rounding.
 * - Disputed should round calcExpected before compairing to manually set amount.
 */
export class InvoiceLine extends Model {
    static backendResourceName = 'invoice_line';

    @observable id = null;
    @observable customLine = false;
    @observable invoicedKm = 0;
    @observable kmAmount = 0;
    @observable kmSurcharge = 0;
    @observable fixedAmount = 0;
    @observable fixedSurcharge = 0;
    @observable weekendAmount = 0;
    @observable waitingAmount = 0;
    @observable otherCostsAmount = 0;
    @observable secondDriverAmount = 0;
    @observable tollAmount = 0;
    @observable customAmount = 0;
    @observable ordering = 0;
    @observable description = '';
    @observable _kmSurchargeExplanation = '';
    @observable _fixedSurchargeExplanation = '';

    @observable waitingAmountFc = 0;
    @observable weekendAmountFc = 0;
    @observable otherCostsAmountFc = 0;
    @observable kmSurchargeFc = 0;
    @observable kmAmountFc = 0;
    @observable fixedAmountFc = 0;
    @observable fixedSurchargeFc = 0;
    @observable secondDriverAmountFc = 0;
    @observable customAmountFc = 0;
    @observable tollAmountFc = 0;

    // {combined-surcharge}
    // kmSurcharge and fixedSurcharged are inputted using 1 input. So the user
    // inputs into _surcharge and the system splits it out in kmSurcharge and
    // fixedSurcharge.
    @observable _surcharge = 0;

    relations() {
        return {
            items: InvoiceLineItemStore,
            invoice: Invoice,
            appliedTariffKmRate: TariffKmRate,
            disputes: DisputeStore,
            currency: Currency,
        };
    }

    parse(data) {
        const result = super.parse(data);

        this.updateCachedSurcharge();

        return result;
    }

    updateCachedSurcharge() {
        this._surcharge = this.kmSurcharge + this.fixedSurcharge;
    }

    // {copy-pasta-activity-ids}
    @computed
    get activityIds() {
        const ids = [];

        this.items.forEach(item =>
            ids.push(item.activity.id)
        )

        return ids;
    }

    /**
     * Calculate and set prices.
     */
    @action
    recalculate(tariff, isCredit = false) {
        if (this.customLine) {
            this.setInput('kmAmount', 0);
            this.setInput('kmSurcharge', 0);
            this.setInput('fixedAmount', 0);
            this.setInput('fixedSurcharge', 0);
            this.setInput('weekendAmount', 0);
            this.setInput('waitingAmount', 0);
            this.setInput('otherCostsAmount', 0);
            this.setInput('secondDriverAmount', 0);
            this.setInput('tollAmount', 0);
            // this.setInput('expectedAmount', 0);
            this.setInput('invoicedKm', 0);
        } else {
            let waitingAmount = 0;
            const kmRate = tariff.getKmRate(this.activities);

            this.items.forEach(item => {
                const activity = item.activity;
                const activityWaitingAmount = tariff.calcApprovedWaitingAmount(activity, this.activities);
                const rate = tariff && tariff.contract && tariff.contract.isFc ? this.currency.rate : 1

                waitingAmount += activityWaitingAmount;

                item.setInput('expectedKm', Math.round(tariff.getInvoiceKmFromActivity(activity)));
                item.setInput('expectedKmAmount', Math.round(tariff.calcKmAmount(activity, this.activities)));
                item.setInput('expectedKmSurcharge', Math.round(tariff.calcKmSurcharge(activity, this.activities)));
                item.setInput('expectedFixedAmount', Math.round(tariff.calcFixedRateAmount(activity, this.activities)));
                item.setInput('expectedFixedSurcharge', Math.round(tariff.calcFixedSurcharge(activity, this.activities)));
                item.setInput('expectedSecondDriverAmount', Math.round(tariff.calcSecondDriverAmount(activity)));
                item.setInput('expectedWeekendAmount', Math.round(tariff.calcWeekendRateAmount(activity)));
                item.setInput('expectedTollAmount', Math.round(tariff.calcTollAmount(activity, rate)));
                item.setInput('expectedOtherCostsAmount', Math.round(tariff.calcOtherCostsAmount(activity)));
                item.setInput('expectedWaitingAmount', Math.round(activityWaitingAmount));
            });

            if (this.appliedTariffKmRate) {
                this.setInput('appliedTariffKmRate', kmRate);
            }

            // The amounts are stored in cents, but the calculations can return
            // floating points. Here we round them to cents, because also the
            // backend stores the amounts in cents.
            this.setInput('kmAmount', Math.round(this.calcExpectedKmAmount(tariff)));
            this.setInput('kmSurcharge', Math.round(this.calcExpectedKmSurcharge(tariff)));
            this.setInput('fixedAmount', Math.round(this.calcExpectedFixedAmount(tariff, this.activities)));
            this.setInput('fixedSurcharge', Math.round(this.calcExpectedFixedSurcharge(tariff)));
            this.setInput('weekendAmount', Math.round(this.calcExpectedWeekendAmount(tariff)));
            this.setInput('waitingAmount', Math.round(waitingAmount));
            this.setInput('secondDriverAmount', Math.round(this.calcExpectedSecondDriverAmount(tariff)));
            this.setInput('tollAmount', Math.round(this.calcExpectedTollAmount(tariff)));

            // this.setInput('expectedAmount', Math.round(this.calcExpectedAmount(tariff)));
            this.setInput('invoicedKm', Math.round(this.calcExpectedInvoicedKm(tariff)));
            this.recalculateOtherCosts();
        }

        if (isCredit) {
            this.setInput('kmAmount', -this.kmAmount);
            this.setInput('kmSurcharge', -this.kmSurcharge);
            this.setInput('fixedAmount', -this.fixedAmount);
            this.setInput('fixedSurcharge', -this.fixedSurcharge);
            this.setInput('weekendAmount', -this.weekendAmount);
            this.setInput('waitingAmount', -this.waitingAmount);
            this.setInput('secondDriverAmount', -this.secondDriverAmount);
            this.setInput('tollAmount', -this.tollAmount);
            // this.setInput('expectedAmount', -this.expectedAmount);
            this.setInput('invoicedKm', -this.invoicedKm);
            this.setInput('otherCostsAmount', -this.otherCostsAmount);

            if(tariff.contract.isFc) {
                this.setInput('kmAmountFc', -this.kmAmountFc);
                this.setInput('kmSurchargeFc', -this.kmSurchargeFc);
                this.setInput('fixedAmountFc', -this.fixedAmountFc);
                this.setInput('fixedSurchargeFc', -this.fixedSurchargeFc);
                this.setInput('weekendAmountFc', -this.weekendAmountFc);
                this.setInput('waitingAmountFc', -this.waitingAmountFc);
                this.setInput('secondDriverAmountFc', -this.secondDriverAmountFc);
                this.setInput('tollAmountFc', -this.tollAmountFc);
                this.setInput('otherCostsAmountFc', -this.otherCostsAmountFc);
            }
        }

        this.recalculateSurcharge(tariff);
        this.recalculateLineItems(tariff);
        this.updateCachedSurcharge();
    }

    /**
     * Recalculate and set invoiced amounts on line items.
     */
    @action
    recalculateLineItems(tariff) {
        const fieldMap = {
            // attr name on invoiceLineItem: settings
            invoicedKm: {
                totalExpected: itemStore => itemStore.models.reduce((res, item) => res + item.expectedKm, 0),
                totalInvoiced: () => this.invoicedKm,
                activityExpected: item => item.expectedKm,
            },
            kmAmount: {
                totalExpected: itemStore => itemStore.models.reduce((res, item) => res + item.expectedKmAmount, 0),
                totalInvoiced: () => this.kmAmount,
                activityExpected: item => item.expectedKmAmount,
            },
            otherCostsAmount: {
                totalExpected: () => this.calcExpectedOtherCostsAmount(tariff),
                totalInvoiced: () => this.otherCostsAmount,
                activityExpected: item => item.expectedOtherCostsAmount,
            },
            fixedAmount: {
                totalExpected: () => this.calcExpectedFixedAmount(tariff),
                totalInvoiced: () => this.fixedAmount,
                activityExpected: item => item.expectedFixedAmount,
            },
            waitingAmount: {
                totalExpected: () => this.calcApprovedWaitingAmount(tariff),
                totalInvoiced: () => this.waitingAmount,
                activityExpected: item => item.expectedWaitingAmount,
            },
            secondDriverAmount: {
                totalExpected: () => this.calcExpectedSecondDriverAmount(tariff),
                totalInvoiced: () => this.secondDriverAmount,
                activityExpected: item => item.expectedSecondDriverAmount,
            },
            kmSurcharge: {
                totalExpected: () => this.calcExpectedKmSurcharge(tariff),
                totalInvoiced: () => this.kmSurcharge,
                activityExpected: item => item.expectedKmSurcharge,
            },
            fixedSurcharge: {
                totalExpected: () => this.calcExpectedFixedSurcharge(tariff),
                totalInvoiced: () => this.fixedSurcharge,
                activityExpected: item => item.expectedFixedSurcharge,
            },
            tollAmount: {
                totalExpected: () => this.calcExpectedTollAmount(tariff),
                totalInvoiced: () => this.tollAmount,
                activityExpected: item => item.expectedTollAmount,
            },
            customAmount: {
                totalExpected: () => this.calcExpectedInvoicedKm(tariff),
                totalInvoiced: () => this.customAmount,
                activityExpected: item => tariff.getInvoiceKmFromActivity(item.activity),
            },
        };

        Object.keys(fieldMap).forEach(field => {
            const settings = fieldMap[field];
            const totalExpected = settings.totalExpected(this.items);
            const totalInvoiced = settings.totalInvoiced();

            distributeAmount(tariff, this.items, field, totalExpected, totalInvoiced, settings.activityExpected);
        });
    }

    @action
    recalculateOtherCosts() {
        let otherCostsAmount = 0;

        this.items.map(i => i.activity).forEach(activity => {
            otherCostsAmount += activity.calcOtherCostsAmount();
        });

        this.setInput('otherCostsAmount', Math.round(otherCostsAmount));
    }

    /**
     * Calculate and set surcharge from kmAmount and fixedAmount.
     */
    @action
    recalculateSurcharge(tariff) {
        const kmSurcharge = this.calcKmSurcharge(tariff, this.kmAmount);
        const fixedSurcharge = this.calcFixedSurcharge(tariff, this.fixedAmount);

        this.setInput('kmSurcharge', Math.sign(kmSurcharge) * Math.round(Math.abs(kmSurcharge)));
        this.setInput('fixedSurcharge', Math.sign(fixedSurcharge) * Math.round(Math.abs(fixedSurcharge)));
        this.updateCachedSurcharge();
    }

    /**
     * In https://phabricator.codeyellow.nl/T17644#372854, a single input
     * element is asked to set all surcharges.
     */
    distributeSurcharge(tariff, amount) {
        const expectedKmSurcharge = this.calcExpectedKmSurcharge(tariff);
        const expectedFixedSurcharge = this.calcExpectedFixedSurcharge(tariff);

        let kmSurcharge = 0;

        if (expectedKmSurcharge + expectedFixedSurcharge !== 0) {
            kmSurcharge = Math.round(amount * expectedKmSurcharge / (expectedKmSurcharge + expectedFixedSurcharge));
        }

        let fixedSurcharge = 0;

        if (expectedKmSurcharge + expectedFixedSurcharge !== 0) {
            fixedSurcharge = Math.round(amount * expectedFixedSurcharge / (expectedKmSurcharge + expectedFixedSurcharge));
        }

        const rounding = amount - kmSurcharge - fixedSurcharge;

        this.setInput('kmSurcharge', kmSurcharge + rounding);
        this.setInput('fixedSurcharge', fixedSurcharge);
    }

    /**
     * When changing the invoicedKm, the kmAmount should be recalculated.
     * See https://phabricator.codeyellow.nl/T17644#372854.
     */
    recalculateByInvoicedKm(tariff) {
        const kmRate = tariff.getKmRateByKm(this.invoicedKm);

        if (kmRate && !kmRate.fixed) {
            this.setInput('kmAmount', kmRate.calcKmAmountForTotalKm(this.invoicedKm));
        }

        this.recalculateSurcharge(tariff);
        this.recalculateLineItems(tariff);
    }

    convertToCredit() {
        this.setInput('id', null);
        this.setInput('kmAmount', -this.kmAmount);
        this.setInput('kmSurcharge', -this.kmSurcharge);
        this.setInput('fixedAmount', -this.fixedAmount);
        this.setInput('fixedSurcharge', -this.fixedSurcharge);
        this.setInput('weekendAmount', -this.weekendAmount);
        this.setInput('waitingAmount', -this.waitingAmount);
        this.setInput('otherCostsAmount', -this.otherCostsAmount);
        this.setInput('secondDriverAmount', -this.secondDriverAmount);
        this.setInput('tollAmount', -this.tollAmount);
        // this.setInput('expectedAmount', -this.expectedAmount);
        this.setInput('customAmount', -this.customAmount);
        this.setInput('invoicedKm', -this.invoicedKm);

        this.setInput('kmAmountFc', -this.kmAmountFc);
        this.setInput('kmSurchargeFc', -this.kmSurchargeFc);
        this.setInput('fixedAmountFc', -this.fixedAmountFc);
        this.setInput('fixedSurchargeFc', -this.fixedSurchargeFc);
        this.setInput('weekendAmountFc', -this.weekendAmountFc);
        this.setInput('waitingAmountFc', -this.waitingAmountFc);
        this.setInput('otherCostsAmountFc', -this.otherCostsAmountFc);
        this.setInput('secondDriverAmountFc', -this.secondDriverAmountFc);
        this.setInput('tollAmountFc', -this.tollAmountFc);
        this.setInput('customAmountFc', -this.customAmountFc);

        this.items.forEach(item => item.convertToCredit());
    }

    // {copy-pasta-invoiced-km}
    calcExpectedInvoicedKm(tariff) {
        const kmRate = tariff.getKmRate(this.activities);

        if (kmRate) {
            return this.items.models.reduce((result, item) => {
                const activity = item.activity;

                return result + kmRate.getInvoiceKmFromActivity(activity);
            }, 0);
        }

        return 0;
    }

    @computed get expectedKm() {
        return this.items.models.reduce((res, item) => res + item.expectedKm, 0);
    }

    @computed get expectedInvoicedKm() {
        return this.expectedKm;
    }

    @computed get expectedKmAmount() {
        return this.items.models.reduce((res, item) => res + item.expectedKmAmount, 0);
    }

    @computed get expectedKmSurcharge() {
        return this.items.models.reduce((res, item) => res + item.expectedKmSurcharge, 0);
    }

    @computed get expectedFixedAmount() {
        return this.items.models.reduce((res, item) => res + item.expectedFixedAmount, 0);
    }

    @computed get expectedFixedSurcharge() {
        return this.items.models.reduce((res, item) => res + item.expectedFixedSurcharge, 0);
    }

    @computed get expectedWeekendAmount() {
        return this.items.models.reduce((res, item) => res + item.expectedWeekendAmount, 0);
    }

    @computed get expectedWaitingAmount() {
        return this.items.models.reduce((res, item) => res + item.expectedWaitingAmount, 0);
    }

    @computed get expectedSecondDriverAmount() {
        return this.items.models.reduce((res, item) => res + item.expectedSecondDriverAmount, 0);
    }

    @computed get expectedOtherCostsAmount() {
        return this.items.models.reduce((res, item) => res + item.expectedOtherCostsAmount, 0);
    }

    @computed get expectedTollAmount() {
        return this.items.models.reduce((res, item) => res + item.expectedTollAmount, 0);
    }

    @computed get waivers() {
        return flatten(this.disputes.models.map(x=>x.waivers.models))
    }

    @computed get waivedInvoicedKm() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedInvoicedKm, 0);
    }

    @computed get waivedKmAmount() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedKmAmount, 0);
    }

    @computed get waivedKmSurcharge() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedKmSurcharge, 0);
    }

    @computed get waivedFixedAmount() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedFixedAmount, 0);
    }

    @computed get waivedFixedSurcharge() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedFixedSurcharge, 0);
    }

    @computed get waivedWeekendAmount() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedWeekendAmount, 0);
    }

    @computed get waivedWaitingAmount() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedWaitingAmount, 0);
    }

    @computed get waivedSecondDriverAmount() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedSecondDriverAmount, 0);
    }

    @computed get waivedOtherCostsAmount() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedOtherCostsAmount, 0);
    }

    @computed get waivedTollAmount() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedTollAmount, 0);
    }

    @computed get waivedKmAmountFc() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedKmAmountFc, 0);
    }

    @computed get waivedKmSurchargeFc() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedKmSurchargeFc, 0);
    }

    @computed get waivedFixedAmountFc() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedFixedAmountFc, 0);
    }

    @computed get waivedFixedSurchargeFc() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedFixedSurchargeFc, 0);
    }

    @computed get waivedWeekendAmountFc() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedWeekendAmountFc, 0);
    }

    @computed get waivedWaitingAmountFc() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedWaitingAmountFc, 0);
    }

    @computed get waivedSecondDriverAmountFc() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedSecondDriverAmountFc, 0);
    }

    @computed get waivedOtherCostsAmountFc() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedOtherCostsAmountFc, 0);
    }

    @computed get waivedTollAmountFc() {
        return this.waivers.reduce((res, waiver) => res + waiver.waivedTollAmountFc, 0);
    }

    @computed get corrections() {
        return flatten(this.disputes.models.map(x=>x.corrections.models))
    }

    @computed get correctedInvoicedKm() {
        return this.corrections.reduce((res, correction) => res + correction.correctedInvoicedKm, 0);
    }

    @computed get correctedKmAmount() {
        return this.corrections.reduce((res, correction) => res + correction.correctedKmAmount, 0);
    }

    @computed get correctedKmSurcharge() {
        return this.corrections.reduce((res, correction) => res + correction.correctedKmSurcharge, 0);
    }

    @computed get correctedFixedAmount() {
        return this.corrections.reduce((res, correction) => res + correction.correctedFixedAmount, 0);
    }

    @computed get correctedFixedSurcharge() {
        return this.corrections.reduce((res, correction) => res + correction.correctedFixedSurcharge, 0);
    }

    @computed get correctedWeekendAmount() {
        return this.corrections.reduce((res, correction) => res + correction.correctedWeekendAmount, 0);
    }

    @computed get correctedWaitingAmount() {
        return this.corrections.reduce((res, correction) => res + correction.correctedWaitingAmount, 0);
    }

    @computed get correctedSecondDriverAmount() {
        return this.corrections.reduce((res, correction) => res + correction.correctedSecondDriverAmount, 0);
    }

    @computed get correctedOtherCostsAmount() {
        return this.corrections.reduce((res, correction) => res + correction.correctedOtherCostsAmount, 0);
    }

    @computed get correctedTollAmount() {
        return this.corrections.reduce((res, correction) => res + correction.correctedTollAmount, 0);
    }

    @computed get correctedKmAmountFc() {
        return this.corrections.reduce((res, correction) => res + correction.correctedKmAmountFc, 0);
    }

    @computed get correctedKmSurchargeFc() {
        return this.corrections.reduce((res, correction) => res + correction.correctedKmSurchargeFc, 0);
    }

    @computed get correctedFixedAmountFc() {
        return this.corrections.reduce((res, correction) => res + correction.correctedFixedAmountFc, 0);
    }

    @computed get correctedFixedSurchargeFc() {
        return this.corrections.reduce((res, correction) => res + correction.correctedFixedSurchargeFc, 0);
    }

    @computed get correctedWeekendAmountFc() {
        return this.corrections.reduce((res, correction) => res + correction.correctedWeekendAmountFc, 0);
    }

    @computed get correctedWaitingAmountFc() {
        return this.corrections.reduce((res, correction) => res + correction.correctedWaitingAmountFc, 0);
    }

    @computed get correctedSecondDriverAmountFc() {
        return this.corrections.reduce((res, correction) => res + correction.correctedSecondDriverAmountFc, 0);
    }

    @computed get correctedOtherCostsAmountFc() {
        return this.corrections.reduce((res, correction) => res + correction.correctedOtherCostsAmountFc, 0);
    }

    @computed get correctedTollAmountFc() {
        return this.corrections.reduce((res, correction) => res + correction.correctedTollAmountFc, 0);
    }




    calcExpectedKmAmount(tariff) {
        const kmRate = tariff.getKmRate(this.activities);

        if (kmRate) {
            if (!kmRate.fixed) {
                return this.activities.reduce(
                    (res, activity) => res + kmRate.calcKmAmountForActivity(activity),
                    0
                );
            } else if (kmRate.fixed && this.activities.length > 0) {
                return this.activities.reduce(
                    (res, activity) => res + kmRate.calcKmAmountForActivity(activity),
                    0
                ) / this.activities.length;
            }
        }

        return 0;
    }

    calcExpectedKmSurcharge(tariff) {
        const kmRate = tariff.getKmRate(this.activities);

        if (kmRate) {
            if (!kmRate.fixed) {
                return this.activities.reduce(
                    (res, activity) => res + kmRate.calcSurchargeForActivity(activity),
                    0
                );
            } else if (kmRate.fixed && this.activities.length > 0) {
                return this.activities.reduce(
                    (res, activity) => res + kmRate.calcSurchargeForActivity(activity),
                    0
                ) / this.activities.length;
            }
        }

        return 0;
    }

    isKmSurchargeDisputed(tariff) {
        return Math.round(this.calcExpectedKmSurcharge(tariff)) !== this.kmSurcharge;
    }

    calcKmSurcharge(tariff, amount) {
        const kmRate = tariff.getKmRate(this.activities);

        if (kmRate) {
            return kmRate.calcSurcharge(amount);
        }

        return 0;
    }

    isKmAmountDisputed(tariff) {
        return Math.round(this.calcExpectedKmAmount(tariff)) !== this.kmAmount;
    }

    calcExpectedTollAmount(tariff) {
        const rate =  tariff && tariff.contract && tariff.contract.isFc
            ? (this.currency && this.currency.rate)
            : 1

        return this.activities.reduce(
            (res, activity) => res + tariff.calcTollAmount(activity, rate),
            0
        );
    }

    isTollAmountDisputed(tariff) {
        return Math.round(this.calcExpectedTollAmount(tariff)) !== this.tollAmount;
    }

    calcExpectedFixedAmount(tariff) {
        const fixedRate = tariff.getFixedRate(this.activities);

        if (fixedRate) {
            return this.activities.reduce(
                (res, activity) => res + fixedRate.calcFixedAmountForActivity(activity, this.activities),
                0
            );
        }

        return 0;
    }

    calcExpectedFixedSurcharge(tariff) {
        const fixedRate = tariff.getFixedRate(this.activities);

        if (fixedRate) {
            return this.activities.reduce(
                (res, activity) => res + fixedRate.calcSurchargeForActivity(activity),
                0
            );
        }

        return 0;
    }

    isFixedSurchargeDisputed(tariff) {
        return Math.round(this.calcExpectedFixedSurcharge(tariff)) !== this.fixedSurcharge;
    }

    calcFixedSurcharge(tariff, amount) {
        const fixedRate = tariff.getFixedRate(this.activities);

        if (fixedRate) {
            return fixedRate.calcSurcharge(amount);
        }

        return 0;
    }

    /**
     * Check whether fixedAmount equals to expected fixedAmount.
     */
    isFixedAmountDisputed(tariff) {
        return Math.round(this.calcExpectedFixedAmount(tariff)) !== this.fixedAmount;
    }

    calcExpectedWeekendAmount(tariff) {
        const weekendRate = tariff.getWeekendRate(this.activities);

        if (weekendRate) {
            return this.activities.reduce(
                (res, activity) => res + weekendRate.calcPriceForActivity(activity),
                0
            );
        }

        return 0;
    }

    /**
     * Check whether weekendAmount equals to expected weekendAmount.
     */
    isWeekendAmountDisputed(tariff) {
        return Math.round(this.calcExpectedWeekendAmount(tariff)) !== this.weekendAmount;
    }

    calcExpectedOtherCostsAmount(tariff) {
        return this.activities.reduce(
            (res, activity) => res + activity.calcOtherCostsAmount(),
            0
        );
    }

    isOtherCostsAmountDisputed(tariff) {
        return Math.round(this.calcExpectedOtherCostsAmount(tariff)) !== this.otherCostsAmount;
    }

    calcApprovedWaitingAmount(tariff) {
        const waitingRate = tariff.getWaitingRate(this.activities);

        if (waitingRate) {
            return this.activities.reduce((res, activity) =>
                res + waitingRate.calcApprovedWaitingAmount(activity),
                0
            );
        }

        return 0;
    }

    calcExpectedWaitingAmount(tariff) {
        const waitingRate = tariff.getWaitingRate(this.activities);

        if (waitingRate) {
            return this.activities.reduce(
                (res, activity) =>
                    res + waitingRate.calcPriceForActivity(activity),
                0
            );
        }

        return 0;
    }

    isWaitingAmountDisputed(tariff) {
        return Math.round(this.calcApprovedWaitingAmount(tariff)) !== this.waitingAmount
    }

    calcExpectedSecondDriverAmount(tariff) {
        const secondDriverRate = tariff.getSecondDriverRate(this.activities);

        if (secondDriverRate) {
            return this.activities.reduce(
                (res, activity) =>
                    res + secondDriverRate.calcPriceForActivity(activity),
                0
            );
        }

        return 0;
    }

    isSecondDriverAmountDisputed(tariff) {
        return Math.round(this.calcExpectedSecondDriverAmount(tariff)) !== this.secondDriverAmount;
    }

    // {copy-pasta-activities}
    @computed
    get activities() {
        return this.items.map(i => i.activity);
    }

    @computed
    get kmSurchargeExplanation() {
        const currencySymbol = getCurrencySymbolFromCode((this.currency && this.currency.type) ? this.currency.type : OWN_CURRENCY )
        return `Fuel Surcharge: ${currencySymbol}${getMoneyForUser(this.kmSurcharge)}`;
    }

    @computed
    get fixedSurchargeExplanation() {
        const currencySymbol = getCurrencySymbolFromCode((this.currency && this.currency.type) ? this.currency.type : OWN_CURRENCY )
        return `Fixed Surcharge: ${currencySymbol}${getMoneyForUser(this.fixedSurcharge)}`;
    }

    calcExpectedAmount(tariff) {
        return (
            this.calcExpectedKmAmount(tariff) +
            this.calcExpectedKmSurcharge(tariff) +
            this.calcExpectedFixedAmount(tariff) +
            this.calcExpectedFixedSurcharge(tariff) +
            this.calcExpectedWeekendAmount(tariff) +
            this.calcApprovedWaitingAmount(tariff) +
            this.calcExpectedOtherCostsAmount(tariff) +
            this.calcExpectedSecondDriverAmount(tariff) +
            this.calcExpectedTollAmount(tariff)
        );
    }

    @computed
    get expectedAmount() {
        return this.items.models.reduce((res, item) => {
            return res + item.expectedAmount;
        }, 0);
    }

    @computed
    get waivedAmount() {
        return this.waivers.reduce((res, waiver) => {
            return res + waiver.waivedAmount;
        }, 0);
    }

    @computed
    get waivedAmountFc() {
        return this.waivers.reduce((res, waiver) => {
            return res + waiver.waivedAmountFc;
        }, 0);
    }

    @computed
    get correctedAmount() {
        return this.corrections.reduce((res, correction) => {
            return res + correction.correctedAmount;
        }, 0);
    }
    @computed
    get correctedAmountFc() {
        return this.corrections.reduce((res, correction) => {
            return res + correction.correctedAmountFc;
        }, 0);
    }

    @computed
    get amount() {
        if (this.customLine) {
            return this.customAmount;
        }

        return (
            this.kmAmount +
            this.kmSurcharge +
            this.fixedAmount +
            this.fixedSurcharge +
            this.weekendAmount +
            this.waitingAmount +
            this.otherCostsAmount +
            this.secondDriverAmount +
            this.tollAmount
        );
    }

    @computed
    get amountFc() {
        if (this.customLine) {
            return this.customAmountFc;
        }

        return (
            this.kmAmountFc +
            this.kmSurchargeFc +
            this.fixedAmountFc +
            this.fixedSurchargeFc +
            this.weekendAmountFc +
            this.waitingAmountFc +
            this.otherCostsAmountFc +
            this.secondDriverAmountFc +
            this.tollAmountFc
        );
    }

    activityRatio(activity) {
        let total = 0;

        this.items.models.forEach(item => {
            total = total + item.activity.givenKm;
        });

        if (total > 0) {
            return (activity.givenKm / total) * 100;
        }

        return 0;
    }

    isAmountDisputed(tariff, isCredit = false) {
        const expectedAmount = Math.round(this.calcExpectedAmount(tariff));
        const amount = this.amountFc ? this.amountFc : this.amount

        if (isCredit) {
            return -expectedAmount !== amount;
        }

        return expectedAmount !== amount;
    }

    /**
     * Debug invoiceline to check if the sum of item.amount is the same as the
     * invoiceline.amount.
     */
    @computed
    get hasIssues() {
        return this.amount !== this.items.models.reduce((res, i) => res + i.total, 0) ||
            this.invoicedKm !== this.items.models.reduce((res, i) => res + i.invoicedKm, 0);
    }
}

class UnorderedInvoiceLineStore extends Store {
    Model = InvoiceLine;

    static backendResourceName = 'invoice_line';
}

export const InvoiceLineStore = OrderedStore(UnorderedInvoiceLineStore, 'ordering');
