import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Polygon } from 'react-leaflet';
import { RestrictedZone } from 'store/RestrictedZone';
import { renderToString } from 'react-dom/server';

export const RESTRICTED_ZONE_FILL_OPACITY = '0.08';

@observer
class RestrictedZonePolygon extends Component {
    static propTypes = {
        restrictedZone: PropTypes.instanceOf(RestrictedZone).isRequired,
        positions: PropTypes.array.isRequired,
    };

    @observable polygonRef = null;
    @observable lastMousePosition = null;

    renderPopupContent() {
        const { restrictedZone } = this.props;
        return (
            <span>
                <b>Banned Reason: {restrictedZone.bannedReason}</b><br />
                Remark: {restrictedZone.bannedRemark}
            </span>
        )
    }

    handlePopupDisplay = (latlng) => {
        let mouseposition = latlng === this.lastMousePosition ? latlng : this.lastMousePosition
        const popupContent = renderToString(this.renderPopupContent());
        if (this.polygonRef) {
            this.polygonRef.bindPopup(popupContent).openPopup(mouseposition);
        }
    }

    handleMouseMove = (e) => {
        this.lastMousePosition = e.latlng;
    };

    render() {
        const { positions } = this.props;

        return (
            <Polygon
                zIndexOffset={1}
                pathOptions={{color:'red', fillOpacity: RESTRICTED_ZONE_FILL_OPACITY}}
                stroke={false}
                positions={positions}
                eventHandlers={{
                    mouseover: (e) => {
                        this.mouseOverTimeout = setTimeout(() => {
                            this.handlePopupDisplay(e.latlng);
                        }, 3000)
                    },
                    mouseout: () => {
                        clearTimeout(this.mouseOverTimeout);
                        this.polygonRef.closePopup().unbindPopup();
                    },
                    mousemove: (e) => this.handleMouseMove(e),
                }}
                ref={(ref) => this.polygonRef = ref}
            />
        )
    }
}

export default RestrictedZonePolygon;
