import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Entity } from 'store/Entity';
import { CostProduct } from 'store/CostProduct';
import { CodeReportStore, CodeReport } from 'store/CodeReport';
import { SERVER_DATE_FORMAT } from 'helpers';

export class LedgerCost extends Model {
    static backendResourceName = 'ledger_cost';

    @observable id = null;
    @observable ledgerCodeAccounting = '';
    @observable nonTaxLedgerCodeAccounting = '';
    @observable ledgerCodeReporting = '';
    @observable assetTruckCategory = '';

    relations() {
        return {
            entity: Entity,
            costProduct: CostProduct,
            codeReports: CodeReportStore,
            currentCodeReport: CodeReport,
        };
    }

    getLedgerCostText(haveReceipt, transactionDatetime) {
        let c = null

        if (transactionDatetime && this.codeReports){
            c = this.codeReports.find(report => report.startDate.format(SERVER_DATE_FORMAT).slice(0, 4) === transactionDatetime.format(SERVER_DATE_FORMAT).slice(0, 4))
        }

        if(haveReceipt) {
            return `${this.nonTaxLedgerCodeAccounting} - ${c ? c.codeReporting : ''} - ${this.assetTruckCategory}`
        } else {
            return `${this.ledgerCodeAccounting} - ${c ? c.codeReporting : ''} - ${this.assetTruckCategory}`
        }
    }
}

export class LedgerCostStore extends Store {
    static backendResourceName = 'ledger_cost';

    Model = LedgerCost;
}
