import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Model } from 'mobx-spine';
import { action, reaction, observable } from 'mobx';
import { observer } from 'mobx-react';
import { NumberInput as BaseNumberInput } from 're-cy-cle';
import { getMoneyInputProps, setMoneyForBackend, formatMoney as defaultFormatMoney } from '../helpers';
import styled from 'styled-components';

const StyledNumberInput = styled(BaseNumberInput)`
    text-align: ${props => props.alignRight ? 'right' : 'left'};
`;

@observer
export default class MoneyInput extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        alignRight: PropTypes.bool,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        afterChange: PropTypes.func,
        decimalLimit: PropTypes.number,
        formatMoney: PropTypes.func,
        setInput: PropTypes.func,
    };

    static defaultProps = {
        formatMoney: defaultFormatMoney,
        decimalLimit: 2,
    }

    @observable amount = '';
    @observable ignoreReaction = false;

    componentDidMount() {
        const { model, name } = this.props;

        this.initAmount();

        this.reaction = reaction(
            () => model[name],
            () => this.initAmount(),
        );

    }

    UNSAFE_componentWillUnmount() {
        this.reaction();
    }

    initAmount() {
        const { model, name, formatMoney, decimalLimit } = this.props;

        this.amount = formatMoney(model[name], decimalLimit);

        if (this.amount === '0,00' || this.amount === '0' || this.amount === '€0,00') {
            this.amount = ''
        }

        this.ignoreReaction = false;
    }


    @action
    updateModel(name, value) {
        const { model, afterChange, formatMoney, decimalLimit } = this.props;

        this.setInput(name, setMoneyForBackend(value, decimalLimit));
        this.amount = model[name] !== 0 ? formatMoney(model[name], decimalLimit) : '';
        this.ignoreReaction = true;

        if (afterChange) {
            afterChange(name, value);
        }
    };

    setInput(name, val) {
        const { model, setInput } = this.props;

        if (setInput) {
            setInput(name, val);
        } else {
            model.setInput(name, val);
        }
    }

    setLocal = (name, value) => {
        this[name] = value;
    };

    render() {
        const { name, disabled, decimalLimit } = this.props;

        return (
            <StyledNumberInput
                autoComplete="off"
                allowNegative
                disabled={disabled}
                name={name}
                placeholder="€0,00"
                value={this.amount}
                onChange={this.setLocal}
                onBlur={this.updateModel.bind(this)}
                decimalLimit={decimalLimit}
                {...getMoneyInputProps()}
                {...this.props}
            />
        );
    }
}
