import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ClaimActionStore, ACTIONS } from 'store/Claim/Action';
import { Accordion, Button } from 're-cy-cle';
import { TargetTextArea, TargetSelect, TargetDatePicker } from 'spider/semantic-ui/Target';
import { IconDelete } from 're-cy-cle';
import { format } from 'helpers/date';
import { DATE_FORMAT } from 'helpers';
import { camelCase } from 'lodash';
import RightDivider from 'spider/component/RightDivider';
import { AddButton } from 'spider/semantic-ui/Button';
import HeaderRight from 'spider/semantic-ui/HeaderRight';
import moment from 'moment';

@observer
export default class ClaimActionList extends Component {
    static propTypes = {
        claimActionStore: PropTypes.instanceOf(ClaimActionStore).isRequired,
    };

    @observable openItems = [];

    accordionChange = value => {
        if (this.openItems.includes(value)) {
            this.openItems.clear();
        } else {
            this.openItems.clear();
            this.openItems.push(value);
        }
    };

    renderClaimAction = claimAction => {
        return (
            <Accordion data-test-claim-status-change
                key={claimAction.cid}
                title={(
                    <div data-test-claim-status-change style={{ display: 'flex' }}>
                        <div data-test-status>{t(`claimAction.field.action.value.${camelCase(claimAction.action)}`)}</div>
                        <RightDivider />
                        <div data-test-date>{format(claimAction.date, DATE_FORMAT)}</div>
                    </div>
                )}
                opened={this.openItems.includes(claimAction.cid)}
                onChange={() => this.accordionChange(claimAction.cid)}
                action={
                    <Button icon onClick={() => this.handleDelete(claimAction)}>
                        <IconDelete />
                    </Button>
                }
            >
                <TargetSelect required
                    target={claimAction}
                    name="action"
                    options={ACTIONS.map(action => ({
                        value: action,
                        text: t(`claimAction.field.action.value.${camelCase(action)}`),
                    }))}
                />
                <TargetDatePicker required includeWeeks
                    target={claimAction}
                    name="date"
                />
                <TargetTextArea
                    target={claimAction}
                    name="reason"
                />
            </Accordion>
        );
    };

    handleDelete = contract => {
        this.props.claimActionStore.remove(contract);
    };

    addClaimAction = () => {
        const model = this.props.claimActionStore.add({
            date: moment(),
        });

        this.accordionChange(model.cid);
    };

    render() {
        const { claimActionStore } = this.props;

        return (
            <React.Fragment>
                <HeaderRight content={t('claimAction.listEditTitle')}>
                    <AddButton data-test-add-claim-action-button compact primary
                        onClick={this.addClaimAction}
                    />
                </HeaderRight>
                {claimActionStore.map(this.renderClaimAction)}
            </React.Fragment>
        );
    }
}
