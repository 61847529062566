import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestion } from './Question';
import { FormQuestionChoiceOptionStore } from './QuestionChoiceOption';

export class FormQuestionChoice extends Model {
    static backendResourceName = 'form_question_choice';

    @observable id = null;
    @observable allowMultiple = false;

    relations() {
        return {
            formQuestion: FormQuestion,
            options: FormQuestionChoiceOptionStore,
        };
    }
}

export class FormQuestionChoiceStore extends Store {
    Model = FormQuestionChoice;
    static backendResourceName = 'form_question_choice';
}
