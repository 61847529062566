import { observable, computed } from 'mobx';
import { Model, Store } from './Base';
import { Activity } from './Activity';
import { InvoiceLineCorrection } from './InvoiceLineCorrection';

export class InvoiceLineCorrectionItem extends Model {
    static backendResourceName = 'invoice_line_correction_item';

    @observable id = null;
    @observable correctedInvoicedKm = 0;
    @observable correctedWaitingAmount = 0;
    @observable correctedWeekendAmount = 0;
    @observable correctedOtherCostsAmount = 0;
    @observable correctedKmAmount = 0;
    @observable correctedKmSurcharge = 0;
    @observable correctedFixedAmount = 0;
    @observable correctedFixedSurcharge = 0;
    @observable correctedSecondDriverAmount = 0;
    @observable correctedCustomAmount = 0;
    @observable correctedTollAmount = 0;

    relations() {
        return {
            activity: Activity,
            invoiceLineCorrection: InvoiceLineCorrection
        };
    }

    // {combined-surcharge}
    // kmSurcharge and fixedSurcharged are inputted using 1 input. So the user
    // inputs into _surcharge and the system splits it out in kmSurcharge and
    // fixedSurcharge.
    @observable _surcharge = 0;
    parse(data) {
        const result = super.parse(data);

        this.updateCachedSurcharge();

        return result;
    }

    updateCachedSurcharge() {
        this._surcharge = this.correctedKmSurcharge + this.correctedFixedSurcharge;
    }

    distributeSurcharge(invoiceLine) {
        const expectedKmSurcharge = invoiceLine.expectedKmSurcharge;
        const expectedFixedSurcharge = invoiceLine.expectedFixedSurcharge;
        const amount = this._surcharge;

        let kmSurcharge = 0;

        if (expectedKmSurcharge + expectedFixedSurcharge !== 0) {
            kmSurcharge = Math.round(amount * expectedKmSurcharge / (expectedKmSurcharge + expectedFixedSurcharge));
        }

        let fixedSurcharge = 0;

        if (expectedKmSurcharge + expectedFixedSurcharge !== 0) {
            fixedSurcharge = Math.round(amount * expectedFixedSurcharge / (expectedKmSurcharge + expectedFixedSurcharge));
        }

        const rounding = amount - kmSurcharge - fixedSurcharge;

        this.setInput('correctedKmSurcharge', kmSurcharge + rounding);
        this.setInput('correctedFixedSurcharge', fixedSurcharge);
    }

    convertToCredit() {
        this.setInput('id', null);
        this.setInput('correctedInvoicedKm', -this.correctedInvoicedKm);
        this.setInput('correctedWaitingAmount', -this.correctedWaitingAmount);
        this.setInput('correctedWeekendAmount', -this.correctedWeekendAmount);
        this.setInput('correctedOtherCostsAmount', -this.correctedOtherCostsAmount);
        this.setInput('correctedKmAmount', -this.correctedKmAmount);
        this.setInput('correctedKmSurcharge', -this.correctedKmSurcharge);
        this.setInput('correctedFixedAmount', -this.correctedFixedAmount);
        this.setInput('correctedFixedSurcharge', -this.correctedFixedSurcharge);
        this.setInput('correctedSecondDriverAmount', -this.correctedSecondDriverAmount);
        this.setInput('correctedCustomAmount', -this.correctedCustomAmount);
        this.setInput('correctedTollAmount', -this.correctedTollAmount);
    }

    @computed get hasCorrections() {
        return !!this.invoicedAmount || !!this.correctedInvoicedKm;
    }

    @computed get invoicedAmount() {
        return this.correctedWaitingAmount +
            this.correctedWeekendAmount +
            this.correctedOtherCostsAmount +
            this.correctedKmAmount +
            this.correctedKmSurcharge +
            this.correctedFixedAmount +
            this.correctedFixedSurcharge +
            this.correctedSecondDriverAmount +
            this.correctedCustomAmount +
            this.correctedTollAmount;
    }

    @computed get amount() {
        return this.invoicedAmount;
    }

    @computed get correctedAmount() {
        return this.invoicedAmount;
    }
}

export class InvoiceLineCorrectionItemStore extends Store {
    Model = InvoiceLineCorrectionItem;

    static backendResourceName = 'invoice_line_correction_item';
}
