import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestion } from './Question';

export class FormQuestionInteger extends Model {
    static backendResourceName = 'form_question_integer';

    @observable id = null;
    @observable minValue = null;
    @observable maxValue = null;
    @observable autoFill = null;

    relations() {
        return {
            formQuestion: FormQuestion,
        };
    }
}

export class FormQuestionIntegerStore extends Store {
    Model = FormQuestionInteger;
    static backendResourceName = 'form_question_integer';
}
