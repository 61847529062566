import React from 'react';

// Hacky helper to ease working with permissions.
let viewStore = null;

export function configurePermission(givenViewStore) {
    viewStore = givenViewStore;
}

/**
 * Deprecated, use hasHighLevelPermission.
 */
export function hasPermission(...perms){
   return viewStore.currentUser.hasPermission(...perms);
}

export function hasHighLevelPermission(...perms){
   return viewStore.currentUser.hasHighLevelPermission(...perms);
}

export function hasLowLevelPermission(...perms){
   return viewStore.currentUser.hasLowLevelPermission(...perms);
}

/**
 * Decorator to add currentUser to props. Example:
 *
 * @currentUser
 * @oberver
 * class TruckSendBulkMessageModal extenda Component {
 *     static propTypes = {
 *         currentUser: PropTypes.instanceOf(User).isRequired,
 *     }
 * }
 */
export function currentUser(Comp) {
    return React.forwardRef((props, ref) => <Comp {...props} ref={ref} currentUser={viewStore.currentUser} />);
}

export function getCurrentUser() {
    return viewStore.currentUser;
}
