import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { CalculationRule } from './CalculationRule';
import { CalculationRuleDescription } from './CalculationRuleDescription';
import { DietRule } from './DietRule';
import { Driver } from './Driver';
import { DriverStatusChange } from './Driver/StatusChange';
import { storeWithHistory, modelWithHistory } from './History';

export class DriverCalculationRuleDescriptionSalary extends Model {
    static backendResourceName = 'driver_calculation_rule_description_salary';

    @observable id = null;
    @observable date = null;
    @observable amount = null;
    @observable approved = false;
    @observable approved = false;

    casts() {
        return {
            date: Casts.date,
        };
    }

    relations() {
        return {
            calculationRule: CalculationRule,
            driver: Driver,
            driverStatusChange: DriverStatusChange,
            calculationRuleDescription: CalculationRuleDescription,
            dietRule: DietRule,
        };
    }
}

export class DriverCalculationRuleDescriptionSalaryStore extends Store {
    Model = DriverCalculationRuleDescriptionSalary;
    static backendResourceName = 'driver_calculation_rule_description_salary';
}

export class HistoryDriverCalculationRuleDescriptionSalaryStore extends storeWithHistory(DriverCalculationRuleDescriptionSalaryStore){};
export class HistoryDriverCalculationRuleDescriptionSalary extends modelWithHistory(DriverCalculationRuleDescriptionSalary){};
