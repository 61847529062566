import { observable, action } from 'mobx';
import { Model, Store } from 'store/Base';

export class DriverLanguage extends Model {
    static backendResourceName = 'driver_language';

    @observable id = null;
    @observable language = '';
    @observable level = 1;
}

export class DriverLanguageStore extends Store {
    Model = DriverLanguage;
    static backendResourceName = 'driver_language';

    @action
    fetchLanguages() {
        return this.api.get('/driver_language/languages/').then(res => {
            return res.data.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }

                if (a.name > b.name) {
                    return 1;
                }

                return 0;
            });
        });
    }
}
