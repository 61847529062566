import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Trailer } from './Trailer';
import { EquipmentType } from './EquipmentType';

export class TrailerEquipment extends Model {
    static backendResourceName = 'trailer_equipment';

    @observable id = null;
    @observable quantity = 0;

    relations() {
        return {
            trailer: Trailer,
            equipmentType: EquipmentType,
        };
    }
}

export class TrailerEquipmentsStore extends Store {
    Model = TrailerEquipment;
    static backendResourceName = 'trailer_equipment';
}
