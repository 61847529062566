import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Icon from 'image/exact-status-icon.png';
import { NavLink } from 'react-router-dom';
import { StyledImg } from 'component/ExactOnlineIcon'

const StyledNavLink = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0 10px;
    svg,
    img {
        width: 20px;
        height: 20px;
    }
`;

@observer
export default class ExactOnlineAuth extends Component {
    static propTypes = {
        title: PropTypes.string,
        to: PropTypes.string,
    };


    render() {
    	const { title, to } = this.props;

        return <StyledNavLink to={to} target="_blank"><StyledImg src={Icon} title={title}/></StyledNavLink>;

    }
}

