import styled from 'styled-components';

function getBgColor(props) {
    if (props.needsAttention) {
        return '#ff4545';
    }
    if (props.danger) {
        return '#e3c3c3';
    }
    if (props.success) {
        return '#c8e4c8';
    }
    if (props.warning) {
        // {warning-color-copy-pasta}
        return 'rgba(253, 153, 39, 0.56)';
    }

    if (props.selected) {
        return '#fbdba7';
    }
    if (props.update) {
        return '#adb5e8';
    }

    if (props.needsRefueling) {
        return '#e7a7fb';
    }
    return '#e3ecf3';
}

export const Row = styled.div`
    margin-bottom: 4px;
    background-color: ${getBgColor};
    border-left: 1px solid #bacedf;
    border-top: 1px solid #bacedf;
    display: flex;
    font-size: 12px;

    ${props => props.onClick && `
        cursor: pointer;
    `};

    ${props => props.height
        ? `height: ${props.height};`
        : 'height: 46px'
    };
`;

export const RowWrapper = styled.div`
    position: relative;
`;

// Unfortunately this is necessary because when you click on a row, we use `scrollIntoView()`
// But on top of the row we show the row headers with `position: sticky;`, so it actually needs to scroll
// into the view minus 25 pixels
export const RowScrollHijack = styled.div`
    position: absolute;
    top: -25px;
`;
