import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { EquipmentType } from './EquipmentType';

export class AllocationEquipment extends Model {
    static backendResourceName = 'allocation_equipment';

    @observable id = null;
    @observable quantity = 0;

    relations() {
        return {
            equipmentType: EquipmentType,
        };
    }
}

export class AllocationEquipmentStore extends Store {
    Model = AllocationEquipment;
    static backendResourceName = 'allocation_equipment';
}
