import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Text, Button } from 're-cy-cle';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    font-size: 14px;
`;

@observer
export default class ActivityFinalizeTruck extends Component {
    static propTypes = {
        consecutiveTrucks: PropTypes.array.isRequired,
        onClickEndKm: PropTypes.func.isRequired,
    };

    handleEndKmClick = truck => {
        this.props.onClickEndKm(truck);
    };

    render() {
        const { consecutiveTrucks } = this.props;

        if (consecutiveTrucks.length < 1) {
            return <Text tone="danger">{t('planning.activityFinalize.noTrucksFound')}</Text>;
        }

        return (
            <Table celled compact definition>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>{t('planning.activityFinalize.truckHeadings.truck')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('planning.activityFinalize.truckHeadings.trailer')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('planning.activityFinalize.truckHeadings.driver')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('activity.field.startKm.label')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('activity.field.endKm.label')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('activity.field.drivenKm.label')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {consecutiveTrucks.map(consecutiveTruck => (
                        <Table.Row data-test-activity-finalize-truck-row key={consecutiveTruck.cid}>
                            <Table.Cell data-test-license-plate collapsing>{consecutiveTruck.truck.licensePlate}</Table.Cell>
                            <Table.Cell data-test-trailer-license-plates>{consecutiveTruck.trailers.map((t) => t.licensePlate).join(', ') }</Table.Cell>
                            <Table.Cell data-test-driver-names>{consecutiveTruck.driver1.name + (consecutiveTruck.driver2 && consecutiveTruck.driver2.id ? (' / ' + consecutiveTruck.driver2.name) : '')}</Table.Cell>

                            <Table.Cell data-test-start-km>{consecutiveTruck.startKm || 0}</Table.Cell>
                            <Table.Cell data-test-end-km>
                                <StyledButton icon data-test-show-change-km-modal
                                    onClick={() => this.handleEndKmClick(consecutiveTruck)}
                                >
                                    {consecutiveTruck.endKm || 0}
                                </StyledButton>
                            </Table.Cell>
                            <Table.Cell>{consecutiveTruck.drivenKm}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    }
}
