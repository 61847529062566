import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { ContractType } from './Contract'

export class DriverExperience extends Model {
    static backendResourceName = 'driver_experience';

    @observable id = null;
    @observable kmDriven = 0;

    relations() {
        return {
            contractType: ContractType,
        };
    }
}

export class DriverExperienceStore extends Store {
    Model = DriverExperience;
    static backendResourceName = 'driver_experience';
}
