import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Activity } from './Activity';

export class FixedRateDay extends Model {
    static backendResourceName = 'fixed_rate_day';

    @observable id = null;
    @observable day = null;

    relations() {
        return {
            activity: Activity,
        }
    }

    casts() {
        return {
            day: Casts.date,
        };
    }
}

export class FixedRateDayStore extends Store {
    Model = FixedRateDay;
    static backendResourceName = 'fixed_rate_day';
}
