import React from 'react';
import { Icon } from 'semantic-ui-react';
import { observable, action, toJS, computed } from 'mobx';
import { Model as BModel, Store as BStore, BinderApi, Casts as BCasts } from 'mobx-spine';
import { uniq, mapKeys, mapValues, get } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { snakeToCamel, camelToSnake, FRONTEND_API_BASE_URL } from 'helpers';
import { addRequestQueueMiddleware } from './Base/requestQueueMiddleware';

export const DATA_SOURCE_FLEETVISOR = 'fleetvisor';
export const DATA_SOURCE_FLEETBOARD = 'fleetboard';
export const DATA_SOURCE_TRANSICS = 'transics';
export const DATA_SOURCE_MOBILE_OFFICE = 'mobile office';
export const DATA_SOURCES = [DATA_SOURCE_FLEETVISOR, DATA_SOURCE_TRANSICS, DATA_SOURCE_MOBILE_OFFICE, DATA_SOURCE_FLEETBOARD];


function isImage(type) {
    return type && type.split('/')[0] === 'image';
}

/**
 * Call options.registerCancelToken and return options extended with the cancelToken.
 *
 * Works together with component/Component.
 */
export function parseRegisterCancelToken(options) {
    const extraOptions = {};

    if (options.registerCancelToken) {
        extraOptions['cancelToken'] = options.registerCancelToken();
    }

    return {
        ...options,
        ...extraOptions,
    };
}

class MyApi extends BinderApi {
    baseUrl = FRONTEND_API_BASE_URL;
    @observable socket;
}

const myApi = new MyApi();

export class FileField {
    // Filled by server.
    @observable name = null;
    @observable url = null;

    // Filled by user, when file is selected for uploading.
    @observable _blob = null;
    @observable _deleted = false;

    @observable _isLoading = false;

    constructor(options = {}) {
        this.name = options.name;
        this.url = options.url;
    }

    @computed get
    isEmpty() {
        return !(this.url || this._blob);
    }

    @computed get
    isLoading() {
        return this._isLoading;
    }

    @computed get
    type() {
        if (!this._deleted && this.url) {
            const urlSearchParam = new URLSearchParams(this.url);

            return urlSearchParam.get('content_type');
        }

        return null;
    }

    @computed get
    downloadUrl() {
        if (!this._deleted && this.url) {
            return this.url + '&download=true';
        }

        return null;
    }

    @computed get
    icon() {
        if (this._deleted) {
            return null;
        }

        const type = this._blob ? this._blob.type : this.type;

        if (isImage(type)) {
            return <Icon name="file image outline" />;
        }

        switch (type) {
            case 'application/pdf': return <Icon name="file pdf outline" />;
            default: return null;
        }
    }

    setInput(file) {
        this._blob = file;
    }

    markAsDeleted() {
        this._deleted = true;
        this.setInput(null);
    }

    wrapLoading(p) {
        this._isLoading = true;
        p.catch(() => {}).then(() => this._isLoading = false);

        return p;
    }

    save(baseUrl) {
        const backendName = camelToSnake(this.name);
        this._isLoading = true;

        if (this._deleted) {
            return this.wrapLoading(myApi.delete(`${baseUrl}${backendName}/`).then(() => {
                this.url = null;
                this._blob = null;
                this._deleted = false;
            }));
        }

        if (!this._blob) {
            return this.wrapLoading(Promise.resolve());
        }

        const data = new FormData();
        data.append(this.name, this._blob, this._blob.name);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        return this.wrapLoading(myApi.post(`${baseUrl}${backendName}/`, data, { headers }).then(response => {
            this.url = response.data[backendName];
            this._blob = null;
        }));
    }
}

// Stolen from mobx spine, since it's not exported.
export function parseBackendValidationErrors(response) {
    const valErrors = get(response, 'data.errors');
    if (response.status === 400 && valErrors) {
        return valErrors;
    }
    return null;
}

class BoekModel extends BModel {
    api = myApi;

    getAllFlattenedWildcardErrors() {
        let errors = this.getWildcardErrors();

        this.__activeCurrentRelations.forEach(attr => {
            errors = errors.concat(this[attr].getWildcardErrors());
        });
        return uniq(errors);
    }

    getWildcardErrors() {
        return toJS(this.backendValidationErrors['*']) || [];
    }

    // Overwrite to for better backend validation.
    @action
    parseValidationErrors(valErrors) {
        const bname = this.constructor.backendResourceName;

        if (valErrors[bname]) {
            const id = this.getInternalId();
            // When there is no id or negative id, the backend may use the string 'null'. Bit weird, but eh.
            const errorsForModel =
                valErrors[bname][id] || valErrors[bname]['null'];
            if (errorsForModel) {
                const camelCasedErrors = mapKeys(errorsForModel, (value, key) =>
                    snakeToCamel(key)
                );
                const formattedErrors = mapValues(
                    camelCasedErrors,
                    valError => {
                        return valError.map(obj => obj.message || obj.code); // Show backend message if available. T16564
                    }
                );
                this.__backendValidationErrors = formattedErrors;
            }
        }

        this.__activeCurrentRelations.forEach(currentRel => {
            this[currentRel].parseValidationErrors(valErrors);
        });
    }

    markChanged(...fields) {
        fields.forEach(field => {
            if (!this.__changes.includes(field)) {
                this.__changes.push(field);
            }
        });
    }

    unmarkChanged(...fields) {
        fields.forEach(field => {
            if (this.__changes.includes(field)) {
                this.__changes.remove(field);
            }
        });
    }

    _cancelToken = null;

    @action
    fetch(options = {}) {
        // Sometimes only 1 fetch is allowed to be "in flight". If
        // cancelPreviousFetch = true, the previous fetch will be cancelled.
        //
        // TODO: figure out if maybe by default this should be true.
        if (options.cancelPreviousFetch) {
            if (this._cancelToken) {
                this._cancelToken();
            }

            options.cancelToken = new axios.CancelToken(c => {
                this._cancelToken = c;
            })
        }

        return super.fetch(parseRegisterCancelToken(options));
    }

    toBackend(...args) {
        const result = super.toBackend(...args);

        return result;
    }
}


export class BoekStore extends BStore {
    api = myApi;

    getWildcardErrors() {
        let errors = [];
        this.models.forEach(model => {
            errors = errors.concat(model.getWildcardErrors());
        });
        return errors;
    }

    @action
    fetch(options = {}) {
        return super.fetch(parseRegisterCancelToken(options));
    }
}

export const api = myApi;
export const Model = addRequestQueueMiddleware(BoekModel)
export const Store = addRequestQueueMiddleware(BoekStore)

export const Casts = {
    ...BCasts,
    point: {
        parse(attr, value) {
            if (!value) {
                return { lat: '', lng: '' };
            }

            return value;
        },
        toJS(attr, value) {
            if (!value) {
                return { lat: '', lng: '' };
            }

            return value;
        },
    },
    decimal: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }
            return value.replace(/,/g, '').replace('.', ',');
        },
        toJS(attr, value) {
            if (value === null || value === '') {
                return null;
            }
            return value.replace(/\./g, '').replace(',', '.');
        },
    },
    durationMinutes: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }
            return moment.duration(value, 'minutes');
        },
        toJS(attr, value) {
            if (value === null) {
                return null;
            }
            // https://github.com/CodeYellowBV/mobx-spine/issues/57
            if (value === 0) {
                return 0;
            }
            return value.asMinutes();
        },
    },
    duration: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }

            return moment.duration(value);
        },
        toJS(attr, value) {
            if (value === null) {
                return null;
            }

            return value.toISOString();
        },
    },
    file: {
        parse(attr, value) {
            return new FileField({ name: attr, url: value });
        },
        toJS(attr, value) {
            return value.url;
        },
    },
};

export function subscribe(room, callback) {
    // Normally api.socket is defined, but in frontend unit tests this might not
    // be defined. Add a guard, so these tests will not break.
    if (!api.socket) {
        return {
            unsubscribe: () => {}
        };
    }

    const result = api.socket.subscribe({
        onPublish: callback,
        room,
    });

    result.unsubscribe = function () {
        // Fix a rare bug where cypress switches to another view before we are
        // subscribed to this view which sometimes causes cypress to crash
        if (result !== undefined) {
            api.socket.unsubscribe(result);
        }
    }

    return result;
}

export function OrderedStore(UnorderedStore, orderingField) {
    return class extends UnorderedStore {
        comparator = orderingField

        _newModel(model = null, i) {
            const m = super._newModel(model);

            // if (!(orderingField in model)) {
                if (this.length > 0) {
                    const nextOrdering = Math.max(...this.map(aModel => aModel[orderingField])) + 1;
                    m[orderingField] = isFinite(nextOrdering) ? nextOrdering : this.length + 1;
                } else {
                    m[orderingField] = i;
                }
            // }

            return m;
        }

        moveUp(model) {
            const prevIndex = this.models.indexOf(model) - 1;

            if (prevIndex >= 0) {
                const prev = this.at(this.models.indexOf(model) - 1);

                const prevOrdering = prev.ordering;
                prev.setInput('ordering', model.ordering);
                model.setInput('ordering', prevOrdering);
                this.sort();
            }
        }

        moveDown(model) {
            const nextIndex = this.models.indexOf(model) + 1;

            if (nextIndex < this.length) {
                const next = this.at(this.models.indexOf(model) + 1);

                const nextOrdering = next.ordering;
                next.setInput('ordering', model.ordering);
                model.setInput('ordering', nextOrdering);
                this.sort();
            }
        }
    };
}
