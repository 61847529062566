import { observable } from 'mobx';
import { Model, Store, Casts } from '../Base';
import { Claim } from '../Claim';
import { User } from '../User';

export const ACTION_INFORMED_INSURANCE = 'informed insurance';
export const ACTION_SENT_DOCUMENTS = 'sent documents';
export const ACTION_ESTIMATE_CONFIRMED = 'estimate confirmed';
export const ACTION_AMOUNTS_CONFIRMED = 'amounts confirmed';
export const ACTION_RECEIVED_PAYMENT = 'received payment';
export const ACTION_FINISHED_WITHOUT_PAYMENT = 'finished without payment';

export const ACTIONS = [ACTION_INFORMED_INSURANCE, ACTION_SENT_DOCUMENTS, ACTION_ESTIMATE_CONFIRMED, ACTION_AMOUNTS_CONFIRMED, ACTION_RECEIVED_PAYMENT, ACTION_FINISHED_WITHOUT_PAYMENT];

export class ClaimAction extends Model {
    static backendResourceName = 'claim_action';

    @observable id = null;
    @observable action = '';
    @observable date = null;
    @observable reason = '';
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            claim: Claim,
            createdBy: User,
        };
    }

    casts() {
        return {
            date: Casts.date,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class ClaimActionStore extends Store {
    Model = ClaimAction;
    static backendResourceName = 'claim_action';
}
