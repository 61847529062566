export default `
{{customerId}} / {{trailerPlate}} waiting for POI {{currentLocation}}



{{customerId}} / {{trailerPlate}} is waiting for POI in {{currentLocation}}
Reason: Wrong reference, could you please provide a new one?

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;