import { observable } from 'mobx';
import { Casts, Model, Store } from './Base';
import { ProposedRoute } from './ProposedRoute';

export class RouteImportError extends Model {
    static backendResourceName = 'route_import_error';

    @observable id = null;
    @observable message = '';
    @observable sourceLine = '';
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            proposedRoute: ProposedRoute,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class RouteImportErrorStore extends Store {
    static backendResourceName = 'route_import_error';

    Model = RouteImportError;
}
