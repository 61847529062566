import { observable, computed } from 'mobx';
import { Model, Store } from 'store/Base';

export class TruckBrand extends Model {
    static backendResourceName = 'truck_brand';

    @observable id = null;
    @observable manufacturer = '';
    @observable brand = '';

    @computed
    get displayName() {
        return `${this.manufacturer} - ${this.brand}`;
    }
}

export class TruckBrandStore extends Store {
    Model = TruckBrand;
    static backendResourceName = 'truck_brand';
}
