import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Route } from './Route';
import { Location } from './Location';
import moment from 'moment';

export class RouteStop extends Model {
    static backendResourceName = 'route_stop';

    @observable id = null;
    @observable kind = '';
    @observable type = '';
    @observable timeDelta = 'PT0H';
    @observable requiredDocuments = [];
    @observable conditions = [];
    @observable minTemp = null;
    @observable maxTemp = null;
    @observable instructions = '';
    @observable cargoDescription = '';

    relations() {
        return {
            route: Route,
            location: Location,
        };
    }

    casts() {
        return {
            timeDelta: Casts.duration,
        };
    }

    /**
     * Read relevant properties from activity to stop.
     */
    fromActivity(activity) {
        this.setInput('type', activity.type);
        this.setInput('kind', activity.kind);
        this.setInput('location', activity.location);
        this.setInput('requiredDocuments', activity.requiredDocuments);
        this.setInput('minTemp', activity.minTemp);
        this.setInput('maxTemp', activity.maxTemp);
        this.setInput('instructions', activity.instructions);
        this.setInput('cargoDescription', activity.cargoDescription);
    }

    toActivity() {
        const now = moment();
        const stopData = this.toBackend();

        return {
            id: stopData.id,
            kind: stopData.kind,
            type: stopData.type,
            location: stopData.location,
            required_documents: stopData.required_documents,
            ordered_arrival_datetime_from: now.clone().add(moment.duration(stopData.time_delta)).format(),
            ordered_arrival_datetime_until: now.clone().add(moment.duration(stopData.time_delta)).format(),
            conditions: stopData.conditions,
            description: stopData.description,
            min_temp: stopData.min_temp,
            max_temp: stopData.max_temp,
            instructions: stopData.instructions,
            cargo_description: stopData.cargo_description,
        };
    }

    @computed
    get timeDeltaHours() {
        return Math.floor(moment.duration(this.timeDelta).asHours());
    }

    @computed
    get timeDeltaMinutes() {
        return Math.floor(moment.duration(this.timeDelta).asMinutes() - this.timeDeltaHours * 60);
    }
}

export class RouteStopStore extends Store {
    Model = RouteStop;
    static backendResourceName = 'route_stop';

    comparator(a, b) {
        const aTimeDelta = moment.duration(a.timeDelta).asMinutes();
        const bTimeDelta = moment.duration(b.timeDelta).asMinutes();

        if (aTimeDelta < bTimeDelta) {
            return -1;
        }

        if (aTimeDelta > bTimeDelta) {
            return 1;
        }

        return 0;
    };
}
