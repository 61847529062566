import React from 'react';
import styled from 'styled-components';

function getBgColor(props) {
    if (props.warning) {
        return '#fd9927';
    }
    if (props.error) {
        return '#ed0c19';
    }
}

// {unify-activity-block}
export const Wrapper = styled(({ tone, opened, warning, ...props }) => (
    <div {...props} />
))`
    width: 190px;
    height: 46px;
    border-right: 1px solid #bacedf;
    background: ${getBgColor};

    ${props =>
        !props.opened &&
        `
        border-bottom: 1px solid #bacedf;
    `};
    ${props =>
        props.opened &&
        `
        background: white;
        border-bottom: 1px solid white;
    `};
    display: flex;
    position: relative;
    color: ${props => (props.tone === 'error' ? '#fff' : '#000')};
    ${props =>
        !!props.onClick &&
        `
        cursor: pointer;
    `};
`;

export const IconSide = styled.div`
    width: 40px;
    display: flex;
    /* height: 46px; */
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    color: #fff;
`;

export const ContentSide = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
