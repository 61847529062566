import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { User } from './User';


export class RestrictedZone extends Model {
    static backendResourceName = 'restricted_zone';

    @observable id = null;
    @observable name = '';
    @observable pointA = {
        lat: null,
        lng: null,
    };
    @observable pointB = {
        lat: null,
        lng: null,
    };
    @observable pointC = {
        lat: null,
        lng: null,
    };
    @observable pointD = {
        lat: null,
        lng: null,
    };
    @observable bannedReason = '';
    @observable bannedRemark = '';
    @observable deleted = false;


    relations() {
        return {
            createdBy: User,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
        };
    }
}

export class RestrictedZoneStore extends Store {
    Model = RestrictedZone;
    static backendResourceName = 'restricted_zone';
}
