import React, { Component } from 'react';
import { Loader } from 're-cy-cle';
import { Loader as LoaderS } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledPageLoader = styled(Loader)`
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
`;

export class PageLoader extends Component {
    render() {
        return (
            <StyledPageLoader height={50} {...this.props} />
        );
    }
}

export class ContentLoader extends Component {
    render() {
        return (
            <LoaderS style={{ top: '150px' }} {...this.props} />
        );
    }
}
