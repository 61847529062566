import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Truck } from './Truck';

export class ShellCard extends Model {
    static backendResourceName = 'shell_card';

    @observable id = null;
    @observable issueDate = null;
    @observable expiryDate = null;
    @observable shellCardId = null;
    @observable pan = '';
    @observable pincode = '';
    @observable status = '';
    @observable lastUsedAt = null;
    @observable lastModifiedDate = null;
    @observable isVirtual = false;
    @observable smartPayEnabled = false;
    @observable cardDisabled = false;
    @observable reason = '';

    relations() {
        return {
            truck: Truck,
        };
    }

    casts() {
        return {
            issueDate: Casts.date,
            expiryDate: Casts.date,
            lastUsedAt: Casts.datetime,
            lastModifiedDate: Casts.datetime,
        };
    }
}

export class ShellCardStore extends Store {
    Model = ShellCard;
    static backendResourceName = 'shell_card';
}