import PropTypes from 'prop-types';
import { observable } from 'mobx';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Grid, Form, Modal } from 'semantic-ui-react';
import { CancelButton } from 'spider/Button';
import { Button, TextArea } from 're-cy-cle';

@observer
export default class ScanRequestModal extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        close: PropTypes.func,
        apply: PropTypes.func,
    };

    @observable requestReason = '';

    handleChange = (name, value) => {
        this.requestReason = value;
    };

    apply = () => {
        const { type, apply, close } = this.props;

        apply(type, this.requestReason);
        close();
    };

    render() {
        return (
            <React.Fragment>
                <Modal.Header>{t('planning.activityFinalize.scanRequested.title', { type: t(`document.field.type.options.${this.props.type}`) })}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    {t('planning.activityFinalize.scanRequested.content')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea
                                        name="requestScanReason"
                                        value={this.requestReason}
                                        onChange={this.handleChange}
                                        placeholder={t('planning.activityFinalize.scanRequested.reasonHint')}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <CancelButton onClick={this.props.close} />
                    <Button
                        data-test-request-scan-apply
                        onClick={this.apply}
                    >
                        {t('planning.activityFinalize.requestScan')}
                    </Button>
                </Modal.Actions>
            </React.Fragment>
        );
    }
}