import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { CompartmentStore } from './Trailer/Compartment';

export class TrailerEvent extends Model {
    static backendResourceName = 'trailer_event';

    @observable id = null;
    @observable supplyTemp = 0;
    @observable returnTemp = 0;
    @observable setPointTemp = 0;
    @observable sensor1Temp = 0;
    @observable sensor2Temp = 0;
    @observable sensor3Temp = 0;
    @observable sensor4Temp = 0;
    @observable doorState = 'open';
    @observable doorState2 = 'open';

    relations() {
        return {
            trailerCompartment: CompartmentStore,
        };
    }

}

export class TrailerEventStore extends Store {
    Model = TrailerEvent;
    static backendResourceName = 'trailer_event';
}
