import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { ContractStore } from './Contract';

export const TYPE_DAYSHIFT = 'dayshift';
export const TYPE_NIGHTSHIFT = 'nightshift';
export const TYPE_ADMINISTRATION = 'administration';
export const TYPE_INVOICE = 'invoice';
export const TYPE_GENERAL = 'general';
export const TYPE_OPERATION = 'operation';
export const TYPE_DISPUTE = 'dispute';
export const TYPE_TEAMS = 'teams';
export const TYPE_PENALTIES = 'penalties';

export class Contact extends Model {
    static backendResourceName = 'contact';
    static types = [
        TYPE_DAYSHIFT,
        TYPE_NIGHTSHIFT,
        TYPE_INVOICE,
        TYPE_OPERATION,
        TYPE_ADMINISTRATION,
        TYPE_GENERAL,
        TYPE_DISPUTE,
        TYPE_TEAMS,
        TYPE_PENALTIES
    ];

    @observable id = null;
    @observable name = '';
    @observable types = ['general'];
    @observable landline = '';
    @observable mobile = '';
    @observable email = '';
    @observable remarks = '';
    @observable deleted = false;

    relations() {
        return {
            contracts: ContractStore,
        }
    }

    removeMemberFromContractChannel() {
        return this.api.post(this.url + 'remove_member_from_contract_channel/')
        .catch(error => {
            const errors = error.response.data.errors;
            console.log(errors);
        });
    }
}

export class ContactStore extends Store {
    Model = Contact;
    static backendResourceName = 'contact';
}
