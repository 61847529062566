import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { CalculationRule } from './CalculationRule';
import { CalculationRuleContractType } from './CalculationRuleContractType';
import { DietRule } from './DietRule';
import { Driver } from './Driver';
import { DriverStatusChange } from './Driver/StatusChange';
import { storeWithHistory, modelWithHistory } from './History';

export class DriverCalculationRuleContractTypeSalary extends Model {
    static backendResourceName = 'driver_calculation_rule_contract_type_salary';

    @observable id = null;
    @observable date = null;
    @observable amount = null;
    @observable approved = false;

    casts() {
        return {
            date: Casts.date,
        };
    }

    relations() {
        return {
            calculationRule: CalculationRule,
            driver: Driver,
            driverStatusChange: DriverStatusChange,
            calculationRuleContractType: CalculationRuleContractType,
            dietRule: DietRule,
        };
    }
}

export class DriverCalculationRuleContractTypeSalaryStore extends Store {
    Model = DriverCalculationRuleContractTypeSalary;
    static backendResourceName = 'driver_calculation_rule_contract_type_salary';
}

export class HistoryDriverCalculationRuleContractTypeSalaryStore extends storeWithHistory(DriverCalculationRuleContractTypeSalaryStore){};
export class HistoryDriverCalculationRuleContractTypeSalary extends modelWithHistory(DriverCalculationRuleContractTypeSalary){};
