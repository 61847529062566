import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col, Subheading } from 're-cy-cle';
import { Loader } from 'semantic-ui-react';

const Details = styled.div`
    margin-top: -4px;
    margin-bottom: 4px;
    background: #fff;
    border: 1px solid #bacedf;
    border-top: none;
    font-size: 12px;
    line-height: 1.4;
    display: flex;
    ${props => (props.vertical ? 'flex-direction: column;' : '')};
`;

const StyledDetails = styled(Details)`
    padding: 7px;
    min-height: 60px;
    position: relative;
`;

export const DetailsRow = styled(Row)`
    margin: 0;
    /* Test on FireFox with a really long word before removing this,
    FF behaves subtly different: https://github.com/philipwalton/flexbugs/issues/39 */
    min-width: 0;
    flex: ${props => (props.grow !== undefined ? props.grow : 1)};
    ${props => props.margins && `
        margin-top: 6px;
        margin-bottom: 6px;
    `}
    ${props => props.noBorders && `
        border: 0px;
    `}
`;

export const DetailsCol = styled(Col)`
    ${props => (props.light ? 'background: #e6f0f4;' : '')};
    ${props => (props.blue ? 'background: #d7e3ed;' : '')};
    ${props => (props.noHeader ? 'padding-top: 10px;' : '')};
    ${props => (props.flush ? 'padding: 0;' : '')};
    ${props => (props.paddingFull ? 'padding: 10px;' : '')};
    ${props => (props.paddingProposed ? 'padding: 20px;' : '')};
    ${props => (props.bottom ? 'display: flex; flex-direction: column; justify-content: space-between;' : '')};
    min-width: 0;
`;

export const DetailsSubheading = styled(Subheading)`
    margin-top: 0px;
    padding-bottom: 15px;
`;

export default class DetailsLoading extends Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children, isLoading, ...rest } = this.props;

        if (isLoading) {
            return (
                <StyledDetails {...rest}>
                    <Loader active />
                </StyledDetails>
            );
        }

        return (
            <Details data-test-activity-details {...rest}>
                {children}
            </Details>
        );
    }
}
