import { observable } from 'mobx';
import { Model, Store, Casts } from '../Base';
import { Dispute } from '../Dispute'
import { User } from '../User'

export class DisputeComment extends Model {
    static backendResourceName = 'dispute_comment';

    @observable id = null;
    @observable comment = '';
    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            dispute: Dispute,
            createdBy: User,
        };
    }
}

export class DisputeCommentStore extends Store {
    Model = DisputeComment;
    static backendResourceName = 'dispute_comment';
}
