import { observable } from 'mobx';
import { Casts, Model, Store } from 'store/Base';
import { TrailerEquipmentsStore } from './TrailerEquipments';

export class EquipmentType extends Model {
    static backendResourceName = 'equipment_type';

    @observable id = null;
    @observable name = '';
    @observable deleted = false;
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            trailerEquipments: TrailerEquipmentsStore,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
         };
    }
}

export class EquipmentTypeStore extends Store {
    Model = EquipmentType;
    static backendResourceName = 'equipment_type';
}
