import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Driver } from './Driver';
import { storeWithHistory, modelWithHistory } from './History';

export class DriverTransactionCard extends Model {
    static backendResourceName = 'driver_transaction_card';

    @observable id = null;
    @observable availableAmount = null;
    @observable blockedAmount = null;
    @observable type = null;
    @observable status = null;
    @observable expiryDate = null;
    @observable unaccountedTransactionsNum = null;
    @observable publicToken = null;
    @observable publicTokenString = null;
    @observable externalId = null;

    casts() {
        return {
            expiryDate: Casts.datetime,
         };
    }

    relations() {
        return {
            driver: Driver,
        };
    }
}

export class DriverTransactionCardStore extends Store {
    Model = DriverTransactionCard;
    static backendResourceName = 'driver_transaction_card';
}

export class HistoryDriverTransactionCardStore extends storeWithHistory(DriverTransactionCardStore){};
export class HistoryDriverTransactionCard extends modelWithHistory(DriverTransactionCard){};
