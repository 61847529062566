import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Assignment } from './Assignment';

export class ReassignmentTruckswap extends Model {
    static backendResourceName = 'reassignment_truckswap';

    @observable id = null;
    @observable datetime = null;
    @observable isImmutable = false;

    casts() {
        return {
            datetime: Casts.datetime,
        };
    }

    relations() {
        return {
            assignment1: Assignment,
            assignment2: Assignment,
        };
    }
}

export class ReassignmentTruckswapStore extends Store {
    Model = ReassignmentTruckswap;
    static backendResourceName = 'reassignment_truckswap';

    refresh(data) {
        const truckswap = this.get(data.data.id);
        const isDeleted = data.data.deleted;

        if (truckswap) {
            if (isDeleted) {
                this.remove(truckswap);
            } else {
                truckswap.fetch();
            }
        } else if (!isDeleted) {
            const newTruckswap = new ReassignmentTruckswap({ id: data.data.id }, {
                // {swap-relations}
                relations: [
                    'assignment1.truck',
                    'assignment1.driver1',
                    'assignment1.driver2',
                    'assignment2.truck',
                    'assignment2.driver1',
                    'assignment2.driver2',
                ],
            });
            newTruckswap.fetch().then(() => {
                this.add(newTruckswap.toJS());
            });
        }
    }

    subscribe() {
        this.reassignmentTruckswapCreate = this.api.socket.subscribe({
            onPublish: this.refresh.bind(this),
            room: {
                target: 'reassignment-truckswap-create',
                assignment: '*',
            },
        });

        this.reassignmentTruckswapUpdate = this.api.socket.subscribe({
            onPublish: this.refresh.bind(this),
            room: {
                target: 'reassignment-truckswap-update',
                assignment: '*',
            },
        });

        this.reassignmentTruckswapDelete = this.api.socket.subscribe({
            onPublish: this.refresh.bind(this),
            room: {
                target: 'reassignment-truckswap-delete',
                assignment: '*',
            },
        });
    }

    unsubscribe() {
        this.api.socket.unsubscribe(this.reassignmentTruckswapCreate);
        delete this.reassignmentTruckswapCreate;

        this.api.socket.unsubscribe(this.reassignmentTruckswapUpdate);
        delete this.reassignmentTruckswapUpdate;

        this.api.socket.unsubscribe(this.reassignmentTruckswapDelete);
        delete this.reassignmentTruckswapDelete;
    }
}
