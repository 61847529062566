import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Invoice } from 'store/Invoice';
import { ExternalLink, IconGetApp } from 're-cy-cle';

@observer
export default class InvoiceDownloadPodButton extends Component {
    static propTypes = {
        invoice: PropTypes.instanceOf(Invoice).isRequired,
        icon: PropTypes.bool.isRequired,
        title: PropTypes.string,
    };

    static defaultProps = {
        icon: false,
    };

    render() {
        const { invoice, title } = this.props;

        return (
            <ExternalLink
                icon={this.props.icon}
                href={`${process.env.REACT_APP_CY_FRONTEND_API_BASE_URL}invoice/${
                    invoice.id
                }/pod/?download=true`}
                title={title}
            >
                <IconGetApp />
                {!this.props.icon && t('invoice.downloadPodButton')}
            </ExternalLink>
        );
    }
}
