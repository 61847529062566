import React from 'react';
import PropTypes from 'prop-types';

const IconUnload = props => {
    return (
        <svg viewBox="0 0 30 30" {...props}>
            <g fill={props.color} fillRule="evenodd">
                <path d="M0 3h20v17H0zM9.5 27C7.567 27 6 25.433 6 23.5S7.567 20 9.5 20s3.5 1.567 3.5 3.5S11.433 27 9.5 27zm0-2c.828 0 1.5-.672 1.5-1.5S10.328 22 9.5 22 8 22.672 8 23.5 8.672 25 9.5 25z" />
                <path d="M15.5 27c-1.933 0-3.5-1.567-3.5-3.5s1.567-3.5 3.5-3.5 3.5 1.567 3.5 3.5-1.567 3.5-3.5 3.5zm0-2c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zM30 11.5L22 16V7" />
            </g>
        </svg>
    );
};
IconUnload.propTypes = {
    color: PropTypes.string,
};

export default IconUnload;
