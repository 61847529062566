import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PARKING_DAY_OF_WEEK, Parking, FACILITIES, PARKING_STATUS_PROPOSED_CHANGES, PARKING_STATUS_REJECTED, PARKING_STATUS_ACCEPTED, PARKING_STATUS_UPDATED, PARKING_STATUS_NEW, PARKING_STATUS_PROPOSED_PARKING } from 'store/Parking';
import { Grid, Label, Loader, Dimmer } from 'semantic-ui-react';
import { EditButton, Button, Icon } from 'spider/semantic-ui/Button';
import { getCurrencySymbolFromCode, DATETIME_SHORT_FORMAT, DATE_SHORT_FORMAT } from 'helpers';
import styled from 'styled-components';
import { Popup } from 'react-leaflet';
import { observable } from 'mobx';
import { hasPermission } from 'helpers/permission';
import { Activity } from 'store/Activity';
import {
    FACILITY_ADR_POSSIBLE,
    FACILITY_CAMERA_SECURITY,
    FACILITY_COOLING_MOTORS_ALLOWED,
    FACILITY_DECOUPLING_TRAILERS,
    FACILITY_ELECTRIC_COOLING_CHARGING,
    FACILITY_FENCE,
    FACILITY_HOTEL,
    FACILITY_LONG_TIME_PARKING,
    FACILITY_PERSONNEL_GUARDING,
    FACILITY_RESTAURANT,
    FACILITY_SHOWER,
    FACILITY_SPREAD_LIGHTING,
    FACILITY_TOILET,
    FACILITY_WIFI,
} from 'store/Parking';


const StyledName = styled(Grid.Row)`
    padding-top: 1.0rem !important;
    padding-bottom: 0.0rem !important;
    padding-left: 0.0rem !important;
    padding-right: 0.0rem !important;
`;

const StyledRowSync = styled(Grid.Row)`
    padding-top: 0.0rem !important;
    padding-bottom: 0.0rem !important;
    opacity: 0.5;

`;

const StyledRowPrice = styled(Grid.Row)`
    padding-top: 0.1rem !important;
    padding-bottom: 0.0rem !important;
`;

const StyledRowHours = styled(Grid.Row)`
    padding-top: 0.1rem !important;
    padding-bottom: 0.0rem !important;
    padding-right: 0.0rem !important;
`;

const StyledGrid = styled(Grid)`
    padding-top: 1.0rem !important;
    padding-bottom: 0.0rem !important;
`;

const StyledParkingName = styled.h4`
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

const StyledColumn = styled(Grid.Column)`
    margin-right: -1.5rem;
`

const StyledIcon = styled(Icon)`
    max-height: 20px;
`

const STATUS_GREEN_BACKGROUND = 'rgba(221, 245, 215, 0.8)'
const STATUS_BLUE_BACKGROUND = 'rgba(232, 232, 232, 0.93)'
const STATUS_RED_BACKGROUND = 'rgba(255, 232, 232, 0.8)'
const CERTIFICATION_GREEN_BACKGROUND = 'rgba(221, 254, 231, 0.8)'
const CERTIFICATION_GRAY_BACKGROUND = 'rgba(191, 201, 238, 0.8)'

const PRICING_HOURS_OPTIONS = ['1h', '9h', '12h', '24h', '48h', '72h', 'Weekend']


@observer
export default class ParkingMapPoint extends Component {
    static propTypes = {
        parking: PropTypes.instanceOf(Parking).isRequired,
        parkingModalMap: PropTypes.bool,
        parkingOverviewMap: PropTypes.bool,
        onClickParking: PropTypes.func,
        activity: PropTypes.instanceOf(Activity),
        onClickParkingFromModal: PropTypes.func,
        bookable: PropTypes.bool,
    };

    getStatusLabel(parking) {
        if (parking.status === PARKING_STATUS_NEW || parking.status === PARKING_STATUS_UPDATED) {
            return <Label key={parking.id} style={{ backgroundColor: STATUS_BLUE_BACKGROUND, color: 'blue' }}>{(parking.userSuggestChangeCounter || parking.travisUpdateCounter) ? PARKING_STATUS_PROPOSED_CHANGES : parking.status}</Label>
        } else if (parking.status === PARKING_STATUS_ACCEPTED){
            return <Label key={parking.id} style={{ backgroundColor: STATUS_GREEN_BACKGROUND, color: 'green' }}>{parking.status}</Label>
        } else if (parking.status === PARKING_STATUS_REJECTED){
            return <Label key={parking.id} style={{ backgroundColor: STATUS_RED_BACKGROUND, color: 'red' }}>{parking.status}</Label>
        } else if (parking.status === PARKING_STATUS_PROPOSED_PARKING || ((parking.userSuggestChangeCounter || parking.travisUpdateCounter) && parking.status !== PARKING_STATUS_REJECTED)) {
            return <Label key={parking.id} style={{ backgroundColor: STATUS_BLUE_BACKGROUND, color: 'blue' }}>{t(`parking.overview.status.${parking.status}`)}</Label>
        }
    }

    getCurrencyAndCertificateLabel(parking) {
        if (parking.isFree) {
            return <Label key={parking.id} style={{ backgroundColor: CERTIFICATION_GREEN_BACKGROUND, color: 'green' }}>{t('parking.overview.price.free')}{(parking.certification === 'TSR1' || parking.certification === 'TSR2') ? ` / ${parking.certification}` : ''}</Label>
        } else {
            return <Label key={parking.id} style={{ backgroundColor: CERTIFICATION_GRAY_BACKGROUND }}>{getCurrencySymbolFromCode(parking.currency)}{(parking.certification === 'TSR1' || parking.certification === 'TSR2') ? ` / ${parking.certification}` : ''}</Label>
        }
    }

    centsToDollars(cents) {
        return (cents / 100).toFixed(2);
    }

    render() {
        const { parkingOverviewMap, parking } = this.props;

        if (parking.status === 'rejected') {
            return this.renderRejectedPoint()
        } else {
            if (parkingOverviewMap) {
                return this.renderParkingOverviewPoint()
            }
            return this.renderParkingActivityPoint()
        }
    }

    renderEmptyFacilitiesOverview() {
        return <React.Fragment>
            <StyledColumn><StyledIcon name='travis-fence-icon' disabled title={FACILITY_FENCE}/></StyledColumn>
            <StyledColumn><div><StyledIcon name='lightbulb' disabled title={FACILITY_SPREAD_LIGHTING}/></div></StyledColumn>
            <StyledColumn><StyledIcon name='travis-camera-icon' disabled title={FACILITY_CAMERA_SECURITY}/></StyledColumn>
            <StyledColumn><StyledIcon name='travis-guard-icon' disabled title={FACILITY_PERSONNEL_GUARDING}/></StyledColumn>
            <StyledColumn><StyledIcon name='travis-toilet-icon' disabled title={FACILITY_TOILET}/></StyledColumn>
            <StyledColumn><div><StyledIcon name='shower' disabled title={FACILITY_SHOWER}/></div></StyledColumn>
            <StyledColumn><div><StyledIcon name='wi-fi' disabled title={FACILITY_WIFI}/></div></StyledColumn>
            <StyledColumn><div><StyledIcon name='snowflake' disabled title={FACILITY_COOLING_MOTORS_ALLOWED}/></div></StyledColumn>
            <StyledColumn><StyledIcon name='travis-electric-icon' disabled title={FACILITY_ELECTRIC_COOLING_CHARGING}/></StyledColumn>
            <StyledColumn><div><StyledIcon name='exclamation triangle' disabled title={FACILITY_ADR_POSSIBLE}/></div></StyledColumn>
            <StyledColumn><StyledIcon name='travis-parking-icon' disabled title={FACILITY_LONG_TIME_PARKING}/></StyledColumn>
            <StyledColumn><StyledIcon name='travis-trailer-icon' disabled title={FACILITY_DECOUPLING_TRAILERS}/></StyledColumn>
            <StyledColumn><StyledIcon name='travis-hotel-icon' disabled title={FACILITY_HOTEL}/></StyledColumn>
            <StyledColumn><div><StyledIcon name='food' disabled title={FACILITY_RESTAURANT}/></div></StyledColumn>
        </React.Fragment>
    }

    renderRejectedPoint() {
        const { parking, parkingOverviewMap } = this.props

        return (
            <Popup
                minWidth={220}
            >
                <Grid columns={2}>
                    <StyledName>
                        <Grid.Column style={{ flexBasis: '60%' }}>
                            <h3>{parking.name}</h3>
                        </Grid.Column>
                        <Grid.Column style={{ flexBasis: '40%' }}>
                            {this.getStatusLabel(parking)}
                        </Grid.Column>
                    </StyledName>
                    <Grid.Column style={{ flexBasis: '100%' }}>
                        {parking.statusChanges ?
                                (<React.Fragment>
                                    {parkingOverviewMap && <Grid.Row>{`${t('parking.rejection.rejectionReason')}: `}{parking.statusChanges.models[0]?.rejectionReason?.description}</Grid.Row>}
                                    <Grid.Row>{`${t('parking.rejection.rejectionExplanation')}: `}{parking.statusChanges.models[0]?.rejectionExplanation}</Grid.Row>
                                    {parkingOverviewMap && <Grid.Row>{t('parking.rejection.remarks.date')}{parking.statusChanges.models[0]?.createdAt.format(DATE_SHORT_FORMAT)}</Grid.Row>}
                                </React.Fragment>)
                        : ''}
                    </Grid.Column>
                    <Grid.Row>
                        <Grid.Column style={{ flexBasis: '100%' }}>
                           {hasPermission(['boekestijn.manage_parking:all']) && <EditButton primary compact style={{ width: '100%' }}
                                content={t('parking.mapPoint.editButton')}
                                as={Link}
                                to={`/operation/parking/${parking.id}/edit`}
                            />}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Popup>
        )
    }

    renderParkingOverviewPoint() {
        const { parking, activity } = this.props
        const currencySymbol = getCurrencySymbolFromCode(parking.currency) || parking.currency
        return (
            <Popup
                minWidth={390}
            >
                <Grid columns={2}>
                    <StyledName style={{ display: 'inline' }}>
                        <Grid.Column style={{ flexBasis: '60%' }}>
                            <h3>{parking.name}</h3>
                        </Grid.Column>
                        <Grid.Column style={{ flexBasis: '40%', display: 'contents' }}>
                            {this.getCurrencyAndCertificateLabel(parking)}{this.getStatusLabel(parking)}
                        </Grid.Column>
                    </StyledName>
                    {parking.dataSource !== 'user' && <StyledRowSync>
                        <Grid.Column>
                            <i>Last sync: {parking.lastSync.format(DATETIME_SHORT_FORMAT)}</i>
                        </Grid.Column>
                    </StyledRowSync>}
                    <Grid.Row>
                        <Grid.Column style={{ flexBasis: '50%' }}>
                            <Grid.Column>
                                <Grid.Row>
                                    <h5>{t('parking.field.point.label')}</h5>
                                </Grid.Row>
                                <Grid.Row >
                                    {`${parking.location.point.lat.toFixed(7)}, ${parking.location.point.lng.toFixed(7)}`}
                                </Grid.Row>
                                <Grid.Row style={activity ? { marginBottom: '1rem' } : { marginBottom: '0.1rem' }}>
                                    <h5>{t('parking.mapPoint.pricing')}</h5>
                                </Grid.Row>
                                <Grid.Row >
                                    <StyledGrid columns={2} style={activity? { marginLeft: '0.2rem' } : { marginLeft: '-1rem' }}>
                                        {parking.isFree ?
                                            <StyledRowPrice>
                                                <Grid.Column>
                                                    {t('parking.overview.price.free')}
                                                </Grid.Column>
                                            </StyledRowPrice>
                                        : (PRICING_HOURS_OPTIONS.map(hour => {
                                            const field = `pricePer${hour}`;
                                            if (!!parking[field]) {
                                                return (
                                                    <StyledRowPrice>
                                                        <Grid.Column>
                                                            {t(`parking.mapPoint.pricePer${hour}`)}
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            {currencySymbol}{this.centsToDollars(parking[field])}
                                                        </Grid.Column>
                                                    </StyledRowPrice>
                                                )
                                            }
                                        }))}
                                    </StyledGrid>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Column>
                        <Grid.Column style={{ flexBasis: '50%' }}>
                            <Grid.Column>
                                <Grid.Row>
                                    <h5>{t('parking.mapPoint.openingHours')}</h5>
                                </Grid.Row>
                                <Grid.Row>
                                    <StyledGrid>
                                        {
                                            parking.isOpenFullWeek()
                                            ?
                                            <StyledRowHours>
                                                <Grid.Column>
                                                    {t('parking.mapPoint.openFullWeek')}
                                                </Grid.Column>
                                            </StyledRowHours>
                                            : PARKING_DAY_OF_WEEK.map(day => {
                                                const fieldStart = `openinghours${day}Start`;
                                                const fieldEnd = `openinghours${day}End`;
                                                return (
                                                    <StyledRowHours>
                                                        <Grid.Column style={{ flexBasis: '35%' }}>
                                                            {day}
                                                        </Grid.Column>
                                                        <Grid.Column data-test-hours style={{ flexBasis: '65%' }}>
                                                            {(parking[fieldStart] === '' && parking[fieldEnd] === '') || (!parking[fieldStart] && !parking[fieldEnd])
                                                            ? 'closed'
                                                            : `${parking[fieldStart]} - ${parking[fieldEnd]}`}
                                                        </Grid.Column>
                                                    </StyledRowHours>
                                                )
                                            })
                                        }
                                    </StyledGrid>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <h5>{t('parking.field.facilities.label')}</h5>
                        </Grid.Column>
                    </Grid.Row>
                    {<Grid.Row columns={9} style={activity ? { marginTop: '-1rem', marginBottom: '-1rem' } : { marginTop: '-2rem', marginBottom: '-1rem' }}>
                        {parking.facilities &&
                            FACILITIES.map(fac => {
                                const available = parking.facilities.find(f => f === fac) ? true : false
                                if (fac === FACILITY_FENCE) {
                                    return (<StyledColumn><StyledIcon name='travis-fence-icon' disabled={!available} title={fac}/></StyledColumn>)
                                } else if (fac === FACILITY_SPREAD_LIGHTING) {
                                    return (<StyledColumn><div><StyledIcon name='lightbulb' disabled={!available}/></div></StyledColumn>)
                                } else if (fac === FACILITY_CAMERA_SECURITY) {
                                    return (<StyledColumn><StyledIcon name='travis-camera-icon' disabled={!available} title={fac}/></StyledColumn>)
                                } else if (fac === FACILITY_PERSONNEL_GUARDING) {
                                    return (<StyledColumn><StyledIcon name='travis-guard-icon' disabled={!available} title={fac}/></StyledColumn>)
                                } else if (fac === FACILITY_TOILET) {
                                    return (<StyledColumn><StyledIcon name='travis-toilet-icon' disabled={!available} title={fac}/></StyledColumn>)
                                } else if (fac === FACILITY_SHOWER) {
                                    return (<StyledColumn><div><StyledIcon name='shower' disabled={!available} title={fac}/></div></StyledColumn>)
                                } else if (fac === FACILITY_WIFI) {
                                    return (<StyledColumn><div><StyledIcon name='wi-fi' disabled={!available} title={fac}/></div></StyledColumn>)
                                } else if (fac === FACILITY_COOLING_MOTORS_ALLOWED) {
                                    return (<StyledColumn><div><StyledIcon name='snowflake' disabled={!available} title={fac}/></div></StyledColumn>)
                                } else if (fac === FACILITY_ELECTRIC_COOLING_CHARGING) {
                                    return (<StyledColumn><StyledIcon name='travis-electric-icon' disabled={!available} title={fac}/></StyledColumn>)
                                } else if (fac === FACILITY_ADR_POSSIBLE) {
                                    return (<StyledColumn><div><StyledIcon name='exclamation triangle' disabled={!available} title={fac}/></div></StyledColumn>)
                                } else if (fac === FACILITY_LONG_TIME_PARKING) {
                                    return (<StyledColumn><StyledIcon name='travis-parking-icon' disabled={!available} title={fac}/></StyledColumn>)
                                } else if (fac === FACILITY_DECOUPLING_TRAILERS) {
                                    return (<StyledColumn><StyledIcon name='travis-trailer-icon' disabled={!available} title={fac}/></StyledColumn>)
                                } else if (fac === FACILITY_HOTEL) {
                                    return (<StyledColumn><StyledIcon name='travis-hotel-icon' disabled={!available} title={fac}/></StyledColumn>)
                                } else if (fac === FACILITY_RESTAURANT) {
                                    return (<StyledColumn><div><StyledIcon name='food' disabled={!available} title={fac}/></div></StyledColumn>)
                                } else {
                                    return (<StyledColumn><StyledIcon name='question' title='custom'/></StyledColumn>)
                                }
                            })
                        }
                    </Grid.Row>}
                    {!parking.facilities && <Grid.Row columns={9} style={activity ? { marginTop: '-1rem', marginBottom: '-1rem' } : { marginTop: '-2rem', marginBottom: '-1rem' }}>
                        {this.renderEmptyFacilitiesOverview()}
                    </Grid.Row>}
                    <Grid.Row>
                        <Grid.Column style={{ flexBasis: '100%' }}>
                            {hasPermission(['boekestijn.manage_parking:all']) && <EditButton compact primary style={{ width: '100%' }}
                                content={t('parking.mapPoint.editButton')}
                                as={Link}
                                to={`/operation/parking/${parking.id}/edit`}
                            />}
                            {!hasPermission(['boekestijn.manage_parking:all']) && <Button compact style={{ width: '100%', fontSize: '95%' }}
                                    data-test-book-button={parking.id}
                                    labelPosition="left"
                                    icon="edit"
                                    content="Propose changes"
                                    as={Link}
                                    to={`/operation/parking/${parking.id}/edit`}
                                />
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Popup>
        )
    }

    renderParkingActivityPoint() {
        const { parking, parkingModalMap } = this.props

        return (
            <Popup
                key={parking.id}
                minWidth={!!parkingModalMap ? 310 : 210}
                maxWidth={!!parkingModalMap ? 500 : 235}
            >
                <ParkingActivityPopup {...this.props} />
            </Popup>
        )
    }
}

@observer
class ParkingActivityPopup extends Component {
    static propTypes = {
        parking: PropTypes.instanceOf(Parking).isRequired,
        parkingModalMap: PropTypes.bool,
        onClickParking: PropTypes.func,
        activity: PropTypes.instanceOf(Activity),
        onClickParkingFromModal: PropTypes.func,
        bookable: PropTypes.bool,
    };

    @observable bookingAvailable = null;
    @observable bookingSpots = null;
    @observable bookingPrice = null;
    @observable bookingCurrency = null;
    @observable responseErrors = null;
    @observable isLoading = true;
    @observable clickApply = false;
    @observable bookingData = {}

    componentDidMount() {
        const { parking, parkingModalMap, onClickParking, activity, onClickParkingFromModal, bookable } = this.props

        this.isLoading = true
        if (bookable) {
            if (parking.dataSource === 'travis') {
                if (activity.orderedArrivalDatetimeFrom !== null && activity.orderedArrivalDatetimeUntil !== null ) {
                    const bookData = {
                        dateFrom: activity.orderedArrivalDatetimeFrom,
                        dateFinish: activity.orderedArrivalDatetimeUntil,
                    }
                    parking.checkParkingAvailability(bookData).then(data => {
                        if (data.data.title !== 'An error occurred') {
                            const response = data.data.locations[0]
                            if (response && response.locationId === parking.travisId) {
                                this.bookingAvailable = response.available;
                                this.bookingSpots = response.availableSpots;
                                this.bookingPrice = response.price.amount;
                                this.bookingCurrency = response.price.currency;
                                this.bookingData = {
                                    available: response.available,
                                    availableSpots: response.availableSpots,
                                    amount: response.price.amount,
                                    currency: response.price.currency,
                                }
                                onClickParking(parking, this.bookingData)
                                this.clickApply = true
                                this.isLoading = false
                                if (parkingModalMap) {
                                    onClickParkingFromModal(parking.id)
                                }
                            }
                        } else {
                            this.responseErrors = [];
                            const errors = data.data.violations
                            errors.map(error => {
                                this.responseErrors.push(`${error.propertyPath} : ${error.message}`)
                            })
                            this.isLoading = false
                            if (parkingModalMap) {
                                onClickParkingFromModal(parking.id)
                            }
                        }
                    })
                } else {
                    this.responseErrors = [];
                    this.responseErrors.push('Fill Order arrival time from and Finish Time! to check availability and prices in Travis Parkings')
                    this.isLoading = false
                    if (parkingModalMap) {
                        onClickParkingFromModal(parking.id)
                    }
                }
            } else {
                this.isLoading = false
                onClickParking(parking, null)
                if (parkingModalMap) {
                    onClickParkingFromModal(parking.id)
                }
            }
        } else {
            this.isLoading = false
        }
    }

    componentWillUnmount() {
        console.log('unmount')
        this.responseErrors = null
        this.bookingAvailable = null
        this.bookingSpots = null
        this.bookingPrice = null
        this.bookingCurrency = null
        this.isLoading = true
    }

    getCurrencyAndCertificateLabel(parking) {
        if (parking.isFree) {
            return <Label key={parking.id} style={{ backgroundColor: CERTIFICATION_GREEN_BACKGROUND, color: 'green' }}>{t('parking.overview.price.free')}{(parking.certification === 'TSR1' || parking.certification === 'TSR2') ? ` / ${parking.certification}` : ''}</Label>
        } else {
            return <Label key={parking.id} style={{ backgroundColor: CERTIFICATION_GRAY_BACKGROUND }}>{getCurrencySymbolFromCode(parking.currency)}{(parking.certification === 'TSR1' || parking.certification === 'TSR2') ? ` / ${parking.certification}` : ''}</Label>
        }
    }

    getStatusLabel(parking) {
        if (parking.status === PARKING_STATUS_NEW || parking.status === PARKING_STATUS_UPDATED) {
            return <Label key={parking.id} style={{ backgroundColor: STATUS_BLUE_BACKGROUND, color: 'blue' }}>{(parking.userSuggestChangeCounter || parking.travisUpdateCounter) ? PARKING_STATUS_PROPOSED_CHANGES : parking.status}</Label>
        } else if (parking.status === PARKING_STATUS_ACCEPTED){
            return <Label key={parking.id} style={{ backgroundColor: STATUS_GREEN_BACKGROUND, color: 'green' }}>{parking.status}</Label>
        } else if (parking.status === PARKING_STATUS_REJECTED){
            return <Label key={parking.id} style={{ backgroundColor: STATUS_RED_BACKGROUND, color: 'red' }}>{parking.status}</Label>
        } else if (parking.status === PARKING_STATUS_PROPOSED_PARKING || ((parking.userSuggestChangeCounter || parking.travisUpdateCounter) && parking.status !== PARKING_STATUS_REJECTED)) {
            return <Label key={parking.id} style={{ backgroundColor: STATUS_BLUE_BACKGROUND, color: 'blue' }}>{t(`parking.overview.status.${parking.status}`)}</Label>
        }
    }

    centsToDollars(cents) {
        return (cents / 100).toFixed(2);
    }

    renderEmptyFacilitiesPlanning() {
        return <React.Fragment>
            <div><Icon style={{ marginLeft: '1rem', height:'15px', maxHeight: '15px' }} name='travis-fence-icon' disabled title={FACILITY_FENCE}/></div>
            <div><StyledIcon name='lightbulb' style={{ paddingLeft: '5px' }} disabled title={FACILITY_SPREAD_LIGHTING}/></div>
            <div><StyledIcon name='travis-camera-icon' disabled title={FACILITY_CAMERA_SECURITY}/></div>
            <div><StyledIcon name='travis-guard-icon' disabled title={FACILITY_PERSONNEL_GUARDING}/></div>
            <div><StyledIcon name='travis-toilet-icon' disabled title={FACILITY_TOILET}/></div>
            <div><StyledIcon name='shower' disabled title={FACILITY_SHOWER}/></div>
            <div><StyledIcon name='wi-fi' disabled title={FACILITY_WIFI}/></div>
            <div><StyledIcon name='snowflake' disabled title={FACILITY_COOLING_MOTORS_ALLOWED}/></div>
            <div><StyledIcon name='travis-electric-icon' disabled title={FACILITY_ELECTRIC_COOLING_CHARGING}/></div>
            <div><StyledIcon name='exclamation triangle' disabled title={FACILITY_ADR_POSSIBLE}/></div>
            <div><StyledIcon name='travis-parking-icon' disabled title={FACILITY_LONG_TIME_PARKING}/></div>
            <div><StyledIcon name='travis-trailer-icon' disabled title={FACILITY_DECOUPLING_TRAILERS}/></div>
            <div><StyledIcon name='travis-hotel-icon' disabled title={FACILITY_HOTEL}/></div>
            <div><StyledIcon name='food' disabled title={FACILITY_RESTAURANT}/></div>
        </React.Fragment>
    }


    render() {
        const { parking, bookable, parkingModalMap } = this.props;
        const currencySymbol = getCurrencySymbolFromCode(parking.currency) || parking.currency
        const currencySymbolBooking = getCurrencySymbolFromCode(this.bookingCurrency) || this.bookingCurrency
        console.log('Render ActivityPopup')

        return (
            <React.Fragment>
                <Grid key={parking.id} columns={2} style={!!parkingModalMap ? { minWidth: '390px' } : { fontSize: '70%' }}>
                <div style={bookable ? {} : { opacity: '0.5', pointerEvents: 'none' }}>
                <Grid.Row style={{ display: 'flex', marginTop: '0.5rem', justifyContent: 'space-between'  }}>
                    <Grid.Column>
                        <StyledParkingName style={!!parkingModalMap ? { maxWidth: '200px' } : { maxWidth: '70px' }}title={parking.name}>
                            {parking.name}
                        </StyledParkingName>
                    </Grid.Column>
                    <Grid.Column>
                        {this.getCurrencyAndCertificateLabel(parking)}
                        {this.getStatusLabel(parking)}
                    </Grid.Column>
                </Grid.Row>
                {parking.dataSource !== 'user' && (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem', marginTop: '-0.5rem' }}>
                    <div>
                    <StyledRowSync>
                        <i>Last sync: {parking.lastSync.format(DATETIME_SHORT_FORMAT)}</i>
                    </StyledRowSync>
                    </div>
                    <div style={{ marginLeft: '1rem' }}>
                        <Icon name="exchange" size='large' title={!parking.rebookable ? 'Non Rebookable' : 'Rebookable'} disabled={!parking.rebookable} style={{ position: 'relative' }}>
                            {!parking.rebookable && <div style={{ content: '', position: 'absolute', top: '50%', left: '0', width: '100%', height: '1px', background: 'black', transform: 'rotate(-45deg) translateY(-50%)' }}></div>}
                        </Icon>
                    </div>
                </div>
                )}
                {(!this.isLoading && this.responseErrors) &&
                    (<Grid>
                        <Grid.Row>
                            <Grid.Column>
                                {this.responseErrors.map(err => <Grid.Row>{err}</Grid.Row>)}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
                    {(!this.isLoading && !this.responseErrors) && (<Grid style={{ marginTop: '-1rem' }}>
                            <Grid.Row style={{ marginBottom: '-1rem' }}>
                                <Grid.Column style={{ flexBasis: '40%' }}>
                                    <Grid.Column>
                                        <Grid.Row>
                                            <h5>{t('parking.mapPoint.pricing')}</h5>
                                        </Grid.Row>
                                        <Grid.Row >
                                            <StyledGrid columns={2}>
                                            {parking.isFree ?
                                                <StyledRowPrice>
                                                    <Grid.Column>
                                                        {t('parking.overview.price.free')}
                                                    </Grid.Column>
                                                </StyledRowPrice>
                                                : (PRICING_HOURS_OPTIONS.map(hour => {
                                                    const field = `pricePer${hour}`;
                                                    if (!!parking[field]) {
                                                        return (
                                                            <StyledRowPrice>
                                                                <Grid.Column>
                                                                    {t(`parking.mapPoint.pricePer${hour}`)}
                                                                </Grid.Column>
                                                                <Grid.Column>
                                                                    {currencySymbol}{this.centsToDollars(parking[field])}
                                                                </Grid.Column>
                                                            </StyledRowPrice>
                                                        )
                                                    }
                                                }))
                                                }
                                            </StyledGrid>
                                        </Grid.Row>
                                    </Grid.Column>
                                </Grid.Column>
                                <Grid.Column style={{ flexBasis: '60%' }}>
                                    <Grid.Column>
                                        <Grid.Row>
                                            <h5>{t('parking.mapPoint.openingHours')}</h5>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <StyledGrid>
                                                {
                                                    parking.isOpenFullWeek()
                                                    ?
                                                    <StyledRowHours>
                                                        <Grid.Column>
                                                            {t('parking.mapPoint.openFullWeek')}
                                                        </Grid.Column>
                                                    </StyledRowHours>
                                                    : PARKING_DAY_OF_WEEK.map(day => {
                                                        const fieldStart = `openinghours${day}Start`;
                                                        const fieldEnd = `openinghours${day}End`;
                                                        return (
                                                            <StyledRowHours>
                                                                <Grid.Column style={{ flexBasis: '35%' }}>
                                                                    {day}
                                                                </Grid.Column>
                                                                <Grid.Column style={{ flexBasis: '65%' }}>
                                                                    {(parking[fieldStart] === '' && parking[fieldEnd] === '') || (!parking[fieldStart] && !parking[fieldEnd])
                                                                    ? 'closed'
                                                                    : `${parking[fieldStart]} - ${parking[fieldEnd]}`}
                                                                </Grid.Column>
                                                            </StyledRowHours>
                                                        )
                                                    })
                                                }
                                            </StyledGrid>
                                        </Grid.Row>
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <h5>{t('parking.field.facilities.label')}</h5>
                                </Grid.Column>
                            </Grid.Row>
                            {<Grid.Row columns={6} style={!!parkingModalMap ?
                            { justifyContent: 'space-evenly', marginTop: '-1rem', marginBottom: '-1rem', maxHeight: '18px', alignItems: 'end', flexWrap: 'nowrap' }
                            : { marginTop: '-1rem', marginBottom: '-1rem', maxHeight: '18px', alignItems: 'end', flexWrap: 'nowrap' }
                            }>
                            {parking.facilities && (
                                    FACILITIES.map(fac => {
                                        const available = parking.facilities.find(f => f === fac) ? true : false
                                        // we need to use some of custom svg because in our semantic icons there is no lot of choice
                                        if (fac === FACILITY_FENCE) {
                                            return (<div><StyledIcon style={{ marginLeft: '1rem', height:'15px', maxHeight: '15px' }} name='travis-fence-icon' disabled={!available} title={fac}/></div>)
                                        } else if (fac === FACILITY_SPREAD_LIGHTING) {
                                            return (<div><StyledIcon name='lightbulb' disabled={!available} title={fac}/></div>)
                                        } else if (fac === FACILITY_CAMERA_SECURITY) {
                                            return (<StyledIcon name='travis-camera-icon' disabled={!available} title={fac}/>)
                                        } else if (fac === FACILITY_PERSONNEL_GUARDING) {
                                            return (<StyledIcon name='travis-guard-icon' disabled={!available} title={fac}/>)
                                        } else if (fac === FACILITY_TOILET) {
                                            return (<StyledIcon name='travis-toilet-icon' disabled={!available} title={fac}/>)
                                        } else if (fac === FACILITY_SHOWER) {
                                            return (<div><StyledIcon name='shower' disabled={!available} title={fac}/></div>)
                                        } else if (fac === FACILITY_WIFI) {
                                            return (<div><StyledIcon name='wi-fi' disabled={!available} title={fac}/></div>)
                                        } else if (fac === FACILITY_COOLING_MOTORS_ALLOWED) {
                                            return (<div><StyledIcon name='snowflake' disabled={!available} title={fac}/></div>)
                                        } else if (fac === FACILITY_ELECTRIC_COOLING_CHARGING) {
                                            return (<StyledIcon name='travis-electric-icon' disabled={!available} title={fac}/>)
                                        } else if (fac === FACILITY_ADR_POSSIBLE) {
                                            return (<div><StyledIcon name='exclamation triangle' disabled={!available} title={fac}/></div>)
                                        } else if (fac === FACILITY_LONG_TIME_PARKING) {
                                            return (<StyledIcon name='travis-parking-icon' disabled={!available} title={fac}/>)
                                        } else if (fac === FACILITY_DECOUPLING_TRAILERS) {
                                            return (<StyledIcon name='travis-trailer-icon' disabled={!available} title={fac}/>)
                                        } else if (fac === FACILITY_HOTEL) {
                                            return (<StyledIcon name='travis-hotel-icon' disabled={!available} title={fac}/>)
                                        } else if (fac === FACILITY_RESTAURANT) {
                                            return (<div><StyledIcon name='food' disabled={!available} title={fac}/></div>)
                                        } else {
                                            return (<StyledIcon name='question' title='custom'/>)
                                        }
                                    })
                                )}
                                {!parking.facilities && this.renderEmptyFacilitiesPlanning()}
                            </Grid.Row>}
                        </Grid>)}
                    {(this.isLoading) && <Dimmer active inverted>
                            <Loader size='medium'>Loading</Loader>
                        </Dimmer>
                }
            </div>
            </Grid>
            <div style={!!parkingModalMap ? { display: 'flex' } : { display: 'flex', marginBottom: '-1rem', marginTop: '2rem', alignItems: 'end' }}>
                <Grid.Column style={!!parkingModalMap ? { marginTop: '2rem' } : { flexBasis: '74%', marginRight: '-3rem', marginTop: '0.5rem' }}>
                    {parking.dataSource === 'travis' && bookable && !!this.bookingSpots && <Grid.Row style={!!parkingModalMap ? {} : { fontSize: '75%', fontWeight: '500' }}>
                        <b>{t('parking.mapPoint.availableSpots')}</b> {this.bookingSpots}/{parking.capacity}
                    </Grid.Row>}
                </Grid.Column>
                <Grid.Column style={!!parkingModalMap ? { paddingLeft: '2rem', marginTop: '2rem' } : { flexBasis: '53%', marginTop: '0.5rem' }}>
                    {parking.dataSource === 'travis' && bookable && this.bookingCurrency && this.bookingPrice && <Grid.Row style={!!parkingModalMap ? {} : { fontSize: '70%', fontWeight: '500' }}>
                    <b>Booking price:</b> {currencySymbolBooking}{this.bookingPrice.toFixed(2)}
                    </Grid.Row>}
                </Grid.Column>
            </div>
            <Grid style={!!parkingModalMap ? { marginTop: '0rem' } : { marginTop: '0.5rem' }}>
                <Grid.Row columns='equal'>
                    <Grid.Column>
                        <Button compact primary style={{ width: '100%', fontSize: '80%' }}
                            data-test-book-button={parking.id}
                            labelPosition="left"
                            icon="edit"
                            content={hasPermission(['boekestijn.manage_parking:all']) ? 'Edit parking' : 'Propose changes'}
                            as={Link}
                            to={`/operation/parking/${parking.id}/edit`}
                        />
                        {this.clickApply && this.responseErrors && <Button
                            content='Fill time and reopen point to Book'
                            disabled
                        />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
        )
    }
}
