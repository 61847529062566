import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestion } from './Question';

export class FormQuestionFile extends Model {
    static backendResourceName = 'form_question_file';

    @observable id = null;
    @observable minCount = 0;
    @observable maxCount = null;

    relations() {
        return {
            formQuestion: FormQuestion,
        };
    }
}

export class FormQuestionFileStore extends Store {
    Model = FormQuestionFile;
    static backendResourceName = 'form_question_file';
}
