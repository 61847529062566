import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Entity } from 'store/Entity';
import { CostSupplier } from 'store/CostSupplier';
import { CostInvoice } from 'store/CostInvoice';
import { CostProduct } from 'store/CostProduct';
import { Truck } from 'store/Truck';
import { Activity } from 'store/Activity';
import { Location } from 'store/Location';
import { Driver } from 'store/Driver';
import { LedgerCost, LedgerCostStore } from 'store/LedgerCost';
import { LedgerVat, LedgerVatStore } from 'store/LedgerVat';
import { OWN_CURRENCY } from 'helpers';
import moment from 'moment-timezone';
import { showSaveNotification } from 'helpers/notification';
import { get } from 'lodash';
import { Declaration } from './Declaration';

export class FuelCost extends Model {
    static backendResourceName = 'fuel_cost';

    @observable id = null;
    @observable transactionId = null;
    @observable transactionDatetime = null;
    @observable originalProductDescription = '';
    @observable originalCardNumber = '';
    @observable originalLicensePlate = '';
    @observable originalDriverCode = null;
    @observable originalAddress = '';
    @observable originalCountryCode = '';
    @observable locationNeedsGeocoding = true;
    @observable odometer = null;
    @observable quantity = null;
    @observable unitPrice = null;
    @observable listPrice = null;
    @observable netOc = null;
    @observable grossOc = null;
    @observable vatOc = null;
    @observable grossFc = null;
    @observable netFc = null;
    @observable vatFc = null;
    @observable vatPercentage = null;
    @observable fcCode = 'EUR';
    @observable paidBy = 'driver';
    @observable haveReceipt = true;
    @observable productFor = 'truck';
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable finalizedAt = null;
    @observable rejectedAt = null;

    relations() {
        return {
            entity: Entity,
            costSupplier: CostSupplier,
            costInvoice: CostInvoice,
            costProduct: CostProduct,
            truck: Truck,
            driver: Driver,
            activity: Activity,
            location: Location,
            ledgerCost: LedgerCost,
            ledgerVat: LedgerVat,
            declaration: Declaration,
        };
    }

    casts() {
        return {
            transactionDatetime: Casts.datetime,
            rejectedAt: Casts.datetime,
        };
    }

    @computed
    get isOwnCurrency() {
        return this.fcCode === OWN_CURRENCY;
    }

    recalculateVat()  {
        this.setInput('vatOc', this.grossOc - this.netOc);
        this.setInput('vatFc', this.grossFc - this.netFc);
        this.setInput('vatPercentage', Math.abs((this.grossFc / this.netFc - 1) * 100));
    }

    calculateUnitPrice()  {
        this.setInput('unitPrice', Math.abs((this.netFc / this.quantity).toFixed(2)));
    }

    syncOc() {
        this.setInput('netOc', this.netFc);
        this.setInput('grossOc', this.grossFc);
        this.setInput('vatOc', this.vatFc);
    }

    syncNet() {
        this.setInput('netFc', this.grossFc);
        this.setInput('netOc', this.grossOc);
    }

    recalculateValuesForDeclaration() {
        if (this.fcCode === 'EUR') {
            this.setInput('netOc', this.grossFc);
            this.setInput('netFc', this.grossFc);
        }
    }

    recalculateValuesForDeclarationEurFc() {
        if (this.fcCode !== 'EUR') {
            this.setInput('netFc', this.grossFc);
        }
    }

    recalculateValuesForDeclarationEurOc() {
        if (this.fcCode !== 'EUR') {
            this.setInput('netOc', this.grossOc);
        }
    }

    /**
     * Fetch ledger cost and set it on this road cost.
     *
     * TODO: Don't fetch everything and match in frontend, but find specific
     * one by filtering on backend.
     */
    fetchAndSetLedgerCost() {
        const ledgerCostStore = new LedgerCostStore({
            relations: ['entity', 'costProduct', 'codeReports', 'currentCodeReport'],
            params: { order_by: 'ledger_code_accounting' },
            limit: null,
        });

        ledgerCostStore.fetch().then(() => this.setLedgerCost(ledgerCostStore));
    }

    setLedgerCost(ledgerCostStore) {
        const ledgerCost = ledgerCostStore.find(lc =>
            lc.entity.id === this.entity.id &&
            lc.assetTruckCategory === this.truck.category &&
            lc.costProduct.id === this.costProduct.id
        );

        this.setInput('ledgerCost', ledgerCost);
    }

    /**
     * Fetch ledger vat and set it on this road cost.
     *
     * TODO: Don't fetch everything and match in frontend, but find specific
     * one by filtering on backend.
     */
    fetchAndSetLedgerVat() {
        const ledgerVatStore = new LedgerVatStore({
            relations: ['entity'],
            params: { order_by: 'ledger_code' },
        });

        ledgerVatStore.fetch().then(() => this.setLedgerVat(ledgerVatStore));
    }

    setLedgerVat(ledgerVatStore) {
        const country = this.costSupplier.country;

        const ledgerVat = ledgerVatStore.find(lc =>
            lc.entity.id === this.entity.id &&
            lc.country === country
        );

        this.setInput('ledgerVat', ledgerVat);
    }

    finalize() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}finalize/`)
            .then(() => {
                this.setInput('finalizedAt', moment());
             }).then(showSaveNotification)
        );
    }

    unfinalize() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}unfinalize/`)
                .then(() => {
                    this.setInput('finalizedAt', null);
                }).then(showSaveNotification)
                .catch(e => {
                    if (get(e, 'response.data.errors')) {
                        this.parseValidationErrors(e.response.data.errors);
                    }
                    throw e;
                })
        );
    }

    rejectFuelCost() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}reject_fuel_cost/`)
            .then(showSaveNotification)
            .then(()=>this.fetch())
        );
    }

    unrejectFuelCost() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}unreject_fuel_cost/`)
            .then(showSaveNotification)
            .then(()=>this.fetch())
        );
    }
}

export class FuelCostStore extends Store {
    static backendResourceName = 'fuel_cost';

    Model = FuelCost;

    @computed
    get netAmount() {
        return this.models.reduce((result, fc) =>
            result + fc.netFc
        , 0);
    }

    @computed
    get grossAmount() {
        return this.models.reduce((result, fc) =>
            result + fc.grossFc
        , 0);
    }

    @computed
    get vatAmount() {
        return this.models.reduce((result, fc) =>
            result + fc.vatFc
        , 0);
    }

    parseBackendValidationErrors(errors) {
        this.models.forEach(fc => fc.parseValidationErrors(errors));
    }
}
