import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { User } from './User';
import { Truck } from './Truck';
import { Driver } from './Driver';
import { Parking } from './Parking';
import { Activity } from './Activity';


export class ParkingBooking extends Model {
    static backendResourceName = 'parking_booking';

    @observable id = null;
    @observable status = null;
    @observable message = null;
    @observable travisCode = null;
    @observable bookingId = null;
    @observable price = null;
    @observable currency = null;
    @observable gateOpeningLink = null;
    @observable accessCode = null;
    @observable trailer = null;

    @observable arrivalDate = null;
    @observable departureDate = null;

    @observable reference = null;
    @observable notes = null;
    @observable canBeUpdated = true;

    @observable createdAt = null;
    @observable updatedAt = null;
    @observable deleted = false;

    relations() {
        return {
            createdBy: User,
            updatedBy: User,
            truck: Truck,
            driver: Driver,
            parking: Parking,
            activity: Activity,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    getBookingInformations() {
        return this.api.get(this.url + 'get_booking_informations/', {});
    }

    cancelBooking() {
        return this.api.get(this.url + 'cancel_booking/', {})
    }

    updateBooking(data) {
        return this.api.post(this.url + 'update_booking/', { data });
    }

    updateBookingReferenceNumber(data) {
        return this.api.post(this.url + 'update_booking_reference_number/', { data });
    }

}

export class ParkingBookingStore extends Store {
    Model = ParkingBooking;
    static backendResourceName = 'parking_booking';
}
