export default `
{{customerId}} / {{trailerPlate}} {{status}} for service in {{currentLocation}}



{{customerId}} / {{trailerPlate}} {{status}} for service in {{currentLocation}}

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
