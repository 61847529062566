import { observable, action, computed } from 'mobx';
import { Casts, Model, Store } from 'store/Base';
import { Location } from './Location';
import { Allocation } from './Allocation';
import { Assignment } from './Assignment';
import { ReassignmentChange } from './ReassignmentChange';
import { ReassignmentTruckswap } from './ReassignmentTruckswap';
import { Trailer } from './Trailer';
import { EquipmentChangeStore, STATUS_APPLIED } from './EquipmentChange';
import { TRACKING_TARGET_ALLOCATION } from './TrackedEquipment';
import { DocumentOrderedByTypeStore } from './Document';
import { ProposedActivity } from './ProposedActivity';
import { User } from './User';
import moment from 'moment-timezone';
import { OtherCostStore } from './OtherCost';
import { CostItemStore } from './CostItem';
import { OrderConfirmationStore } from './OrderConfirmation';
import { ActivityIssueStore } from './ActivityIssue';
import { WaiverItemStore } from './WaiverItem';
import { Message, MessageStore } from './Message';
import { omit, pick, each, omitBy, result, trim, last, dropRight, camelCase } from 'lodash';
import { DATE_RANGE_FORMAT, getMoneyForUser, convertMomentToTimezone, getCurrencySymbolFromCode } from 'helpers';
import { InvoiceLineItemStore } from './InvoiceLineItem';
import { InvoiceLineCorrectionItemStore } from './InvoiceLineCorrectionItem';
import { PetrolStation, PetrolStationStore } from './PetrolStation';
import { BookingEurotunnelStore } from './Booking/Eurotunnel';
import { FixedRateDayStore } from './FixedRateDay';
import { USER_TIMEZONE, DATETIME_SHORT_FORMAT, OWN_CURRENCY } from 'helpers';
import { ParkingBookingStore } from './ParkingBooking';
import { Parking } from './Parking';

export const KIND_ACTIVITY = 'activity';
export const KIND_CUSTOMER_STOP = 'customer stop';
export const KIND_BOEK_STOP = 'boek stop';

export const TYPE_POI = 'poi';
export const TYPE_TRUCK_SWAP = 'truck swap';
export const TYPE_CHANGE = 'driver/truck change';
export const TYPE_BORDER = 'border/customs';
export const TYPE_LOAD = 'load';
export const TYPE_UNLOAD = 'unload';
export const TYPE_TRAILER_CHANGE = 'trailer change';
export const TYPE_SECURE_PARKING = 'secure parking';
export const TYPE_PARKING = 'parking';
export const TYPE_TRUCK_DROP = 'truck drop';
export const TYPE_FERRY_TUNNEL = 'ferry-tunnel';
export const TYPE_TANKING = 'tanking';
export const TYPE_TACHO_CALIBRATION = 'tacho calibration';
export const TYPE_TUV = 'tuv';
export const TYPE_ALLOCATION_EXIT = 'allocation exit';

export const KIND_ACTIVITY_TYPES = [TYPE_LOAD, TYPE_UNLOAD, TYPE_TRAILER_CHANGE, TYPE_POI];

export const STATUS_PLANNED = 'planned';
export const STATUS_ARRIVED = 'arrived';
export const STATUS_STARTED = 'started';
export const STATUS_FINISHED = 'finished';
export const STATUS_ETA = 'eta';
export const STATUS_WAITING = 'waiting';

export const KINDS = [KIND_ACTIVITY, KIND_CUSTOMER_STOP, KIND_BOEK_STOP];
export const CONDITION_TEMPERATURE = 'temperature';
export const CONDITION_ADR = 'adr';
export const CONDITION_TSR1 = 'tsr1';
export const CONDITION_TSR1_PLUS = 'tsr1+';
export const CONDITION_TSR2 = 'tsr2';
export const CONDITION_SENT_GEO = 'sent-geo';
export const CONDITION_GDP = 'gdp';
export const OVERLOADABLE_TIME_TYPES = [TYPE_SECURE_PARKING, TYPE_FERRY_TUNNEL, TYPE_PARKING];

// If we use a wysiwyg editor, it will encode the {{UUID}} tag.
export function useActualUuidTag(str) {
    return str.replace(/href="(([^"]*)%7B%7BUUID%7D%7D([^"]*))"/g, 'href="$2{{UUID}}$3"');
};

// {copy-pasta-format-duration}
function formatDuration(duration) {
    if (duration && duration.asMinutes()) {
        const diff = duration.format('h', { trim: false });

        return `${parseInt(diff) > 0 ? '+' : ''}${diff}`;

    }

    return '';
}


export class Activity extends Model {
    static backendResourceName = 'activity';

    static types = [
        TYPE_LOAD,
        TYPE_UNLOAD,
        TYPE_FERRY_TUNNEL,
        'equipment pick-up',
        TYPE_CHANGE,
        TYPE_TRUCK_SWAP,
        TYPE_TRUCK_DROP,
        TYPE_TANKING,
        'homebase',
        TYPE_TRAILER_CHANGE,
        TYPE_SECURE_PARKING,
        TYPE_PARKING,
        'service',
        TYPE_TACHO_CALIBRATION,
        TYPE_TUV,
        TYPE_BORDER,
        'other',
        TYPE_POI,
    ];
    static activityTypes = KIND_ACTIVITY_TYPES;
    static customerTypes = [
        TYPE_FERRY_TUNNEL,
        'equipment pick-up',
        'service',
        TYPE_PARKING,
        TYPE_BORDER,
        'homebase',
        'other',
    ];
    static stopTypes = [
        'tanking',
        'homebase',
        TYPE_PARKING,
        'service',
        TYPE_CHANGE,
        TYPE_TRUCK_SWAP,
        TYPE_TRUCK_DROP,
        TYPE_TACHO_CALIBRATION,
        TYPE_TUV,
        'other',
        TYPE_ALLOCATION_EXIT,
    ];
    static statuses = [
        STATUS_PLANNED,
        STATUS_ETA,
        STATUS_ARRIVED,
        STATUS_WAITING,
        STATUS_STARTED,
        STATUS_FINISHED,
    ];
    static allConditions = [
        CONDITION_ADR,
        'aircargo',
        'overlength',
        'overwidth',
        CONDITION_TEMPERATURE,
        CONDITION_TSR1_PLUS,
        CONDITION_TSR1,
        CONDITION_TSR2,
        CONDITION_SENT_GEO,
        CONDITION_GDP,
    ];

    static allTankingOptions = [
        'diesel',
        'adblue',
        'frigo'
    ];

    @observable id = null;
    @observable orderReference = '';
    @observable status = STATUS_PLANNED;
    @observable isAsap = true;
    @observable type = TYPE_LOAD;
    @observable kind = KIND_ACTIVITY;
    @observable instructions = '';
    @observable cargoDescription = '';
    @observable reasonNonApprovedPetrolStation = '';
    @observable remarks = '';
    @observable givenKm = null;
    @observable plannedKm = null;
    @observable lastGivenKm = null;
    @observable lastPlannedKm = null;
    @observable detourKm = 0;
    @observable startKm = null;
    @observable endKm = null;
    @observable remainingKm = null;
    @observable uuid = '';
    @observable totalActivityDrivenKm = null;
    @observable orderedArrivalDatetimeFrom = null;
    @observable actualArrivalDatetime = null;
    @observable orderedArrivalDatetimeUntil = null;
    @observable finishedDatetime = null;
    @observable _finishedDatetime = null; // Hack to be able quickly calculate waiting rates for planning customer.
    @observable communicatedArrivalDatetime = null;
    @observable estimatedRemainingTravelTime = null; // In seconds
    @observable estimatedArrivalDatetime = null;
    @observable lastRouteSent = null; // not a property of activity itself
    @observable lastStatusUpdateSentAt = null;
    @observable startDatetime = null;
    @observable waitStartDatetime = null;
    @observable finalizedAt = null;
    @observable tollCosts = 0;
    @observable outsideEu = null;
    @observable equipmentPlanned = '';
    @observable equipmentActual = '';
    @observable requiredDocuments = ['cmr'];
    @observable conditions = [];
    @observable tankingOptions = [];
    @observable minTemp = null;
    @observable maxTemp = null;
    @observable deleted = false;
    @observable createdAt = null;
    @observable documentsRejectedByCustomerAt = null;
    @observable kmDeviation = null;

    @observable calculatedWaitingAmount = 0;
    // @observable waitCostApproved = 0; // -> renamed to preliminaryWaitingAmount
    // @observable expectedKmAmount = 0;
    // @observable expectedKmSurcharge = 0;
    // @observable expectedFixedAmount = 0;
    // @observable expectedFixedSurcharge = 0;
    // @observable expectedSecondDriverAmount = 0;
    // @observable expectedWeekendAmount = 0;
    // @observable expectedTollAmount = 0;

    @observable preliminaryWeekendAmount = 0;
    @observable preliminaryKmAmount = 0;
    @observable preliminaryKmSurcharge = 0;
    @observable preliminaryFixedAmount = 0;
    @observable preliminaryFixedSurcharge = 0;
    @observable preliminarySecondDriverAmount = 0;
    @observable preliminaryTollAmount = 0;
    @observable preliminaryWaitingAmount = 0;

    @observable routeUseRealtimeInfo = true;
    @observable routeOptimizationLevel = null;
    @observable combinedTransportId = '';

    @observable startFuelLevel = null;
    @observable endFuelLevel = null;
    @observable reportedFuelLevelIncrease = null;
    @observable requestedFuelLevelIncrease = null;
    @observable refuelNotificationMessage = '';

    @observable lastStatusUpdateDelayReasonTime = null;
	@observable expectedTimeForActivity = null; // In minutes, or null

    @observable invoiced = false;
    @observable invoiceSummary = {
        invoiced_total_amount: 0,
        agreed_total: 0,
    };

    // This is not normal activity attribute we use this when there is validation error for "reopening an already finished activity"
    // So we set this attribute to true and we send it to backend to reopen finished activity.
    @observable allowReopeningFinishedActivities = false;

    @observable _lastMessageUsedForSettingMissingTimes = null;
    @observable _etaInfo = {};

    // Cache, so no observable.
    _tariffKmRateExplaination = '';

    comparator = 'orderedArrivalDatetimeFrom';

    relations() {
        return {
            location: Location,
            assignment: Assignment,
            allocation: Allocation,
            trailer: Trailer,
            nextTrailer: Trailer,
            documents: DocumentOrderedByTypeStore,
            previousActivity: Activity,
            previousStops: ActivityStore,
            otherCosts: OtherCostStore,
            orderConfirmations: OrderConfirmationStore,
            finalizedBy: User,
            enteredBy: User,

            // For truck / driver changes, you have to set the next assignment.
            reassignmentChange: ReassignmentChange,
            reassignmentTruckswap: ReassignmentTruckswap,
            invoiceLineItems: InvoiceLineItemStore,
            issues: ActivityIssueStore,
            lastRouteMessage: Message,
            petrolStationsInRange: PetrolStationStore,
            ignoredPetrolStationsInRange: PetrolStationStore,
            petrolStation: PetrolStation,
            fixedRateDays: FixedRateDayStore,
            equipmentChanges: EquipmentChangeStore,
            proposedActivity: ProposedActivity,
            costItems: CostItemStore,
            waiverItems: WaiverItemStore,
            invoiceLineCorrectionItems: InvoiceLineCorrectionItemStore,

            bookingsEurotunnel: BookingEurotunnelStore,
            parkingBookings: ParkingBookingStore,
            parking: Parking,
        };
    }

    casts() {
        return {
            actualArrivalDatetime: Casts.datetime,
            orderedArrivalDatetimeFrom: Casts.datetime,
            orderedArrivalDatetimeUntil: Casts.datetime,
            finishedDatetime: Casts.datetime,
            startDatetime: Casts.datetime,
            waitStartDatetime: Casts.datetime,
            communicatedArrivalDatetime: Casts.datetime,
            estimatedArrivalDatetime: Casts.datetime,
            finalizedAt: Casts.datetime,
            lastRouteSent: Casts.datetime,
            lastStatusUpdateSentAt: Casts.datetime,
            createdAt: Casts.datetime,
            lastStatusUpdateDelayReasonTime: Casts.datetime,
            documentsRejectedByCustomerAt: Casts.datetime,
        };
    }

    @action
    parse(data) {
        const result = super.parse(data);

        // When timezone is available, display local time of location.
        if (this.location && this.location.timezone) {
            if (moment.isMoment(this.orderedArrivalDatetimeFrom)) {
                this.orderedArrivalDatetimeFrom = moment.tz(this.orderedArrivalDatetimeFrom.format(), this.location.timezone);
            }

            if (moment.isMoment(this.orderedArrivalDatetimeUntil)) {
                this.orderedArrivalDatetimeUntil = moment.tz(this.orderedArrivalDatetimeUntil.format(), this.location.timezone);
            }

            if (moment.isMoment(this.communicatedArrivalDatetime)) {
                this.communicatedArrivalDatetime = moment.tz(this.communicatedArrivalDatetime.format(), this.location.timezone);
            }
        }

        return result;
    }

    @computed
    get canFinalize() {
        return this.plannedKm !== null && this.givenKm !== null;
    }

    @computed
    get isReassignment() {
        return [TYPE_CHANGE, TYPE_TRUCK_SWAP].includes(this.type);
    }

    @computed
    get documentRejectUrl() {
        return `${window.location.origin}/public/document/reject/{{UUID}}/`;
    }

    @computed
    get debugMessage() {
        let message = '';

        if (this.assignment) {
            message += `id: ${this.id}\nassignment: ${this.assignment.id}\n`;
        }

        if (this.trailer) {
            message += `current trailer: ${this.trailer.id}\n${this.showTrailer() ? `next trailer: ${this.nextTrailer.id}\n` : ''}`;
        }

        return message;
    }

    @computed
    get drivenKm() {
        if (this.startKm !== null && this.endKm !== null) {
            return this.endKm - this.startKm;
        }
        return 0;
    }

    /**
     * Calculates driven km taking previous stops into account.
     */
    @computed
    get drivenKmIncludingStops() {
        let km = this.drivenKm;

        if (this.previousStops) {
            this.previousStops.filter(stop => !stop.deleted).forEach(stop => {
                km += stop.drivenKmIncludingStops;
            });
        }

        return km;
    }

    @computed
    get startKmIncludingStops() {
        if (this.startKm) {
            let startKm = this.startKm;
            const currentTruckId = this.assignment?.truck?.id

            // {previous-activity-deleted}
            if (this.previousStops && this.previousStops.filter(stop => !stop.deleted && stop.assignment?.truck?.id === currentTruckId).length > 0) {
                startKm = this.previousStops.filter(stop => !stop.deleted && stop.assignment?.truck?.id === currentTruckId)[0].startKm;
            }

            return startKm;
        }
        return 0;
    }

    @computed
    get deviationKm() {
        return this.drivenKm - this.plannedKm;
    }

    @computed
    get deviationKmIncludingStops() {
        return this.drivenKmIncludingStops - this.plannedKm;
    }

    @computed
    get unpaidKm() {
        if (this.givenKm === 0) {
            return this.plannedKm;
        }
        return this.plannedKm - this.givenKm;
    }

    @computed
    get inWeekend() {
        // 6 = Saturday, 7 = Sunday
        if (
            this.finishedDatetime &&
            ['6', '7'].includes(this.finishedDatetime.format('E'))
        ) {
            return true;
        }

        return false;
    }

    @computed
    get deviationKmPercent() {
        if (this.deviationKm !== 0) {
            return ((1 - this.plannedKm / this.drivenKm) * 100).toFixed(2);
        }
        return 0;
    }

    @computed
    get deviationKmPercentIncludingStops() {
        if (this.deviationKm !== 0) {
            return ((1 - this.plannedKm / this.drivenKmIncludingStops) * 100).toFixed(2);
        }
        return 0;
    }

    @computed
    get hasMinimalData() {
        if (this.type === TYPE_CHANGE) {
            return !this.reassignmentChange.isNew;
        }

        return true;
    }

    @computed
    get expectedKm() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.expectedKm, 0);
        }

        return 0;
    }

    @computed
    get expectedInvoicedKm() {
        return this.expectedKm;
    }

    @computed
    get agreedInvoicedKm() {
        if (this.invoiceLineItems) {
            return this.expectedKm - this.waivedInvoicedKm;
        }

        return 0;
    }

    @computed
    get invoicedInvoicedKm() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.invoicedKm, 0);
        }

        return 0;
    }

     @computed
    get correctedInvoicedKm() {
        if (this.invoiceLineCorrectionItems) {
            return this.invoiceLineCorrectionItems.models.reduce((res, item) => res + item.correctedInvoicedKm, 0);
        }

        return 0;
    }

    @computed
    get waivedInvoicedKm() {
        if (this.waiverItems) {
            return this.waiverItems.models.reduce((res, item) => res + item.waivedInvoicedKm, 0);
        }

        return 0;
    }

    @computed
    get expectedKmAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.expectedKmAmount, 0);
        }

        return 0;
    }

    @computed
    get agreedKmAmount() {
        if (this.invoiceLineItems) {
            return this.expectedKmAmount - this.waivedKmAmount;
        }

        return 0;
    }

    @computed
    get waivedKmAmount() {
        if (this.waiverItems) {
            return this.waiverItems.models.reduce((res, item) => res + item.waivedKmAmount, 0);
        }

        return 0;
    }

    @computed
    get correctedKmAmount() {
        if (this.invoiceLineCorrectionItems) {
            return this.invoiceLineCorrectionItems.models.reduce((res, item) => res + item.correctedKmAmount, 0);
        }

        return 0;
    }

    @computed
    get invoicedKmAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.kmAmount, 0);
        }

        return 0;
    }

    @computed
    get agreedFixedAmount() {
        if (this.invoiceLineItems) {
            return this.expectedFixedAmount - this.waivedFixedAmount;
        }

        return 0;
    }

    @computed
    get waivedFixedAmount() {
        if (this.waiverItems) {
            return this.waiverItems.models.reduce((res, item) => res + item.waivedFixedAmount, 0);
        }

        return 0;
    }

    @computed
    get correctedFixedAmount() {
        if (this.invoiceLineCorrectionItems) {
            return this.invoiceLineCorrectionItems.models.reduce((res, item) => res + item.correctedFixedAmount, 0)
        }

        return 0;
    }

    @computed
    get expectedFixedAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.expectedFixedAmount, 0);
        }

        return 0;
    }

    @computed
    get invoicedFixedAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.fixedAmount, 0);
        }

        return 0;
    }

    @computed
    get correctedWaitingAmount() {
        return this.invoiceLineCorrectionItems.models.reduce((res, item) => res + item.correctedWaitingAmount, 0);
    }

    @computed
    get agreedWaitingAmount() {
        if (this.invoiceLineItems) {
            return this.expectedWaitingAmount - this.waivedWaitingAmount;
        }

        return 0;
    }

    @computed
    get waivedWaitingAmount() {
        if (this.waiverItems) {
            return this.waiverItems.models.reduce((res, item) => res + item.waivedWaitingAmount, 0);
        }

        return 0;
    }

    @computed
    get expectedWaitingAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.expectedWaitingAmount, 0);
        }

        return 0;
    }

    @computed
    get invoicedWaitingAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.waitingAmount, 0);
        }

        return 0;
    }

    @computed
    get invoicedSecondDriverAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.secondDriverAmount, 0);
        }

        return 0;
    }

    @computed
    get expectedSecondDriverAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.expectedSecondDriverAmount, 0);
        }

        return 0;
    }

    @computed
    get agreedSecondDriverAmount() {
        if (this.invoiceLineItems) {
            return this.expectedSecondDriverAmount - this.waivedSecondDriverAmount;
        }

        return 0;
    }

    @computed
    get correctedSecondDriverAmount() {
        if (this.invoiceLineCorrectionItems) {
            return this.invoiceLineCorrectionItems.models.reduce((res, item) => res + item.correctedSecondDriverAmount, 0);
        }

        return 0;
    }

    @computed
    get waivedSecondDriverAmount() {
        if (this.waiverItems) {
            return this.waiverItems.models.reduce((res, item) => res + item.waivedSecondDriverAmount, 0);
        }

        return 0;
    }


    @computed
    get agreedTollAmount() {
        if (this.invoiceLineItems) {
            return this.expectedTollAmount - this.waivedTollAmount;
        }

        return 0;
    }

    @computed
    get waivedTollAmount() {
        if (this.waiverItems) {
            return this.waiverItems.models.reduce((res, item) => res + item.waivedTollAmount, 0);
        }

        return 0;
    }

    @computed
    get correctedTollAmount() {
        return this.invoiceLineCorrectionItems.models.reduce((res, item) => res + item.correctedTollAmount, 0);
    }

    @computed
    get expectedTollAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.expectedTollAmount, 0);
        }

        return 0;
    }

    @computed
    get invoicedTollAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.tollAmount, 0);
        }

        return 0;
    }

    @computed
    get agreedAmount() {
        return this.agreedKmAmount
            + this.agreedFixedAmount
            + this.agreedWaitingAmount
            + this.agreedSecondDriverAmount
            + this.agreedTollAmount
            + this.agreedKmSurcharge
            + this.agreedFixedSurcharge
            + this.agreedOtherCostsAmount
            + this.agreedCustomAmount;
    }

    @computed
    get waivedAmount() {
        return this.waivedKmAmount
            + this.waivedFixedAmount
            + this.waivedWaitingAmount
            + this.waivedSecondDriverAmount
            + this.waivedTollAmount
            + this.waivedKmSurcharge
            + this.waivedFixedSurcharge
            + this.waivedOtherCostsAmount
            + this.waivedCustomAmount;
    }

    get correctedAmount() {
        return this.correctedKmAmount
            + this.correctedFixedAmount
            + this.correctedWaitingAmount
            + this.correctedSecondDriverAmount
            + this.correctedTollAmount
            + this.correctedKmSurcharge
            + this.correctedFixedSurcharge
            + this.correctedOtherCostsAmount
            + this.correctedCustomAmount;
    }

    @computed
    get invoicedAmount() {
        return this.invoicedKmAmount
            + this.invoicedFixedAmount
            + this.invoicedWaitingAmount
            + this.invoicedSecondDriverAmount
            + this.invoicedTollAmount
            + this.invoicedKmSurcharge
            + this.invoicedFixedSurcharge
            + this.invoicedOtherCostsAmount
            + this.invoicedCustomAmount;
    }

    @computed
    get agreedKmSurcharge() {
        if (this.invoiceLineItems) {
            return this.expectedKmSurcharge - this.waivedKmSurcharge;
        }

        return 0;
    }

    @computed
    get waivedKmSurcharge() {
        if (this.waiverItems) {
            return this.waiverItems.models.reduce((res, item) => res + item.waivedKmSurcharge, 0);
        }

        return 0;
    }

    @computed
    get correctedKmSurcharge() {
        if (this.invoiceLineCorrectionItems) {
            return this.invoiceLineCorrectionItems.models.reduce((res, item) => res + item.correctedKmSurcharge, 0);
        }

        return 0;
    }

    @computed
    get expectedKmSurcharge() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.expectedKmSurcharge, 0);
        }

        return 0;
    }

    @computed
    get invoicedKmSurcharge() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.kmSurcharge, 0);
        }

        return 0;
    }

    @computed
    get agreedFixedSurcharge() {
        if (this.invoiceLineItems) {
            return this.expectedFixedSurcharge - this.waivedFixedSurcharge;
        }

        return 0;
    }

    @computed
    get waivedFixedSurcharge() {
        if (this.waiverItems) {
            return this.waiverItems.models.reduce((res, item) => res + item.waivedFixedSurcharge, 0);
        }

        return 0;
    }

    @computed
    get correctedFixedSurcharge() {
        if (this.invoiceLineCorrectionItems) {
            return this.invoiceLineCorrectionItems.models.reduce((res, item) => res + item.correctedFixedSurcharge, 0);
        }

        return 0;
    }

    @computed
    get expectedFixedSurcharge() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.expectedFixedSurcharge, 0);
        }

        return 0;
    }

    @computed
    get invoicedFixedSurcharge() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.fixedSurcharge, 0);
        }

        return 0;
    }

    @computed
    get expectedOtherCostsAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.expectedOtherCostsAmount, 0);
        }

        return 0;
    }

    @computed
    get waivedOtherCostsAmount() {
        if (this.waiverItems) {
            return this.waiverItems.models.reduce((res, item) => res + item.waivedOtherCostsAmount, 0);
        }

        return 0;
    }

    @computed
    get correctedOtherCostsAmount() {
        if (this.invoiceLineCorrectionItems) {
            return this.invoiceLineCorrectionItems.models.reduce((res, item) => res + item.correctedOtherCostsAmount, 0);
        }

        return 0;
    }

    @computed
    get agreedOtherCostsAmount() {
        if (this.invoiceLineItems) {
            return this.expectedOtherCostsAmount - this.waivedOtherCostsAmount;
        }

        return 0;
    }

    @computed
    get invoicedOtherCostsAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.otherCostsAmount, 0) + this.correctedOtherCostsAmount;
        }

        return 0;
    }

    @computed
    get agreedCustomAmount() {
        if (this.invoiceLineItems) {
            return this.expectedCustomAmount - this.waivedCustomAmount;
        }

        return 0;
    }

    @computed
    get correctedCustomAmount() {
        if (this.invoiceLineCorrectionItems) {
            return this.invoiceLineCorrectionItems.models.reduce((res, item) => res + item.correctedCustomAmount, 0);
        }

        return 0;
    }

    @computed
    get waivedCustomAmount() {
        if (this.waiverItems) {
            return this.waiverItems.models.reduce((res, item) => res + item.waivedCustomAmount, 0);
        }

        return 0;
    }

    @computed
    get expectedCustomAmount() {
        return this.invoicedCustomAmount;
    }

    @computed
    get invoicedCustomAmount() {
        if (this.invoiceLineItems) {
            return this.invoiceLineItems.models.reduce((res, item) => res + item.customAmount, 0);
        }

        return 0;
    }

    @computed
    get timezoneOffsetDuration() {
        const utc = moment.utc();
        const userOffset = moment.tz.zone(USER_TIMEZONE).utcOffset(utc);
        const activityOffset = moment.tz.zone(this.location.timezone).utcOffset(utc);
        return moment.duration(userOffset - activityOffset, 'minutes');
    }

    @computed
    get timezoneOffsetHours() {
        return this.timezoneOffsetDuration ? formatDuration(this.timezoneOffsetDuration) : '';
    }

    /**
     * Get before, drop, take and after quantities for trackedEquipment.
     *
     * @returns {Object} { equipmentChange, name: 'name',  before: 0, drop: 0, take: 0, after: 0 }
     */
    trackEquipmentCounts(trackedEquipment, allocation) {
        const result = { name: '', before: 0, drop: 0, take: 0, after: 0 };

        if (!trackedEquipment) {
            return result;
        }

        const equipmentChange = this.equipmentChanges.find(e => e.equipmentType.id === trackedEquipment.equipmentType.id && !e.isCorrection);
        const equipmentChangeCorrection = this.equipmentChanges.find(e => e.equipmentType.id === trackedEquipment.equipmentType.id && e.isCorrection);

        // Activity is still fetching.
        if (!(equipmentChange instanceof Model) || !(equipmentChangeCorrection instanceof Model)) {
            return result;
        }

        let equipment = this.trailer.equipments.find(e => e.equipmentType.id === trackedEquipment.equipmentType.id);
        let startEquipmentQuantity = equipment ? equipment.quantity : 0;

        if (trackedEquipment.trackingTarget === TRACKING_TARGET_ALLOCATION) {
            equipment = allocation.equipments.find(e => e.equipmentType.id === trackedEquipment.equipmentType.id);
            startEquipmentQuantity = equipment ? equipment.quantity : 0;
        }

        result.equipmentChange = equipmentChange;
        result.equipmentChangeCorrection = equipmentChangeCorrection;
        result.name = trackedEquipment.equipmentType.name;
        result.drop = equipmentChange.unload;
        result.take = equipmentChange.load;


        result.before = startEquipmentQuantity;

        // Make sure to avoid counting double when changes are already applied.
        if (equipmentChangeCorrection.status !== STATUS_APPLIED) {
            result.before = result.before
                - equipmentChangeCorrection.unload
                + equipmentChangeCorrection.load
        } else {
            result.before = result.before
                + equipmentChangeCorrection.unload
                - equipmentChangeCorrection.load
        }

        if (equipmentChangeCorrection.status === STATUS_APPLIED) {
            result.before = result.before
                + equipmentChange.unload
                - equipmentChange.load;
        }

        result.after = result.before
            - equipmentChange.unload
            + equipmentChange.load;

        return result;
    }

    toBackend(options) {
        const orderReferenceKey = Model.toBackendAttrKey('orderReference');
        let data = omit(super.toBackend(options), [
            'previous_stops',
            'previous_activity',
            'last_route_sent',
            'invoiced',
            'invoice_summary',
        ]);

        if (data[orderReferenceKey] !== undefined) {
            data[orderReferenceKey] = trim(data[orderReferenceKey]);
        }

        if (this.type !== TYPE_TANKING) {
            data = omit(data, 'refuel_notification_message');
        }

        return data;
    }

    finalize(email) {
        return this.api.post(this.url + 'finalize/', {
            to: email.recipients,
            subject: useActualUuidTag(email.subject),
            body: useActualUuidTag(email.content),
        });
    }

    convertToDropPickup(requestingActivity) {
        return this.api.post(this.url + 'convert_to_drop_pickup/', {
            requesting_activity: requestingActivity.id,
        });
    }

    ignoreAllPetrolStationsInRange() {
        this.wrapPendingRequestCount(this.api.patch(this.url, {
            ignored_petrol_stations_in_range: this.petrolStationsInRange.map(ps => ps.id),
        }));
    }

    @computed
    get petrolStationsInRangeNotIgnored() {
        return this.petrolStationsInRange.filter(ps => !this.ignoredPetrolStationsInRange.get(ps.id));
    }

    /**
     * Set preliminary fields using tariff. These are rounded numbers, because
     * these are stored in the database as integers.
     */
    setPreliminaryAmounts(tariff) {
        // Preliminary waiting amount is not calculated, but given by the client.
        // We still want to save the calculated value though.
        this.setInput('calculatedWaitingAmount', Math.round(tariff.calcWaitingRateAmount(this)));

        this.setInput('preliminaryKmAmount', Math.round(tariff.calcPreliminaryKmAmount(this)));
        this.setInput('preliminaryKmSurcharge', Math.round(tariff.calcKmSurcharge(this)));
        this.setInput('preliminaryFixedAmount', Math.round(tariff.calcFixedRateAmount(this)));
        this.setInput('preliminaryFixedSurcharge', Math.round(tariff.calcFixedSurcharge(this)));
        this.setInput('preliminarySecondDriverAmount', Math.round(tariff.calcSecondDriverAmount(this)));
        this.setInput('preliminaryWeekendAmount', Math.round(tariff.calcWeekendRateAmount(this)));

        // Currently toll costs and preliminary toll amount are always the same.
        // The idea is that the toll costs are the costs boekestijn had to pay,
        // and preliminary toll amount the toll amount approved by the client
        // (similar to calculatedWaitingAmount / preliminary waiting amount).
        this.setInput('preliminaryTollAmount', Math.round(tariff.calcTollAmount(this)));
    }

    // /**
    //  * Set expected fields using tariff. These are rounded numbers, because
    //  * these are stored in the database as integers.
    //  */
    // setExpectedAmounts(tariff, bundledActivities = []) {
    //     this.setInput('expectedKmAmount', Math.round(tariff.calcKmAmount(this, bundledActivities)));
    //     this.setInput('expectedKmSurcharge', Math.round(tariff.calcKmSurcharge(this, bundledActivities)));
    //     this.setInput('expectedFixedAmount', Math.round(tariff.calcFixedRateAmount(this, bundledActivities)));
    //     this.setInput('expectedFixedSurcharge', Math.round(tariff.calcFixedSurcharge(this, bundledActivities)));
    //     this.setInput('expectedSecondDriverAmount', Math.round(tariff.calcSecondDriverAmount(this)));
    //     this.setInput('expectedWeekendAmount', Math.round(tariff.calcWeekendRateAmount(this)));
    //     this.setInput('expectedTollAmount', Math.round(tariff.calcTollAmount(this)));
    // }

    @computed get expectedAmount() {
        return this.expectedWaitingAmount +
            this.expectedKmAmount +
            this.expectedKmSurcharge +
            this.expectedFixedAmount +
            this.expectedFixedSurcharge +
            this.expectedOtherCostsAmount +
            this.expectedCustomAmount +
            this.expectedSecondDriverAmount +
            //this.expectedWeekendAmount +
            this.expectedTollAmount;
    }

    getRelevantTypes() {
        if (this.kind === 'boek stop') {
            return this.constructor.stopTypes;
        }
        if (this.kind === KIND_CUSTOMER_STOP) {
            return this.constructor.customerTypes;
        }
        return this.constructor.activityTypes;
    }

    @action
    cleanHiddenFields() {
        if (!this.showCargoDescription()) {
            this.cargoDescription = '';
        }
        if (!this.showRequiredDocuments()) {
            this.requiredDocuments.clear();
        }
        if (!this.showConditions()) {
            this.conditions.clear();
        }
        if (!this.showOrderReference()) {
            this.orderReference = '';
        }
        if (!this.showTemps) {
            this.minTemp = null;
            this.maxTemp = null;
        }
    }

    unFinalize() {
        this.__pendingRequestCount += 1;
        return this.api
            .post(this.url + 'unfinalize/')
            .then(response => {
                // Copy specific fields from backend.
                this.finalizedAt = response.data.finalized_at;
                this.finalizedBy = response.data.finalized_by_id;
            })
            .then(() => {
                this.__pendingRequestCount -= 1;
            })
            .catch(err => {
                this.__pendingRequestCount -= 1;
                throw err;
            });
    }

    clearKms(recalculate = true) {
        this.__pendingRequestCount += 1;
        return this.api
            .post(this.url + 'clear_kms/', {
                recalculate,
            })
            .then(response => {
                // Copy specific fields from backend.
                this.plannedKm = response.data.planned_km;
                this.givenKm = response.data.given_km;
                this.tollCosts = response.data.toll_costs;
                // this.countriesOnRoute = response.data.countries;
            })
            .then(() => {
                this.__pendingRequestCount -= 1;
            })
            .catch(err => {
                this.__pendingRequestCount -= 1;
                throw err;
            });
    }

    regenerateTemperaturePrint() {
        this.__pendingRequestCount += 1;

        const p = this.api.post(this.url + 'regenerate_temperature_print/').then(res => {
            const doc = this.documents.find(doc => doc.id === res.document.id);
            if (!doc) {
                this.documents.add(res.document);
            }

            return res;
        });

        p.then(() => this.__pendingRequestCount -= 1).catch(this.__pendingRequestCount -= 1);

        return p;
    }


    getStatusDatetimeAttr(status) {
        switch (status) {
            case 'planned':
            case 'eta':
                return 'communicatedArrivalDatetime';
            case 'arrived':
                return 'actualArrivalDatetime';
            case 'waiting':
                return 'waitStartDatetime';
            case 'started':
                return 'startDatetime';
            case 'finished':
                return 'finishedDatetime';
            default:
                return '';
        }
    }

    /**
     * Try to auto set activity status datetime using the message sent
     * by the driver.
     */
    @action
    setStatusDatetime(status, truck, scopeUsingUuid = false, fetchProps = {}) {
        let field = this.getStatusDatetimeAttr(status);

        if (field) {
            // TODO: This should be moved to somewhere else
            const store = new MessageStore({ relations:
                ['truck', 'activity.location'], // To render message in popup.
            });
            store.params = {
                '.type': 'form',
                '.driver_activity_status': status,
                '.truck': truck.id,
                order_by: '-written_at',
                limit: 1,
            };

            if (scopeUsingUuid && this.uuid) {
                store.params['.job_uuid'] = this.uuid;
            }

            return store.fetch({ supressError: true, ...fetchProps }).then(() => {
                let now = this.location ? moment.tz(this.location.timezone) : moment();
                let message = null;

                if (store.length > 0 && this.createdAt <= store.at(0).writtenAt) {
                    message = store.at(0);
                    now = message.writtenAt;
                }

                if (!this[field]) {
                    this.setInput(field, now);
                    this.setInput('_lastMessageUsedForSettingMissingTimes', message);
                }
            });
        }
        return Promise.resolve();
    }

    @action
    setLastStatusUpdate() {
        this.setInput('lastStatusUpdateSentAt', moment());

        // Should really build in support for this...
        const toBackend = this.toBackend;

        this.toBackend = function(...args) {
            const data = toBackend.call(this, ...args);
            return pick(data, 'last_status_update_sent_at');
        };
        const result = this.save({ onlyChanges: true });

        this.toBackend = toBackend;
        return result;
    }

    showCargoDescription() {
        return [TYPE_LOAD, TYPE_UNLOAD].includes(this.type);
    }

    showTrailer() {
        return this.type === TYPE_TRAILER_CHANGE;
    }

    showRequiredDocuments() {
        return [
            TYPE_LOAD,
            TYPE_UNLOAD,
            TYPE_TUV,
            'equipment pick-up',
            TYPE_TRAILER_CHANGE,
        ].includes(this.type);
    }

    showConditions() {
        return [TYPE_LOAD, TYPE_UNLOAD, TYPE_TRAILER_CHANGE].includes(this.type);
    }

    showOrderReference() {
        return this.kind === KIND_ACTIVITY;
    }

    @computed
    get showTemps() {
        return this.conditions.includes('temperature');
    }

    calcOtherCostsAmount() {
        if (this.otherCosts) {
            return this.otherCosts.models.reduce((res, oc) => res + oc.amount, 0);
        }

        return 0;
    }

    @computed
    get explainOtherCosts() {
        let reason = '';
        const currencySymbol = getCurrencySymbolFromCode((this.allocation && this.allocation.contract && this.allocation.contract.fcCode) ? this.allocation.contract.fcCode : OWN_CURRENCY)
        if (this.otherCosts) {
            this.otherCosts.forEach(oc => {
                reason += `${oc.description}: ${currencySymbol}${getMoneyForUser(oc.amount)}\n\n`;
            });
        }

        return reason;
    }

    @computed
    get expectedWaitingTime() {
        if (this.expectedTimeForActivity !== null && OVERLOADABLE_TIME_TYPES.includes(this.type)) {
            return this.expectedTimeForActivity;
        } else {
            return this.allocation.contract[camelCase('expected_time_for_' + this.type)];
        }
    }

    // Returns a list of trucks and the kilometers they drove consecutively (aaneenvolgend) until another truck took over.
    getConsecutivelyDrivenByTruck() {
        if (!this.__activeCurrentRelations.includes('previousStops')) {
            throw new Error('No previousStops relation');
        }
        const list = [];
        const activities = [this, ...this.previousStops.models].sort((a, b) => {
            if (!a.orderedArrivalDatetimeFrom || !b.orderedArrivalDatetimeFrom) {
                return 0;
            }

            const aDatetime = a.orderedArrivalDatetimeFrom.format('x');
            const bDatetime = b.orderedArrivalDatetimeFrom.format('x');

            if (aDatetime < bDatetime) {
                return -1;
            }

            if (aDatetime > bDatetime) {
                return 1;
            }

            return 0;
        });

        each(activities, (activity, i) => {
            const truck = activity.assignment.truck;
            const last = list[list.length - 1];
            if (!truck.id) {
                return;
            }

            if (last && last.truck.id === truck.id) {
                last.drivenKm += activity.drivenKm;
                last.endKm = activity.endKm;
                if (activity.trailer) {
                    last.trailers.push(activity.trailer);
                }
            } else {
                list.push({
                    truck,
                    driver1: activity.assignment.driver1,
                    driver2: activity.assignment.driver2,
                    drivenKm: activity.drivenKm,
                    startKm: activity.startKm,
                    endKm: activity.endKm,
                    trailers: activity.trailer && activity.trailer.id ? [activity.trailer] : [],
                });
            }
        });

        return list;
    }

    calculateDistance(locations) {
        return this.api
            .post('/location/calculate_distance/', { locations }, { skipRequestError: true })
            .then(res => {
                return res.distance;
            })
            .catch(res => {
                return 0;
            });
    }

    /**
     * Recursively calculate detour for given boek stops. This updates the
     * detourKm attribute.
     */
    calculateDetour(baseKm, boekStops) {
        const stop = boekStops.shift();

        if (!stop) {
            return;
        }

        const boekStopIndex = this.previousStops.models.indexOf(stop);

        const prevActivity = this.previousActivity;
        const locations = [{
            lat: prevActivity.location.point.lat,
            lng: prevActivity.location.point.lng,
        }];
        if (prevActivity.combinedTransportId) {
            locations.push({ combined_transport_id: prevActivity.combinedTransportId });
        }

        this.previousStops.forEach((a, i) => {
            if((a.kind === KIND_BOEK_STOP && i <= boekStopIndex) || (a.kind === KIND_CUSTOMER_STOP)){
                locations.push({
                    lat: a.location.point.lat,
                    lng: a.location.point.lng,
                });
                if (a.combinedTransportId) {
                    locations.push({ combined_transport_id: a.combinedTransportId });
                }
            }
        });

        locations.push({
            lat: this.location.point.lat,
            lng: this.location.point.lng,
        });
        if (this.combinedTransportId) {
            locations.push({ combined_transport_id: this.combinedTransportId });
        }

        return this.calculateDistance(locations).then(
            totalKmWithStop => {
                const detourKm = totalKmWithStop - baseKm;

                if (typeof totalKmWithStop === 'number' && detourKm >= 0) {
                    stop.setInput('detourKm', detourKm);
                }

                this.calculateDetour(totalKmWithStop, boekStops);
            }
        );
    }

    /**
     * Calculate detour for KIND_BOEK_STOP. Algorithm:
     *
     * 1. baseKm1 = route without boek stops
     * 2. Calculate route with boekStop1 = baseKm2. Detour for boekStop1 = baseKm2 - baseKm1.
     * 3. Calculate route with boekStop2 = baseKm3. Detour for boekStop2 = baseKm3 - baseKm2.
     * ...
     */
    @action
    calculateDetourKms() {
        const prevActivity = this.previousActivity;

        const locations = [{
            lat: prevActivity.location.point.lat,
            lng: prevActivity.location.point.lng,
        }];

        if (prevActivity.combinedTransportId) {
            locations.push({ combined_transport_id: prevActivity.combinedTransportId });
        }
        this.previousStops.forEach(a => {
            if (a.kind === KIND_CUSTOMER_STOP) {
                locations.push({
                    lat: a.location.point.lat,
                    lng: a.location.point.lng,
                });
                if (a.combinedTransportId) {
                    locations.push({ combined_transport_id: a.combinedTransportId });
                }
            }
        });
        locations.push({
            lat: this.location.point.lat,
            lng: this.location.point.lng,
        });
        if (this.combinedTransportId) {
            locations.push({ combined_transport_id: this.combinedTransportId });
        }

        if (prevActivity.location.isNew) {
            locations.shift();
        }

        const boekStops = this.previousStops.filter(
            a => a.kind === KIND_BOEK_STOP && !a.deleted
        );

        return this.calculateDistance(locations)
            .then(baseKm1 => {
                return this.calculateDetour(baseKm1, boekStops)
            });

    }

    locationIsAlreadySent() {
        return this.api
            .get('/message/', {
                type: 'route',
                '.location': this.location.id,
                order_by: '-id',
                limit: 1,
                ...this.params,
            })
            .then(res => {
                return res.data.length > 0;
            });
    }

    fetchLocationMessage() {
        return this.api
            .get('/message/', {
                type: 'route',
                '.location': this.location.id,
                order_by: '-id',
                limit: 1,
                ...this.params,
            })
            .then(res => {
                if (res.data.length === 1) {
                    return new Message(res.data[0]);
                }
            });
    }

    sendRoute(omitRoute = false) {
        const data = {};

        if (omitRoute) {
            data.omit_route = omitRoute;
        }

        this.__pendingRequestCount += 1;
        return this.api
            .post(`${this.url}send_activity_to_truck/`, data, {
                supressError: true,
            })
            .then(() => {
                this.__pendingRequestCount -= 1;
            })
            .catch(err => {
                this.__pendingRequestCount -= 1;
                throw err;
            });
    }

    tweakEndKm(km) {
        const data = { end_km: km };

        this.__pendingRequestCount += 1;
        return this.api
            .post(`${this.url}tweak_end_km/`, data)
            .then(() => {
                this.endKm = km;
                this.__pendingRequestCount -= 1;
            })
            .catch(err => {
                this.__pendingRequestCount -= 1;
                throw err;
            });
    }

    fetchPrecedingRoute() {
        return this.api.get(`${this.url}preceding_route/`);
    }

    getAvailableParkingsList(arrival, departure, countries, onlyFree) {
        const data = { 'arrival': arrival, 'departure': departure, 'countries': countries, 'only_free': onlyFree};
        return this.api.get(`${this.url}get_available_parkings_list/`, data);
    }

    getFreeParkingOpenList() {
        return this.api.get(`${this.url}get_free_parking_open_list/`);
    }

    isFromDpg2() {
        return this?.allocation?.contract?.customer?.isDpg2IntegrationEnabled
    }

    calculateTemporaryEta(previousActivity) {  // DEPRECATED(?)
        const locations = [{
            lat: previousActivity.location.point.lat,
            lng: previousActivity.location.point.lng,
        }];
        if (previousActivity.combinedTransportId) {
            locations.push({ combined_transport_id: previousActivity.combinedTransportId });
        }
        locations.push({
            lat: this.location.point.lat,
            lng: this.location.point.lng,
        });
        if (this.combinedTransportId) {
            locations.push({ combined_transport_id: this.combinedTransportId });
        }

        return Location.calculateRoute(locations, { key: this.id }).then(res => {
            const travelTime = res.summary.travelTime;
            // It is debatable if you want to use `finishedDatetime` from the current activity here or now()
            const finishedAt = moment();
            const nextEta = finishedAt.add(travelTime, 'seconds');
            this.setInput('communicatedArrivalDatetime', nextEta);
            this.setInput('remainingKm', Math.round(res.summary.distance / 1000));
        });
    }

    calculateTravelTimeAndDistance(startLocation) {
        const locations = [{
            lat: startLocation.point.lat,
            lng: startLocation.point.lng,
        }];
        locations.push({
            lat: this.location.point.lat,
            lng: this.location.point.lng,
        });
        if (this.combinedTransportId) {
            locations.push({ combined_transport_id: this.combinedTransportId });
        }

        return Location.calculateRoute(locations, { key: this.id }).then(res => {
            this.setInput('estimatedRemainingTravelTime', res.summary.travelTime); // In seconds
            this.setInput('remainingKm', Math.round(res.summary.distance / 1000));

            // DEPRECATED, remove all the stuff below once we no longer use the estimatedArrivalDatetime field
            const travelTime = res.summary.travelTime;
            // It is debatable if you want to use `finishedDatetime` from the current activity here or now()
            const finishedAt = moment();
            const nextEta = finishedAt.add(travelTime, 'seconds');
            this.setInput('estimatedArrivalDatetime', nextEta);
        });
    }

    /**
     * This is retarded code.  Memoize it, or *something*!
     *
     * Note that assignment now simply is allocation.currentAssignment. This is not correct, since it does not take
     * into account any driver/truck changes. The code before looked at this.assignment, but for planned activities,
     * that is never filled in.
     *
     * The correct approach would be that the caller calculates this activity's assignment using
     * allocation.calcActivityAssignment, but I don't have time to do it right now since there are performance issues
     * which I've been working on for a full week now...
     */
    calculateETAInfo(previousActivities, assignment) {
        const now = moment();
        let lastETAInfo = null;
        const cumulativeRemainingKm = this.remainingKm + previousActivities.reduce((res, act) => res + act.remainingKm, 0);

        if (previousActivities.length === 0) {
            const driver1RemainingDrivingTime = assignment.driver1.nonExtendedRemainingDayDrivingTime ? assignment.driver1.nonExtendedRemainingDayDrivingTime.asMinutes() : 0;
            const driver2RemainingDrivingTime = assignment.driver2.nonExtendedRemainingDayDrivingTime ? assignment.driver2.nonExtendedRemainingDayDrivingTime.asMinutes() : 0;

            const isDoubleTeam = !!assignment.driver2.id; // stupid
            const reducedRestsRemaining = Math.min(assignment.driver1.work15HoursDaysRemaining, isDoubleTeam ? assignment.driver2.work15HoursDaysRemaining : assignment.driver1.work15HoursDaysRemaining);

            const dayStart = moment.min(assignment.driver1.dayStart || moment(), assignment.driver2.dayStart || assignment.driver1.dayStart || moment());
            const dayEnd = dayStart.clone().add(isDoubleTeam ? { hours: 19 } : { hours: 13 }).add(reducedRestsRemaining ? { hours: 2 } : {});

            const d1WeekendbreakStart = assignment.driver1.weekendBreakStart || moment().startOf('isoweek').add(5, 'days');
            const d2WeekendbreakStart = assignment.driver2.weekendBreakStart || moment().startOf('isoweek').add(5, 'days');

            const startOfCurrentRest = isDoubleTeam ? (assignment.driver1.startOfCurrentRest && assignment.driver2.startOfCurrentRest && moment.min(assignment.driver1.startOfCurrentRest, assignment.driver2.startOfCurrentRest)) : assignment.driver1.startOfCurrentRest;

            const weekendbreakLengths = [assignment.driver1.nextWeekendbreakLength, isDoubleTeam ? assignment.driver2.nextWeekendbreakLength : assignment.driver2.nextWeekendbreakLength];

            // There is lots of duplicate code for calculating breaks in case of double team...
            // {duplicate-eta-and-break-code}
            const weekendBreakStart = moment.min(d1WeekendbreakStart, d2WeekendbreakStart);
            const nextBreakStart = weekendBreakStart ? moment.min(dayEnd, weekendBreakStart) : dayEnd ;

            lastETAInfo = {
                ETA: moment(),
                remainingDayDrivingTime: driver1RemainingDrivingTime + driver2RemainingDrivingTime,
                remainingDayWorkingTime: dayEnd.diff(moment(), 'minutes'),
                isDoubleTeam: isDoubleTeam,
                nextWeekendbreakStart: isDoubleTeam ? moment.min(d1WeekendbreakStart, d2WeekendbreakStart) : d1WeekendbreakStart,
                nextWeekendbreakLength: weekendbreakLengths.includes('long') ? 'long' : 'short', // unknown => short
                shortWeekendbreaksTaken: 0,
                longWeekendbreaksTaken: 0,
                extendedShiftsRemaining: assignment.driver1.drive10HoursRemaining + (isDoubleTeam ? assignment.driver2.drive10HoursRemaining : 0),
                reducedRestsRemaining: reducedRestsRemaining,
                reducedRestsUsed: 0,
                breaksTaken: 0,
                shortBreaksTaken: 0,
                extendedShiftsUsed: 0,
                expectedWaitingTime: 0,
                remainingContinuousDrivingTime: (isDoubleTeam ? nextBreakStart : assignment.driver1.shortBreakStart(now)).diff(moment(), 'minutes'),
                cumulativeRemainingKm,
                startOfCurrentRest: startOfCurrentRest,
                breakDuration: 0,
                weekendBreakDuration: 0,
            };
        } else {
            lastETAInfo = last(previousActivities).calculateETAInfo(dropRight(previousActivities), assignment);
        }

        // NOTE: See if lastETAInfo can be clone()d
        const result = {
            ETA: moment(lastETAInfo.ETA),
            remainingDayDrivingTime: lastETAInfo.remainingDayDrivingTime,
            remainingDayWorkingTime: lastETAInfo.remainingDayWorkingTime,
            remainingContinuousDrivingTime: lastETAInfo.remainingContinuousDrivingTime, // Is this supplied by Astrata?
            nextWeekendbreakStart: lastETAInfo.nextWeekendbreakStart,
            nextWeekendbreakLength: lastETAInfo.nextWeekendbreakLength,

            extendedShiftsRemaining: lastETAInfo.extendedShiftsRemaining,
            extendedShiftsUsed: lastETAInfo.extendedShiftsUsed,
            reducedRestsRemaining: lastETAInfo.reducedRestsRemaining,
            reducedRestsUsed: lastETAInfo.reducedRestsUsed,

            // This info is for constructing the "explanatory string":
            shortWeekendbreaksTaken: lastETAInfo.shortWeekendbreaksTaken,
            longWeekendbreaksTaken: lastETAInfo.longWeekendbreaksTaken,
            breaksTaken: lastETAInfo.breaksTaken,           // breaks are 9h or 11h rests
            shortBreaksTaken: lastETAInfo.shortBreaksTaken, // short breaks are 45 minute breaks

            expectedWaitingTime: this.expectedWaitingTime, // NOTE: This is _not_ from lastETA

            isDoubleTeam: lastETAInfo.isDoubleTeam,
            cumulativeRemainingKm,
            startOfCurrentRest: lastETAInfo.startOfCurrentRest,
            breakDuration: lastETAInfo.breakDuration,
            weekendBreakDuration: lastETAInfo.weekendBreakDuration,
        };

        // Calculations are in minutes (who cares about seconds)
        let toTravel = Math.round(this.estimatedRemainingTravelTime / 60);

        // When a truck has arrived, forget about amount of KMs to still travel.
        if (![STATUS_PLANNED, STATUS_ETA].includes(this.status)) {
            toTravel = 0;
        }

        function maybeAddWeekendbreak() {
            if (result.ETA.isAfter(result.nextWeekendbreakStart)) {
                if (result.nextWeekendbreakLength === 'short') {
                    result.shortWeekendbreaksTaken++;
                    result.nextWeekendbreakLength = 'long';
                    result.ETA.add(24, 'hours');
                    result.nextWeekendbreakStart.add(144 + 24, 'hours');
                    result.weekendBreakDuration += 24 * 60;
                } else {
                    result.longWeekendbreaksTaken++;
                    result.nextWeekendbreakLength = 'short';
                    result.ETA.add(45, 'hours');
                    result.nextWeekendbreakStart.add(144 + 45, 'hours');
                    result.weekendBreakDuration += 45 * 60;
                }
                // Driver is freshly rested, regardless of previous value
                resetRests(true);

                return true;
            }
            return false;
        }

        function resetRests(afterWeekendBreak) {
            if (result.isDoubleTeam) {

                if (afterWeekendBreak) {
                    result.reducedRestsRemaining = 3;
                    result.remainingDayWorkingTime = 21 * 60;
                } else {
                    result.remainingDayWorkingTime = result.reducedRestsRemaining ? 21 * 60 : 19 * 60;
                }
                result.remainingDayDrivingTime = 18 * 60;
                result.remainingContinuousDrivingTime = 4 * 60 + 30;

            } else { // Single driver

                if (afterWeekendBreak) {
                    result.reducedRestsRemaining = 3;
                    result.remainingDayWorkingTime = 15 * 60;
                } else {
                    result.remainingDayWorkingTime = result.reducedRestsRemaining ? 15 * 60 : 13 * 60;
                }
                result.remainingDayDrivingTime = 9 * 60;
                result.remainingContinuousDrivingTime = 4 * 60 + 30;
            }

            result.startOfCurrentRest = null;
        }

        function addBreak() {
            let restHours = result.reducedRestsRemaining > 0 ? 9 : 11;

            if (result.startOfCurrentRest) {
                restHours = Math.max(0, restHours - Math.max(moment().diff(result.startOfCurrentRest, 'hours'), 0));
            }

            result.ETA.add(restHours, 'hours');   // Add rest
            result.breakDuration += restHours * 60;

            if (restHours === 9) {
                result.reducedRestsUsed++;
                result.reducedRestsRemaining--;
            }
            resetRests(false);
            if (restHours > 0) {
                result.breaksTaken++;
            }
        }

        // Break from driving for 45 minutes, every 4.5 hours of driving
        function addShortBreak() {
            if (!result.isDoubleTeam) {
                result.ETA.add(45, 'minutes'); // Add rest
                result.shortBreaksTaken++;
                result.breakDuration += 45;
            }
            result.remainingContinuousDrivingTime = 4 * 60 + 30;
        }

        // Start by working on the previous activity.
        // But take a break first if needed.
        if (lastETAInfo.expectedWaitingTime > result.remainingDayWorkingTime) {
            addBreak();
        }

        result.remainingDayWorkingTime -= lastETAInfo.expectedWaitingTime;
        result.ETA.add(lastETAInfo.expectedWaitingTime, 'minutes');

        // Simulate driving, resting, driving etc until we can keep
        // driving in one day.  TODO: Rewrite this in terms of
        // modulo/remainder.  It's unnecessary to do a loop.
        while (toTravel > 0 && toTravel >= Math.min(result.remainingDayDrivingTime, result.remainingDayWorkingTime, result.remainingContinuousDrivingTime)) {
            if (maybeAddWeekendbreak()) continue;

            const availableMinutes = Math.min(result.remainingDayDrivingTime, result.remainingDayWorkingTime, result.remainingContinuousDrivingTime);

            toTravel -= availableMinutes;
            result.ETA.add(availableMinutes, 'minutes');         // Add drive / work

            if (toTravel === 0) break; // No needless breaks right at the end! (TODO: Test for this)

            if (result.remainingContinuousDrivingTime <= Math.min(result.remainingDayDrivingTime, result.remainingDayWorkingTime)) {
                result.remainingDayDrivingTime -= availableMinutes;
                result.remainingDayWorkingTime -= availableMinutes;
                addShortBreak();
            } else {
                // Take the 10h driving if we can, but only if that
                // wouldn't introduce an extra 45m break.
                if (result.extendedShiftsRemaining > 0
                    && toTravel > 60 /* ??? makes no sense otherwise ??? */
                    && result.remainingContinuousDrivingTime >= 60
                    && result.remainingDayWorkingTime >= 60) {

                    toTravel -= 60;
                    result.ETA.add(1, 'hour');
                    result.extendedShiftsRemaining--;
                    result.extendedShiftsUsed++;
                }
                addBreak();
            }
        }

        // Driver might need a weekend break (NOTE: Should this be done *here*?)
        maybeAddWeekendbreak();

        // Final leg of the journey, no more rests needed
        result.ETA.add({ minutes: toTravel });
        result.remainingDayDrivingTime -= toTravel;
        result.remainingDayWorkingTime -= toTravel;
        result.remainingContinuousDrivingTime -= toTravel;

        // If a break is happening right now, reduce it from the ETA (T28226).
        if (assignment.driver1.currentTachoEvent.action === 'rest') {
            const currentBreakLength = moment().diff(assignment.driver1.currentTachoEvent.measuredAt, 'minutes');
            const totalBreakDuration = result.breakDuration + result.weekendBreakDuration;

            result.ETA.subtract({ minutes: currentBreakLength });

            // If driver takes more breaks than expected.
            if (currentBreakLength > totalBreakDuration) {
                result.ETA.add({ minutes: currentBreakLength - totalBreakDuration });
            }
        }

        return result;
    }

    // This receives new activity data and only applies it to the fields that do
    // not have any changes from the user applied.
    // Note: this does not take relations into account.
    parseFieldsWithoutChanges(data) {
        const newData = omitBy(data, (value, key) => {
            return this.__changes.includes(key);
        });
        this.parse(newData);
    }

    /**
     * When a planner skips statuses, try to fill them using driver update
     * messages.
     *
     * See T16064.
     */
    setMissingDates() {
        this._lastMessageUsedForSettingMissingTimes = null;

        const status = this.status;
        const promises = [];
        const startedDateTime = this.getStatusDatetimeAttr('started');
        const waitingDateTime = this.getStatusDatetimeAttr('waiting');
        const arrivedDateTime = this.getStatusDatetimeAttr('arrived');
        const finishedDateTime = this.getStatusDatetimeAttr('finished');
        const etaDateTime = this.getStatusDatetimeAttr('eta');

        promises.push(this.setStatusDatetime(status, this.assignment.truck, true));


        switch (status) {
            case 'finished':
                if (!this[finishedDateTime]) {
                    promises.push(this.setStatusDatetime('finished', this.assignment.truck));
                }
            // Now they don't the waiting & starttime to be filled in automagically anymore T16064,
            // so waiting + started may be left empty if there are no driver updates for these statusses
            //
            // case 'started':
            //     if (!this[startedDateTime]) {
            //         promises.push(this.setStatusDatetime('started', this.assignment.truck));
            //     }
            // case 'waiting':
            //     if (!this[waitingDateTime]) {
            //         promises.push(this.setStatusDatetime('waiting', this.assignment.truck));
            //     }
                break;
            case 'arrived':
                if (!this[arrivedDateTime]) {
                    promises.push(this.setStatusDatetime('arrived', this.assignment.truck));
                }
                break;
            case 'eta':
                if (!this[etaDateTime]) {
                    promises.push(this.setStatusDatetime('eta', this.assignment.truck));
                }
                break;
            default:
                break;
        }

        return Promise.all(promises).then(() => {
            // Fix out of sync dates.


            if (!this[startedDateTime] && (this[finishedDateTime] && this[startedDateTime] > this[finishedDateTime])) {
                this[startedDateTime] = this[finishedDateTime];
            }

            if (!this[waitingDateTime] && (this[startedDateTime] && this[waitingDateTime] > this[startedDateTime])) {
                this[waitingDateTime] = this[startedDateTime];
            }

            // Since waiting and started now might not be filled in anymore, we now have to check also against the start time

            if (!this[arrivedDateTime] && (this[arrivedDateTime] === null || (this[finishedDateTime] && this[startedDateTime] > this[finishedDateTime]))) {
                this[arrivedDateTime] = this[finishedDateTime];
            }

            if (!this[arrivedDateTime] && (this[arrivedDateTime] === null || (this[startedDateTime] && this[arrivedDateTime] > this[startedDateTime]))) {
                this[arrivedDateTime] = this[startedDateTime];
            }

            if (!this[arrivedDateTime] && (this[arrivedDateTime] === null || (this[waitingDateTime] && this[arrivedDateTime] > this[waitingDateTime]))) {
                this[arrivedDateTime] = this[waitingDateTime];
            }

        });
    }

    convertTimezoneUsingLocation() {
        this.convertTimezone(this.location.timezone);
    }

    convertTimezone(timezone) {
        this.setInput('orderedArrivalDatetimeFrom', convertMomentToTimezone(this.orderedArrivalDatetimeFrom, timezone));
        this.setInput('orderedArrivalDatetimeUntil', convertMomentToTimezone(this.orderedArrivalDatetimeUntil, timezone));
    }

    hasRequiredDocuments(){
        return (![TYPE_LOAD, TYPE_TRAILER_CHANGE].includes(this.type) && this.requiredDocuments.length > 0);
    }

    sentRequiredDocuments(){
        let res = { icon : '-', titles : [] }
        const sentDocumentTypes = this.documents.filter(d => this.requiredDocuments.includes(d.type) && !!d.mailedToCustomerAt);

        res.icon = sentDocumentTypes.length === this.requiredDocuments.length ? 'check' : 'close';
        res.titles = this.documents.map(d => t(`document.field.type.options.${d.type}`) + `: ${d.mailedToCustomerAt ? d.mailedToCustomerAt.format(DATETIME_SHORT_FORMAT) : t('document.notSent')}`);

        if (sentDocumentTypes.length !== this.requiredDocuments.length) {
            this.requiredDocuments.filter(rq => !sentDocumentTypes.includes(rq)).forEach(rq => {
                res.titles.push(t(`document.field.type.options.${rq}`) + `: ${t('document.notSent')}`);
            });
        }

        return res;
    }

    getPlannedDeviation() {
        // Return the detourKm directly if it's greater than 0.
        if (this.detourKm > 0) {
            return this.detourKm;
            // Calculate deviation using lastPlannedKm and lastGivenKm for backward compatibility if both are available.
        } else if (this.lastPlannedKm != null && this.lastGivenKm != null) {
            return this.lastPlannedKm - this.lastGivenKm;
            // Otherwise, calculate deviation using plannedKm and givenKm if both are available.
        } else if (this.plannedKm != null && this.givenKm != null) {
            return this.plannedKm - this.givenKm;
            // Return 0 or handle as needed when no values are available.
        } else {
            return 0;
        }
    }

    getPlannedDeviationPercentage() {
        let givenKm, plannedKm;

        // Use lastGivenKm and lastPlannedKm for backward compatibility if both are available and lastPlannedKm is not 0.
        if (this.lastGivenKm && this.lastPlannedKm && this.lastPlannedKm !== 0) {
            givenKm = this.lastGivenKm;
            plannedKm = this.lastPlannedKm;
            // Otherwise, use givenKm and plannedKm if both are available and plannedKm is not 0.
        } else if (this.givenKm && this.plannedKm && this.plannedKm !== 0) {
            givenKm = this.givenKm;
            plannedKm = this.plannedKm;
            // Return 0 if no valid data is available.
        } else {
            return 0;
        }

        // Calculate and return the deviation percentage, rounded to one decimal place.
        return Number(((givenKm / plannedKm) - 1) * 100).toFixed(1);
    }


    getEstimatedCostOfPlannedDeviation(tollPerKm, fuelPerKm) {
        return this.getPlannedDeviation() * (tollPerKm + fuelPerKm);
    }

    getDriverDeviation(previousActivity) {
        // Only calculate deviation for finished activities.
        if (this.status !== 'finished') {
            return;
        }

        const previousStopPlannedKm = (previousActivity && previousActivity.kind !== 'activity')
            ? (previousActivity.plannedKm || previousActivity.lastPlannedKm || 0)
            : 0;

        // Calculate deviation using lastPlannedKm for backward compatibility if available.
        if (this.lastPlannedKm != null) {
            return this.drivenKm - (this.lastPlannedKm - previousStopPlannedKm);
            // Otherwise, calculate deviation using plannedKm if available.
        } else if (this.plannedKm != null) {
            return this.drivenKm - (this.plannedKm - previousStopPlannedKm);
            // Return 0 or handle as needed when no values are available.
        } else {
            return 0;
        }
    }

    getDriverDeviationPercentage(previousActivity) {
        let plannedKm;

        // Only calculate percentage deviation for finished activities.
        if (this.status !== 'finished') {
            return 0;
        }

        const previousStopPlannedKm = (previousActivity && previousActivity.kind !== 'activity')
            ? (previousActivity.plannedKm || previousActivity.lastPlannedKm || 0)
            : 0;

        // Use lastPlannedKm for backward compatibility if available and non-zero.
        if (this.lastPlannedKm != null && this.lastPlannedKm !== 0) {
            plannedKm = this.lastPlannedKm - previousStopPlannedKm;
            // Otherwise, use plannedKm if available and non-zero.
        } else if (this.plannedKm != null && this.plannedKm !== 0) {
            plannedKm = this.plannedKm - previousStopPlannedKm;
            // Otherwise, use plannedKm if available and non-zero.
            // Return 0 if no valid data is available.
        } else {
            // Otherwise, use plannedKm if available and non-zero.
            return 0;
        }

        if (plannedKm === 0) {
            return 0;
        }

        // Calculate and return the deviation percentage, rounded to one decimal place.
        return Number(((this.drivenKm / plannedKm) - 1) * 100).toFixed(1);
    }

    getEstimatedCostOfDriverDeviation(tollPerKm, fuelPerKm) {
        return this.getDriverDeviation() * (tollPerKm + fuelPerKm);
    }

}

export class ActivityStore extends Store {
    Model = Activity;
    static backendResourceName = 'activity';

    findNextActivityById(activityId) {
        const currentActivityIdx = this.models.indexOf(this.get(activityId));
        const nextActivityIdx = currentActivityIdx + 1;
        if (currentActivityIdx >= 0 && this.length > nextActivityIdx) {
            return this.at(nextActivityIdx);
        }
    }

    fetchOrderReferences() {
        return this.api.get(result(this, 'url') + 'order_reference/', Object.assign({}, this.params, { limit: 'none', offset: 0 }));
    }

    // TODO: saveAll() does not yet exist on a store, see https://github.com/CodeYellowBV/mobx-spine/issues/49
    saveAllHack(options) {
        const saves = this.map(a => !a.deleted && a.save(options));
        return Promise.all(saves);
    }

    /**
     * Finds previous activity which comes before activity based on index. Will skip deleted activities.
     *
     * Different from backend, since the activity.previousActivity also skips
     * stops.
     *
     * {copy-paste-findPreviousActivity}
     */
    findPreviousActivityByIndex(activity) {
        const i = this.map(a => a.id).indexOf(activity.id);

        try {
            const prevIndex = i - 1;

            if (prevIndex < 0) {
                return null;
            }

            const prev = this.at(prevIndex);

            if (prev.deleted) {
                return this.findPreviousActivityByIndex(prev);
            }

            return prev;
        } catch (e) {
            return null;
        }
    }

    findNextActivityByIndex(activity) {
        const currentActivityIdx = this.models.indexOf(this.get(activity.id));
        const nextActivityIdx = currentActivityIdx + 1;

        if (currentActivityIdx >= 0 && this.length > nextActivityIdx) {
            return this.at(nextActivityIdx);
        }
    }

    findPreviousActivity(allocation, activity, fetchProps = {}) {
        this.setLimit(1);
        this.params = {
            '.allocation': allocation.id,
            '.ordered_arrival_datetime_from:lt': activity.orderedArrivalDatetimeFrom
                .clone()
                .utc()
                .format(DATE_RANGE_FORMAT),
            '.id:not': activity.id,
            order_by: '-ordered_arrival_datetime_from',
        };
        return this.fetch(fetchProps).then(() => {
            if (this.length > 0) {
                return this.at(0);
            } else {
                return null;
            }
        });
    }

    findPreviousActivities(allocation, activity, fetchProps = {}) {
        this.setLimit(false);
        this.params = {
            '.allocation': allocation.id,
            '.ordered_arrival_datetime_from:lt': activity.orderedArrivalDatetimeFrom
                .clone()
                .utc()
                .format(DATE_RANGE_FORMAT),
            '.id:not': activity.id,
            order_by: '-ordered_arrival_datetime_from',
        };
        return this.fetch(fetchProps);
    }

    getTotalGivenKm() {
        // Calculate the total given kilometers, considering lastGivenKm for backward compatibility.
        return this.models.reduce((res, activity) => res + (activity.lastGivenKm || activity.givenKm || 0), 0);
    }

    getTotalPlannedKm() {
        // Calculate the total planned kilometers, considering lastPlannedKm for backward compatibility.
        return this.models.reduce((res, activity) => res + (activity.lastPlannedKm || activity.plannedKm || 0), 0);
    }

    getTotalDrivenKm() {
        return this.models.reduce((res, activity) => res + activity.drivenKm, 0);
    }

    getTotalGivenKmForFinishedActivities() {
        return this.models
            .filter(activity => activity.status === 'finished')
            .reduce((res, activity) => res + (activity.lastGivenKm || activity.givenKm || 0), 0);
    }

    getTotalPlannedKmForFinishedActivities() {
        return this.models
            .filter(activity => activity.status === 'finished')
            .reduce((res, activity) => {
                const previousActivity = this.findNextActivityByIndex(activity)

                const previousStopPlannedKm = (previousActivity && previousActivity.kind !== 'activity')
                    ? (previousActivity.plannedKm || previousActivity.lastPlannedKm || 0)
                    : 0;

                let plannedKm = 0;
                if (activity.lastPlannedKm != null) {
                    plannedKm = activity.lastPlannedKm - previousStopPlannedKm;
                } else if (activity.plannedKm != null) {
                    plannedKm = activity.plannedKm - previousStopPlannedKm;
                }
                return res + plannedKm;
            }, 0);
    }

    getTotalDrivenKmForFinishedActivities() {
        return this.models
            .filter(activity => activity.status === 'finished')
            .reduce((res, activity) => res + activity.drivenKm, 0);
    }

    getTotalPlannedDeviation() {
        return this.getTotalPlannedKm() - this.getTotalGivenKm();
    }

    getEstimatedCostOfDeviation(kmDeviation, tollPerKmAssumption, fuelPerKmAssumption) {
        return (kmDeviation * (Number(tollPerKmAssumption) + Number(fuelPerKmAssumption))).toFixed(2);
    }

    getTotalDriverDeviation() {
        return this.getTotalDrivenKmForFinishedActivities() - this.getTotalPlannedKmForFinishedActivities();
    }

    getTotalPlannedDeviationPercentage() {
        let totalGivenKm = this.getTotalGivenKm();
        let totalPlannedKm = this.getTotalPlannedKm();

        if (!totalGivenKm || !totalPlannedKm || totalPlannedKm === 0) {
            return 0;
        }

        return Number(((totalGivenKm / totalPlannedKm) - 1) * 100).toFixed(2);
    }

    getTotalDriverDeviationPercentage() {
        let totalDrivenKm = this.getTotalDrivenKmForFinishedActivities();
        let totalPlannedKm = this.getTotalPlannedKmForFinishedActivities();

        if (!totalDrivenKm|| !totalPlannedKm || totalPlannedKm === 0) {
            return 0;
        }
        return Number(((totalDrivenKm / totalPlannedKm) - 1) * 100).toFixed(2);
    }

    getDispatchers() {
        const uniqueIds = new Set();
        return this.filter(activity => {
            const id = activity.enteredBy.id;
            if (!uniqueIds.has(id)) {
                uniqueIds.add(id);
                return true;
            }
            return false;
        }).map(activity => ({
                name: `${activity.enteredBy.firstName} ${activity.enteredBy.lastName}`,
                id: activity.enteredBy.id
            }));
    }

    getDrivers() {
        const uniqueIds = new Set();
        const drivers = [];

        this.forEach(activity => {
            if (activity.assignment.driver1 && !uniqueIds.has(activity.assignment.driver1.id)) {
                uniqueIds.add(activity.assignment.driver1.id);
                drivers.push({
                    name: activity.assignment.driver1.name,
                    id: activity.assignment.driver1.id
                });
            }

            if (activity.assignment.driver2.id && !uniqueIds.has(activity.assignment.driver2.id)) {
                uniqueIds.add(activity.assignment.driver2.id);
                drivers.push({
                    name: activity.assignment.driver2.name,
                    id: activity.assignment.driver2.id
                });
            }
        });

        return drivers;
    }

    generateComparatorKmDeviation(direction, column) {
        return (a, b) => {
            let valueA, valueB;

            if (column === 'plannedDeviation') {
                valueA = a.getPlannedDeviation();
                valueB = b.getPlannedDeviation();
            } else if (column === 'driverDeviation') {
                valueA = a.getDriverDeviation();
                valueB = b.getDriverDeviation();
            } else if (column === 'activity') {
                valueA = a.orderedArrivalDatetimeFrom
                valueB = b.orderedArrivalDatetimeFrom
            } else if (column === 'activityId') {
                valueA = a.id;
                valueB = b.id;
            } else {
                throw new Error('Invalid deviation type');
            }

            if (valueA == null) return (direction === 'ascending') ? -1 : 1;
            if (valueB == null) return (direction === 'ascending') ? 1 : -1;

            if (direction === 'ascending') {
                return valueA - valueB;
            } else if (direction === 'descending') {
                return valueB - valueA;
            } else {
                throw new Error('Invalid direction');
            }
        };
    }
}

export class SortedActivityStore extends ActivityStore {
    comparator = 'orderedArrivalDatetimeFrom';
}

export const STATUSES = Activity.statuses;
