import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { IconButton } from 'spider/Button';

const MyDropzone = styled(Dropzone)`
    position: relative;
    display: inline;
    cursor: pointer;
`;

const activeStyle = {
    position: 'relative',
    display: 'inline',
    cursor: 'pointer'
};

@observer
export default class DroppableButton extends Component {
    static propTypes = {
        onDrop: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
    };

    @observable droppable = false;

    onDrop = (...args) => {
        const { onDrop } = this.props;

        this.droppable = false;

        onDrop(...args);
    }

    render() {
        const { title, ...props } = this.props;

        return (
            <MyDropzone
                activeStyle={activeStyle}
                {...props}
                onDragOver={() => this.droppable = true}
                onDragLeave={() => this.droppable = false}
                onDrop={this.onDrop}
            >
                <IconButton title={title} name="upload" color={this.droppable ? 'red' : 'black'} />
            </MyDropzone>
        );
    }
}


// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { observable } from 'mobx';
// import { observer } from 'mobx-react';
// import { IconCloudUpload } from 're-cy-cle';
// import Dropzone from 'react-dropzone';
// import styled, { css } from 'styled-components';
// import { DownloadButton, SearchModalButton } from 'spider/Button';
// import { theme } from 'styles';

// const MyDropzone = styled(Dropzone)`
//     position: relative;
//     display: inline;
//     cursor: pointer;
//     padding: 6px;
// `;

// const activeStyle = css`
//     position: relative;
//     display: inline;
//     cursor: pointer;
//     padding: 6px;
// `;

// @observer
// export default class DroppableButton extends Component {
//     static propTypes = {
//         onDrop: PropTypes.func.isRequired,
//     };

//     @observable droppable = false;

//     onDrop = (...args) => {
//         const { onDrop } = this.props;

//         console.log('onDrop');
//         this.droppable = false;

//         onDrop(...args);
//     }

//     render() {
//         return (
//             <MyDropzone
//                 activeStyle={activeStyle}
//                 {...this.props}
//                 onDragOver={() => this.droppable = true}
//                 onDragLeave={() => this.droppable = false}
//                 onDrop={this.onDrop}
//             >
//                 <IconCloudUpload color={this.droppable ? theme.primaryColor : null} />
//             </MyDropzone>
//         );
//     }
// }
