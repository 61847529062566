export default `
{{customerId}} / {{trailerPlate}} {{status}} service in {{currentLocation}}



{{customerId}} / {{trailerPlate}} {{status}} service in {{currentLocation}}

Time IN: {{currentArrivalTime}}
Time Start: {{currentStartTime}}

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
