export default `
{{customerId}} / {{trailerPlate}} {{status}} border customs in {{currentLocation}}



{{customerId}} / {{trailerPlate}} {{status}} border customs in {{currentLocation}}

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
