import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
    Text,
    SuperText,
    Button,
    Row,
    Col,
    IconAddCircle,
    IconDelete,
    FormField,
    TextInput,
} from 're-cy-cle';
import {
    setMoneyForBackend,
} from 'helpers';
import MoneyInput from 'spider/component/MoneyInput';

@observer
class OtherCostItem extends Component {
    static propTypes = {
        otherCost: PropTypes.object.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    handleChange = (name, value) => {
        if (name === 'amount') {
            value = setMoneyForBackend(value);
        }
        this.props.otherCost.setInput(name, value);
    };

    render() {
        const { otherCost } = this.props;
        return (
            <Row middle="xs">
                <Col xs>
                    <FormField
                        label={t('activityOtherCost.field.amount.label')}
                        error={otherCost.backendValidationErrors.amount}
                    >
                        <MoneyInput
                            model={otherCost}
                            name="amount"
                        />
                    </FormField>
                </Col>
                <Col xs>
                    <FormField
                        label={t('activityOtherCost.field.description.label')}
                        error={otherCost.backendValidationErrors.description}
                    >
                        <TextInput
                            name="description"
                            value={otherCost.description}
                            onChange={this.handleChange}
                        />
                    </FormField>
                </Col>
                <Col>
                    <Button onClick={() => this.props.onRemove(otherCost)}>
                        <IconDelete />
                    </Button>
                </Col>
            </Row>
        );
    }
}

@observer
export default class ActivityFinalizeOtherCosts extends Component {
    static propTypes = {
        activity: PropTypes.object.isRequired,
    };

    addCost = () => {
        this.props.activity.otherCosts.add({});
    };

    removeCost = otherCost => {
        this.props.activity.otherCosts.remove(otherCost);
    };

    render() {
        const { activity } = this.props;

        return (
            <div>
                <Row middle="xs">
                    <Col xs>
                        <SuperText>
                            {t('planning.customer.panelTitles.otherCosts')}
                        </SuperText>
                    </Col>
                    <Col>
                        <Button data-test-add-other-cost-button onClick={this.addCost}>
                            <IconAddCircle />
                        </Button>
                    </Col>
                </Row>
                {activity.otherCosts.length < 1 && (
                    <Text>{t('activityOtherCost.emptyText')}</Text>
                )}
                {activity.otherCosts.map(cost => (
                    <OtherCostItem
                        key={cost.cid}
                        otherCost={cost}
                        onRemove={this.removeCost}
                    />
                ))}
            </div>
        );
    }
}
