import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { CalculationRule } from './CalculationRule';
import { CalculationRuleCountry } from './CalculationRuleCountry';
import { DietRule } from './DietRule';
import { Driver } from './Driver';
import { DriverStatusChange } from './Driver/StatusChange';
import { storeWithHistory, modelWithHistory } from './History';

export class DriverCalculationRuleCountrySalary extends Model {
    static backendResourceName = 'driver_calculation_rule_country_salary';

    @observable id = null;
    @observable date = null;
    @observable amount = null;
    @observable approved = false;

    casts() {
        return {
            date: Casts.date,
        };
    }

    relations() {
        return {
            calculationRule: CalculationRule,
            driver: Driver,
            driverStatusChange: DriverStatusChange,
            calculationRuleCountry: CalculationRuleCountry,
            dietRule: DietRule,
        };
    }
}

export class DriverCalculationRuleCountrySalaryStore extends Store {
    Model = DriverCalculationRuleCountrySalary;
    static backendResourceName = 'driver_calculation_rule_country_salary';
}

export class HistoryDriverCalculationRuleCountrySalaryStore extends storeWithHistory(DriverCalculationRuleCountrySalaryStore){};
export class HistoryDriverCalculationRuleCountrySalary extends modelWithHistory(DriverCalculationRuleCountrySalary){};
