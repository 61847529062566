import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestionInteger } from './QuestionInteger';

export class FormAnswerInteger extends Model {
    static backendResourceName = 'form_answer_integer';

    @observable id = null;
    @observable value = '';

    relations() {
        return {
            formQuestionInteger: FormQuestionInteger,
        };
    }
}

export class FormAnswerIntegerStore extends Store {
    Model = FormAnswerInteger;
    static backendResourceName = 'form_answer_integer';
}
