import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Claim } from '../Claim';
import { User } from '../User';
import { Entity } from 'store/Entity';

export const TYPE_DAMAGE_FORM = 'damage form';
export const TYPE_POLICE_REPORT = 'police report';
export const TYPE_EVIDENCE = 'evidence';
export const TYPE_CLAIM_LETTER = 'claim letter';
export const TYPE_INVOICE = 'invoice';
export const TYPE_COST_APPROVAL = 'cost approval';
export const TYPES = [TYPE_DAMAGE_FORM, TYPE_POLICE_REPORT, TYPE_EVIDENCE, TYPE_CLAIM_LETTER, TYPE_INVOICE, TYPE_COST_APPROVAL];

export class ClaimDocument extends Model {
    static backendResourceName = 'claim_document';

    @observable id = null;
    @observable type = TYPE_DAMAGE_FORM;
    @observable file = null;
    @observable deleted = false;
    @observable createdAt = null;
    @observable updatedAt = null;

    @observable documentNumber = null;
    @observable documentDate = null;
    @observable netAmount = null;

    relations() {
        return {
            claim: Claim,
            createdBy: User,
            entity: Entity,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            documentDate: Casts.date,
        };
    }
}

export class ClaimDocumentStore extends Store {
    Model = ClaimDocument;
    static backendResourceName = 'claim_document';
}
