import React, { Component } from 'react';
import { COLOR_WARNING } from 'styles';
import styled from 'styled-components';

export const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

export const TBody = styled.tbody`
    ${props => {
        if (props.ok !== undefined) {
            if (props.ok) {
                return 'border-left: 5px solid green';
            }

            return 'border-left: 5px solid red';
        }
    }}
`;

export const TFoot = styled.tfoot`
`;

export class TBodyFiller extends Component {
    render() {
        return (
            <tbody>
                <tr height="15px">
                    <td></td>
                </tr>
            </tbody>
        );
    }
}

export const Th = styled.th`
    ${props => props.sticky && `
        position: sticky;
        top: 0;
        z-index: 1; /* Prevents checkbox rendering on top. See T26336#579987 */
    `}

    color: #b6b6b6;
    padding: 5px;
    background-color: white;

    ${props => props.alignLeft ? `
        text-align: left;
    ` : `
        text-align: right;
    `}
`;

export const THead = styled.thead`
`;

export const Tr = styled.tr`
    ${props => props.summary ? `
        background-color: white;
    ` : `
        background-color: #d7e3ed;
    `}

    ${props => props.warning && `
        // {warning-color-copy-pasta}
        background-color: rgba(253, 153, 39, 0.56);
    `}

    ${props => props.sticky !== undefined && `
        td {
            position: sticky;
            bottom: ${typeof props.sticky === 'number' ? props.sticky : 0}px;
        }
    `}

    font-size: 12px;
    text-align: right;
`;

export const Td = styled.td`
&&& {
    ${props => props.alignLeft && `
        text-align: left;
    `}

    ${props => props.filler ? `
        // border: 1px solid transparent;
        // border-bottom: 1px solid white;
        background-color: white;
    ` : `
        border: 1px solid #bacedf;
    `};

    ${props => props.title && `
        cursor: help;
    `}

    padding: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${props => props.onClick && `
        cursor: pointer;
    `}

    ${props => props.noBorders && `
        border: 1px solid transparent;
    `}

    ${props => {
        switch (props.borders) {
            case 'all':
                return 'border: 1px solid #bacedf;';
            case 'bottom':
                return `border: 1px solid transparent;
                        border-bottom: 1px solid #bacedf;`;
            case 'none':
                return 'border: 1px solid transparent;';
            default:
                return '';
        }
    }}

    ${props => props.noPadding && `
        padding: 0px;
    `}

    ${props => props.tariffMismatch && `
        background-color: #fef2f2;
    `}

    ${props => props.unfinalized && `
        background-color: ${COLOR_WARNING};
    `}

    ${props => props.incompleteMonth && `
        background-color: ${COLOR_WARNING};
    `}
}
`;
