import { observable } from 'mobx';
import { Model, Store } from './Base';
import { Certification } from './Certification';

export class Course extends Model {
    static backendResourceName = 'course';

    @observable id = null;
    @observable name = '';
    @observable level = 0;
    @observable certificationDuration = '';
    @observable status = '';

    relations() {
        return {
            tmsCertification: Certification,
        };
    }
}

export class CourseStore extends Store {
    Model = Course;
    static backendResourceName = 'course';
}
