import React from 'react';
import PropTypes from 'prop-types';

const IconBannedParking = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0.5 -1 23 22" fill="none" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
    );
};

IconBannedParking.propTypes = {
    color: PropTypes.string,
};

export default IconBannedParking;
