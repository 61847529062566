export default `
{{customerId}} / {{trailerPlate}} waiting for load {{currentLocation}}



{{customerId}} / {{trailerPlate}} is waiting for load in {{currentLocation}}
Reason: Truck is waiting in line, trucks in front of him.

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
