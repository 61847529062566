import React from 'react';
import PropTypes from 'prop-types';

const IconLoad = props => {
    return (
        <svg viewBox="0 0 30 30" {...props}>
            <g fill={props.color} fillRule="evenodd">
                <path d="M10 3h20v17H10zM14.5 27c-1.933 0-3.5-1.567-3.5-3.5s1.567-3.5 3.5-3.5 3.5 1.567 3.5 3.5-1.567 3.5-3.5 3.5zm0-2c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5z" />
                <path d="M20.5 27c-1.933 0-3.5-1.567-3.5-3.5s1.567-3.5 3.5-3.5 3.5 1.567 3.5 3.5-1.567 3.5-3.5 3.5zm0-2c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zM8 11.5L0 16V7" />
            </g>
        </svg>
    );
};

IconLoad.propTypes = {
    color: PropTypes.string,
};

export default IconLoad;
