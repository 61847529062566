import { observable } from 'mobx';
import { Casts, Model, Store } from './Base';
import { Waiver } from './Waiver';
import { Activity } from './Activity';

export class WaiverItem extends Model {
    static backendResourceName = 'waiver_item';

    @observable id = null;
    @observable waivedInvoicedKm = 0;
    @observable waivedWaitingAmount = 0;
    @observable waivedWeekendAmount = 0;
    @observable waivedOtherCostsAmount = 0;
    @observable waivedKmAmount = 0;
    @observable waivedKmSurcharge = 0;
    @observable waivedFixedAmount = 0;
    @observable waivedFixedSurcharge = 0;
    @observable waivedSecondDriverAmount = 0;
    @observable waivedCustomAmount = 0;
    @observable waivedTollAmount = 0;
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            waiver: Waiver,
            activity: Activity,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class WaiverItemStore extends Store {
    Model = WaiverItem;
    static backendResourceName = 'waiver_item';
}
