import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export class FormAnswerFileUpload extends Model {
    static backendResourceName = 'form_answer_file_upload';

    @observable id = null;
    @observable file = null;

    fileFields() {
        return ['file'];
    }
}

export class FormAnswerFileUploadStore extends Store {
    Model = FormAnswerFileUpload;
    static backendResourceName = 'form_answer_file_upload';
}
