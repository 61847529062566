export default `
{{customerId}} / {{trailerPlate}} {{status}} police control to {{currentLocation}}



{{customerId}} / {{trailerPlate}} {{status}}  police control at {{currentLocation}} is: {{currentCommunicatedTime}}
Reason: Police control in area {{currentLocation}}

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
