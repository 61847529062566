export default `
{{customerId}} / {{trailerPlate}} ETA to unload {{currentLocation}}



{{customerId}} / {{trailerPlate}} his ETA at unloadplace {{currentLocation}} is: {{currentCommunicatedTime}}
Info will follow when start to unload
Reason:

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
