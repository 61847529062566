import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Model } from 'store/Base';
import { observer } from 'mobx-react';
import { FormField, NumberInput } from 're-cy-cle';
import { snakeToCamel } from '../helpers';

// Stolen from re-cy-cle.
const ValuePropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
]);

@observer
export default class FormNumberInput extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        name: PropTypes.string.isRequired,
        value: ValuePropType,
        hasError: PropTypes.bool,
        onChange: PropTypes.func,
        maxLength: PropTypes.number,
        className: PropTypes.string,
        helpText: PropTypes.string,
    };

    setInput = (name, value) => {
        const { model } = this.props;

        model.setInput(name, value);
    };

    render() {
        const { model, label, name, onChange, disabled, value, hasError, maxLength, className, helpText } = this.props;

        return (
            <FormField
                label={Object.keys(this.props).includes('label') ? label : t(`${snakeToCamel(model.constructor.backendResourceName)}.field.${name}.label`)}
                error={model.backendValidationErrors && model.backendValidationErrors[name]}
                className={className}
                helpText={helpText}
            >
                <NumberInput
                    disabled={disabled}
                    name={name}
                    value={value || model[name]}
                    hasError={hasError}
                    onChange={onChange || this.setInput}
                    maxLength={maxLength}
                />
            </FormField>
        );
    }
}
