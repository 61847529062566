import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';

export class Compartment extends Model {
    static backendResourceName = 'trailer_compartment';

    @observable id = null;
    @observable compartmentNumber = 0;

    @observable ignoreSupplyTemp = false;
    @observable ignoreReturnTemp = false;
    @observable ignoreSetPointTemp = false;
    @observable ignoreSensor1Temp = false;
    @observable ignoreSensor2Temp = false;
    @observable ignoreSensor3Temp = false;
    @observable ignoreSensor4Temp = false;
    @observable ignoreDoorState = false;
    @observable ignoreDoorState2 = false;

    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.date,
        };
    }
}

export class CompartmentStore extends Store {
    Model = Compartment;
    static backendResourceName = 'trailer_compartment';
}
