import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { VatRule, VatRuleStore } from './VatRule';
import { UserStore } from './User';
import { ContactStore } from './Contact';

export const PDF_TEMPLATES = ['nl', 'de','degmbh', 'egala'];

export class Entity extends Model {
    static backendResourceName = 'entity';

    @observable id = null;
    @observable name = '';
    @observable chamberOfCommerce = '';
    @observable vatCode = '';
    @observable iban = '';
    @observable invoicePdfTemplate = '';
    @observable exactAccountCodeProd = 0;
    @observable exactAccountCodeTest = 0;
    @observable address = '';
    @observable invoiceEmailContent = '';
    @observable invoiceEmailSubject = '';
    @observable isDefaultForCostInvoice = false;


    relations() {
        return {
            users: UserStore,
            cashManagers: UserStore,
            vatRules: VatRuleStore,
            defaultVatRule: VatRule,
            contacts: ContactStore,
        };
    }
}

export class EntityStore extends Store {
    Model = Entity;
    static backendResourceName = 'entity';
}
