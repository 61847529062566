import { observable } from 'mobx';
import { Model, Store, Casts, FileField } from 'store/Base';

export class License extends Model {
    static backendResourceName = 'license';

    @observable id = null;
    @observable name = '';
    @observable slug = '';
}

export class LicenseStore extends Store {
    Model = License;
    static backendResourceName = 'license';
}

export class TruckLicense extends Model {
    static backendResourceName = 'truck_license';

    @observable id = null;
    @observable dateObtained = null;
    @observable dateOfExpiry = null;
    @observable documentNumber = '';
    @observable issuingCountries = [];
    @observable documentScan = new FileField({ name: 'documentScan' });

    casts() {
        return {
            dateObtained: Casts.date,
            dateOfExpiry: Casts.date,
            documentScan: Casts.file,
        };
    }

    relations() {
        return {
            license: License,
        };
    }
}

export class TruckLicenseStore extends Store {
    Model = TruckLicense;
    static backendResourceName = 'truck_license';
}

export class TrailerLicense extends Model {
    static backendResourceName = 'trailer_license';

    @observable id = null;
    @observable dateObtained = null;
    @observable dateOfExpiry = null;
    @observable documentNumber = '';
    @observable issuingCountries = [];
    @observable documentScan = new FileField({ name: 'documentScan' });

    casts() {
        return {
            dateObtained: Casts.date,
            dateOfExpiry: Casts.date,
            documentScan: Casts.file,
        };
    }

    relations() {
        return {
            license: License,
        };
    }
}

export class TrailerLicenseStore extends Store {
    Model = TrailerLicense;
    static backendResourceName = 'trailer_license';
}
