import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Activity } from './Activity';

export class OtherCost extends Model {
    static backendResourceName = 'activity_other_cost';

    @observable id = null;
    @observable amount = null;
    @observable description = '';

    relations() {
        return {
            activity: Activity,
        };
    }
}

// Stolen from mobx-spine, since it doesn't export it.
// function relationsToNestedKeys(relations) {
//     const nestedRelations = {};
//
//     relations.forEach(rel => {
//         let current = nestedRelations;
//         const components = rel.split('.');
//         const len = components.length;
//
//         for (var i = 0; i < len; ++i) {
//             const head = components[i];
//             if (current[head] === undefined) {
//                 current[head] = {};
//             }
//             current = current[head];
//         }
//     });
//
//     return nestedRelations;
// }

export class OtherCostStore extends Store {
    Model = OtherCost;
    static backendResourceName = 'activity_other_cost';

//     Wanted to add support for deletion in other costs for invoicing, but it
//     seems there was already some scoping done and simply adding a field was
//     easier.
//
//     @observable removedIds = [];
//
//     remove(...args) {
//         const removed = super.remove(...args);
//
//         this.removedIds = [...this.removedIds, ...removed.map(r => r.id)];
//
//         return removed;
//     }
//
//     /**
//      * Hacked version to support saving a store.
//      */
//     save(options) {
//         const data = this.models.map(m => this.toBackendAll({
//             data: options.data,
//             mapData: options.mapData,
//             nestedRelations: relationsToNestedKeys(options.relations || []),
//             onlyChanges: options.onlyChanges,
//         }))[0].data;
//
//         return this.wrapPendingRequestCount(
//             this.api.put(
//                 this.url(),
//                 {
//                     data: data,
//                     deletions: {
//                         activity_other_cost: this.removedIds,
//                     },
//                 },
//             ).then(() => this.removedIds = [])
//         );
//     }
}
