import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { CalculationRule } from './CalculationRule';
import { ContractType } from './Contract';

export const DESCRIPTION = 'description';
export const LEVEL = 'level';
export const OPTIONAL_FIELDS = [DESCRIPTION, LEVEL];

export class CalculationRuleContractType extends Model {
    static backendResourceName = 'calculation_rule_contract_type';

    @observable id = null;
    @observable rate = 0;


    relations() {
        return {
            calculationRule: CalculationRule,
            type: ContractType,
        };
    }
}

export class CalculationRuleContractTypeStore extends Store {
    Model = CalculationRuleContractType;
    static backendResourceName = 'calculation_rule_contract_type';
}
