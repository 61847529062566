export default `
{{customerId}} / {{trailerPlate}} waiting to {{trailerDropOrPickup}} trailer in {{currentLocation}}



{{customerId}} / {{trailerPlate}} waiting to {{trailerDropOrPickup}} trailer in {{currentLocation}}
Reason: Truck is waiting in line, trucks in front of him.

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
