import { observable } from 'mobx';
import { Model, Store, Casts } from './Base';
import { Activity } from './Activity';
import { Cost } from './Cost';

export class CostItem extends Model {
    static backendResourceName = 'cost_item';

    @observable id = null;
    @observable km = 0;
    @observable amount = 0;
    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            activity: Activity,
            cost: Cost
        };
    }
}

export class CostItemStore extends Store {
    Model = CostItem;

    static backendResourceName = 'cost_item';
}
