import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Icon from 'image/exact-status-icon.png';

export const StyledImg = styled.img`
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 3px;
    margin-right: 6px;
    text-align: center;
`;


@observer
export default class ExactOnlineIcon extends Component {
    static propTypes = {
        title: PropTypes.string,
    };


    render() {
    	const { title } = this.props;

        return <StyledImg src={Icon} title={title}/>;

    }
}
