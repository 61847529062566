export default `
{{customerId}} / {{trailerPlate}} arrived at load {{currentLocation}}



{{customerId}} / {{trailerPlate}} just arrived to loading {{currentLocation}}

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
