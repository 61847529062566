import React from 'react';
import PropTypes from 'prop-types';

const IconEquipmentPickup = props => {
    return (
        <svg viewBox="0 0 30 30" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M2-1h24v24H2z" />
                <path
                    d="M27 3H3v13h1c0 1.66 2.008 3 3 3s2.5-.5 2.5-2c0 1.5 1.537 2 2.5 2 .963 0 3-1.34 3-3 1.333-1.011 2.666-1.511 4-1.5 1.345.012 3.012.512 5 1.5h3V3zM7 17.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm5 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
                    fill={props.color}
                    fillRule="nonzero"
                />
                <path
                    fill={props.color}
                    d="M20 20v-3h-2v3h-3v2h3v3h2v-3h3v-2z"
                />
            </g>
        </svg>
    );
};

IconEquipmentPickup.propTypes = {
    color: PropTypes.string,
};

export default IconEquipmentPickup;
