import { observable, computed } from 'mobx';
import { Model, Store, Casts, FileField } from 'store/Base';
import { Supplier } from './Supplier';
import { Entity } from './Entity';
import { Truck } from './Truck';
import { Location } from './Location';
import { LeaseContract } from './LeaseContract';

export const TYPE_ALL_IN = 'all in';
export const TYPE_MAINTENANCE_ONLY = 'maintenance only';
export const TYPE_BREAKDOWN_ONLY = 'breakdown only';

export class ServiceContract extends Model {
    static backendResourceName = 'service_contract';

    @observable id = null;
    @observable startDate = null;
    @observable endDate = null;
    @observable contractNumber = '';
    @observable type = TYPE_ALL_IN;
    @observable remarks = '';
    @observable monthlyPayment = 0;
    @observable contractScan = new FileField({ name: 'contractScan' });
    @observable _contractScan = null; // file blob

    relations() {
        return {
            truck: Truck,
            serviceCompany: Supplier,
            leaseContract: LeaseContract,
            entity: Entity,
            fixedLocation: Location,
        };
    }

    casts() {
        return {
            startDate: Casts.date,
            endDate: Casts.date,
            contractScan: Casts.file,
        };
    }

    saveContractScan() {
        const contractScan = this._contractScan;
        if (!contractScan) {
            return;
        }
        const data = new FormData();
        data.append('contractScan', contractScan, contractScan.name);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        return this.api.post(`${this.url}contract_scan/`, data, {
            headers,
        }).then(response => {
            this.contractScan = response.data.contract_scan;
        });
    }

    @computed
    get downloadContractScanUrl() {
        return `${this.api.baseUrl.replace(/\/$/, '')}${
          this.url
          }contract_scan/?download`;
    }

    saveAsCopy(serviceContract) {
        return this.save({ url: serviceContract.url + 'copy/' });
    }
}

export class ServiceContractStore extends Store {
    Model = ServiceContract;
    static backendResourceName = 'service_contract';
}
