import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestion } from './Question';

export class FormQuestionDate extends Model {
    static backendResourceName = 'form_question_date';

    @observable id = null;

    relations() {
        return {
            formQuestion: FormQuestion,
        };
    }
}

export class FormQuestionDateStore extends Store {
    Model = FormQuestionDate;
    static backendResourceName = 'form_question_date';
}
