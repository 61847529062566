import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { getMoneyForUser, OWN_CURRENCY_SYMBOL } from 'helpers';
import { Tr, Td } from 'component/Invoice/Table';
import styled from 'styled-components';
import { COLOR_TEXT } from 'styles';
const StyledTr = styled(Tr)`
    td {
        background-color: white;
        color: ${props => props.color};
    }
`;

@observer
export default class InvoiceActivityEditTotals extends Component {
    static propTypes = {
        isCredit: PropTypes.bool,
        // Add filler column in the beginning. Set to false to disable.
        fillerColspan: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        sticky: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        borders: PropTypes.string,
        invoicedKm: PropTypes.number.isRequired,
        kmAmount: PropTypes.number.isRequired,
        fixedAmount: PropTypes.number.isRequired,
        waitingAmount: PropTypes.number.isRequired,
        secondDriverAmount: PropTypes.number.isRequired,
        tollAmount: PropTypes.number.isRequired,
        kmSurcharge: PropTypes.number.isRequired,
        fixedSurcharge: PropTypes.number.isRequired,
        otherCosts: PropTypes.number.isRequired,
        customAmount: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        showColumnVat: PropTypes.bool.isRequired,
        showColumnCustom: PropTypes.bool.isRequired,
        color: PropTypes.string,
        className: PropTypes.string,
        currencySymbol: PropTypes.string.isRequired,
    };

    static defaultProps = {
        fillerColspan: 2,
        borders: 'none',
        customAmount: 0,
        showColumnVat: true,
        showColumnCustom: false,
        currencySymbol: OWN_CURRENCY_SYMBOL,
    }

    renderColumns() {
        const { isCredit, showColumnVat, showColumnCustom, customAmount, borders, fillerColspan, title, invoicedKm, kmAmount, fixedAmount, waitingAmount, secondDriverAmount, tollAmount, kmSurcharge, fixedSurcharge, otherCosts, total, currencySymbol } = this.props;

        return (
            <React.Fragment>
                {fillerColspan && <Td borders={borders} colSpan={fillerColspan} filler />}
                <Td borders={borders} expected width="100px">{title}</Td>
                <Td borders={borders} width="99px" data-test-total-invoiced-km>{isCredit && '-'}{invoicedKm}</Td>
                <Td borders={borders} width="101px" data-test-total-km-amount>{typeof kmAmount === 'number' && currencySymbol}{isCredit && '-'}{getMoneyForUser(kmAmount)}</Td>
                <Td borders={borders} width="99px" data-test-total-fixed-amount>{typeof fixedAmount === 'number' && currencySymbol}{isCredit && '-'}{getMoneyForUser(fixedAmount)}</Td>
                <Td borders={borders} width="125px" data-test-total-waiting-amount>{typeof waitingAmount === 'number' && currencySymbol}{isCredit && '-'}{getMoneyForUser(waitingAmount)}</Td>
                <Td borders={borders} width="100px" data-test-total-second-driver-amount>{typeof secondDriverAmount === 'number' && currencySymbol}{isCredit && '-'}{getMoneyForUser(secondDriverAmount)}</Td>
                <Td borders={borders} width="105px" data-test-total-toll-amount>{typeof tollAmount === 'number' && currencySymbol}{isCredit && '-'}{getMoneyForUser(tollAmount)}</Td>
                <Td borders={borders} width="105px" data-test-total-surcharge-amount>{typeof kmSurcharge === 'number' && typeof fixedSurcharge === 'number' && currencySymbol}{isCredit && '-'}{getMoneyForUser(kmSurcharge + fixedSurcharge)}</Td>
                <Td borders={borders} width="100px" data-test-total-other-costs-amount>{typeof otherCosts === 'number' && currencySymbol}{getMoneyForUser(otherCosts)}</Td>
                {showColumnVat && <Td borders={borders} width="100px" />}
                {showColumnCustom && <Td borders={borders} width="100px" data-test-total-custom-amount>{typeof customAmount === 'number' && currencySymbol}{getMoneyForUser(customAmount)}</Td>}
                <Td borders={borders} width="114px" data-test-total-amount>{typeof total === 'number' ? `${currencySymbol}${isCredit ? '-' : ''}${getMoneyForUser(total)}` : total}</Td>
            </React.Fragment>
        );
    }

    render() {
        const { sticky, color, className, title } = this.props;

        return (
            <StyledTr summary data-test-activity-totals data-test-title={title} sticky={sticky} color={color ? color : COLOR_TEXT} className={className}>
                {this.renderColumns()}
            </StyledTr>
        );
    }
}
