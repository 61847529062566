import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { DATETIME_MINI_FORMAT } from 'helpers';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Table, Popup } from 'semantic-ui-react';
import { STATUS_PLANNED, STATUS_ETA } from 'store/Activity';

const StyledSpan = styled.span`
    ${props => props.remainingKm ? 'padding-right: 10px' : ''};
`;

// Apply specific hack to render popup properly within a scrollbox.
const StyledPopup = styled(Popup)`
    ${({ inScrollBox }) => inScrollBox && `
        top: 47px !important;

        :before {
            top: -0.30714286em !important;
            box-shadow: -1px -1px 0px 0px #bababc !important;
        }
    `}
`;

@observer
export default class ActivityInfoStatus extends Component {
    static propTypes = {
        activity: PropTypes.object.isRequired,
        inScrollBox: PropTypes.bool.isRequired,
    }

    render() {
        const { activity, inScrollBox } = this.props;
        let datetime = null;
        let showStatus = true;
        let displayStatus = activity.status ? activity.status.toUpperCase() : '';

        switch (activity.status) {
            case 'arrived':
                datetime = activity.actualArrivalDatetime;
                break;
            case 'started':
                datetime = activity.startDatetime;
                break;
            case 'waiting':
                datetime = activity.waitStartDatetime;
                break;
            case 'finished':
                datetime = activity.finishedDatetime;
                break;
            default:
                datetime = activity.estimatedArrivalDatetime;
                break;
        }

        // When an activity is planned, and there is a ETA calculated, show
        // display as ETA.
        if (datetime && activity.status === STATUS_PLANNED) {
            displayStatus = STATUS_ETA.toUpperCase();
        }

        if (datetime && activity.location) {
            datetime = moment(datetime).tz(activity.location.timezone);
        }

        if (showStatus) {
            const cumulativeRemainingKm = activity._etaInfo ? activity._etaInfo.cumulativeRemainingKm : activity.remainingKm;
            const showRemainingKm = [STATUS_PLANNED, STATUS_ETA].includes(activity.status) && activity.remainingKm !== null && cumulativeRemainingKm;
            const content = (
                <StyledSpan data-test-info-status remainingKm={showRemainingKm}>
                    {displayStatus}{' '}
                    {datetime ? datetime.format(DATETIME_MINI_FORMAT) : ''}
                    {showRemainingKm ? <span data-test-remaining-km>{` (${cumulativeRemainingKm}km)`}</span> : ''}
                </StyledSpan>
            );

            if (!activity._etaInfo) {
                return content;
            }

            return (
                <StyledPopup inScrollBox={inScrollBox} trigger={content} position="bottom center">
                    <Table definition compact>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{t('activity.etaInfo.weekendBreaks')}</Table.Cell>
                                <Table.Cell textAlign="right">{moment.duration((activity._etaInfo.weekendBreakDuration || 0), 'minutes').format('h:mm', { trim: false })}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{t('activity.etaInfo.breaks')}</Table.Cell>
                                <Table.Cell data-test-breaks textAlign="right">{moment.duration((activity._etaInfo.breakDuration || 0), 'minutes').format('h:mm', { trim: false })}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>{t('activity.etaInfo.activityDuration')}</Table.Cell>
                                <Table.Cell textAlign="right">{moment.duration(activity._etaInfo.expectedWaitingTime, 'minutes').format('h:mm', { trim: false })}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </StyledPopup>
            );
        }
        return <span>-</span>;
    }
};
