import { trimStart } from 'lodash';
import moment from 'moment';

export function format(moment, format, defaultValue = '') {
    if (moment) {
        return moment.format(format);
    }

    return defaultValue;
}

function parsePeriod(char) {
    switch (char.toUpperCase()) {
        case 'D': return 'days';
        case 'W': return 'weeks';
        case 'M': return 'months';
        case 'Y': return 'years';
        default: return '';
    }
}

export function addInterval(momentInstance, interval) {
    if (!/P(\d+)[DWMY]/.test(interval) || !moment.isMoment(momentInstance)) {
        return momentInstance;
    }

    return momentInstance.clone().add(
        parseInt(trimStart(interval, 'P')),
        parsePeriod(interval.slice(-1))
    );
}
