import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { getMoneyForUser, getCurrencySymbolFromCode } from 'helpers';

export class TariffWeekendRate extends Model {
    static backendResourceName = 'tariff_weekend_rate';

    @observable id = null;
    @observable price = 0;

    calcPriceForActivity(activity) {
        if (activity.inWeekend) {
            return this.price;
        }

        return 0;
    }

    explainForActivity(activity) {
        const currencySymbol = getCurrencySymbolFromCode(activity.allocation.contract.fcCode)
        const amount = activity.inWeekend
            ? `${currencySymbol}${getMoneyForUser(this.price)}`
            : `${currencySymbol}${getMoneyForUser(0)}`;

        return `${t('activity.field.finishedDatetime.label')}: ${activity.finishedDatetime ? activity.finishedDatetime.format('ddd') : 'Not yet finished'} = ${amount}`;
    }
}

export class TariffWeekendRateStore extends Store {
    Model = TariffWeekendRate;
    static backendResourceName = 'tariff_weekend_rate';
}
