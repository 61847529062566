import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Incident } from '../Incident';
import { User } from '../User';

export const TYPE_DAMAGE_FORM = 'damage form';
export const TYPE_POLICE_REPORT = 'police report';
export const TYPE_EVIDENCE = 'evidence';
export const TYPE_CLAIM_LETTER = 'claim letter';
export const TYPE_INVOICE = 'invoice';
export const TYPE_COST_APPROVAL = 'cost approval';
export const TYPES = [TYPE_DAMAGE_FORM, TYPE_POLICE_REPORT, TYPE_EVIDENCE, TYPE_CLAIM_LETTER, TYPE_INVOICE, TYPE_COST_APPROVAL];

export class IncidentDocument extends Model {
    static backendResourceName = 'incident_document';

    @observable id = null;
    @observable type = TYPE_DAMAGE_FORM;
    @observable file = null;
    @observable deleted = false;
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            incident: Incident,
            createdBy: User,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class IncidentDocumentStore extends Store {
    Model = IncidentDocument;
    static backendResourceName = 'incident_document';
}
