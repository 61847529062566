import { observable } from 'mobx';
import { Model, Store } from 'store/Base';


export class Homebase extends Model {
    static backendResourceName = 'homebase';

    @observable id = null;
    @observable name = '';
    @observable point = {
        lat: null,
        lng: null,
    };
    @observable deleted = false;
}

export class HomebaseStore extends Store {
    Model = Homebase;
    static backendResourceName = 'homebase';
}
