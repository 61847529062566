import { observable, computed, action } from 'mobx';
import { Model, Store, Casts } from './Base';
import { Location } from './Location';
import { Activity, KIND_ACTIVITY, KIND_CUSTOMER_STOP, TYPE_LOAD, STATUS_FINISHED } from './Activity';
import { Trailer } from './Trailer';
import { ProposedRoute } from './ProposedRoute';
import { TYPE_TEMPERATURE_PRINT, TYPE_CMR } from './Document';

export class ProposedActivity extends Model {
    static backendResourceName = 'proposed_activity';

    @observable id = null;
    @observable type = TYPE_LOAD;
    @observable externalActivityType = null;
    @observable orderedArrivalDatetimeFrom = null;
    @observable orderedArrivalDatetimeUntil = null;
    @observable orderReference = '';
    @observable internalOrderReference = '';
    @observable externalReference = '';
    @observable originalExternalReference = '';
    @observable externalLocationReference = '';
    @observable originalExternalLocationReference = '';
    @observable externallyDeleted = false;
    @observable instructions = '';
    @observable requiredDocuments = [];
    @observable conditions = [];
    @observable minTemp = null;
    @observable maxTemp = null;
    @observable createdAt = null;
    @observable remarks = '';
    @observable deleted = false;

    // Used for showing pre-converted activity.
    @observable _convertedActivityBeforeCopyingAttributes = null;

    // Used for drag&drop converted activity to other proposed activity.
    @observable _stolenConvertedActivityId = null;

    @computed get kind() {
        const activityKindActvity = new Activity({ kind: KIND_ACTIVITY });

        if (activityKindActvity.getRelevantTypes().includes(this.type)) {
            return KIND_ACTIVITY;
        }

        return KIND_CUSTOMER_STOP;
    }

    relations() {
        return {
            location: Location,
            convertedActivity: Activity,
            trailer: Trailer,
            route: ProposedRoute,
        };
    }

    casts() {
        return {
            orderedArrivalDatetimeFrom: Casts.datetime,
            orderedArrivalDatetimeUntil: Casts.datetime,
            createdAt: Casts.datetime,
        };
    }

    @action
    copyAttributesToConvertedActivity(allocation) {
        const requiredDocuments = [...this.requiredDocuments];
        this._convertedActivityBeforeCopyingAttributes = new Activity(this.convertedActivity.toJS(), { relations: this.convertedActivity.__activeCurrentRelations });
        this._convertedActivityBeforeCopyingAttributes._copy = true;

        // Cowardly refuse to update deleted activities which should
        // stay deleted, because Binder will return 405 if we try to
        // update a soft-deleted model (see T23294).
        if (this.externallyDeleted && this.convertedActivity.deleted) {
            return;
        }

        // When activity is finished, update only order reference.
        if (this.convertedActivity.status === STATUS_FINISHED) {
            this.convertedActivity.setInput('orderReference', this.orderReference);
            return;
        }

        this.convertedActivity.setInput('kind', this.kind);
        this.convertedActivity.setInput('type', this.type);
        this.convertedActivity.setInput('location', this.location);
        this.convertedActivity.setInput('isAsap', false);
        this.convertedActivity.setInput('allocation', allocation);
        this.convertedActivity.setInput('orderedArrivalDatetimeFrom', this.orderedArrivalDatetimeFrom);
        this.convertedActivity.setInput('orderedArrivalDatetimeUntil', this.orderedArrivalDatetimeUntil || this.orderedArrivalDatetimeFrom);
        this.convertedActivity.setInput('orderReference', this.orderReference);
        this.convertedActivity.setInput('instructions', this.instructions);
        this.convertedActivity.setInput('conditions', this.conditions);
        this.convertedActivity.setInput('minTemp', this.minTemp);
        this.convertedActivity.setInput('maxTemp', this.maxTemp);
        this.convertedActivity.setInput('createdAt', this.createdAt);
        this.convertedActivity.setInput('remarks', this.remarks);
        this.convertedActivity.setInput('nextTrailer', this.trailer); // Mindfuck, ProposedActivity.trailer = Activity.nextTrailer.
        this.convertedActivity.setInput('routeOptimizationLevel', allocation.contract.normalRouteOptimizationLevel);


        if ((this.minTemp !== null || this.maxTemp !== null) && !this.requiredDocuments.includes(TYPE_TEMPERATURE_PRINT)) {
            requiredDocuments.push(TYPE_TEMPERATURE_PRINT);
        }

        // By default require cmr if no requiredDocuments exists.
        if (requiredDocuments.length === 0) {
            // But not for DPG2.
            if (!allocation.contract?.customer?.isDpg2IntegrationEnabled) {
                requiredDocuments.push(TYPE_CMR);
            }
        }

        this.convertedActivity.setInput('requiredDocuments', requiredDocuments);
    }
}

export class ProposedActivityStore extends Store {
    static backendResourceName = 'proposed_activity';

    Model = ProposedActivity;
}
