import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Driver } from './Driver';
import { TruckPosition } from './TruckPosition';
import moment from 'moment';

export const ACTION_NONE = 'none';
export const ACTION_DRIVE = 'drive';
export const ACTION_WORK = 'work';
export const ACTION_WAIT = 'wait';
export const ACTION_REST = 'rest';
export const ACTION_AVAILABILITY = 'availability';
export const ACTION_CARD_OUT = 'card out';
export const ACTIONS = [ACTION_NONE, ACTION_DRIVE, ACTION_WORK, ACTION_WAIT, ACTION_REST, ACTION_AVAILABILITY, ACTION_CARD_OUT];

export const DATA_SOURCE_FLEETVISOR = 'fleetvisor';
export const DATA_SOURCE_TRANSICS = 'transics';
export const DATA_SOURCE_DAF = 'daf';
export const DATA_SOURCE_MOBILE_OFFICE = 'mobile office';
export const DATA_SOURCE_FLEETBOARD = 'fleetboard';
export const DATA_SOURCES = [DATA_SOURCE_FLEETVISOR, DATA_SOURCE_TRANSICS, DATA_SOURCE_DAF, DATA_SOURCE_MOBILE_OFFICE, DATA_SOURCE_FLEETBOARD];

function sortMeasuredAt(a, b) {
    if (a.measuredAt < b.measuredAt) {
        return -1;
    }

    if (a.measuredAt > b.measuredAt) {
        return 1;
    }

    return 0;
}

export class TachoEvent extends Model {
    static backendResourceName = 'tacho_event';

    @observable id = null;
    @observable action = ACTION_NONE;
    @observable point = { lng: '', lat: '' };
    @observable measuredAt = null;
    @observable dataExternalId = null;
    @observable dataSource = '';
    @observable endDate = null;

    casts() {
        return {
            measuredAt: Casts.datetime,
            endDate: Casts.datetime,
        };
    }

    relations() {
        return {
            driver: Driver,
            truckPosition: TruckPosition,
        };
    }

    finishedAt(tachoEventStore) {
        let sortedtachoEventStore = tachoEventStore.toJS().sort((a, b) => {
            if(a.measuredAt === b.measuredAt && a.id === null){
                return b.id - a.id
            }else if(a.measuredAt === b.measuredAt && a.id !== null){
                return a.id - b.id
            }
            return new Date(a.measuredAt) - new Date(b.measuredAt)
        })

        let nextTachoEvent = null
        let finishedAt = moment()

        sortedtachoEventStore.forEach((tachoEvent, index) => {
            if(tachoEvent.id === this.toJS().id && sortedtachoEventStore.length > index+1){
                nextTachoEvent = sortedtachoEventStore[index+1]
                finishedAt = moment(nextTachoEvent.measuredAt)
            }
        })

        return finishedAt;
    }
}

export class TachoEventStore extends Store {
    Model = TachoEvent;
    static backendResourceName = 'tacho_event';

    driveDurationSeconds(start, end) {
        const sortedTachoEvents = this.models.slice().sort(sortMeasuredAt);
        const releventTachoEvents = sortedTachoEvents
            .map((tachoEvent, i) => ({
                start: tachoEvent.measuredAt.clone(),
                end: i < sortedTachoEvents.length - 1 ? sortedTachoEvents[i + 1].measuredAt.clone() : end.clone(),
                action: tachoEvent.action,
            }))
            .filter(tachoEvent => tachoEvent.action === ACTION_DRIVE)
            .filter(tachoEvent => tachoEvent.end > start );
        return releventTachoEvents.reduce((res, tachoEvent) => res + moment.min(tachoEvent.end, end).diff(moment.max(tachoEvent.start, start), 'seconds'), 0);
    }

    driveDurationAndWorkingSeconds(start, end) {
        const sortedTachoEvents = this.models.slice().sort(sortMeasuredAt);
        const releventTachoEvents = sortedTachoEvents
            .map((tachoEvent, i) => ({
                start: tachoEvent.measuredAt.clone(),
                end: i < sortedTachoEvents.length - 1 ? sortedTachoEvents[i + 1].measuredAt.clone() : end.clone(),
                action: tachoEvent.action,
            }))
            .filter(tachoEvent => tachoEvent.action === ACTION_DRIVE || tachoEvent.action === ACTION_WORK || tachoEvent.action === ACTION_WAIT)
            .filter(tachoEvent => tachoEvent.end > start );


        return releventTachoEvents.reduce((res, tachoEvent) => res + moment.min(tachoEvent.end, end).diff(moment.max(tachoEvent.start, start), 'seconds'), 0);
    }
}
