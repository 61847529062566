import React from 'react';
import styled, { css } from 'styled-components';
import DropZone from 'react-dropzone';

const styles = css`
    border-radius: 4px;
    padding: 8px;
    overflow: hidden;
    border: 3px dashed #ccc;
    cursor: pointer;
`;

const activeStyle = {
    border: '3px dashed #999',
};

const MyDropZone = styled(DropZone)`
    ${styles};
`;

export default props => {
    return <MyDropZone {...props} activeStyle={activeStyle} />;
};
