export default `
{{customerId}} / {{trailerPlate}} ETA to load {{currentLocation}}



{{customerId}} / {{trailerPlate}} his ETA at loadplace {{currentLocation}} is: {{currentCommunicatedTime}}
Reason: Police control in area {{truckCurrentLocation}}

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
