import { observable } from 'mobx';
import { Casts, Model, Store } from 'store/Base';
import { Contract } from './Contract';
import { EquipmentType } from './EquipmentType';

export const TRACKING_TARGET_TRAILER = 'trailer';
export const TRACKING_TARGET_ALLOCATION = 'allocation';
export const TRACKING_TARGETS = [TRACKING_TARGET_TRAILER, TRACKING_TARGET_ALLOCATION];

export class TrackedEquipment extends Model {
    static backendResourceName = 'tracked_equipment';

    @observable id = null;
    @observable trackingTarget = '';
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            contract: Contract,
            equipmentType: EquipmentType,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
         };
    }
}

export class TrackedEquipmentStore extends Store {
    Model = TrackedEquipment;
    static backendResourceName = 'tracked_equipment';
}
