import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export class TollboxSupplier extends Model {
    static backendResourceName = 'tollbox_supplier';

    @observable id = null;
    @observable name = '';
}

export class TollboxSupplierStore extends Store {
    Model = TollboxSupplier;
    static backendResourceName = 'tollbox_supplier';
}
