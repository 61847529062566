export default `
{{customerId}} / {{trailerPlate}} started unload in {{currentLocation}}



{{customerId}} / {{trailerPlate}} started unloading in {{currentLocation}}

Time IN: {{currentArrivalTime}}
Time Start: {{currentStartTime}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
