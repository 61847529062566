import React from 'react';
import { Button } from 're-cy-cle';
import styled from 'styled-components';

const HeadingRowSpacer = styled.div`
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
`;

export const HeadingRow = styled(props => (
    <HeadingRowSpacer>
        <div {...props} />
    </HeadingRowSpacer>
))`
    display: flex;
    font-size: 12px;
    overflow: hidden;
    background: #fff;
    padding-top: 6px;
    padding-bottom: 4px;
`;

export const HeadingColumn = styled.div`
    text-indent: 7px;
    flex: ${props => props.size};
    width: ${props => props.width};
    overflow: hidden;
    justify-content: center;
    text-overflow: ellipsis;
    color: #b6b6b6;
    text-transform: uppercase;
    font-weight: 500;
`;

export const HeadingColumnRight = styled(HeadingColumn)`
    text-align: right;
    padding-right: 6px;
`;

export const HeadingButton = styled(Button)`
    font-size: 12px;
`;
