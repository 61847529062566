import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { LedgerCost } from './LedgerCost';



export class CodeReport extends Model {
    static backendResourceName = 'code_report';

    @observable id = null;
    @observable codeReporting = null;
    @observable startDate = null;
    @observable endDate = null;
    @observable deleted = false;
    @observable createdAt = null;

    casts() {
        return {
            startDate: Casts.date,
            endDate: Casts.date,
            createdAt: Casts.date,
        };
    }

    relations() {
        return {
            ledgerCost: LedgerCost,
        }
    }
}

export class CodeReportStore extends Store {
    Model = CodeReport;
    static backendResourceName = 'code_report';
}
