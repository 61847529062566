import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export const FORM_TYPE_SCAN_REJECTION = 'scan rejection';
export const FORM_TYPES = ['activity', 'scan rejection'];
export class FleetvisorForm extends Model {
    static backendResourceName = 'fleetvisor_form';

    @observable id = null;
    @observable formType = '';
    @observable activityType = '';
    @observable activityKind = '';
    @observable formId = 0;
    @observable formVersion = 0;
}

export class FleetvisorFormStore extends Store {
    Model = FleetvisorForm;
    static backendResourceName = 'fleetvisor_form';

    /**
     * Simply look at formType and get the first `scan rejection`. This is
     * not correct, since there are multiple scan rejections:
     *
     * - T24609 (is in our db as `scan rejection``)
     * - T22708 (is not in our db?)
     */
    isScanRejection(form) {
        const scanRejectionForm = this.find(f => f.formType === 'scan rejection');

        if (!scanRejectionForm) {
            return false;
        }

        return form.dataExternalFormId === scanRejectionForm.formId;
    }
}

/**
 * Some forms need special treatment, like scan rejection. This used to be
 * hardcoded on formId, but that of course breaks when there is a new form.
 *
 * At the time of writing, only `scan rejection` is special, so we only fetch
 * that one.
 *
 * The actual fetching happens in the bootstrap handler.
 */
export const fleetvisorFormStore = new FleetvisorFormStore({
    params: {
        '.form_type': 'scan rejection'
    },
});
