import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Model } from 'store/Base';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { FormField, TextInput } from 're-cy-cle';
import { snakeToCamel } from '../helpers';

// Stolen from re-cy-cle.
const ValuePropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
]);

export function getTextValue(props) {
    if ('value' in props) {
        return props.value;
    }

    return props.model[props.name];
}

@observer
export default class FormTextInput extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        name: PropTypes.string.isRequired,
        value: ValuePropType,
        onChange: PropTypes.func,
        afterChange: PropTypes.func,
        helpText: PropTypes.string,
        required: PropTypes.bool,
        noPadding: PropTypes.bool,
    };

    setInput = (name, value) => {
        const { model, onChange, afterChange } = this.props;

        if (onChange) {
            onChange(name, value);
        } else {
            model.setInput(name, value);
        }

        if (afterChange) {
            afterChange(model);
        }
    };

    render() {
        const { model, label, noPadding, required, name, disabled, helpText } = this.props;
        const backendValidationErrors = toJS(model.backendValidationErrors);

        return (
            <FormField
                label={label || t(`${snakeToCamel(model.constructor.backendResourceName)}.field.${name}.label`)}
                error={backendValidationErrors ? backendValidationErrors[name] : null}
                helpText={helpText}
                noPadding={noPadding}
                required={required}
            >
                <TextInput
                    disabled={disabled}
                    name={name}
                    value={getTextValue(this.props)}
                    onChange={this.setInput}
                />
            </FormField>
        );
    }
}
