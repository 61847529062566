import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Truck } from './Truck';
import { Driver } from './Driver';
import { Allocation } from './Allocation';
import { ActivityStore } from './Activity';
import { ReassignmentChange } from './ReassignmentChange';
import moment from 'moment';

export class Assignment extends Model {
    static backendResourceName = 'assignment';

    @observable id = null;
    @observable activatedAt = null;
    @observable finishedAt = null;
    @observable remarks = '';
    @observable deleted = false;
    @observable isImmutable = false;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable plannedDriver1Finish = null;
    @observable plannedDriver2Finish = null;
    @observable plannedTruckFinish = null;

    casts() {
        return {
            activatedAt: Casts.datetime,
            finishedAt: Casts.datetime,
            driver1ArrivalDatetime: Casts.datetime,
            driver2ArrivalDatetime: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            plannedDriver1Finish: Casts.datetime,
            plannedDriver2Finish: Casts.datetime,
            plannedTruckFinish: Casts.datetime,
        };
    }

    relations() {
        return {
            allocation: Allocation,
            activities: ActivityStore,
            reassignmentChange: ReassignmentChange,
            truck: Truck,
            driver1: Driver,
            driver2: Driver,
            reallocatedAssignment: Assignment,
        };
    }

    @computed get
    plannedFinish() {
        const hasFinish = this.plannedDriver1Finish && this.plannedTruckFinish && this.plannedDriver2Finish
        const finishDates = [this.plannedDriver1Finish, this.plannedTruckFinish, this.plannedDriver2Finish].filter(Boolean)

        if (hasFinish) {
            return moment.min(finishDates);
        } else {
            return null;
        }
    }

    /**
     * Finds previous activity. Will skip deleted activities.
     *
     * Different from backend, since the activity.previousActivity also skips
     * stops.
     *
     * {copy-paste-findPreviousActivity}
     */
    findPreviousActivity(activity) {
        const i = this.activities.map(a => a.id).indexOf(activity.id);

        try {
            const prevIndex = i - 1;

            if (prevIndex < 0) {
                return null;
            }

            const prev = this.activities.at(prevIndex);

            if (prev.deleted) {
                return this.findPreviousActivity(prev);
            }

            return prev;
        } catch (e) {
            return null;
        }
    }

    /**
     * {copy-paste-findNextActivity}
     */
    findNextActivity(activity) {
        const i = this.activities.map(a => a.id).indexOf(activity.id);

        try {
            const next = this.activities.at(i + 1);

            if (next.deleted) {
                return this.findNextActivity(next);
            }

            return next;
        } catch (e) {
            return null;
        }
    }
}

export class AssignmentStore extends Store {
    Model = Assignment;
    static backendResourceName = 'assignment';

    comparator = (a, b) => {
            return 0;
    };
}
