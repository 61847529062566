import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import NotificationArea from 'component/NotificationArea'
import AppHeader from './AppHeader'
import { withRouter } from 'react-router-dom'
import Router from './Router'
import StartupError from './StartupError'
import RuntimeError from './RuntimeError'
// import Raven from 'raven-js'
import styled from 'styled-components'
import { pdfjs } from 'react-pdf'
import BaseLogin, { PrimaryLink } from 'spider/semantic-ui/Login'
import { BrandingContainer } from 'spider/semantic-ui/SidebarScreen'
import { Sidebar as USidebar, SidebarContent } from 'spider/semantic-ui/Admin/Overview'
import { Body, ContentContainer, AppContainer } from 're-cy-cle'
import LoginBackground from 'image/login-background.jpg'
import BoekLogo from 'component/Icons/BoekLogo';
import { Icon, Button, Form, Input, Message, Accordion, Header } from 'semantic-ui-react';
import { FRONTEND_SSO_LOGIN_URL } from 'helpers'
import { showNotification } from 'helpers/notification';
import { theme } from 'styles';

pdfjs.GlobalWorkerOptions.workerSrc = `/static/pdf.worker.${pdfjs.version}.js`

const BackgroundDiv = styled.div`
    flex: 1;
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100vh;

    background-image: url('${props => props.src}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;

const Sidebar = styled(USidebar)`
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.80) 100%);
    z-index: 10;

    &, label, h2 {
        color: #fff !important;
    }

    h2 {
        font-size: 1.3rem;
    }

    input {
        color: rgba(0, 0, 0, 0.87) !important;
    }

    ${PrimaryLink} {
        color: white !important;
    }

    ${SidebarContent} {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

    ${BrandingContainer} {
        color: rgba(255, 255, 255, 0.7);
        &:hover {
            color: #fff;
            filter: unset;
        }
    }
`
const ShowPasswordIcon = styled(Icon)`
    position: absolute;
    color: rgba(0, 0, 0, 0.87) !important;
    opacity: 0.3 !important;
    &:hover {
        opacity: 0.6 !important;
    }
    transition: opacity 300ms ease;
    cursor: pointer;
    margin: 0 !important;
    width: 2.57142857em !important;
    height: 2.57142857em !important;
    line-height: 2.57142857em !important;
    right: 1px;
    bottom: 1px;
`;
const Logo = styled(BoekLogo)`
    height: 150px;
    transform: scale(1.5);
`
const ButtonSSO = styled(Button)`
    background-color: #2c8175!important;
`;
const LoginForm = styled(Form)`
    marginTop: 1.5rem;
`;

export function loginScreen(Screen) {
    return observer(class extends Screen {
        render() {
            return (
                <Body style={{ alignItems: 'flex-end' }}>
                    <ContentContainer>
                        <Sidebar>
                            <Logo />
                            {this.renderContent()}
                            <div style={{ flex: '1' }} />
                            {this.renderBranding()}
                        </Sidebar>
                    </ContentContainer>
                    <BackgroundDiv src={LoginBackground} />
                </Body>
            )
        }
    })
}

@loginScreen
export class Login extends BaseLogin {
    static defaultProps = {
        ...BaseLogin.defaultProps,
        usernameField: 'username',
    }

    @observable showLoginForm = 0;

    toggleShowLoginForm = () => {
        this.showLoginForm = this.showLoginForm === 0 ? 1 : 0;
    }

    renderHeader() {
        return (
            <Header as="h2" content={t('user.login.title')} onClick={this.toggleShowLoginForm} style={{ 'cursor': 'pointer', 'marginBottom': '0px', 'marginTop': '40px' }} data-test-show-login-panel/>
        );
    }

    renderForm() {
        const { usernameField, labelColor, submitButtonColor } = this.props;
        const urlParams = new URLSearchParams(window.location.search);
        const errorPage = urlParams.get('error')
        if (errorPage === 'permission-denied') {
            showNotification({
                type: 'info',
                dismissAfter: 4000,
                message: 'Permission denied',
            });
        }

        return (
            <LoginForm error={this.errorMsg !== ''}>
                <Accordion>
                    <Accordion.Title active={this.showLoginForm === 1} index={1} style={{ 'display': 'none' }}></Accordion.Title>
                    <Accordion.Content active={this.showLoginForm === 1}>
                        <Form.Field>
                            <label style={{ 'color': labelColor }}>{t(`user.field.${usernameField}.label`)}</label>
                            <Input
                                name={usernameField}
                                value={this.input[usernameField]}
                                onChange={(e, { value }) => this.input[usernameField] = value}
                                autoFocus
                            />
                        </Form.Field>
                        <Form.Field style={{ position: 'relative' }}>
                            <label style={{ 'color': labelColor }}>{t('user.field.password.label')}</label>
                            <Input
                                name="password"
                                type={this.showPassword ? undefined : 'password'}
                                value={this.input.password}
                                onChange={(e, { value }) => this.input.password = value}
                            />
                            <ShowPasswordIcon
                                name={this.showPassword ? 'eye slash' : 'eye'}
                                onClick={() => this.showPassword = !this.showPassword}
                            />
                        </Form.Field>
                        {this.renderForgotPasswordLink()}
                        <Message error content={this.errorMsg} />
                        <Button style={{ 'backgroundColor': submitButtonColor, 'marginBottom': '20px' }} data-test-login data-test-login-button primary fluid
                            content={t('user.login.loginButton')}
                            onClick={this.handleSubmit}
                        />
                    </Accordion.Content>
                </Accordion>

                <ButtonSSO data-test-login-sso data-test-login-button-sso primary fluid
                    content={t('user.login.loginButtonSSO')}
                    onClick={() => {window.location.replace(FRONTEND_SSO_LOGIN_URL)}}
                />
            </LoginForm>
        );
    }
}

// Horrible way to do this, you need to check the url in Router.js and add it
// here. Better would be something like this:
//
// <Route unauthenticated path="x/y/z" />
const pathsThatSkipAuthentication = [
    /^\/login\/forgot$/,
    /^\/user\/\d+\/reset-password\/[^/]+$/,
    /^\/register/,
    /^\/register/,
    /^\/administration\/invoice\/\d+\/approve\//,
    /^\/public\/document\/reject\//,
    /^\/public\/customer\/login\//,
];

const pathsFullScreen = [
    /^\/maps/,
];

@withRouter
@observer
export default class App extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object,
    };

    static childContextTypes = {
        viewStore: PropTypes.object,
    };

    getChildContext() {
        return {
            viewStore: this.props.store,
        };
    }

    componentDidCatch(err) {
        this.hasCrashed = true;
        if (process.env.CY_FRONTEND_SENTRY_DSN) {
            Sentry.captureException(err);
            Sentry.showReportDialog();
        }
    }

    @observable hasCrashed = false;

    render() {
        const { store, location } = this.props;

        if (this.hasCrashed) {
            return <RuntimeError />;
        }

        let content = null;

        if (
            store.isAuthenticated ||
            pathsThatSkipAuthentication.some(regex =>
                regex.test(location.pathname)
            )
        ) {
            content = <Router store={store} />;
        } else if (store.bootstrapCode === 200) {
            content = <Login viewStore={store} />;
        } else if (store.bootstrapCode !== null) {
            // The not null check is important, since we don't want to flash a startup error while the XHR request is running.
            return <StartupError code={store.bootstrapCode} />;
        }

        if(pathsFullScreen.some(regex =>
                regex.test(location.pathname)
            )){
            return (
            <AppContainer data-theme={JSON.stringify(theme)}>
                <Body>{content}</Body>
            </AppContainer>
            )
        }

        return (
            <AppContainer data-theme={JSON.stringify(theme)}>
                <AppHeader store={store}/>
                <Body data-test-body>{content}</Body>
                {store.currentModal ? (
                    <store.currentModal.render
                        viewStore={store}
                        {...store.currentModal}
                    />
                ) : null}
                <NotificationArea store={store} />
            </AppContainer>
        );
    }
}
