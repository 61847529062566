import { observable } from 'mobx';
import { Model, Store, Casts } from '../Base';
import { User } from '../User'
import { Parking } from 'store/Parking';
import { RejectionReason } from 'store/RejectionReason';

export class ParkingStatusChange extends Model {
    static backendResourceName = 'parking_status_change';

    @observable id = null;
    @observable rejectionExplanation = '';
    @observable createdAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
        };
    }

    relations() {
        return {
            parking: Parking,
            rejectionReason: RejectionReason,
            createdBy: User,
        };
    }
}

export class ParkingStatusChangeStore extends Store {
    Model = ParkingStatusChange;
    static backendResourceName = 'parking_status_change';
}
