export default `
{{customerId}} / {{trailerPlate}} {{status}} tacho stop in {{currentLocation}}



{{customerId}} / {{trailerPlate}} {{status}} tacho stop in {{currentLocation}} is: {{currentCommunicatedTime}}
Reason: Driver had to take his legally required break

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
