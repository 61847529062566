import React from 'react';
import PropTypes from 'prop-types';

const IconSecureParking = props => {
    return (
        <svg viewBox="0 0 30 30" {...props}>
            <path
                d="M16 6H9v18h4v-6h3c3.31 0 6-2.69 6-6s-2.69-6-6-6zm.2 8H13v-4h3.2c1.1 0 2 .9 2 2s-.9 2-2 2z"
                fill={props.color}
            />
        </svg>
    );
};

IconSecureParking.propTypes = {
    color: PropTypes.string,
};

export default IconSecureParking;
