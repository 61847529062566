import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Casts } from 'store/Base';
import { Feed, List } from 'semantic-ui-react';
import { trim, groupBy } from 'lodash';
import { FeedUserAvatar } from 'component/UserAvatar';
import { User } from 'store/User';
import FeedSummary from 'component/SemanticUi/FeedSummary';
import RightDivider from 'spider/component/RightDivider';
import { DATETIME_FORMAT } from 'helpers';
import { format } from 'helpers/date';

@observer
export default class HistoryItem extends Component {
    static propTypes = {
        history: PropTypes.instanceOf(History).isRequired,
        afterSave: PropTypes.func.isRequired,
        currentUser: PropTypes.instanceOf(User).isRequired,
    };

    render() {
        const { history, currentUser } = this.props;
        const changesGrouped = groupBy(history.changes.models, change => `${change.model}|${change.oid}`);

        return (
            <Feed.Event key={history.id}>
                <FeedUserAvatar user={history.user} />
                <Feed.Content style={{ marginTop: '0px', borderLeft: '1px solid #d5d8e1', border: '1px solid #d5d8e1', borderRadius: '3px' }}>
                    <FeedSummary own={history.user.id === currentUser.id}>
                        <Feed.User>{history.user.fullName}</Feed.User>
                        <RightDivider />
                        <Feed.Date>{history.date.format(DATETIME_FORMAT)}</Feed.Date>
                    </FeedSummary>
                    <Feed.Extra style={{ padding: '5px', margin: '0px' }}>
                        <List divided>
                            {Object.keys(changesGrouped).map(key => {
                                const [model, id] = key.split('|');
                                const changes = changesGrouped[key];

                                return (
                                    <List.Item key={key}>
                                        <b>{model} {id}</b>
                                        <List>
                                            {changes.map(change => {
                                                let { field, before, after } = change;

                                                if (field === 'updated_at') {
                                                    before = format(Casts.datetime.parse(field, trim(before, '"')), DATETIME_FORMAT);
                                                    after = format(Casts.datetime.parse(field, trim(after, '"')), DATETIME_FORMAT);
                                                }

                                                return <List.Item key={change.id}><b>{field}</b> {before} ⇨ {after}</List.Item>;
                                            })}
                                        </List>
                                    </List.Item>
                                );
                            })}
                        </List>
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        );
    }
}
