export default `
{{customerId}} / {{trailerPlate}} ETA to unload {{currentLocation}}



{{customerId}} / {{trailerPlate}} his ETA at unloadplace {{currentLocation}} is: {{currentCommunicatedTime}}
Info will follow when start to unload
Reason: Driver had to take his legally required break

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
