import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export class VersteijnenIntegration extends Model {
    static backendResourceName = 'versteijnen_integration';

    @observable id = null;
    @observable name = '';
    @observable urlDownload = '';
    @observable urlUpload = '';
    @observable activityLinkRequiredForFinalize = false;

}

export class VersteijnenIntegrationStore extends Store {
    Model = VersteijnenIntegration;
    static backendResourceName = 'versteijnen_integration';
}
