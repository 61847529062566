export default `
{{customerId}} waiting for a new plan



Currently there is no plan for truck {{customerId}}.

Location: {{currentPosition}}
Working time till: {{workingTimeTill}}
Driving time left: {{drivingTimeLeft}}
Start weekend break: {{startWeekendBreak}} ({{weekendBreakLength}})

Could you please provide a new plan?

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
