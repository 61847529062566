import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Incident } from './Incident';
import { User } from './User';
import { ClaimActionStore, ACTION_RECEIVED_PAYMENT } from './Claim/Action';
import { ClaimDocumentStore } from './Claim/Document';

export const TYPE_TRUCK = 'truck';
export const TYPE_TRAILER = 'trailer';
export const TYPE_CARGO = 'cargo';
export const TYPE_THIRD_PARTY = 'third party';

export const TYPES = [TYPE_TRUCK, TYPE_TRAILER, TYPE_CARGO, TYPE_THIRD_PARTY];

export class Claim extends Model {
    static backendResourceName = 'claim';

    @observable id = null;
    @observable type = '';
    @observable claimDate = null;
    @observable status = '';
    @observable reference = '';
    @observable instance = 1;
    @observable description = '';
    @observable cost = 0; // In cents
    @observable ownRisk = 0; // In cents
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            incident: Incident,
            createdBy: User,
            actions: ClaimActionStore,
            documents: ClaimDocumentStore,
        };
    }

    casts() {
        return {
            paymentDate: Casts.date, // Computed field, but needs casts anyway to get dateLib.
            claimDate: Casts.date,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    @computed get net() {
        return this.cost - this.ownRisk;
    }

    @computed get paymentDate() {
        if (!this.actions) {
            return null;
        }

        const paymentActions = this.actions.filter(a => a.action === ACTION_RECEIVED_PAYMENT);

        if (paymentActions.length === 0) {
            return null;
        }

        return paymentActions[0].date;
    }
}

export class ClaimStore extends Store {
    Model = Claim;
    static backendResourceName = 'claim';
}
