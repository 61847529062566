import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestionText } from './QuestionText';

export class FormAnswerText extends Model {
    static backendResourceName = 'form_answer_text';

    @observable id = null;
    @observable value = '';

    relations() {
        return {
            formQuestionText: FormQuestionText,
        };
    }
}

export class FormAnswerTextStore extends Store {
    Model = FormAnswerText;
    static backendResourceName = 'form_answer_text';
}
