import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export class TruckEquipment extends Model {
    static backendResourceName = 'truck_equipment';

    @observable id = null;
    @observable quantity = 0;
    @observable name = '';
    @observable kind = 'new';

}

export class TruckEquipmentStore extends Store {
    Model = TruckEquipment;
    static backendResourceName = 'truck_equipment';
}
