import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Activity } from './Activity';
import { Entity } from './Entity';
import { Location } from './Location';
import { User } from './User';
import { IncidentFollowupStore } from './Incident/Followup';
import { IncidentDocumentStore } from './Incident/Document';
import { ClaimStore } from './Claim';
import { HistoryStore } from './History';
import { camelCase } from 'lodash';

export const STATUS_NO_CLAIM = 'no claim';
export const STATUS_TO_INFORM_INSURANCE = 'to inform insurance';
export const STATUS_COLLECTING_DOCUMENTS = 'collecting documents';
export const STATUS_WAITING_FOR_ESTIMATE = 'waiting for estimate';
export const STATUS_REPAIRING_WAITING_FOR_INVOICE = 'repairing / waiting for invoice';
export const STATUS_FINISHED_WAITING_FOR_PAYMENT = 'finished / waiting for payment';
export const STATUS_FINALIZED = 'finalized';

export const STATUSES = [
    STATUS_NO_CLAIM, STATUS_TO_INFORM_INSURANCE,
    STATUS_COLLECTING_DOCUMENTS, STATUS_REPAIRING_WAITING_FOR_INVOICE,
    STATUS_FINISHED_WAITING_FOR_PAYMENT, STATUS_FINALIZED,
];

export const TYPE_TRAFFIC = 'traffic';
export const TYPE_MANEUVERING = 'maneuvering';
export const TYPE_CAUSED_BY_3RD_PARTY = 'caused by 3rd party';
export const TYPE_THEFT = 'theft';
export const TYPE_CARGO = 'cargo';
export const TYPES = [TYPE_TRAFFIC, TYPE_MANEUVERING, TYPE_CAUSED_BY_3RD_PARTY, TYPE_THEFT, TYPE_CARGO];

export const FOLLOWUP_ACTION_FILL_DATA = 'fill data';
export const FOLLOWUP_ACTION_ADD_AGENCY = 'add agency';
export const FOLLOWUP_ACTION_MAKE_CONTROL = 'make control';
export const FOLLOWUP_ACTION_CHECK_STATUS = 'check status';
export const FOLLOWUP_ACTION_FINAL_CONTROL = 'final control';
export const FOLLOWUP_ACTIONS = [FOLLOWUP_ACTION_FILL_DATA, FOLLOWUP_ACTION_ADD_AGENCY, FOLLOWUP_ACTION_MAKE_CONTROL, FOLLOWUP_ACTION_CHECK_STATUS, FOLLOWUP_ACTION_FINAL_CONTROL];

export class Incident extends Model {
    static backendResourceName = 'incident';

    @observable id = null;
    @observable type = TYPE_TRAFFIC;
    @observable status = '';
    @observable reference = '';
    @observable description = '';
    @observable incidentDatetime = null;
    @observable followupAction = FOLLOWUP_ACTION_FILL_DATA;
    @observable followupActionDate = null;
    @observable penalty = 0;
    @observable hasSurveyor = false;
    @observable surveyorName = '';
    @observable surveyorPhoneNumber = '';
    @observable surveyorDate = null;
    @observable createdAt = null;
    @observable updatedAt = null;

    @observable _history = new HistoryStore({ relations: ['user', 'changes'] });

    relations() {
        return {
            activity: Activity,
            entity: Entity,
            location: Location,
            followupActionTaker: User,
            createdBy: User,
            updatedBy: User,
            finishedBy: User,
            finalizedBy: User,
            followups: IncidentFollowupStore,
            claims: ClaimStore,
            documents: IncidentDocumentStore,
        };
    }

    casts() {
        return {
            incidentDatetime: Casts.datetime,
            followupActionDate: Casts.date,
            surveyorDate: Casts.date,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            finishedAt: Casts.datetime,
            finalizedAt: Casts.datetime,
        };
    }

    @computed get statusTranslated() {
        return t(`incident.field.status.value.${camelCase(this.status)}`);
    }

    @computed get cost() {
        if (!this.claims) {
            return 0;
        }

        return this.claims.models.reduce((res, claim) => res + claim.cost, 0);
    }

    @computed get ownRisk() {
        if (!this.claims) {
            return 0;
        }

        return this.claims.models.reduce((res, claim) => res + claim.ownRisk, 0);
    }

    @computed get net() {
        if (!this.claims) {
            return 0;
        }

        return this.claims.models.reduce((res, claim) => res + claim.net, 0);
    }

    fetchHistory() {
        this._history.url = this.url + 'history/';
        this._history.fetch();
    }
}

export class IncidentStore extends Store {
    Model = Incident;
    static backendResourceName = 'incident';
}
