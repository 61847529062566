import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer, PropTypes as MobxTypes } from 'mobx-react';
import { observable } from 'mobx';
import { TruckPositionStore } from 'store/TruckPosition';
import ActivityTruckMap from 'container/PlanningCustomer/ActivityDetails/TruckMap';
import { MapContainer } from 'component/Location/Map';
import { ParkingStore } from 'store/Parking';
import { Activity, TYPE_PARKING } from 'store/Activity';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { PointOfInterestStore } from 'store/Location/PointOfInterest';

const CompressMapButton = styled(Button)`
    position: absolute;
    top: 17%;
    left: 1.4%;
    scale: 70%;
    z-index: 999;
    border-style: hidden;
    border-radius: 5px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
`;


@observer
export default class ParkingMapModal extends Component {
    static propTypes = {
        pointOfInterestParkingStore: PropTypes.instanceOf(ParkingStore),
        store: PropTypes.instanceOf(TruckPositionStore),
        onClickParking: PropTypes.func,
        activity: PropTypes.instanceOf(Activity),
        onClickParkingFromModal: PropTypes.func,
        onClickCollapseButton: PropTypes.func,
        onClick: PropTypes.func,
        pointOfInterestStore: PropTypes.instanceOf(PointOfInterestStore),
        parkingBookableSet: PropTypes.object,
        travisBookableSet: PropTypes.object,
        activityRouteForParking: MobxTypes.arrayOrObservableArray,
        activityMarkersForParking: MobxTypes.arrayOrObservableArray,
        freeParkingOpenSet: PropTypes.object,
    }

    @observable parkingModalMap = true;

    render() {
        const { pointOfInterestParkingStore, activityRouteForParking, activityMarkersForParking, store, onClickParking, activity, onClickParkingFromModal, onClickCollapseButton, parkingBookableSet, onClick, pointOfInterestStore, travisBookableSet, freeParkingOpenSet } = this.props;
        const activityMarkers = [];

        if (activity.location.id && activity.type === TYPE_PARKING) {
            activityMarkers.push(activity);
        }

        return (
            <React.Fragment>
                <div data-test-map-modal>
                    <MapContainer style={{ flexDirection: 'column', width: '100%', height: '760px' }}>
                        <CompressMapButton icon='compress' style={{ background: 'white', color: 'black', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)' }} onClick={onClickCollapseButton} />
                        <ActivityTruckMap
                            store={store}
                            pointOfInterestParkingStore={pointOfInterestParkingStore}
                            onClickParking={onClickParking}
                            parkingModalMap={this.parkingModalMap}
                            activity={activity}
                            onClickParkingFromModal={onClickParkingFromModal}
                            activityMarkers={activityMarkers}
                            onClick={onClick}
                            pointOfInterestStore={pointOfInterestStore}
                            parkingBookableSet={parkingBookableSet}
                            travisBookableSet={travisBookableSet}
                            activityRouteForParking={activityRouteForParking}
                            activityMarkersForParking={activityMarkersForParking}
                            freeParkingOpenSet={freeParkingOpenSet}
                        />
                    </MapContainer>
                </div>
            </React.Fragment>
        );
    }
}
