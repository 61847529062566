import { observable, computed } from 'mobx';
import { Model, Store } from 'store/Base';
import { Activity } from './Activity';
import { InvoiceLine } from './InvoiceLine';
import { Currency } from './Currency';

export class InvoiceLineItem extends Model {
    static backendResourceName = 'invoice_line_item';

    @observable id = null;
    @observable invoicedKm = 0;
    @observable waitingAmount = 0;
    @observable weekendAmount = 0;
    @observable otherCostsAmount = 0;
    @observable kmAmount = 0;
    @observable kmSurcharge = 0;
    @observable fixedAmount = 0;
    @observable fixedSurcharge = 0;
    @observable secondDriverAmount = 0;
    @observable customAmount = 0;
    @observable tollAmount = 0;

    @observable expectedWaitingAmount = 0;
    @observable expectedWeekendAmount = 0;
    @observable expectedOtherCostsAmount = 0;
    @observable expectedKm = 0;
    @observable expectedKmAmount = 0;
    @observable expectedKmSurcharge = 0;
    @observable expectedFixedAmount = 0;
    @observable expectedFixedSurcharge = 0;
    @observable expectedSecondDriverAmount = 0;
    @observable expectedTollAmount = 0;

    @computed
    get total() {
        const total =
            this.waitingAmount +
            this.weekendAmount +
            this.otherCostsAmount +
            this.kmAmount +
            this.kmSurcharge +
            this.fixedAmount +
            this.fixedSurcharge +
            this.secondDriverAmount +
            this.customAmount +
            this.tollAmount
        ;

        return total;
    }

    @computed
    get amount() {
        return this.total;
    }

    @computed
    get expectedAmount() {
        return this.expectedWaitingAmount +
            this.expectedWeekendAmount +
            this.expectedOtherCostsAmount +
            this.expectedKmAmount +
            this.expectedKmSurcharge +
            this.expectedFixedAmount +
            this.expectedFixedSurcharge +
            this.expectedSecondDriverAmount +
            this.expectedTollAmount;
    }

    fraction(attr) {
        return this[attr] / (this.invoiceLine[attr] || 1);
    }

    calcWaivedAmount(waiver) {
        return this.fraction('waitingAmount') * waiver.waivedWaitingAmount +
            this.fraction('weekendAmount') * waiver.waivedWeekendAmount +
            this.fraction('otherCostsAmount') * waiver.waivedOtherCostsAmount +
            this.fraction('kmAmount') * waiver.waivedKmAmount +
            this.fraction('kmSurcharge') * waiver.waivedKmSurcharge +
            this.fraction('fixedAmount') * waiver.waivedFixedAmount +
            this.fraction('fixedSurcharge') * waiver.waivedFixedSurcharge +
            this.fraction('secondDriverAmount') * waiver.waivedSecondDriverAmount +
            this.fraction('tollAmount')* waiver.waivedTollAmount;
    }

    relations() {
        return {
            activity: Activity,
            invoiceLine: InvoiceLine,
            currency: Currency,
        };
    }

    convertToCredit() {
        this.setInput('id', null);
        this.setInput('invoicedKm', -this.invoicedKm);
        this.setInput('waitingAmount', -this.waitingAmount);
        this.setInput('weekendAmount', -this.weekendAmount);
        this.setInput('otherCostsAmount', -this.otherCostsAmount);
        this.setInput('kmAmount', -this.kmAmount);
        this.setInput('kmSurcharge', -this.kmSurcharge);
        this.setInput('fixedAmount', -this.fixedAmount);
        this.setInput('fixedSurcharge', -this.fixedSurcharge);
        this.setInput('secondDriverAmount', -this.secondDriverAmount);
        this.setInput('customAmount', -this.customAmount);
        this.setInput('tollAmount', -this.tollAmount);
    }
}

export class InvoiceLineItemStore extends Store {
    Model = InvoiceLineItem;

    static backendResourceName = 'invoice_line_item';
}
