export default `
{{customerId}} / {{trailerPlate}} ETA to {{trailerDropOrPickup}} trailer{{nextTrailerPlate}} at {{currentLocation}}



{{customerId}} / {{trailerPlate}} his ETA to {{trailerDropOrPickup}} trailer{{nextTrailerPlate}} at {{currentLocation}} is: {{currentCommunicatedTime}}

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
