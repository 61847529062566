import React from 'react';
import PropTypes from 'prop-types';

const IconTanking = props => {
    return (
        <svg viewBox="0 0 30 30" {...props}>
{ props.price &&
           <text fill={props.color} font-size="14" font-family="Verdana" x="14" y="13" text-anchor="middle">{ props.price/100}</text>

}
{ props.price &&
           <path transform="translate(0,10)"
                d="M22.77 10.23l.01-.01-3.72-3.72L18 7.56l2.11 2.11c-.94.36-1.61 1.26-1.61 2.33a2.5 2.5 0 0 0 2.5 2.5c.36 0 .69-.08 1-.21v7.21c0 .55-.45 1-1 1s-1-.45-1-1V17c0-1.1-.9-2-2-2h-1V8c0-1.1-.9-2-2-2H9c-1.1 0-2 .9-2 2v16h10v-7.5h1.5v5a2.5 2.5 0 0 0 5 0V12c0-.69-.28-1.32-.73-1.77zM15 13H9V8h6v5zm6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
                fill={props.color}
            />
}
{ !props.price &&
              <path
                d="M22.77 10.23l.01-.01-3.72-3.72L18 7.56l2.11 2.11c-.94.36-1.61 1.26-1.61 2.33a2.5 2.5 0 0 0 2.5 2.5c.36 0 .69-.08 1-.21v7.21c0 .55-.45 1-1 1s-1-.45-1-1V17c0-1.1-.9-2-2-2h-1V8c0-1.1-.9-2-2-2H9c-1.1 0-2 .9-2 2v16h10v-7.5h1.5v5a2.5 2.5 0 0 0 5 0V12c0-.69-.28-1.32-.73-1.77zM15 13H9V8h6v5zm6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
                fill={props.color}
            />
}
        </svg>
    );
};
IconTanking.propTypes = {
    color: PropTypes.string,
    price: PropTypes.number,
};

export default IconTanking;
