export default `
{{customerId}} / {{trailerPlate}} arrived to {{trailerDropOrPickup}} trailer{{nextTrailerPlate}} at {{currentLocation}}



{{customerId}} / {{trailerPlate}} just arrived to {{trailerDropOrPickup}} trailer{{nextTrailerPlate}} at {{currentLocation}}

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
