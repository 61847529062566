import { observable, computed } from 'mobx';
import { Model, Store, Casts } from './Base';
import { Contract } from './Contract';
import { CostItemStore } from './CostItem';
import { User } from './User';
import { distributeAmount } from './InvoiceLine';

export const TYPE_MISSING_REVENUE = 'missing revenue';
export const TYPE_COSTS = 'costs';

export class Cost extends Model {
    static backendResourceName = 'cost';

    static types = [ TYPE_MISSING_REVENUE, TYPE_COSTS ];

    @observable id = null;
    @observable date = null;
    @observable type = null;
    @observable remarks = '';
    @observable amount = 0;
    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            date: Casts.date,
        };
    }

    relations() {
        return {
            contract: Contract,
            items: CostItemStore,
            createdBy: User,
        };
    }

    @computed get km() {
        return this.items.models.reduce((result, item) => {
            return result + item.km;
        }, 0);
    }

    // {copy-pasta-invoiced-km}
    calcExpectedInvoicedKm(tariff) {
        const kmRate = tariff.getKmRate(this.activities);

        if (kmRate) {
            return this.items.models.reduce((result, item) => {
                const activity = item.activity;

                return result + kmRate.getInvoiceKmFromActivity(activity);
            }, 0);
        }

        return 0;
    }

    recalculate(tariff) {
        this.items.forEach(item => {
            item.setInput('km', Math.round(tariff.getInvoiceKmFromActivity(item.activity)));
        });

        distributeAmount(tariff, this.items, 'amount', this.km, this.amount, item => item.km);
    }

    // {copy-pasta-activities}
    @computed
    get activities() {
        return this.items.map(i => i.activity);
    }

    // {copy-pasta-activity-ids}
    @computed get activityIds() {
        return this.activities.map(a => a.id);
    }
}

export class CostStore extends Store {
    Model = Cost;

    static backendResourceName = 'cost';
}
