import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestionFile } from './QuestionFile';
import { FormAnswerFileUploadStore } from './AnswerFileUpload';

export class FormAnswerFile extends Model {
    static backendResourceName = 'form_answer_file';

    @observable id = null;

    relations() {
        return {
            formQuestionFile: FormQuestionFile,
            uploads: FormAnswerFileUploadStore,
        };
    }
}

export class FormAnswerFileStore extends Store {
    Model = FormAnswerFile;
    static backendResourceName = 'form_answer_file';
}
