import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table } from 'semantic-ui-react';
import { getMoneyForUser, DATE_SHORT_FORMAT } from 'helpers';

@observer
export default class ActivityFinalizeWaivers extends Component {
    static propTypes = {
        activity: PropTypes.object.isRequired,
    };

    render() {
        const { activity } = this.props;

        return (
            <Table data-test-costs celled compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell singleLine>{t('cost.field.createdBy.label')}</Table.HeaderCell>
                        <Table.HeaderCell singleLine>{t('cost.field.type.label')}</Table.HeaderCell>
                        <Table.HeaderCell singleLine>{t('cost.field.date.label')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('cost.field.remarks.label')}</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign="right">{t('planning.activityFinalizeModal.invoice.totalAmount')}</Table.HeaderCell>
                        <Table.HeaderCell collapsing textAlign="right">{t('planning.activityFinalizeModal.invoice.activityAmount')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {activity.costItems.map(costItem => {
                        return (
                            <Table.Row key={costItem.id}>
                                <Table.Cell collapsing>{costItem.cost.createdBy.fullName}</Table.Cell>
                                <Table.Cell collapsing>{t(`cost.field.type.value.${costItem.cost.type}`)}</Table.Cell>
                                <Table.Cell collapsing>{costItem.cost.date.format(DATE_SHORT_FORMAT)}</Table.Cell>
                                <Table.Cell>{costItem.cost.remarks}</Table.Cell>
                                <Table.Cell data-test-total-amount textAlign="right">€{getMoneyForUser(costItem.cost.amount)}</Table.Cell>
                                <Table.Cell data-test-activity-amount textAlign="right">€{getMoneyForUser(costItem.amount)}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    }
}
