import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Activity } from './Activity';
import { Certification } from './Certification';
import { License } from './License';


export class ActivityIssue extends Model {
    static backendResourceName = 'activity_issue';

    @observable id = null;
    @observable countries = [];
    @observable condition = '';
    @observable reason = '';
    @observable temperature = '';

    relations() {
        return {
            activity: Activity,
            certification: Certification,
            license: License,
        };
    }
}

export class ActivityIssueStore extends Store {
    Model = ActivityIssue;
    static backendResourceName = 'activity_issue';
}
