export default `
{{customerId}} / {{trailerPlate}} ETA to POI {{currentLocation}}



{{customerId}} / {{trailerPlate}} his ETA at POI {{currentLocation}} is: {{currentCommunicatedTime}}
Reason: Driver had to take his legally required break

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
