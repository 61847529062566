import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Contract } from './Contract';
import { VatRule } from './VatRule';
import { TariffKmRateStore } from './Tariff/KmRate';
import { TariffFixedRateStore } from './Tariff/FixedRate';
import { TariffSecondDriverRateStore } from './Tariff/SecondDriverRate';
import { TariffWeekendRateStore } from './Tariff/WeekendRate';
import { TariffWaitingRateStore } from './Tariff/WaitingRate';
import { omit } from 'lodash';

/**
 * A tariff holds information how much activities should cost. These are stored
 * in rates, and the tariff controls the rates to do the actual calculations.
 *
 * Each rate of a tariff (kmRate, fixedRate, ...) can have volume discount
 * (staffels), which is calculated when getting a rate:
 *
 * getKmRate([a1, a2, a3]) => returns kmRate with volume rate applied
 * calcKmAmount(a1) => returns the kmAmount for a single activity
 *
 */
export class Tariff extends Model {
    static backendResourceName = 'tariff';

    @observable id = null;
    @observable remarks = '';
    @observable name = '';
    @observable startDate = null;
    @observable isImmutable = false;
    @observable includeToll = false;
    @observable rateAgreement = null;
    @observable realisticKmRateOverridePrice = null;

    relations() {
        return {
            kmRates: TariffKmRateStore,
            fixedRates: TariffFixedRateStore,
            weekendRates: TariffWeekendRateStore,
            waitingRates: TariffWaitingRateStore,
            secondDriverRates: TariffSecondDriverRateStore,
            contract: Contract,
            vatRule: VatRule,
        };
    }

    casts() {
        return {
            startDate: Casts.date,
        };
    }

    toBackend(options) {
        return omit(super.toBackend(options), [ 'is_immutable', ]);
    }

    copy(tariff) {
        const tariffAttrs = tariff.toJS();

        this.__attributes.forEach(key => {
            this.setInput(key, tariffAttrs[key]);
        });

        // Treat FileField as special.
        this.rateAgreement = tariff.rateAgreement;

        tariffAttrs.id = null;

        this.__activeRelations.forEach(r => {
            if (this[r] instanceof Store) {
                const attrs = tariff[r].toJS().map(a => omit(a, 'id', 'isImmutable'));

                this[r].parse(attrs);
            } else {
                const attrs = tariff[r].toJS();

                Object.keys(attrs).forEach(key => {
                    this[r].setInput(key, attrs[key]);
                });
            }
        });
    }

    getInvoiceKmFromActivity(activity) {
        if (!this.kmRates || this.kmRates.length === 0) {
            return activity.givenKm;
        }

        const firstKmRate = this.kmRates.at(0);

        return firstKmRate.getInvoiceKmFromActivity(activity);
    }

    /**
     * Get kmRate which applies to activities, for example, based on volume
     * rates.
     *
     * @param {array} activities
     */
    getKmRate(activities) {
        if (!this.kmRates || this.kmRates.length === 0) {
            return undefined;
        }

        const totalKm = this.calcTotalInvoiceLineKm(activities)

        if (this.kmRates) {
            return this.kmRates.getRateForKm(totalKm);
        }

        return undefined;
    }

    /**
     * A bit weird that this exists next to getKmRate, but the user can also
     * enter a custom invoicedKm which might lead to using another km rate.
     *
     * See https://phabricator.codeyellow.nl/T17644#374552
     */
    getKmRateByKm(totalKm) {
        if (!this.kmRates || this.kmRates.length === 0) {
            return undefined;
        }

        if (this.kmRates) {
            return this.kmRates.getRateForKm(totalKm);
        }

        return undefined;
    }

    calcTotalInvoiceLineKm(activities) {
        if (!this.kmRates || this.kmRates.length === 0) {
            return 0;
        }

        // All kmRates should use same planned or driven km.
        const firstKmRate = this.kmRates.at(0);
        const totalKm = activities.reduce(
            (res, activity) => res + firstKmRate.getInvoiceKmFromActivity(activity),
            0
        );

        return totalKm;
    }

    getFixedRate(activities) {
        if (this.fixedRates && this.fixedRates.length > 0) {
            return this.fixedRates.at(0);
        }

        return undefined;
    }

    getWeekendRate(activities) {
        if (this.weekendRates && this.weekendRates.length > 0) {
            return this.weekendRates.at(0);
        }

        return undefined;
    }

    getWaitingRate(activities) {
        if (this.waitingRates && this.waitingRates.length > 0) {
            return this.waitingRates.at(0);
        }

        return undefined;
    }

    getSecondDriverRate(activities) {
        if (this.secondDriverRates && this.secondDriverRates.length > 0) {
            return this.secondDriverRates.at(0);
        }

        return undefined;
    }

    /**
     * Calculate kmAmount for a specific activity. If bundledActivities is given
     * then it will be used to find the volume kmRate.
     */
    calcKmAmount(activity, bundledActivities) {
        const kmRate = this.getKmRate(bundledActivities ? bundledActivities : [activity]);
        const totalKm = this.calcTotalInvoiceLineKm(bundledActivities ? bundledActivities : [activity]);

        if (kmRate) {
            return kmRate.calcPriceForActivity(activity, totalKm);
        }

        return 0;
    }

    /**
     * Same as calcKmAmount, unless we have an override; then use that
     * for preliminary amount.  There will be no bundled activities
     * and therefore we won't know the volume kmRate to apply.
     */
    calcPreliminaryKmAmount(activity) {
        let result = this.calcKmAmount(activity, [activity]);
        const firstKmRate = this.kmRates.length > 0 && this.kmRates.at(0);

        if (this.realisticKmRateOverridePrice && firstKmRate) {
            const totalKm = firstKmRate.getInvoiceKmFromActivity(activity);
            const amount = this.realisticKmRateOverridePrice / 10;

            result = totalKm * amount;
        }

        return result;
    }

    calcKmSurcharge(activity, bundledActivities) {
        const kmRate = this.getKmRate(bundledActivities ? bundledActivities : [activity]);
        const totalKm = this.calcTotalInvoiceLineKm(bundledActivities ? bundledActivities : [activity]);

        if (kmRate) {
            return kmRate.calcSurchargeForActivity(activity, totalKm);
        }

        return 0;
    }

    calcFixedRateAmount(activity, bundledActivities) {
        const fixedRate = this.getFixedRate(bundledActivities ? bundledActivities : [activity]);

        if (fixedRate) {
            return fixedRate.calcFixedAmountForActivity(activity);
        }

        return 0;
    }

    calcFixedSurcharge(activity) {
        const fixedRate = this.getFixedRate([activity]);

        if (fixedRate) {
            return fixedRate.calcSurchargeForActivity(activity);
        }

        return 0;
    }

    calcWeekendRateAmount(activity) {
        const weekendRate = this.getWeekendRate([activity]);

        if (weekendRate) {
            return weekendRate.price;
        }

        return 0;
    }

    calcApprovedWaitingAmount(activity) {
        const waitingRate = this.getWaitingRate([activity]);

        if (waitingRate) {
            return waitingRate.calcApprovedWaitingAmount(activity);
        }

        return 0;
    }

    calcWaitingRateAmount(activity, includingMinutes = false) {
        const waitingRate = this.getWaitingRate([activity]);

        if (waitingRate) {
            return waitingRate.calcPriceForActivity(activity, includingMinutes);
        }

        return 0;
    }

    calcWaitingRateMinutes(activity) {
        const waitingRate = this.getWaitingRate([activity]);

        if (waitingRate) {
            return waitingRate.calcMinutes(activity);
        }

        return 0;
    }

    calcWaitingRateStart(activity) {
        const waitingRate = this.getWaitingRate([activity]);

        if (waitingRate) {
            return waitingRate.calcStartForActivity(activity);
        }

        return 0;
    }

    calcSecondDriverAmount(activity) {
        const secondDriverRate = this.getSecondDriverRate([activity]);

        if (secondDriverRate) {
            return secondDriverRate.calcPriceForActivity(activity);
        }

        return 0;
    }

    calcTollAmount(activity, rate=1) {
        if (this.includeToll) {
            return activity.tollCosts * rate;
        }

        return 0;
    }

    calcOtherCostsAmount(activity) {
        return activity.calcOtherCostsAmount();
    }

    /**
     * When calculating how much an activity has actually earned, we use some
     * magic based on activity expected / invoice line expected / invoiced line.
     */
    calcFractionalAmount(activityExpectedAmount, invoiceLineExpectedAmount, invoiceLineInvoicedAmount) {
        if (invoiceLineExpectedAmount !== 0) {
            return activityExpectedAmount / invoiceLineExpectedAmount * invoiceLineInvoicedAmount;
        }

        return 0;
    }
}

export class TariffStore extends Store {
    Model = Tariff;
    static backendResourceName = 'tariff';

    comparator(a, b) {
        if (a.startDate < b.startDate) {
            return 1;
        }

        if (a.startDate > b.startDate) {
            return -1;
        }

        return 0;
    }
}
