import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FuelCostStore } from 'store/FuelCost';
import { SaveButton, CancelButton } from 'spider/semantic-ui/Button';
import { Button, Modal, List, Icon, Label } from 'semantic-ui-react';
import { showSaveNotification } from 'helpers/notification';
import { pull, uniq } from 'lodash';
import { FormField } from 're-cy-cle';
import RightDivider from 'spider/component/RightDivider';
import { TargetRadioButtons } from 'spider/semantic-ui/Target';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';
import { parseBackendErrorMessages } from 'helpers';
import moment from 'moment';
import { SERVER_DATE_FORMAT } from 'helpers';

const Help = styled.span`
    height: 15px;
    cursor: help;
`;

@observer
export default class ModalChangeProductFor extends Component {
    static propTypes = {
        close: PropTypes.func,
        viewStore: PropTypes.object.isRequired,
    };

    yearAgo = moment().subtract(13, 'months').format(SERVER_DATE_FORMAT);

    fuelCostStore = new FuelCostStore({
        limit: null,
        params: {
            '.finalized_at:isnull': true,
        },
    });

    @observable showButton = false;
    @observable showParseList = false;
    @observable fuelCostProvidedList = [];
    @observable _productFor = '';
    @observable isLoading = false;

    handleTextInput = (e) => {
        let value = e.target.value.split(' ')
        this.fuelCostProvidedList = uniq(value)
        this.showButton = true;
    };

    renderParsedItems = (item) => {
        return (
            <Button as='div' labelPosition='left'>
                <Label as='a' basic>
                    {item}
                </Label>
                <Button icon onClick={() => this.handleDeleteItem(item)}>
                    <Icon name='delete' />
                </Button>
            </Button>
        )
    }

    handleDeleteItem = (item) => {
        pull(this.fuelCostProvidedList, item)
    }

    handleShowList = () => {
        this.showParseList = !this.showParseList
    }

    saveProductFor = () => {
        const { viewStore } = this.props

        this.isLoading = true;
        let messages = [];
        this.fuelCostStore.params['.transaction_id:in'] = this.fuelCostProvidedList.join(',');

        this.fuelCostStore.fetch()
            .then(() => {
                this.fuelCostStore.models.forEach(fuelCost => {
                    let empty = false;

                    if (this.fuelCostProvidedList.length === 1 && this.fuelCostProvidedList[0] === '') {
                        empty = true
                    }

                    if (!empty && this.fuelCostProvidedList.includes(fuelCost.transactionId)) {
                        fuelCost.setInput('productFor', this._productFor)
                        fuelCost
                            .save()
                            .then(showSaveNotification)
                            .catch(response => {
                                messages.push(parseBackendErrorMessages(response.response.data.errors))

                                messages.forEach(message => {
                                    viewStore.showNotification({
                                        key: message,
                                        type: 'error',
                                        dismissAfter: 4000,
                                        message,
                                });
                            })
                        })
                    }
                })
            })
            .then(() => this.isLoading = false)
            .then(this.props.close);
    }

    render() {
        return (
            <React.Fragment>
                <Modal.Header>
                    {t('fuelCost.modalChangeProductFor.header')}
                </Modal.Header>
                <Modal.Content>
                    <FormField
                        label={(
                            <React.Fragment>
                                {t('fuelCost.field.transactionId.label')}
                                <Help title={t('fuelCost.modalChangeProductFor.helpText')}><Icon name="info circle" /></Help>
                            </React.Fragment>
                        )}
                        >
                        <Input
                            fluid
                            disabled={this.showParseList}
                            name="transactionId"
                            onChange={this.handleTextInput}
                        />
                        {this.showButton && !this.showParseList &&
                        <Button onClick={this.handleShowList}>{t('fuelCost.modalChangeProductFor.parseButton')}</Button>
                        }
                    </FormField>
                    <FormField
                        label="Product For"
                        required>
                        <TargetRadioButtons
                            value={this._productFor}
                            options={[
                                { value: 'truck', text: t('fuelCost.field.productFor.truck') },
                                { value: 'trailer', text: t('fuelCost.field.productFor.trailer') },
                            ]}
                            onChange={(value) => {
                                this._productFor = value
                            }}
                        />
                    </FormField>
                            {this.showParseList &&
                                <List horizontal>
                                    <List.Item>
                                        <List.Content>
                                            <List.Header>
                                                {t('fuelCost.modalChangeProductFor.listHeader')}
                                            </List.Header>
                                            {this.fuelCostProvidedList.map(this.renderParsedItems)}
                                        </List.Content>
                                    </List.Item>
                                </List>
                            }
                </Modal.Content>
                <Modal.Actions style={{ display: 'flex' }}>
                    <CancelButton onClick={this.props.close} />
                    <RightDivider />
                    <SaveButton primary data-test-bulk-button
                        disabled={!this.showParseList || this._productFor === ''}
                        content="Save"
                        loading={this.isLoading}
                        onClick={this.saveProductFor}
                    />
                </Modal.Actions>
            </React.Fragment>
        );
    }
}
