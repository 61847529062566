import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const OuterColumn = styled.div`
    border-right: 1px solid #bacedf;
    border-bottom: 1px solid #bacedf;
    flex: ${props => props.size};
    width: ${props => props.width};
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    word-wrap: break-word;
    word-wrap: break-word;

    ${props => props.alignRight && `
        text-align: right;
    `}

    ${props => props.textCenter && `
        text-align: center;
    `}

    ${props => props.onClick && `
        cursor: pointer;
    `}

    ${props => props.title && `
        cursor: help;
    `}

    ${props => props.opened && `
        background: #fff;
        border-bottom-color: #fff;
    `}

    ${props => props.overflow && `
        overflow: visible;
    `}

    ${props => props.highlight && `
        // {warning-color-copy-pasta}
        background-color: rgba(253, 153, 39, 0.56);
    `}
`;

// Horrible hack because e.g. `flex: 1; padding: 7px;` adds the padding to the flex width.
// This makes it difficult to apply headings at the top of the "flextable", because the headings aren't aware of each padding.
const InnerColumn = styled.div`
    margin: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    ${props => props.overflow && `
        overflow: visible;
    `}
    ${props => props.isCustomerCode && `
    white-space: normal;
    `}
`;

export const Column = ({
    noPadding,
    size,
    width,
    children,
    onClick,
    opened,
    className,
    title,
    alignRight,
    textCenter,
    overflow,
    onMouseEnter,
    onMouseLeave,
    highlight,
    isCustomerCode,
    ...rest
}) => {
    return (
        <OuterColumn
            title={title}
            className={className}
            size={size}
            width={width}
            onClick={onClick}
            opened={opened}
            alignRight={alignRight}
            textCenter={textCenter}
            overflow={overflow}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            highlight={highlight}
            {...rest}
        >
            {noPadding ? children : <InnerColumn isCustomerCode={isCustomerCode} overflow={overflow}>{children}</InnerColumn>}
        </OuterColumn>
    );
};

Column.propTypes = {
    noPadding: PropTypes.bool,
    size: PropTypes.number,
    width: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
    opened: PropTypes.bool,
    overflow: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    alignRight: PropTypes.bool,
    textCenter: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    highlight: PropTypes.bool,
    isCustomerCode: PropTypes.bool,
};

export const RightColumn = styled(Column)`
    text-align: right;
`;
