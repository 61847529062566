// Hacky helper to ease working with notifications.
let viewStore = null;

export function configureNotification(givenViewStore) {
    viewStore = givenViewStore;
}

export function showSaveNotification() {
    // We've ran into some flaky tests, where either the notification was not
    // shown, or the button could not be clicked. When in doubt, setTimeout.
    setTimeout(() => viewStore.showSaveNotification(), 0);
}

export function showDeleteNotification() {
    setTimeout(() => viewStore.showDeleteNotification(), 0);
}

export function showNotification(message) {
    setTimeout(() => viewStore.showNotification(message), 0);
}

export function showErrorNotification(message) {
    setTimeout(() => viewStore.showNotification({ error: true, message }), 0);
}
