import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { TIME_FORMAT, DATE_MINI_FORMAT, DATETIME_MINI_FORMAT } from 'helpers';


function formatTwoTimes(a, b) {
    if (a.isSame(b) || !b) {
        return a.format(TIME_FORMAT);
    }
    return `${a.format(TIME_FORMAT)} - ${b.format(TIME_FORMAT)}`;
}



const ActivityInfoTimes = ({ activity, now, showAsap }) => {

    if (activity.parking && activity.parking.id) {
        if (activity.status === 'eta' && activity.communicatedArrivalDatetime && !activity.parking.isFree) {
            return <span data-test-info-time>{t('activity.booked')}{activity.communicatedArrivalDatetime.format(DATETIME_MINI_FORMAT)}</span>;
        }
    }

    if (activity.status === 'eta' && activity.communicatedArrivalDatetime) {
        return <span data-test-info-time>
            {t('activity.expected')}{activity.communicatedArrivalDatetime.format(DATETIME_MINI_FORMAT)}</span>;
    }

    if (['arrived', 'waiting', 'started'].includes(activity.status)) {
        return (
            <React.Fragment>
                {t('activity.arrived')}{' '}
                {activity.actualArrivalDatetime
                    ? moment(activity.actualArrivalDatetime).tz(activity.location.timezone).format(DATETIME_MINI_FORMAT)
                    : ''}
            </React.Fragment>
        );
    }

    if (
        (activity.status === 'eta' && activity.isAsap) ||
        (showAsap && activity.isAsap)
    ) {
        return t('activity.field.isAsap.label');
    }

    return (
        <React.Fragment>
            {activity.orderedArrivalDatetimeFrom ? activity.orderedArrivalDatetimeFrom.format(DATE_MINI_FORMAT) : ''}{' '}
            {activity.orderedArrivalDatetimeFrom ? formatTwoTimes(activity.orderedArrivalDatetimeFrom, activity.orderedArrivalDatetimeUntil) : ''}
        </React.Fragment>
    );
};

ActivityInfoTimes.propTypes = {
    activity: PropTypes.object.isRequired,
    now: PropTypes.object.isRequired,
    showAsap: PropTypes.bool,
};

ActivityInfoTimes.defaultProps = {
    showAsap: true,
};

export default ActivityInfoTimes;
