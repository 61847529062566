import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestionChoice } from './QuestionChoice';
import { FormAnswerChoiceOptionStore } from './AnswerChoiceOption';

export class FormAnswerChoice extends Model {
    static backendResourceName = 'form_answer_choice';

    @observable id = null;

    relations() {
        return {
            formQuestionChoice: FormQuestionChoice,
            options: FormAnswerChoiceOptionStore,
        };
    }
}

export class FormAnswerChoiceStore extends Store {
    Model = FormAnswerChoice;
    static backendResourceName = 'form_answer_choice';
}
