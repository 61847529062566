import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Location } from 'store/Location';
import { User } from './User';
import { showNotification } from 'helpers/notification';
import { ParkingStatusChangeStore } from './Parking/StatusChange';
import { storeWithHistory } from './History';

export const SOURCE_TRAVIS = 'travis';
export const SOURCE_USER = 'user';
export const SOURCES = [SOURCE_TRAVIS, SOURCE_USER];

export const PARKING_STATUS_NEW = 'new'
export const PARKING_STATUS_UPDATED = 'updated'
export const PARKING_STATUS_ACCEPTED = 'accepted'
export const PARKING_STATUS_REJECTED = 'rejected'
export const PARKING_STATUS_PROPOSED_CHANGES = 'proposed_changes'
export const PARKING_STATUS_PROPOSED_PARKING = 'proposed_parking'
export const PARKING_STATUSES = [PARKING_STATUS_NEW, PARKING_STATUS_UPDATED, PARKING_STATUS_PROPOSED_CHANGES, PARKING_STATUS_PROPOSED_PARKING, PARKING_STATUS_ACCEPTED, PARKING_STATUS_REJECTED]

export const PARKING_CERTIFICATION_TSR1 = 'TSR1'
export const PARKING_CERTIFICATION_TSR2 = 'TSR2'
export const PARKING_CERTIFICATION_FREEPARKING = 'Free parking'
export const PARKING_CERTIFICATION_OTHER = 'Other'
export const PARKING_CERTIFICATIONS = [PARKING_CERTIFICATION_TSR1, PARKING_CERTIFICATION_TSR2, PARKING_CERTIFICATION_FREEPARKING, PARKING_CERTIFICATION_OTHER]

export const EXTRA_SERVICE_TYPE_TRUCKWASH = 'truckwash';
export const EXTRA_SERVICE_TYPE_TANKCLEANING = 'tankcleaning';
export const EXTRA_SERVICE_TYPE_REPAIR = 'repair';
export const EXTRA_SERVICE_TYPE_CARWASH = 'carwash';
export const EXTRA_SERVICES_TYPES = [
    EXTRA_SERVICE_TYPE_TRUCKWASH,
    EXTRA_SERVICE_TYPE_TANKCLEANING,
    EXTRA_SERVICE_TYPE_REPAIR,
    EXTRA_SERVICE_TYPE_CARWASH
];

export const FACILITY_FENCE = 'Fence > 1.80m and fully surrounded';
export const FACILITY_SPREAD_LIGHTING = 'Light at night';
export const FACILITY_CAMERA_SECURITY = 'Camera security';
export const FACILITY_PERSONNEL_GUARDING = 'Security guard';
export const FACILITY_TOILET = 'Toilet (24/7 accessible)';
export const FACILITY_SHOWER = 'Shower (24/7 accessible)';
export const FACILITY_WIFI = 'Wifi';
export const FACILITY_HOTEL = 'Hotel';
export const FACILITY_RESTAURANT = 'Restaurant';
export const FACILITY_COOLING_MOTORS_ALLOWED = 'Acceptance of running refrigerated trailers';
export const FACILITY_ELECTRIC_COOLING_CHARGING = 'Electric charging available for refrigerated trailers';
export const FACILITY_ADR_POSSIBLE = 'ADR';
export const FACILITY_LONG_TIME_PARKING = 'Long time parking (more than 7 days)';
export const FACILITY_DECOUPLING_TRAILERS = 'Decopling of trailers (Trailer drop off)';

export const FACILITIES = [
    FACILITY_FENCE,
    FACILITY_SPREAD_LIGHTING,
    FACILITY_CAMERA_SECURITY,
    FACILITY_PERSONNEL_GUARDING,
    FACILITY_TOILET,
    FACILITY_SHOWER,
    FACILITY_WIFI,
    FACILITY_HOTEL,
    FACILITY_RESTAURANT,
    FACILITY_COOLING_MOTORS_ALLOWED,
    FACILITY_ELECTRIC_COOLING_CHARGING,
    FACILITY_ADR_POSSIBLE,
    FACILITY_LONG_TIME_PARKING,
    FACILITY_DECOUPLING_TRAILERS,
];

export const PARKING_DAY_OF_WEEK = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']


export class Parking extends Model {
    static backendResourceName = 'parking';

    static omitFields = [
        'userSuggestChangeCounter',
        'travisUpdateCounter'
    ]

    @observable id = null;
    @observable status = PARKING_STATUS_NEW;
    @observable travisId = null;
    @observable active = true;
    @observable name = '';
    @observable color = '';
    @observable certification = null;
    @observable customers = null;

    @observable operationTimezone = null;
    @observable detour = null; // In meters
    @observable dataSource = null;
    @observable extraServices = null;
    @observable facilities = null;
    @observable currency = null;
    @observable rebookable = true;

    @observable openinghoursMondayStart = '';
    @observable openinghoursMondayEnd = '';
    @observable openinghoursTuesdayStart = '';
    @observable openinghoursTuesdayEnd = '';
    @observable openinghoursWednesdayStart = '';
    @observable openinghoursWednesdayEnd = '';
    @observable openinghoursThursdayStart = '';
    @observable openinghoursThursdayEnd = '';
    @observable openinghoursFridayStart = '';
    @observable openinghoursFridayEnd = '';
    @observable openinghoursSaturdayStart = '';
    @observable openinghoursSaturdayEnd = '';
    @observable openinghoursSundayStart = '';
    @observable openinghoursSundayEnd = '';

    @observable pricePer1h = null; // in cents
    @observable pricePer9h = null;
    @observable pricePer12h = null;
    @observable pricePer24h = null;
    @observable pricePer48h = null;
    @observable pricePer72h = null;
    @observable pricePerWeekend = null;
    @observable isFree = true;

    @observable parkingDescription = '';
    @observable defaultDriverInstruction = '';
    @observable capacity = null;

    @observable createdAt = null;
    @observable updatedAt = null;
    @observable deleted = false;
    @observable lastSync = null;

    @observable userSuggestChangeCounter = null;
    @observable travisUpdateCounter = null;

    relations() {
        return {
            location: Location,
            statusChanges: ParkingStatusChangeStore,
            approvedBy: User,
            createdBy: User,
            updatedBy: User,
            rejectionBy: User,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            lastSync: Casts.datetime,
        };
    }

    isOpenFullWeek() {
        return PARKING_DAY_OF_WEEK.every(day => {
            const fieldStart = `openinghours${day}Start`;
            const fieldEnd = `openinghours${day}End`;
            return this[fieldStart] === '00:00' && this[fieldEnd] === '00:00';
          });
    }

    approve() {
        return this.api.post(this.url + 'approve/', { certification : this.certification, detour: this.detour });
    }

    reject(reason, explanation) {
        return this.api.post(this.url + 'reject/', { reason, explanation });
    }

    checkParkingAvailability(data) {
        return this.api.get(this.url + 'check_parking_availability/', { data: data });
    }

    book(data) {
        return this.api.post(this.url + 'book/', { data });
    }

    syncParking() {
        this.api.get(this.url + 'sync_parking/', {})
            .then(()=>this.fetch())
            .then(() => document.location.reload())
            .then(showNotification({
                type: 'info',
                dismissAfter: 4000,
                message: `Parking ID: ${this.id} successfully synchronized with Travis.` ,
            }))
            return
    }

    suggest(data) {
        return this.api.post(this.url + 'suggest/', { data });
    }

    saveSuggestion(data) {
        return this.api.post(this.url + 'save_suggestion/', { data });
    }

    acceptAllTravisUpdates() {
        return this.api.get(this.url + 'accept_all_travis_updates/',)
    }
}


export class ParkingStore extends Store {
    Model = Parking;
    static backendResourceName = 'parking';

    fetchParkingsCount() {
        return this.api.get('/parking/count_parkings/').then(res => {
            return res.data.count;
        });
    }
}

export class HistoryParkingStore extends storeWithHistory(ParkingStore){};
