export default `
{{customerId}} / {{trailerPlate}} {{status}} secure parking in {{currentLocation}}



{{customerId}} / {{trailerPlate}} {{status}} secure parking in {{currentLocation}}

Time IN: {{currentCommunicatedTime}}

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
