import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { OWN_CURRENCY, getMoneyForUser, getFuelSurchargeForUser, getCurrencySymbolFromCode } from 'helpers';

export class TariffFixedRate extends Model {
    static backendResourceName = 'tariff_fixed_rate';

    @observable id = null;
    @observable title = '';
    @observable price = 0;
    @observable period = 'P1D';
    @observable fuelSurchargeFactor = 0;

    calcFixedAmountForActivity(activity) {
        return this.price * activity.fixedRateDays.length;
    }

    calcSurchargeForActivity(activity) {
        return this.calcSurcharge(this.calcFixedAmountForActivity(activity));
    }

    calcSurcharge(amount) {
        return amount * this.fuelSurchargeFactor / 10000;
    }

    explainForActivity(activity) {
        const fixedAmount = this.calcFixedAmountForActivity(activity);
        const currencySymbol = getCurrencySymbolFromCode((activity && activity.allocation && activity.allocation.contract && activity.allocation.contract.fcCode) ? activity.allocation.contract.fcCode : OWN_CURRENCY)

        return (
`Price / day: ${currencySymbol}${getMoneyForUser(this.price)} * ${activity.fixedRateDays.length} day = ${currencySymbol}${getMoneyForUser(fixedAmount)}
${this.explainSurchargeForActivity(activity)}`
        );
    }

    explainSurchargeForActivity(activity) {
        const fixedAmount = this.calcFixedAmountForActivity(activity);
        const currencySymbol = getCurrencySymbolFromCode((activity && activity.allocation && activity.allocation.contract && activity.allocation.contract.fcCode) ? activity.allocation.contract.fcCode : OWN_CURRENCY)
        return `Fuel surcharge: ${currencySymbol}${getMoneyForUser(fixedAmount)} * ${getFuelSurchargeForUser(this.fuelSurchargeFactor)}% = ${currencySymbol}${getMoneyForUser(this.calcSurchargeForActivity(activity))}`;
    }
}

export class TariffFixedRateStore extends Store {
    Model = TariffFixedRate;
    static backendResourceName = 'tariff_fixed_rate';
}
