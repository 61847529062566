export default `
{{customerId}} / {{trailerPlate}} arrived at unload {{currentLocation}}



{{customerId}} / {{trailerPlate}} just arrived to unloading {{currentLocation}}
Info will follow when start to unload

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
