import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Activity } from './Activity';

export class OrderConfirmation extends Model {
    static backendResourceName = 'order_confirmation';

    @observable id = null;
    @observable title = '';
    @observable file = null;
    @observable deleted = false;
    @observable createdAt = null;
    @observable updatedAt = null;


    relations() {
        return {
            activity: Activity,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class OrderConfirmationStore extends Store {
    Model = OrderConfirmation;
    static backendResourceName = 'order_confirmation';
}
