import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export const PDF_TEMPLATES = ['nl'];

export class VatRule extends Model {
    static backendResourceName = 'vat_rule';

    @observable id = null;
    @observable name = '';
    @observable factor = null;
    @observable exactCode = '';
    @observable nameOutsideEu = '';
    @observable exactCodeOutsideEu = '';
}

export class VatRuleStore extends Store {
    Model = VatRule;
    static backendResourceName = 'vat_rule';
}
