import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Grid } from 'semantic-ui-react';
import { FormSelect } from 'spider/semantic-ui/Form';
import RemoteSelect from 'component/RemoteSelect';
import {
    BEPTicket, CONTEXT_TYPE_ACTIVITY, CONTEXT_TYPE_INVOICE,
    CONTEXT_TYPE_TRUCK, CONTEXT_TYPE_TRAILER,
} from 'store/BEPTicket';
import { ActivityStore } from 'store/Activity';
import { InvoiceStore } from 'store/Invoice';
import { TruckStore } from 'store/Truck';
import { TrailerStore } from 'store/Trailer';

/**
 * Render a "context type" and depending on the selected type, render
 * the related context type's dropdown.
 */
@observer
export default class Context extends Component {
    static propTypes = {
        bepTicket: PropTypes.instanceOf(BEPTicket).isRequired,
        activityStore: PropTypes.instanceOf(ActivityStore).isRequired,
        invoiceStore: PropTypes.instanceOf(InvoiceStore).isRequired,
        truckStore: PropTypes.instanceOf(TruckStore).isRequired,
        trailerStore: PropTypes.instanceOf(TrailerStore).isRequired,
        subject: PropTypes.string.isRequired,
    };

    clearUnusedContexts = () => {
        const { bepTicket } = this.props;

        [
            CONTEXT_TYPE_ACTIVITY, CONTEXT_TYPE_INVOICE,
            CONTEXT_TYPE_TRUCK, CONTEXT_TYPE_TRAILER,
        ].forEach(contextType => {
            if (contextType !== bepTicket['contextType']) {
                const type = capitalize(contextType);
                bepTicket[`context${type}`].clear();
                bepTicket.setInput(`context${type}`, bepTicket[`context${type}`]);
            }
        });
    }

    render() {
        const { bepTicket } = this.props;

        return (
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <FormSelect
                            label={t('bepTicket.field.context.label')}
                            model={bepTicket}
                            name="contextType"
                            placeholder={t('bepTicket.contextType.none')}
                            options={[
                                null,
                                CONTEXT_TYPE_ACTIVITY, CONTEXT_TYPE_INVOICE,
                                CONTEXT_TYPE_TRUCK, CONTEXT_TYPE_TRAILER,
                            ].map(k => (k ? {
                                text: t(`bepTicket.contextType.${k}`),
                                value: k,
                            } : {
                                text: t('bepTicket.contextType.none'),
                                value: k,
                            }))}
                            afterChange={this.clearUnusedContexts}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        {bepTicket['contextType'] === CONTEXT_TYPE_ACTIVITY &&
                         <RemoteSelect
                             model={bepTicket}
                             name="contextActivity"
                             label={t('bepTicket.field.contextActivity.label')}
                             remoteStore={this.props.activityStore}
                             renderOption={activity => ({
                                 text: activity.id,
                                 value: activity.id,
                             })}
                             renderCell={activity => activity.id}
                             afterChange={this.clearUnusedContexts}
                         />
                        }
                        {bepTicket['contextType'] === CONTEXT_TYPE_INVOICE &&
                         <RemoteSelect
                             model={bepTicket}
                             name="contextInvoice"
                             label={t('bepTicket.field.contextInvoice.label')}
                             remoteStore={this.props.invoiceStore}
                             renderOption={invoice => ({
                                 text: invoice.invoiceNumber,
                                 value: invoice.id,
                             })}
                             renderCell={invoice => invoice.invoiceNumber}
                             afterChange={this.clearUnusedContexts}
                         />
                        }
                        {bepTicket['contextType'] === CONTEXT_TYPE_TRUCK &&
                         <RemoteSelect
                             model={bepTicket}
                             name="contextTruck"
                             label={t('bepTicket.field.contextTruck.label')}
                             remoteStore={this.props.truckStore}
                             renderOption={truck => ({
                                 text: truck.licensePlate,
                                 value: truck.id,
                             })}
                             renderCell={truck => truck.licensePlate}
                             afterChange={this.clearUnusedContexts}
                         />
                        }
                        {bepTicket['contextType'] === CONTEXT_TYPE_TRAILER &&
                         <RemoteSelect
                             model={bepTicket}
                             name="contextTrailer"
                             label={t('bepTicket.field.contextTrailer.label')}
                             remoteStore={this.props.trailerStore}
                             renderOption={trailer => ({
                                 text: trailer.licensePlate,
                                 value: trailer.id,
                             })}
                             renderCell={trailer => trailer.licensePlate}
                             afterChange={this.clearUnusedContexts}
                         />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
