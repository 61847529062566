import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Incident } from '../Incident';
import { User } from '../User';

export class IncidentComment extends Model {
    static backendResourceName = 'incident_comment';

    @observable id = null;
    @observable content = '';
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            incident: Incident,
            createdBy: User,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class IncidentCommentStore extends Store {
    Model = IncidentComment;
    static backendResourceName = 'incident_comment';
}
