import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestion, TYPES } from './Question';
import { FormResponse } from './Response';
import { FormAnswerText } from './AnswerText';
import { FormAnswerInteger } from './AnswerInteger';
import { FormAnswerFile } from './AnswerFile';
import { FormAnswerDate } from './AnswerDate';
import { FormAnswerChoice } from './AnswerChoice';
import { omit } from 'lodash';

export class FormAnswer extends Model {
    static backendResourceName = 'form_answer';

    @observable id = null;

    relations() {
        return {
            formQuestion: FormQuestion,
            formResponse: FormResponse,
            text: FormAnswerText,
            file: FormAnswerFile,
            integer: FormAnswerInteger,
            date: FormAnswerDate,
            choice: FormAnswerChoice,
        };
    }

    toBackend(...args) {
        let data = super.toBackend(...args);

        // Remove relations which don't make sense for this type.
        data = omit(data, TYPES.filter(type => this.formQuestion.type !== type));

        return data;
    }
}

export class FormAnswerStore extends Store {
    Model = FormAnswer;
    static backendResourceName = 'form_answer';
}
