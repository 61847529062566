import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { CalculationRuleStore } from './CalculationRule';
import { PayrollCompany } from './PayrollCompany';

export const DESCRIPTION = 'description';
export const LEVEL = 'level';
export const OPTIONAL_FIELDS = [DESCRIPTION, LEVEL];

export class DietRule extends Model {
    static backendResourceName = 'diet_rule';

    @observable id = null;
    @observable name = '';
    @observable deleted = false;

    relations() {
        return {
            payrollCompany: PayrollCompany,
            calculationRules: CalculationRuleStore,
        };
    }
}

export class DietRuleStore extends Store {
    Model = DietRule;
    static backendResourceName = 'diet_rule';
}
