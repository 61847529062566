import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { TYPE_STANDARD } from '../Truck';
import { VerificationFormVersionStore } from './FormVersion';

export class VerificationForm extends Model {
    static backendResourceName = 'verification_form';

    @observable id = null;
    @observable truckType = TYPE_STANDARD;
    @observable description = '';

    relations() {
        return {
            versions: VerificationFormVersionStore,
        };
    }
}

export class VerificationFormStore extends Store {
    Model = VerificationForm;
    static backendResourceName = 'verification_form';
}
