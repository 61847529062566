import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Model, Store } from 'mobx-spine';
import { observer } from 'mobx-react';
import { snakeToCamel } from '../helpers';
import { RadioButtons } from 're-cy-cle';
import { t } from 'i18n';
import { Icon, Form, Input, Label, Checkbox, Select, TextArea, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { omit, pick, debounce } from 'lodash';
import moment from 'moment';
import { SingleDatePicker, TimeInput } from 're-cy-cle';
import styled from 'styled-components';
import MoneyInput from '../component/MoneyInput';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { LocalTime } from 'js-joda';
import { DroppableButton, IconButton } from './Button';
import RightDivider from '../component/RightDivider';
import AutosizeTextarea from 'react-textarea-autosize';

const ACTION_DELAY = 300;

// Stolen from re-cy-cle.
const ValuePropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
]);

// More compatible with semantic-ui Input heights.
const StyledRadioButtons = styled(RadioButtons)`
    label {
        padding: 8px 5px;
    }
`;

function isRelation(model, name) {
    return model[name] instanceof Model;
}

function isManyRelation(model, name) {
    return model[name] instanceof Store;
}

export class ErrorLabel extends Label {
    static defaultProps = {
        pointing: true,
        color: 'red',
    }
};

/**
 * If given, use props.value.
 * Otherwise, model[props.name]
 */
export function getValue(props) {
    if ('value' in props) {
        return props.value;
    } else if (isRelation(props.model, props.name)) {
        return props.model[props.name].id;
    } else if (isManyRelation(props.model, props.name)) {
        return props.model[props.name].map('id');
    } else {
        return props.model[props.name];
    }
}

const LabelLink = styled(Link)`
    color: rgba(0, 0, 0, 0.4) !important;
    text-decoration: none !important;
`;

export const FormLabel = styled.label`
    display: flex !important;
`;

const FormSubLabel = styled.span`
    font-weight: normal;
    opacity: 0.7;
    display: inline-block;
    margin-left: auto;
`;

/**
 * Get label based on backendResourceName and a specific i18n layout.
 *
 * For Target, supply a model. Otherwise, fall back using old behavior.
 */
export function getLabelFromProps(props, model = undefined) {
    let label;

    if (!model) {
        model = props.model;
    }

    if ('label' in props) {
        label = props.label;
    } else if (model === undefined) {
        return '';
    } else {
        // {copy-paste-auto-label}
        label = t(`${snakeToCamel(model.constructor.backendResourceName.replace(/\//g, '_'))}.field.${props.name}.label`);
    }


    if (props.viewTo !== undefined) {
        let viewTo = props.viewTo;
        if (typeof viewTo === "function") {
            viewTo = viewTo(model[props.name], props.model);
        }
        if (typeof viewTo === "string") {
            viewTo = (
                <LabelLink to={viewTo}>
                    <Icon name="eye" />
                </LabelLink>
            );
        }
        label = (
            <label>{label} {viewTo}</label>
        );
    }
    return label;
}

/**
 * Get label based on backendResourceName and a specific i18n layout.
 */
export function getErrorsFromProps(props) {
    const { model, name } = props;
    let errors = [];

    if (model && model.backendValidationErrors && model.backendValidationErrors[name]) {
        errors = model.backendValidationErrors[name];
    }

    return errors;
}

export class FormBase extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        name: PropTypes.string.isRequired,
        value: ValuePropType,
        onChange: PropTypes.func, // You can manually handle onChange.
        afterChange: PropTypes.func,
        required: PropTypes.bool,
        width: PropTypes.number,
        subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        getErrors: PropTypes.func,
        fromModel: PropTypes.func.isRequired,
        toModel: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        afterChange: () => {},
        toModel: value => value,
        fromModel: value => value,
    }

    getValue() {
        const val = getValue(this.props);

        if (this.props.fromModel) {
            return this.props.fromModel(val);
        }

        return val;
    }

    setInput(e, { value }) {
        const { model, name, afterChange } = this.props;

        model.setInput(name, value);
        afterChange(model);
    };

    getLabel(props) {
        return getLabelFromProps(props);
    }

    getSubLabel(props) {
        if ('subLabel' in props) {
            return props.subLabel;
        }
    }

    getErrors(props) {
        const errorsFromProps = getErrorsFromProps(props);

        if (this.props.getErrors) {
            return this.props.getErrors(errorsFromProps);
        }

        return errorsFromProps;
    }

    renderLabel() {
        const label = this.getLabel(this.props);
        const subLabel = this.getSubLabel(this.props);

        if (label) {
            return (
                <FormLabel>
                    {label}
                    {subLabel && (
                        <FormSubLabel>{subLabel}</FormSubLabel>
                    )}
                </FormLabel>
            );
        }

        return null;
    }

    renderErrors(errorProps = {}) {
        const errors = this.getErrors(this.props);

        if (errors.length > 0) {
            return (
                <ErrorLabel {...errorProps}>
                    {errors.map((error, i) => (
                        <div key={i}>{error}</div>
                    ))}
                </ErrorLabel>
            );
        }

        return null;
    }

    /**
     * Render actual form component in here. Props has already been filtered
     * out from for example, viewTo.
     *
     * You can also define a props = { contentProps: contentProps }, where
     * contentProps will be merged to props. This is usefull for example "label"
     *
     * <FormNumberInput
     *     contentProps={{ label: { basic: true, content: 'kg' } }}
     * />
     */
    renderContent(props) {}

    render() {
        const { required, width, inline, className, ...rest } = this.props;
        const errorProps = Object.keys(rest).includes('errorProps') ? rest.errorProps : {};
        const contentProps = Object.keys(rest).includes('contentProps') ? rest.contentProps : {};
        const errors = this.getErrors(this.props);
        const errorLabel = this.renderErrors(errorProps);

        return (
            <Form.Field
                required={required}
                error={errors.length > 0}
                width={width}
                inline={inline}
                className={className}
            >
                {this.renderLabel()}
                {errorProps.pointing === 'right' && errorLabel}
                {this.renderContent({
                    ...omit(rest, 'viewTo', 'afterChange', 'label', 'contentProps', 'errorProps'),
                    ...contentProps,
                })}
                {errorProps.pointing !== 'right' && errorLabel}
            </Form.Field>
        );
    }
}

/**
 * Typical usage:
 *
 * <FormTextInput
 *     model={activity}
 *     name="remarks"
 * />
 */
@observer
export class FormTextInput extends FormBase {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        name: PropTypes.string.isRequired,
        value: ValuePropType,
        onChange: PropTypes.func,
        afterChange: PropTypes.func,
    };

    renderContent(props) {
        const { model, name, onChange,  ...rest } = props;

        return (
            <Input
                {...rest}
                ref={ref => {
                    if (ref) {
                        this.refInput = ref.inputRef;
                    }
                }}
                name={name}
                value={getValue(this.props)}
                onChange={onChange || this.setInput.bind(this)}
            />
        );
    }
}

@observer
class FilePreview extends Component {
    static propTypes = {
        file: PropTypes.instanceOf(Object).isRequired,
    };

    static isPreviewable(file) {
        const type = file._blob ? file._blob.type : file.type;

        switch (type) {
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/png':
                return true;
            default:
                return false;
        }
    }

    static getIconName(file) {
        const type = file._blob ? file._blob.type : file.type;

        switch (type) {
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/png':
                return 'file image outline';
            case 'application/pdf':
                return 'file pdf outline';
            case 'text/plain':
                return 'file alternate outline';
            default:
                return '';
        }
    }

    renderFileImagePreview() {
        const { file, ...rest } = this.props;

        return <Image src={file.url} {...rest} />;
    }

    renderBlobImagePreview() {
        const { file, ...rest } = this.props;

        return <Image src={file._blob.preview} {...rest} />;
    }

    renderIcon(name) {
        return <Icon size="big" name={name} />;
    }

    render() {
        const { file } = this.props;

        if (!this.constructor.isPreviewable(file)) {
            return null;
        }

        if (file._blob) {
            switch (file._blob.type) {
                case 'image/jpg':
                case 'image/jpeg':
                case 'image/png':
                    return this.renderBlobImagePreview();
                default:
                    return null;
            }
        }

        switch (file.type) {
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/png':
                return this.renderFileImagePreview();
            default:
                return null;
        }
    }
}

class FormFileButtons extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        name: PropTypes.string.isRequired,
        onDelete: PropTypes.func,
        afterDrop: PropTypes.func,
        disabled: PropTypes.bool,
    };

    render() {
        const { model, name, onDelete, afterDrop, disabled } = this.props;

        return (
            <React.Fragment>
                {model[name].downloadUrl && (
                    <React.Fragment>
                        <IconButton
                            name="download"
                            href={model[name].downloadUrl}
                            title={model[name]._blob ? t('form.fileSaveBeforeDownload') : null}
                            disabled={model[name]._blob}
                            onClick={e => e.stopPropagation()}
                        />

                        <IconButton
                            name="trash"
                            onClick={e => {
                                e.stopPropagation();
                                model[name].markAsDeleted();
                                model.markChanged('rateAgreement');
                                onDelete && onDelete();
                            }}
                            disabled={disabled}
                        />
                    </React.Fragment>

                )}
                <DroppableButton
                    name={name}
                    onDrop={files => {
                        files.forEach((file) => {
                            model[name].setInput(file);
                        });
                        afterDrop && afterDrop();
                    }}
                    title={t('activity.field.requiredDocuments.upload')}
                    disabled={disabled}
                />
            </React.Fragment>
        );
    }
}

/**
 * Currently quick & dirty, since it needs FileField support.
 */
@observer
export class FormFile extends FormBase {
    static Buttons = FormFileButtons;
    static Preview = FilePreview;

    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        name: PropTypes.string.isRequired,
        value: ValuePropType,
        onChange: PropTypes.func,
        afterChange: PropTypes.func,
    };

    renderContent(props) {
        const { model, name } = props;

        return (
            <React.Fragment>
                <div style={{ display: 'flex' }}>
                    {model[name].icon}
                    <RightDivider />
                    <FormFileButtons {...props} />
                </div>
                <FilePreview file={model[name]} />
            </React.Fragment>
        );
    }
}




@observer
export class FormCheckbox extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.bool,
        onChange: PropTypes.func,
        afterChange: PropTypes.func,
        labelRight: PropTypes.bool.isRequired,
        fromModel: PropTypes.func,
        toModel: PropTypes.func,
    };

    setInput = (e, { checked }) => {
        const { model, name, afterChange } = this.props;

        if (this.props.toModel) {
            model.setInput(name, this.props.toModel(checked));
        } else {
            model.setInput(name, checked);
        }

        if (afterChange) {
            afterChange(model);
        }
    };

    getErrors() {
        const { model, name } = this.props;
        let errors = [];

        if (model && model.backendValidationErrors && model.backendValidationErrors[name]) {
            errors = model.backendValidationErrors[name];
        }

        return errors;
    }

    getValue() {
        const val = getValue(this.props);

        if (this.props.fromModel) {
            return this.props.fromModel(val);
        }

        return val;
    }

    render() {
        const { model, name, onChange, labelRight, ...rest } = this.props;
        const label = getLabelFromProps(this.props);
        if ('label' in rest) {
            delete rest['label']
        }

        const errors = this.getErrors();

        return (
            <Form.Field error={errors.length > 0}>
                {label && !labelRight && <label>{label}</label>}
                <Checkbox
                    {...rest}
                    ref={ref => {
                        if (ref) {
                            this.refInput = ref.inputRef;
                        }
                    }}
                    name={name}
                    checked={this.getValue()}
                    onChange={onChange || this.setInput}
                />
                {label && labelRight && (
                    <label style={{
                        display: 'inline',
                        position: 'relative',
                        left: '0.6em',
                        bottom: '0.4em',
                        fontWeight: 'normal',
                    }}>
                        {label}
                    </label>
                )}
                {this.renderErrors()}
            </Form.Field>
        );
    }

    renderErrors() {
        const errors = this.getErrors();

        return errors.map((error, i) => (
            <ErrorLabel key={i}>
                {error}
            </ErrorLabel>
        ));
    }
}

@observer
export class FormSelect extends FormBase {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        name: PropTypes.string.isRequired,
        value: ValuePropType,
        onChange: PropTypes.func,
        afterChange: PropTypes.func,
        store: PropTypes.instanceOf(Store),
    };

    getValue() {
        const val = super.getValue();
        const userAddedOption = this.props.options.find(o => o.value === null);

        // When allowAdditions is used, we treat null as special.
        if (
            val === null &&
            Object.keys(this.props).includes('allowAdditions') &&
            !!userAddedOption
        ) {
            return userAddedOption.text;
        }

        return val;
    }

    setInput = (e, { value }) => {
        const { model, name, afterChange, store } = this.props;

        if (isRelation(model, name)) {
            // Assume we want to set the id. This ugly toJS is needed because
            // mobx-spine assumes you set a model...
            let valueModel;
            if (store) {
                if (value === null) {
                    valueModel = new store.Model();
                } else {
                    valueModel = store.get(value);

                    // In the event that store does not contain the model, it might
                    // be a remote store where the already selected model is not
                    // fetched in the store. Really need a test for this. {model-not-in-remote-store}
                    if (!valueModel) {
                        valueModel = model[name];
                    }
                }
            } else {
                // Fallback, store was not required before. This will have the old behaviour
                valueModel = { toJS() {
                    return { id: parseInt(value) };
                } };
            }


            model.setInput(name, valueModel);
        } else if (isManyRelation(model, name)) {
            model.setInput(name, store.getByIds(value));
        } else {
            model.setInput(name, this.props.toModel(value));
        }

        if (afterChange) {
            afterChange(model);
        }
    };

    renderContent(props) {
        const { model, name, onChange, value, store, multiple, options, ...rest } = props;

        return (
            <Select
                {...rest}
                name={name}
                value={this.getValue()}
                onChange={onChange || this.setInput}
                multiple={'multiple' in this.props ? multiple : isManyRelation(model, name)}
                options={options.map(o => ({
                    // When allowAdditions is used, we treat null as special.
                    value: o.value === null && Object.keys(this.props).includes('allowAdditions') ? o.text : o.value,
                    text: o.text,
                }))}
            />
        );
    }
}

/**
 * Usage:
 *
 * <FormRemoteSelect
 *     store={this.trailerStore}
 *     model={activity}
 *     name="nextTrailer"
 *     options={this.trailerStore.map(trailer => ({
 *         value: trailer.id,
 *         text: trailer.licensePlate,
 *     }))}
 * />
 */
@observer
export class FormRemoteSelect extends FormSelect {
    static propTypes = {
        store: PropTypes.instanceOf(Store).isRequired,
        name: PropTypes.string.isRequired,
        afterChange: PropTypes.func,

        /**
         * When sending a request to the backend, the queryKey is used as key.
         * Defaults to "search":
         *
         * /api/recruiter?search=kasper
         *
         */
        queryKey: PropTypes.string.isRequired,

        /**
         * By default this component will fetch the remote store automatically.
         * This can be disabled, for example, if you show multiple dropdowns
         * using the same store.
         */
        initialFetch: PropTypes.bool.isRequired,
    };

    static defaultProps = Object.assign({
        queryKey: 'search',
        initialFetch: true,
    }, FormSelect.defaultProps);

    componentDidMount() {
        const { store, initialFetch } = this.props;

        if (store && initialFetch) {
            store.fetch();
        }
    }

    search = q => {
        const { store, queryKey } = this.props;

        if (store) {
            store.params[queryKey] = q;

            this.fetchDebounced();
        }
    }

    fetchDebounced = debounce(() => {
        const { store } = this.props;

        store.fetch();
    }, ACTION_DELAY);

    renderContent(props) {
        const { options, ...rest } = this.props;
        const seenValues = [];

        // Only show unique options. {model-not-in-remote-store}
        // Make test!
        const unqiueOptions = options.filter(o => {
            const seen = seenValues.includes(o.value);

            if (!seen) {
                seenValues.push(o.value);
            }

            return !seen;
        });

        return super.renderContent({
            ...rest,
            options: unqiueOptions,
            onSearchChange: (e, { searchQuery }) => this.search(searchQuery),

            // When using remote search, let the server decide based on
            // searchQuery what should be included or not. This disables the
            // FE search.
            // WARNING: the slice is important when you are using allowAdditions,
            // since it will add the not yet selected option to options. Options
            // will then keep growing:
            // a
            // ad
            // add
            // addM
            // aaddMe
            search: options => options.slice(),
        })
    }
}

@observer
export class FormRadio extends Component {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        checked: PropTypes.bool,
    };

    setInput = (e, { checked }) => {
        const { model, name } = this.props;

        model.setInput(name, checked);
    };

    render() {
        const { model, onChange, checked, ...props } = this.props;

        if ('label' in props) {
            delete props['label']
        }

        return <Form.Radio
            {...props}
            label={getLabelFromProps(this.props)}
            onChange={onChange || this.setInput}
            checked={checked || model[props.name]}
        />;
    }
}

@observer
export class FormTextArea extends FormBase {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,

        // Semantic UI dropped support for auto height, so we now use a package
        // to provide backwards compat.
        autoHeight: PropTypes.bool,
    };

    renderContent(props) {
        const { autoFocus, model, name, onChange, autoHeight, ...rest } = props;
        const autoHeightProps = autoHeight ? { as: AutosizeTextarea } : {};

        return (
            <TextArea
                {...autoHeightProps}
                {...rest}
                name={name}
                value={getValue(this.props) || ''} // When value = null, it keeps the old value?
                onChange={onChange || this.setInput.bind(this)}
                ref={ref => {
                    if (ref) {
                        this.refTextarea = ref;

                        if (autoFocus) {
                            ref.focus();
                        }
                    }
                }}
            />
        );
    }
}

export function getOptions() {
    return [
        { label: t('form.no'), value: false },
        { label: t('form.yes'), value: true },
    ];
}

@observer
export class FormRadioButtons extends FormBase {
    static propTypes = {
        model: PropTypes.instanceOf(Model).isRequired,
        name: PropTypes.string.isRequired,
        value: ValuePropType,
        onChange: PropTypes.func,
        afterChange: PropTypes.func,
    };

    setInput(name, value) {
        return super.setInput(null, { value });
    }

    /**
     * Compatibility for semantic-ui / re-cy-cle.
     */
    recycleOptions(options) {
        return options.map(option => (Object.assign({
            label: option.text,
        }, option)));
    }

    renderContent(props) {
        const { model, name, options, onChange,  ...rest } = props;

        return (
            <StyledRadioButtons
                {...rest}
                options={this.recycleOptions(options)}
                name={name}
                value={getValue(props)}
                onChange={onChange || this.setInput.bind(this)}
            />
        );
    }
}

@observer
export class FormRadioButtonsBoolean extends FormRadioButtons {
    static defaultProps = Object.assign({
        options: getOptions(),
    }, FormRadioButtons.defaultProps);
}

@observer
export class FormDatetimePicker extends FormBase {
    static propTypes = {
        ...FormBase.propTypes,
        defaultTime: PropTypes.instanceOf(moment),
    };

    getValue() {
        const val = getValue(this.props);

        if (this.props.fromModel) {
            return this.props.fromModel(val);
        }

        return val;
    }

    setInput(name, value) {
        const { model, afterChange } = this.props;
        const previousValue = model[name];

        if (this.props.toModel) {
            model.setInput(name, this.props.toModel(value));
        } else {
            model.setInput(name, value);
        }

        afterChange(model, previousValue);
    };

    setDateInput(name, value) {
        const { onChange, defaultTime } = this.props;

        const original = this.getValue(this.props) || defaultTime;
        if (original) {
            value.hour(original.hour());
            value.minute(original.minute());
            value.second(original.second());
        }

        return (onChange || this.setInput.bind(this))(name, value);
    }

    renderContent(props) {
        const { name, onChange, disabled } = props;

        return (
            <Form.Group widths="equal">
                <Form.Field>
                    <SingleDatePicker
                        name={name}
                        value={this.getValue(this.props)}
                        onChange={this.setDateInput.bind(this)}
                        autoComplete="off"
                        disabled={typeof disabled === 'function' ? disabled('date') : disabled}
                    />
                </Form.Field>
                <Form.Field>
                    <TimeInput
                        name={name}
                        value={this.getValue(this.props)}
                        onChange={onChange || this.setInput.bind(this)}
                        autoComplete="off"
                        disabled={typeof disabled === 'function' ? disabled('time') : disabled}
                    />
                </Form.Field>
            </Form.Group>
        );
    }
}

@observer
export class FormDatepicker extends FormBase {
    setInput(name, value) {
        const { model, afterChange } = this.props;

        model.setInput(name, value);
        afterChange(model);
    };

    render() {
        const { name, onChange, ...rest } = this.props;
        const errors = this.getErrors(this.props);

        return (
            <Form.Field error={errors.length > 0}>
                {this.renderLabel()}
                <SingleDatePicker
                    {...rest}
                    name={name}
                    value={getValue(this.props)}
                    onChange={onChange || this.setInput.bind(this)}
                    autoComplete="off"
                />
                {this.renderErrors()}
            </Form.Field>
        );
    }
}

@observer
export class FormMoneyInput extends FormBase {
    renderContent(props) {
        const { model, name, onChange, ...rest } = this.props;

        return (
            <MoneyInput
                {...rest}
                model={model}
                name={name}
                // value={getValue(this.props)}
                // onChange={onChange} // MoneyInput has it's own setInput.
            />
        );
    }
}

/**
 * Currently only supports HH:MM time format.
 */
@observer
export class FormTimeInput extends FormBase {
    setInput(name, value) {
        super.setInput({}, { value: LocalTime.parse(value.format('HH:mm')) });
    }

    getValue() {
        let val = super.getValue();

        if (val instanceof LocalTime) {
            // Small hack, since TimeInput assumes a moment.
            return moment(`${val.hour()}:${val.minute()}`, 'HH:mm');
        }

        return val;
    }

    renderContent(props) {
        const { model, name, onChange, ...rest } = props;

        return (
            <TimeInput
                name={name}
                value={this.getValue()}
                onChange={onChange || this.setInput.bind(this)}
                autoComplete="off"
                {...rest}
            />
        );
    }
}

@observer
export class FormNumberInput extends FormBase {
    static defaultProps = Object.assign({
        prefix: '',
        includeThousandsSeparator: false,
    }, FormBase.defaultProps);

    setInput(name, value) {
        const { model, afterChange } = this.props;

        if (this.props.toModel) {
            model.setInput(name, this.props.toModel(value));
        } else {
            model.setInput(name, value);
        }

        afterChange(model);
    };

    // TODO: This should return an int. But what to do when input is empty?
    // Assume null?
    parseValue = e => {
        let value = e.target.value;
        const { prefix, suffix, thousandsSeparatorSymbol } = this.props;

        if (prefix) {
            value = value.replace(prefix, '');
        }

        if (suffix) {
            value = value.replace(suffix, '');
        }

        if (thousandsSeparatorSymbol) {
            // The thousands separator symbol is a visual thingy so should be removed. It can occur multipe times.
            value = value.split(thousandsSeparatorSymbol).join('');
        }

        // TODO: As `value` prop both a string and a number are allowed. However, after a change the value is always a string.
        // Perhaps we can detect that and cast to number?
        return value;
    };

    onChange = (e) => {
        const { name, onChange } = this.props;
        const value = this.parseValue(e);

        if (onChange) {
            onChange(name, value);
        } else {
            this.setInput(name, value);
        }
    }

    getValue() {
        let val = getValue(this.props);

        if (this.props.fromModel) {
            val = this.props.fromModel(val);
        }

        // Convert to string, since that is wat the input expects.
        return val + '';
    }

    createMask(props) {
        const { createMask } = this.props;

        if (createMask) {
            return createMask(props);
        }

        const PROPS_MASK = ['prefix', 'suffix', 'includeThousandsSeparator', 'thousandsSeparatorSymbol', 'allowDecimal', 'allowNegative', 'decimalSymbol', 'decimalLimit'];

        return {
            mask: createNumberMask(pick(props, PROPS_MASK)),
            props: omit(props, ...PROPS_MASK),
        };
    }

    renderContent(givenProps) {
        const {
            props: { className, ...props },
            mask,
        } = this.createMask(givenProps);

        return (
            <MaskedInput
                className={className ? `ui input ${className}` : "ui input"}
                {...props}
                mask={mask}
                value={this.getValue()}
                onChange={this.onChange}
            />
        );
    }
}
