import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable, toJS, action } from 'mobx';
import { observer } from 'mobx-react';
import { FuelCostStore } from 'store/FuelCost';
import { CostImportWarningStore } from 'store/CostImportWarning';
import { DriverStore } from 'store/Driver';
import { TruckStore } from 'store/Truck';
import AdminOverview, { ToolbarButton, PaginationControls } from 'component/AdminOverview';
import { DroppableButton, AddButton, SaveButton } from 'spider/semantic-ui/Button';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import { Table } from 'semantic-ui-react';
import { showSaveNotification } from 'helpers/notification';
import { ButtonWrapper } from 'spider/component/RightDivider';
import { isEqual } from 'lodash';
import { formatMoney } from 'helpers';
import { EditModal as FuelEditModal } from 'container/FuelCost/Edit';
import { EditModal as RoadEditModal } from 'container/RoadCost/Edit';
import { IconButton } from 'spider/semantic-ui/Button';
import { Toolbar } from 're-cy-cle';
import { Link } from 'react-router-dom';
import RightDivider from 'spider/component/RightDivider';
import ModalChangeProductFor from './ModalChangeProductFor';
import { getViewStore } from 'helpers/viewStore';

export class CustomDroppableButton extends DroppableButton {
    renderContent({ title, ...rest }) {
        const extraProps = {};

        if (this.droppable) {
            extraProps.positive = true;
        } else {
            extraProps.primary = true;
        }

        return (
            <ToolbarButton {...rest} {...extraProps}
                content={title}
                type="button"
                icon="upload"
            />
        );
    }
}

@observer
export class WarningTable extends Component {
    static propTypes = {
        costImportWarningStore: PropTypes.instanceOf(CostImportWarningStore).isRequired,
        costInvoiceId: PropTypes.isRequired,
        afterSave: PropTypes.func.isRequired,
    };

    @observable busyDeleting = false;

    async deleteAllWarnings() {
        const { costImportWarningStore, costInvoiceId } = this.props;
        this.busyDeleting = true;

        costImportWarningStore.setPage();
        await costImportWarningStore.deleteAllCostInvoiceWarnings(costInvoiceId);
        await costImportWarningStore.fetch();

        this.busyDeleting = false;
        showSaveNotification();
    }

    render() {
        const { costImportWarningStore } = this.props;


        return (
            <div data-test-import-warnings>
                <ButtonWrapper>
                    <Button fluid data-test-delete-all-button
                        loading={this.busyDeleting}
                        content={t('costImportWarning.deleteAllWarningButton')}
                        onClick={this.deleteAllWarnings.bind(this)}
                        type="button"
                        color="red"
                    />
                    <PaginationControls store={costImportWarningStore} />
                </ButtonWrapper>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell>{t('costImportWarning.field.lineNumber.label')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('costImportWarning.field.field.label')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('costImportWarning.field.message.label')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body data-test-csv-warrnings>
                        {costImportWarningStore.map(importWarning => (
                            <Table.Row key={importWarning.id}>
                                <Table.Cell>
                                    <ButtonWrapper>
                                        {importWarning.roadCost.id ?
                                            <RoadEditModal
                                                trigger={props => <IconButton data-test-edit-button name="edit" {...props}/>}
                                                roadCost={importWarning.roadCost}
                                            />:
                                            <FuelEditModal
                                                trigger={props => <IconButton data-test-edit-button name="edit" {...props}/>}
                                                fuelCost={importWarning.fuelCost}
                                            />
                                        }
                                    </ButtonWrapper>
                                </Table.Cell>
                                <Table.Cell>{importWarning.lineNumber}</Table.Cell>
                                <Table.Cell>{importWarning.field}</Table.Cell>
                                <Table.Cell>{importWarning.message}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

@observer
export default class FuelCostOverview extends AdminOverview {
    store = new FuelCostStore({ relations: ['costSupplier', 'costProduct', 'costInvoice', 'driver', 'truck', 'location'] });
    params = {}

    @observable selectAllChecked = false;
    @observable driverStore = new DriverStore({ params: { order_by: 'name' } });
    @observable truckStore = new TruckStore({ params: { order_by: 'license_plate' } });
    @observable warningStore = new CostImportWarningStore();
    @observable showBepSidebar = localStorage.getItem('dcm-hide-bep-sidebar') !== 'true';
    @observable selectedFuelCostStore = new FuelCostStore();
    @observable showModalProductFor = false;

    settings = [
        ()=>({ attr: fc => <Checkbox data-test-check-fuel-cost onChange={(e, { checked })=>this.toggleFuelCost(fc, checked)}  checked={!!this.selectedFuelCostStore.get(fc.id)} />,
        label: (
                <Checkbox checked={this.selectAllChecked} onChange={action((e, { checked }) => {
                if (checked) {
                    this.store.map(fc => this.toggleFuelCost(fc, true));
                } else {
                    this.selectedFuelCostStore.clear();
                }
                this.selectAllChecked = checked;
            })} />
        ),
        cellProps: { collapsing: true }
        }),
        { attr: 'id' },
        { attr: 'transactionId' },
        { attr: 'costSupplier.name' },
        { attr: 'transactionDatetime' },
        { attr: 'driver.name' },
        { attr: 'truck.licensePlate' },
        { attr: 'productFor' },
        { attr: 'costProduct.description' },
        { attr: 'location.country', label: t('fuelCost.field.originalCountryCode.label') },
        { attr: fuelCost => formatMoney(fuelCost.netOc), label: t('fuelCost.field.netOc.label') },
        ''
    ];

    buttons = [
        { type: 'edit', to: fuelCost => `/administration/fuel-cost/${fuelCost.id}/edit` },
        { type: fuelCost => (!fuelCost.costInvoice.id || !fuelCost.costInvoice.finalizedAt) && 'delete' },
        { type: fuelCost => fuelCost.costInvoice.id && 'view', to: fuelCost => `/administration/cost-invoice/${fuelCost.costInvoice.id}/edit` },
    ];

    toolbar = [
        { type: 'add', to: '/administration/fuel-cost/add' },
    ];

    filters = [
        {
            type: 'search',
            name: '.id',
            label: t('fuelCost.field.id.label'),
        },
        {
            type: 'select',
            name: '.truck',
            label: t('fuelCost.field.originalTruckLicensePlate.label'),
            remote: true,
            store: this.truckStore,
            toOption: truck => ({
                text: truck.licensePlate,
                value: truck.id,
            }),
        },
        {
            type: 'select',
            name: '.driver',
            remote: true,
            store: this.driverStore,
            label: t('fuelCost.field.driver.label'),
            toOption: driver => ({
                value: driver.id,
                text: driver.name,
            }),
        },
        {
            type: 'radioButtons',
            name: '.finalized_at:not:isnull',
            label: t('costInvoice.field.finalized.label'),
            options: [
                { text: t('costInvoice.field.showAll.label'), value: undefined },
                { text: t('form.no'), value: 'false' },
                { text: t('form.yes'), value: 'true' },
            ],

        },
        {
            type: 'radioButtons',
            name: '.rejected_at:not:isnull',
            label: t('costInvoice.field.rejected.label'),
            options: [
                { text: t('costInvoice.field.showAll.label'), value: undefined },
                { text: t('form.no'), value: 'false' },
                { text: t('form.yes'), value: 'true' },
            ],

        },
        {
            type: 'dateRange',
            name: '.transaction_datetime:range',
            label: t('fuelCost.field.transactionDatetime.filter'),
            afterChange: isValidDateRange => {
                if (isValidDateRange) {
                    this.debouncedFetch();
                }
            },
        },
        {
            type: 'custom',
            label: t('form.clearButton'),
            callback: () => (
                <Button fluid primary onClick={this.handleClear}>
                    {t('form.clearButton')}
                </Button>
            )
        },
    ];

    saveAndFinalize = () => {
        this.selectedFuelCostStore.forEach(fc=>fc.finalize())
    }

    toggleFuelCost = (fc, selected) => {
        if (selected) {
            this.selectedFuelCostStore.models.push(fc);
        } else {
            this.selectedFuelCostStore.removeById(fc.id);
        }
    }

    renderToolbar() {
        const { store, toolbar } = this;

        if (!toolbar) {
            return null;
        }

        return (
            <Toolbar>
                <PaginationControls store={store} />
                {' '}
                <AddButton compact primary
                    type=""
                    as={Link}
                    to={`/administration/fuel-cost/add/${this.generateSearchParams()}`}
                />
                 <SaveButton primary compact data-test-save-and-finalize-button
                    content={t('costInvoice.edit.saveAndFinalizeButton')}
                    onClick={this.saveAndFinalize}
                />
                <RightDivider />
                    <Modal
                        open={this.showModalProductFor}
                        onClose={() => this.showModalProductFor = false}
                        trigger={(
                            <Button compact data-test-bulk-action-button primary onClick={() => this.showModalProductFor = true}>
                                {t('fuelCost.field.modalButton.label')}
                            </Button>
                        )}
                    >
                    <ModalChangeProductFor
                        viewStore={getViewStore()}
                        close={() => this.showModalProductFor = false}
                    />
                </Modal>
            </Toolbar>
        );
    }

    handleClear = () => {
        this.store.params = Object.assign({}, this.store.originalParams);
        this.debouncedFetch();
    };

    fetchOnMount = () => {
        return !isEqual(this.params, toJS(this.store.params));
    };

    componentDidMount() {
        super.componentDidMount();

        this.warningStore.fetch();
        this.driverStore.fetch();
        this.truckStore.fetch();
    }

    renderWarningSidebar() {
        return (
            <WarningTable
                costImportWarningStore={this.warningStore}
            />
        );
    }
}
