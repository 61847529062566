import { observable, computed } from 'mobx';
import { Casts, Model, Store } from './Base';
import { distributeAmount } from './InvoiceLine';
import { WaiverItemStore } from './WaiverItem';
import { User } from './User';
import { Tariff } from './Tariff';
import { Dispute } from './Dispute';

const SYSTEM_REASON = 'Auto-created waiver for dispute within contract tolerance';

export class Waiver extends Model {
    static backendResourceName = 'waiver';

    @observable id = null;
    @observable waivedInvoicedKm = 0;
    @observable waivedWaitingAmount = 0;
    @observable waivedWeekendAmount = 0;
    @observable waivedOtherCostsAmount = 0;
    @observable waivedKmAmount = 0;
    @observable waivedKmSurcharge = 0;
    @observable waivedFixedAmount = 0;
    @observable waivedFixedSurcharge = 0;
    @observable waivedSecondDriverAmount = 0;
    @observable waivedCustomAmount = 0;
    @observable waivedTollAmount = 0;
    @observable message = '';
    @observable remarks = '';
    @observable createdAt = null;
    @observable updatedAt = null;

    @observable waivedWaitingAmountFc = 0;
    @observable waivedWeekendAmountFc = 0;
    @observable waivedOtherCostsAmountFc = 0;
    @observable waivedKmAmountFc = 0;
    @observable waivedKmSurchargeFc = 0;
    @observable waivedFixedAmountFc = 0;
    @observable waivedFixedSurchargeFc = 0;
    @observable waivedSecondDriverAmountFc = 0;
    @observable waivedCustomAmountFc = 0;
    @observable waivedTollAmountFc = 0;

    // {combined-surcharge}
    // kmSurcharge and fixedSurcharged are inputted using 1 input. So the user
    // inputs into _surcharge and the system splits it out in kmSurcharge and
    // fixedSurcharge.
    @observable _surcharge = 0;
    parse(data) {
        const result = super.parse(data);

        this.updateCachedSurcharge();

        return result;
    }

    @computed get hasWaivers() {
        return (!!this.waivedAmount || !!this.waivedInvoicedKm) || (!!this.waivedAmountFc || !!this.waivedInvoicedKm)
    }

    @computed get isAutoCreated() {
        return this.message === SYSTEM_REASON;
    }

    updateCachedSurcharge() {
        this._surcharge = this.waivedKmSurcharge + this.waivedFixedSurcharge;
    }

    distributeSurcharge(invoiceLine) {
        const expectedKmSurcharge = invoiceLine.expectedKmSurcharge;
        const expectedFixedSurcharge = invoiceLine.expectedFixedSurcharge;
        const isFc = invoiceLine.invoice.tariff.contract.isFc;

        const amount = this._surcharge;

        let kmSurcharge = 0;

        if (expectedKmSurcharge + expectedFixedSurcharge !== 0) {
            kmSurcharge = Math.round(amount * expectedKmSurcharge / (expectedKmSurcharge + expectedFixedSurcharge));
        }

        let fixedSurcharge = 0;

        if (expectedKmSurcharge + expectedFixedSurcharge !== 0) {
            fixedSurcharge = Math.round(amount * expectedFixedSurcharge / (expectedKmSurcharge + expectedFixedSurcharge));
        }

        const rounding = amount - kmSurcharge - fixedSurcharge;

        if (isFc) {
                this.setInput('waivedKmSurchargeFc', kmSurcharge + rounding);
                this.setInput('waivedFixedSurchargeFc', fixedSurcharge);
        } else {
                this.setInput('waivedKmSurcharge', kmSurcharge + rounding);
                this.setInput('waivedFixedSurcharge', fixedSurcharge);
        }
    }

    recalculateItems(invoiceLine) {
        const tariff = new Tariff();
        const isFc = invoiceLine.invoice.tariff.contract.isFc;
        const fieldMap = {
            // attr name on invoiceLineItem: settings
            waivedInvoicedKm: {
                totalExpected: () => invoiceLine.expectedInvoicedKm,
                totalInvoiced: () => this.waivedInvoicedKm,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedKm,
            },
            waivedKmAmount: {
                totalExpected: () => invoiceLine.expectedKmAmount,
                totalInvoiced: () => isFc ? this.waivedKmAmountFc : this.waivedKmAmount,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedKmAmount,
            },
            // Other costs cannot create a dispute, since you cannot change the
            // custom amount when invoicing.
            // waivedOtherCostsAmount: {
            //     totalExpected: () => invoiceLine.expectedOtherCostsAmount,
            //     totalInvoiced: () => this.waivedOtherCostsAmount,
            //     activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedOtherCostsAmount,
            // },
            waivedFixedAmount: {
                totalExpected: () => invoiceLine.expectedFixedAmount,
                totalInvoiced: () => isFc ? this.waivedFixedAmountFc : this.waivedFixedAmount,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedFixedAmount,
            },
            waivedWaitingAmount: {
                totalExpected: () => invoiceLine.expectedWaitingAmount,
                totalInvoiced: () => isFc ? this.waivedWaitingAmountFc : this.waivedWaitingAmount,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedWaitingAmount,
            },
            waivedSecondDriverAmount: {
                totalExpected: () => invoiceLine.expectedSecondDriverAmount,
                totalInvoiced: () => isFc ? this.waivedSecondDriverAmountFc : this.waivedSecondDriverAmount,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedSecondDriverAmount,
            },
            waivedKmSurcharge: {
                totalExpected: () => invoiceLine.expectedKmSurcharge,
                totalInvoiced: () => isFc ? this.waivedKmSurchargeFc : this.waivedKmSurcharge,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedKmSurcharge,
            },
            waivedFixedSurcharge: {
                totalExpected: () => invoiceLine.expectedFixedSurcharge,
                totalInvoiced: () => isFc ? this.waivedFixedSurchargeFc : this.waivedFixedSurcharge,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedFixedSurcharge,
            },
            waivedTollAmount: {
                totalExpected: () => invoiceLine.expectedTollAmount,
                totalInvoiced: () => isFc ? this.waivedTollAmountFc : this.waivedTollAmount,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedTollAmount,
            },
            // Custom amounts cannot create disputes, no need to distribute.
            // waivedCustomAmount: {
            //     totalExpected: () => invoiceLine.expectedCus,
            //     totalInvoiced: () => this.customAmount,
            //     activityExpected: item => tariff.getInvoiceKmFromActivity(item.activity),
            // },
        };

        Object.keys(fieldMap).forEach(field => {
            const settings = fieldMap[field];
            const totalExpected = settings.totalExpected(this.items);
            const totalInvoiced = settings.totalInvoiced();

            distributeAmount(tariff, this.waiverItems, field, totalExpected, totalInvoiced, settings.activityExpected);
        });
    }

    relations() {
        return {
            dispute: Dispute,
            createdBy: User,
            waiverItems: WaiverItemStore,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    @computed
    get waivedTotalAmount() {
        return this.waivedWaitingAmount +
            this.waivedWeekendAmount +
            this.waivedOtherCostsAmount +
            this.waivedKmAmount +
            this.waivedKmSurcharge +
            this.waivedFixedAmount +
            this.waivedFixedSurcharge +
            this.waivedSecondDriverAmount +
            this.waivedCustomAmount +
            this.waivedTollAmount;
    }
    @computed
    get waivedTotalAmountFc() {
        return this.waivedWaitingAmountFc +
            this.waivedWeekendAmountFc +
            this.waivedOtherCostsAmountFc +
            this.waivedKmAmountFc +
            this.waivedKmSurchargeFc +
            this.waivedFixedAmountFc +
            this.waivedFixedSurchargeFc +
            this.waivedSecondDriverAmountFc +
            this.waivedCustomAmountFc +
            this.waivedTollAmountFc;
    }


    @computed get waivedAmount() {
        return (this.waivedWaitingAmount +
            this.waivedWeekendAmount +
            this.waivedOtherCostsAmount +
            this.waivedKmAmount +
            this.waivedKmSurcharge +
            this.waivedFixedAmount +
            this.waivedFixedSurcharge +
            this.waivedSecondDriverAmount +
            this.waivedCustomAmount +
            this.waivedTollAmount)
        }

    @computed get waivedAmountFc() {
        return (this.waivedWaitingAmountFc +
            this.waivedWeekendAmountFc +
            this.waivedOtherCostsAmountFc +
            this.waivedKmAmountFc +
            this.waivedKmSurchargeFc +
            this.waivedFixedAmountFc +
            this.waivedFixedSurchargeFc +
            this.waivedSecondDriverAmountFc +
            this.waivedCustomAmountFc +
            this.waivedTollAmountFc)
        }

    /**
     * Sum up amounts of items. Useful for checking if sum of items is the same
     * as sum of waiver.
     */
    @computed get waivedAmountFromItems() {
        return this.waiverItems.models.reduce((res, waiverItem) => res +
            waiverItem.waivedWeekendAmount +
            waiverItem.waivedOtherCostsAmount +
            waiverItem.waivedKmAmount +
            waiverItem.waivedKmSurcharge +
            waiverItem.waivedFixedAmount +
            waiverItem.waivedFixedSurcharge +
            waiverItem.waivedSecondDriverAmount +
            waiverItem.waivedCustomAmount +
            waiverItem.waivedTollAmount
        , 0);
    }
}

export class WaiverStore extends Store {
    Model = Waiver;
    static backendResourceName = 'waiver';
}
