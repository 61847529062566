import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Modal } from 'semantic-ui-react';
import { CancelButton, ApplyButton } from 'spider/semantic-ui/Button';
import RightDivider from 'spider/component/RightDivider';

@observer
export default class ConfirmModal extends Component {
    static propTypes = {
        trigger: PropTypes.func.isRequired,
        header: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        onCancel: PropTypes.func.isRequired,
        onApply: PropTypes.func.isRequired,

        // Weird names to avoid collision with boolean `open`.`
        toOpen: PropTypes.func,
        toClose: PropTypes.func,
        hideCancelButton: PropTypes.bool.isRequired,
        renderApplyButton: PropTypes.func.isRequired,
    };

    static defaultProps = {
        hideCancelButton: false,
        renderApplyButton: props => <ApplyButton  primary {...props} />,
    };

    @observable isApplying = false;
    @observable isOpen = false;
    open = () => {
        const { toOpen } = this.props;

        if (toOpen) {
            toOpen();
        } else {
            this.isOpen = true;
        }
    }

    cancel = () => {
        const { onCancel } = this.props;

        if (onCancel) {
            onCancel();
        }

        this.close();
    }

    close = () => {
        const { toClose } = this.props;

        if (toClose) {
            toClose();
        } else {
            this.isOpen = false;
        }
    }

    apply = () => {
        const { onApply } = this.props;

        this.isApplying = true;

        Promise.resolve(onApply())
            // Close on success.
            .then(this.close)

            // Always set isApplying to false.
            .catch(() => {}).then(() => this.isApplying = false);
    }

    renderApplyButton = () => {
        const { onApply, renderApplyButton } = this.props;

        if (!onApply) {
            return null;
        }

        return renderApplyButton({
            'data-test-apply-button': true,
            loading: this.isApplying,
            onClick: this.apply,
        })
    }

    render() {
        const { trigger, header, content, hideCancelButton, ...rest } = this.props;

        if (trigger) {
            rest.trigger = trigger({ onClick: this.open });
        }

        return (
            <Modal
                size="tiny"
                open={this.isOpen}
                centered={false}
                {...rest}
                data-test-confirm-modal
            >
                <Modal.Header>{header}</Modal.Header>
                <Modal.Content>
                    <p>{content}</p>
                </Modal.Content>
                <Modal.Actions>
                    {!hideCancelButton && (
                        <CancelButton data-test-cancel-button
                            loading={this.isApplying}
                            onClick={this.cancel}
                        />
                    )}
                    <RightDivider />
                    {this.renderApplyButton()}
                </Modal.Actions>
            </Modal>
        );
    }
}
