import { observable } from 'mobx';
import { Model, Store } from './Base';
import { Driver } from './Driver';

export class LMSUser extends Model {
    static backendResourceName = 'lmsuser'; // Would like this to be lms_user but yeah...

    @observable id = null;
    @observable firstName = '';
    @observable lastName = '';
    @observable email = '';
    @observable userType = '';
    @observable status = '';

    relations() {
        return {
            driver: Driver,
        };
    }
}

export class LMSUserStore extends Store {
    Model = LMSUser;
    static backendResourceName = 'lmsuser'; // Would like this to be lms_user but yeah...
}
