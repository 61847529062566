import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { storeWithHistory } from './History';

export class SimpledTransaction extends Model {
    static backendResourceName = 'simpled_transaction';

    @observable id = null;
    @observable publicToken = null;
    @observable publicTokenString = null;
    @observable billingAmount = 0;
    @observable txnType = '';
    @observable description = '';
    @observable originalTxnAmount = null;

    casts() {
        return {

        };
    }

    relations() {
        return {

        };
    }
}

export class SimpledTransactionStore extends Store {
    Model = SimpledTransaction;
    static backendResourceName = 'simpled_transaction';
}

export class HistorySimpledTransactionStore extends storeWithHistory(SimpledTransactionStore){};
