import { observable, autorun } from 'mobx';
import { get } from 'lodash';
import { Model, Store } from 'mobx-spine';

/**
 * The fetchQueue holds all upcoming fetch promises, and goes through them 1 by
 * 1, ordered by priority. The priorities are:
 *
 * 1. Highest (urgent)
 * 2. High
 * 3. Normal (default)
 * 4. Low
 * 5. Lowest
 *
 * Background processes can be 4 or 5, and user actions (for example opening
 * details and fetching more data) can be 1 or 2.
 *
 * TODO:
 * - Take into account cancelTokenRequestOptions (filter, unfilter).
 */
const MAX_IN_FLIGHT = 7;
const PRIORITY_URGENT = 1;
// const PRIORITY_HIGH = 2;
const PRIORITY_NORMAL = 3;
const PRIORITY_LOW = 4;
const PRIORITY_BACKGROUND = 5;

class Request extends Model {
    @observable priority = PRIORITY_NORMAL;
    @observable callback = null;
}

class RequestStore extends Store {
    Model = Request
    comparator = 'priority';

    @observable inFlight = 0;
}

const requestStore = new RequestStore();
function reduceInFlightCount() {
    requestStore.inFlight--;
}

autorun(() => {
    if (requestStore.length > 0 && requestStore.inFlight < MAX_IN_FLIGHT) {
        const p = requestStore.at(0)
        requestStore.remove(p)
        requestStore.inFlight++

        p.callback().finally(() => {
            // Allow skipping setTimeout, which makes Cypress tests which also
            // control time way easier to use.
            if (window.REQUEST_QUEUE_MIDDLEWARE_SKIP_SET_TIMEOUT) {
                reduceInFlightCount();
                return;
            }

            // The setTimeout of 0 makes sure the the requestStore doesn't hog
            // the event loop. If the setTimeout is removed, then the dispatcher
            // view becomes noticabley slower. Allocations in the viewport are
            // not rendered fully, but instead are rendered as the light version
            // (see PlanningCustomer/OverviewItem.js renderFull vs renderLite
            // and hoc/viewport::_renderViewport).
            setTimeout(reduceInFlightCount, 0);
        });
    }
})

export function addRequestQueueMiddleware(SomeClass, fetchFunction = 'fetch') {
    return class extends SomeClass {
        fetch(...args) {
            return new Promise((resolve, reject) => {
                let priority = PRIORITY_NORMAL;

                if (args.length === 1) {
                    priority = get(args[0], 'priority', PRIORITY_NORMAL);
                }

                requestStore.add({
                    priority,
                    callback: () =>
                        super.fetch(...args)
                            .then(resolve)
                            .catch(reject)
                })
            });
        }

        fetchUrgent(...args) {
            if (args.length === 1 && typeof args[0] === 'object') {
                args[0].priority = PRIORITY_URGENT
            }

            return this.fetch(...args)
        }

        fetchLow(...args) {
            if (args.length === 1 && typeof args[0] === 'object') {
                args[0].priority = PRIORITY_LOW
            }

            return this.fetch(...args)
        }

        fetchBackground(...args) {
            if (args.length === 1 && typeof args[0] === 'object') {
                args[0].priority = PRIORITY_BACKGROUND
            }

            return this.fetch(...args)
        }
    }
}
