import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';

export class LisIntegration extends Model {
    static backendResourceName = 'lis_integration';

    @observable id = null;
    @observable name = '';
    @observable $url = ''; // Prefixed with $ since url is a forbidden attr in mobx-spine.
    @observable localPath = '';
    @observable activityLinkRequiredForFinalize = false;
    @observable lastVehicleUpdate = null;

    casts() {
        return {
            lastVehicleUpdate: Casts.datetime,
        };
    }

    /**
     * Convert $url to url.
     */
    fromBackend({ data = {}, ...rest }) {
        data.$url = data.url;

        return super.fromBackend({ data, ...rest });
    }

    /**
     * Convert $url to url.
     */
    toBackend({ data, ...rest } = {}) {
        const result = super.toBackend({ data, ...rest });

        result.url = result.$url;
        delete result.$url;

        return result;
    }
}

export class LisIntegrationStore extends Store {
    Model = LisIntegration;
    static backendResourceName = 'lis_integration';
}
