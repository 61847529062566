import { observable, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';

export class CostImport extends Model {
    static backendResourceName = 'cost_import';

    @observable id = null;
    @observable importStartedAt = null;
    @observable importCompletedAt = null;
    @observable taskImportFailedFirstAt = null;
    @observable taskImportFailedLastAt = null;
    @observable taskImportFailureReason = '';
    @observable seenAt = null; // To mark errors as seen.
    @observable deleted = false
    // Doesn't exist on model, but passed using websockets.
    @observable _progressLineNumber = 0;
    @observable _progressTotalLines = 0;

    @computed get done() {
        return !!this.importCompletedAt || this.taskImportFailureReason !== '';
    }

    casts() {
        return {
            taskImportFailedFirstAt: Casts.datetime,
            taskImportFailedLastAt: Casts.datetime,
            seenAt: Casts.datetime,
        };
    }
}

export class CostImportStore extends Store {
    static backendResourceName = 'cost_import';

    Model = CostImport;
}
