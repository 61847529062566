import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { FormQuestionDate } from './QuestionDate';

export class FormAnswerDate extends Model {
    static backendResourceName = 'form_answer_date';

    @observable id = null;
    @observable value = '';

    relations() {
        return {
            formQuestionDate: FormQuestionDate,
        };
    }

    casts() {
        return {
            value: Casts.date,
        };
    }
}

export class FormAnswerDateStore extends Store {
    Model = FormAnswerDate;
    static backendResourceName = 'form_answer_date';
}
