import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Activity } from 'store/Activity';

export const TimeZone = styled.div`
    font-weight: bold;
    position: absolute;
    top: 0px;
    right: 2px;
    color: #2c8c7f;
`;

export const Country = styled.div`
    font-weight: bold;
    position: absolute;
    top: 0px;
    right: 2px;
    color: #2c8c7f;
`;

export const City = styled.div`
    font-weight: bold;
    max-width: 110px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    margin: 0px auto;
    padding-right: 5px;
`;

const Container = styled.div`
    position: relative;
    width: 100%;
`;

@observer
class TimezoneAware extends Component {
    static propTypes = {
        timeZoneDiff: PropTypes.string,
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children, timeZoneDiff } = this.props;

        return (
            <Container data-test-time>
                {timeZoneDiff ? `(${timeZoneDiff}) ` : ''}
                {children}
            </Container>
        );
    }
}

@observer
export default class ActivityInfoCity extends Component {
    static propTypes = {
        title: PropTypes.string,
        city: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        activity: PropTypes.instanceOf(Activity),
    };

    render() {
        const { title, city, country, activity } = this.props;

        return (
            <Container title={title}>
                <City data-test-city={city}>{city.toUpperCase()}</City>
                <Country>
                    <TimezoneAware timeZoneDiff={activity ? activity.timezoneOffsetHours : null}>
                        {country.toUpperCase()}
                    </TimezoneAware>
                </Country>
            </Container>
        );
    }
}
