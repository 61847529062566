import React from 'react';
import PropTypes from 'prop-types';

const IconFerrytunnel = props => {
    return (
        <svg viewBox="0 0 30 30" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#C8FBF5"
                    d="M7.037 26.903l4.516-8.99V14.39l1.127-1.014 1.083-.987h2.702l2.1 2.001v3.521l4.52 8.99V6.93H7.037z"
                />
                <path
                    fill="#8ED7CE"
                    d="M11.597 14.468l-5.03-5.065L9.703 6.38h10.724l2.984 3.024-4.882 5.065-2.014-2.072h-2.973z"
                />
                <path
                    d="M3 27V13C3 6.333 7 3 15 3.001V7c-5.333 0-8 2-8 6v14H3zm24 0h-4V13c0-4-2.667-6-8-6V3.001c8 0 12 3.332 12 9.999v14z"
                    fill={props.color}
                />
                <path
                    d="M11.5 18v-2c0-2.333 1.166-3.5 3.497-3.5 2.332 0 3.5 1.167 3.503 3.5v2"
                    stroke={props.color}
                />
                <path
                    d="M6.5 26.5l5.003-8.5M18.5 18l5 8.5"
                    stroke={props.color}
                    strokeLinecap="square"
                />
            </g>
        </svg>
    );
};

IconFerrytunnel.propTypes = {
    color: PropTypes.string,
};

export default IconFerrytunnel;
