export default `
{{customerId}} / {{trailerPlate}} unloaded {{currentLocation}}



{{customerId}} / {{trailerPlate}} finished unload in {{currentLocation}}
<%=nextNoStopActivity ? \`ETA next place: \${nextNoStopLocation} \${nextArrivalTime}\` : 'Please send a new plan.'%>

Time IN: {{currentArrivalTime}}
Time Start: {{currentStartTime}}
Time Out: {{currentFinishedTime}}
Driving time left: {{remainingDayDrivingTime}}
Working time till: {{dayEnd}}

{{truckEquipment}}

{{timezoneOffsetHoursWithCest}}{{waitingRateApplies}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
