import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Grid, Table, Modal } from 'semantic-ui-react';
import { BEPDocument } from 'store/BEPDocument';
import { CancelButton, SaveButton } from 'spider/semantic-ui/Button';
import { BEPTicket } from 'store/BEPTicket';
import { FormTextInput } from 'spider/semantic-ui/Form';
import RightDivider from 'spider/component/RightDivider';
import { IconButton, AddButton } from 'spider/semantic-ui/Button';
import HeaderRight from 'spider/semantic-ui/HeaderRight';
import { TargetFile } from 'spider/semantic-ui/Target';

@observer
export class Documents extends Component {
    static propTypes = {
        bepTicket: PropTypes.instanceOf(BEPTicket).isRequired,
    };

    @observable newDocument = new BEPDocument();
    @observable showModal = false;

    render() {
        const { bepTicket } = this.props;

        return (
            <Grid.Row data-test-documents>
                <Grid.Column width={12}>
                    <HeaderRight content={t('bepTicket.field.documents.label')}>
                        <AddButton data-test-add-document
                                   disabled={!!bepTicket.reviewedAt}
                                   onClick={() => this.showModal = true}
                        />
                        <DocumentModal
                            open={this.showModal}
                            onClose={() => {
                                this.newDocument = new BEPDocument();
                                this.showModal = false;
                            }}
                            onSave={() => {
                                const bepDocument = bepTicket.documents.add(this.newDocument.toJS());
                                // Utter hack to keep the uploaded file
                                bepDocument.file = this.newDocument.file;
                                this.newDocument = new BEPDocument();
                                this.showModal = false;
                            }}
                            bepDocument={this.newDocument}
                            {...this.props}
                        />
                    </HeaderRight>
                </Grid.Column>
                <Grid.Column width={16}>
                    {bepTicket.documents.length === 0 ? '' : (
                        <Table compact="very">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('bepDocument.field.id.label')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('bepDocument.field.title.label')}</Table.HeaderCell>
                                    <Table.HeaderCell>{/* actions */}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {bepTicket.documents.map(doc => <DocumentRow key={doc.id} bepDocument={doc} {...this.props} />)}
                        </Table>
                    )}
                </Grid.Column>
            </Grid.Row>
        );
    }
}

@observer
class DocumentRow extends Component {
    static propTypes = {
        bepDocument: PropTypes.instanceOf(BEPDocument).isRequired,
        readOnly: PropTypes.bool.isRequired,
        bepTicket: PropTypes.instanceOf(BEPTicket).isRequired,
    };

    @observable showModal = false;

    render() {
        const { bepTicket, bepDocument, readOnly } = this.props;

        return (
            <Table.Body>
                <Table.Row>
                    <Table.Cell collapsing>{bepDocument.id || '-'}</Table.Cell>
                    <Table.Cell>
                        <FormTextInput
                            label
                            model={bepDocument}
                            name="title"
                            readOnly={readOnly}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <IconButton data-test-show-document-modal name="eye" onClick={() => this.showModal = true} />
                        <DocumentModal
                            open={this.showModal}
                            bepDocument={bepDocument}
                            disabled={bepTicket.reviewedAt}
                            {...this.props}
                            onClose={() => {
                                if (!bepDocument.isNew) bepDocument.fetch(); // What should we do else?
                                this.showModal = false;
                            }}
                            onSave={() => this.showModal = false}
                        />
                        <IconButton name="delete" onClick={() => bepTicket.documents.remove(bepDocument)} />
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        );
    }
}

@observer
class DocumentModal extends Component {
    static propTypes = {
        bepDocument: PropTypes.instanceOf(BEPDocument).isRequired,
        readOnly: PropTypes.bool.isRequired,
        onSave: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    render() {
        const { bepDocument, readOnly, onSave, onClose } = this.props;
        window.bepDocument = bepDocument;

        return (
            <Modal {...this.props}>
                <Modal.Header>
                    {bepDocument.isNew ? t('bepDocument.create.title') : t('bepDocument.edit.title')}
                </Modal.Header>
                <Modal.Content>
                    <FormTextInput
                        model={bepDocument}
                        name="title"
                        label={t('bepDocument.field.title.label')}
                    />
                    <TargetFile
                        target={bepDocument}
                        name="file"
                        label={t('bepDocument.field.file.label')}
                    />
                </Modal.Content>
                <Modal.Actions style={{ display: 'flex' }}>
                    <CancelButton onClick={onClose} />
                    <RightDivider />
                    <SaveButton primary data-test-ok
                        content="OK"
                        disabled={readOnly}
                        loading={bepDocument.isLoading}
                        onClick={() => onSave && onSave()}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
