import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { CostProductSupplierCodeStore } from 'store/CostProductSupplierCode';

export class CostSupplier extends Model {
    static backendResourceName = 'cost_supplier';

    @observable id = null;
    @observable name = '';
    @observable paidBy = '';
    @observable vatNumber = '';
    @observable country = '';

    relations() {
        return {
            costProductSupplierCodes: CostProductSupplierCodeStore,
        }
    }
}

export class CostSupplierStore extends Store {
    static backendResourceName = 'cost_supplier';

    Model = CostSupplier;
}
