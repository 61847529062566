import { observable, computed } from 'mobx';
import { Model, Store } from 'store/Base';
import { Invoice } from './Invoice';
import { InvoiceLine, distributeAmount } from './InvoiceLine';
import { InvoiceLineCorrectionItemStore } from './InvoiceLineCorrectionItem';
import { User } from './User';
import { Tariff } from './Tariff';
import { Dispute } from './Dispute';

export class InvoiceLineCorrection extends Model {
    static backendResourceName = 'invoice_line_correction';

    @observable id = null;
    @observable correctedInvoicedKm = 0;
    @observable correctedWaitingAmount = 0;
    @observable correctedWeekendAmount = 0;
    @observable correctedOtherCostsAmount = 0;
    @observable correctedKmAmount = 0;
    @observable correctedKmSurcharge = 0;
    @observable correctedFixedAmount = 0;
    @observable correctedFixedSurcharge = 0;
    @observable correctedSecondDriverAmount = 0;
    @observable correctedCustomAmount = 0;
    @observable correctedTollAmount = 0;
    @observable description = '';


    @observable correctedWaitingAmountFc = 0;
    @observable correctedWeekendAmountFc = 0;
    @observable correctedOtherCostsAmountFc = 0;
    @observable correctedKmAmountFc = 0;
    @observable correctedKmSurchargeFc = 0;
    @observable correctedFixedAmountFc = 0;
    @observable correctedFixedSurchargeFc = 0;
    @observable correctedSecondDriverAmountFc = 0;
    @observable correctedCustomAmountFc = 0;
    @observable correctedTollAmountFc = 0;

    // {combined-surcharge}
    // kmSurcharge and fixedSurcharged are inputted using 1 input. So the user
    // inputs into _surcharge and the system splits it out in kmSurcharge and
    // fixedSurcharge.
    @observable _surcharge = 0;
    parse(data) {
        const result = super.parse(data);

        this.updateCachedSurcharge();

        return result;
    }

    recalculateItems(invoiceLine) {
        const tariff = new Tariff();
        const isFc = invoiceLine.invoice.tariff.contract.isFc
        const fieldMap = {
            // attr name on invoiceLineItem: settings
            correctedInvoicedKm: {
                totalExpected: () => invoiceLine.expectedInvoicedKm,
                totalInvoiced: () => this.correctedInvoicedKm,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedKm,
            },
            correctedKmAmount: {
                totalExpected: () => invoiceLine.expectedKmAmount,
                totalInvoiced: () => isFc ? this.correctedKmAmountFc : this.correctedKmAmount,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedKmAmount,
            },
            // Other costs cannot create a dispute, since you cannot change the
            // custom amount when invoicing.
            // correctedOtherCostsAmount: {
            //     totalExpected: () => invoiceLine.expectedOtherCostsAmount,
            //     totalInvoiced: () => this.correctedOtherCostsAmount,
            //     activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedOtherCostsAmount,
            // },
            correctedFixedAmount: {
                totalExpected: () => invoiceLine.expectedFixedAmount,
                totalInvoiced: () => isFc ? this.correctedFixedAmountFc : this.correctedFixedAmount,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedFixedAmount,
            },
            correctedWaitingAmount: {
                totalExpected: () => invoiceLine.expectedWaitingAmount,
                totalInvoiced: () => isFc ? this.correctedWaitingAmountFc : this.correctedWaitingAmount,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedWaitingAmount,
            },
            correctedSecondDriverAmount: {
                totalExpected: () => invoiceLine.expectedSecondDriverAmount,
                totalInvoiced: () => isFc ? this.correctedSecondDriverAmountFc : this.correctedSecondDriverAmount,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedSecondDriverAmount,
            },
            correctedKmSurcharge: {
                totalExpected: () => invoiceLine.expectedKmSurcharge,
                totalInvoiced: () => isFc ? this.correctedKmSurchargeFc : this.correctedKmSurcharge,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedKmSurcharge,
            },
            correctedFixedSurcharge: {
                totalExpected: () => invoiceLine.expectedFixedSurcharge,
                totalInvoiced: () => isFc ? this.correctedFixedSurchargeFc : this.correctedFixedSurcharge,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedFixedSurcharge,
            },
            correctedTollAmount: {
                totalExpected: () => invoiceLine.expectedTollAmount,
                totalInvoiced: () => isFc ? this.correctedTollAmountFc : this.correctedTollAmount,
                activityExpected: item => invoiceLine.items.find(i => i.activity.id === item.activity.id).expectedTollAmount,
            },
            // Custom amounts cannot create disputes, no need to distribute.
            // correctedCustomAmount: {
            //     totalExpected: () => invoiceLine.expectedCus,
            //     totalInvoiced: () => this.customAmount,
            //     activityExpected: item => tariff.getInvoiceKmFromActivity(item.activity),
            // },
        };

        Object.keys(fieldMap).forEach(field => {
            const settings = fieldMap[field];
            const totalExpected = settings.totalExpected(this.items);
            const totalInvoiced = settings.totalInvoiced();

            distributeAmount(tariff, this.items, field, totalExpected, totalInvoiced, settings.activityExpected);
        });
    }

    updateCachedSurcharge() {
        this._surcharge = this.correctedKmSurcharge + this.correctedFixedSurcharge;
    }

    distributeSurcharge(invoiceLine) {
        const expectedKmSurcharge = invoiceLine.expectedKmSurcharge;
        const expectedFixedSurcharge = invoiceLine.expectedFixedSurcharge;
        const amount = this._surcharge;
        const isFc = invoiceLine.invoice.tariff.contract.isFc;

        let kmSurcharge = 0;

        if (expectedKmSurcharge + expectedFixedSurcharge !== 0) {
            kmSurcharge = Math.round(amount * expectedKmSurcharge / (expectedKmSurcharge + expectedFixedSurcharge));
        }

        let fixedSurcharge = 0;

        if (expectedKmSurcharge + expectedFixedSurcharge !== 0) {
            fixedSurcharge = Math.round(amount * expectedFixedSurcharge / (expectedKmSurcharge + expectedFixedSurcharge));
        }

        const rounding = amount - kmSurcharge - fixedSurcharge;

        if (isFc) {
            this.setInput('correctedKmSurchargeFc', kmSurcharge + rounding);
            this.setInput('correctedFixedSurchargeFc', fixedSurcharge);
        } else {
            this.setInput('correctedKmSurcharge', kmSurcharge + rounding);
            this.setInput('correctedFixedSurcharge', fixedSurcharge);
        }
    }

    relations() {
        return {
            invoice: Invoice,
            invoiceLine: InvoiceLine,
            createdBy: User,
            items: InvoiceLineCorrectionItemStore,
            dispute: Dispute
        };
    }

    convertToCredit() {
        this.setInput('id', null);
        this.setInput('description', 'Credited');
        this.setInput('correctedInvoicedKm', -this.correctedInvoicedKm);
        this.setInput('correctedWaitingAmount', -this.correctedWaitingAmount);
        this.setInput('correctedWeekendAmount', -this.correctedWeekendAmount);
        this.setInput('correctedOtherCostsAmount', -this.correctedOtherCostsAmount);
        this.setInput('correctedKmAmount', -this.correctedKmAmount);
        this.setInput('correctedKmSurcharge', -this.correctedKmSurcharge);
        this.setInput('correctedFixedAmount', -this.correctedFixedAmount);
        this.setInput('correctedFixedSurcharge', -this.correctedFixedSurcharge);
        this.setInput('correctedSecondDriverAmount', -this.correctedSecondDriverAmount);
        this.setInput('correctedCustomAmount', -this.correctedCustomAmount);
        this.setInput('correctedTollAmount', -this.correctedTollAmount);

        if (this.dispute.invoiceLine.invoice.tariff.contract.isFc) {
            this.setInput('correctedWaitingAmount', -this.correctedWaitingAmountFc);
            this.setInput('correctedWeekendAmount', -this.correctedWeekendAmountFc);
            this.setInput('correctedOtherCostsAmount', -this.correctedOtherCostsAmountFc);
            this.setInput('correctedKmAmount', -this.correctedKmAmountFc);
            this.setInput('correctedKmSurcharge', -this.correctedKmSurchargeFc);
            this.setInput('correctedFixedAmount', -this.correctedFixedAmountFc);
            this.setInput('correctedFixedSurcharge', -this.correctedFixedSurchargeFc);
            this.setInput('correctedSecondDriverAmount', -this.correctedSecondDriverAmountFc);
            this.setInput('correctedCustomAmount', -this.correctedCustomAmountFc);
            this.setInput('correctedTollAmount', -this.correctedTollAmountFc);
        }

        this.items.forEach(item => item.convertToCredit());
    }

    @computed get hasCorrections() {
        return (!!this.invoicedAmount || !!this.correctedInvoicedKm) || (!!this.invoicedAmountFc || !!this.correctedInvoicedKm)
    }

    @computed get invoicedAmount() {
        return this.correctedWaitingAmount +
            this.correctedWeekendAmount +
            this.correctedOtherCostsAmount +
            this.correctedKmAmount +
            this.correctedKmSurcharge +
            this.correctedFixedAmount +
            this.correctedFixedSurcharge +
            this.correctedSecondDriverAmount +
            this.correctedCustomAmount +
            this.correctedTollAmount;
    }
    @computed get invoicedAmountFc() {
        return this.correctedWaitingAmountFc +
            this.correctedWeekendAmountFc +
            this.correctedOtherCostsAmountFc +
            this.correctedKmAmountFc +
            this.correctedKmSurchargeFc +
            this.correctedFixedAmountFc +
            this.correctedFixedSurchargeFc +
            this.correctedSecondDriverAmountFc +
            this.correctedCustomAmountFc +
            this.correctedTollAmountFc;
    }
    @computed get amount() {
        return this.invoicedAmount;
    }

    @computed get amountFc() {
        return this.invoicedAmountFc;
    }

    @computed get correctedAmount() {
        return this.invoicedAmount;
    }
    @computed get correctedAmountFc() {
        return this.invoicedAmountFc;
    }
}

export class InvoiceLineCorrectionStore extends Store {
    Model = InvoiceLineCorrection;




    static backendResourceName = 'invoice_line_correction';
}
