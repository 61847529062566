import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { RouteStop } from 'store/RouteStop';
import ActivityTypeIcon from 'component/Activity/TypeIcon';
import ActivityInfoTimes from 'component/Activity/InfoTimes';
import ActivityInfoStatus from 'component/Activity/InfoStatus';
import ActivityInfoCity from 'component/Activity/InfoCity';
import { DATETIME_MINI_FORMAT } from 'helpers';
import { IconSide as IconSideBase, ContentSide as _ContentSide } from 'component/Compact/ActivityBlock';
import { theme } from 'styles';
import styled from 'styled-components';
import moment from 'moment';
import { ProposedActivity } from 'store/ProposedActivity';

export const SINGLE_BORDER_CSS = '1px solid #bacedf';
export const SINGLE_BORDER_TAB_CSS = `1px solid ${theme.primaryColor}`;
export const BACKGROUND_COLOR = '#d2e1ef';

// {unify-activity-block}
export const Container = styled.div`
    margin-right: 1px;
    display: flex;
    height: 45px;
    line-height: 1.2;
    width: 190px;

    ${props => props.active ? `
        border-top: ${SINGLE_BORDER_TAB_CSS};
        border-left: ${SINGLE_BORDER_TAB_CSS};
        border-right: ${SINGLE_BORDER_TAB_CSS};
        border-bottom: 1px solid transparent;
        background-color: white;
        ` : `
        border-top: 1px solid transparent;
        border-left: ${SINGLE_BORDER_CSS};
        border-right: 1px solid transparent;
        border-bottom: ${SINGLE_BORDER_CSS};
        background-color: ${props.backgroundColor};
    `};

    ${props => props.warning && `
        background-color: #fd9927;
    `}

    ${props => props.centered && `
        margin: 0 auto;
    `}

    ${props => props.backgroundColor && `
        background-color: ${props.backgroundColor};
    `}

    ${props => props.onClick && `
        cursor: pointer;
    `}

    ${props => props.deleted && `
        opacity: 0.4;
    `}
`;

const RouteStopInfoContainer = styled(Container)`
    color: ${props => (props.tone === 'error' ? '#fff' : '#000')};
    ${props => props.active ? `
        border-top: ${SINGLE_BORDER_TAB_CSS};
        border-left: ${SINGLE_BORDER_TAB_CSS};
        border-right: ${SINGLE_BORDER_TAB_CSS};
        border-bottom: 1px solid transparent;
        background-color: white;
        ` : `
        border-top: 1px solid transparent;
        border-bottom: ${SINGLE_BORDER_TAB_CSS};
        background-color: ${BACKGROUND_COLOR};
    `};
`;

const IconSide = styled(IconSideBase)`
`;

const ContentSide = styled(_ContentSide)`
    width: 140px;
`;

@observer
export default class Activity extends Component {
    static propTypes = {
        activity: PropTypes.object.isRequired,
        onClick: PropTypes.func,
        active: PropTypes.bool,
        children: PropTypes.node,
        backgroundColor: PropTypes.string,
        centered: PropTypes.bool.isRequired,

        /**
         * When true, a css hack is applied to make sure the popup with status
         * info is displayed correctly.
         */
        inScrollBox: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        centered: false,
        inScrollBox: false,
    }

    render() {
        const { activity, active, children, backgroundColor, onClick, inScrollBox, ...rest } = this.props;
        const now = moment();

        return (
            <Container data-test-component-activity={activity.id} data-test-activity {...rest}
                deleted={activity instanceof ProposedActivity ? activity.externallyDeleted : activity.deleted}
                activity={activity}
                active={active}
                onClick={onClick}
                backgroundColor={backgroundColor}
            >
                <IconSide title={activity.id}>
                    {activity.parking ?
                        (<ActivityTypeIcon
                            type={activity.type}
                            color={activity instanceof ProposedActivity ? '#4363d8' : undefined}
                            parking={activity.parking}
                        />)
                        :
                        (<ActivityTypeIcon
                            type={activity.type}
                            color={activity instanceof ProposedActivity ? '#4363d8' : undefined}
                        />)
                    }
                </IconSide>
                <ContentSide>
                    <ActivityInfoCity
                        city={activity.location.city}
                        country={activity.location.country}
                        activity={activity}
                    />
                    <ActivityInfoTimes
                        activity={activity}
                        now={now}
                        showAsap={false}
                    />
                    <ActivityInfoStatus inScrollBox={inScrollBox} activity={activity} now={now} />
                    {children}
                </ContentSide>
            </Container>
        );
    }
}

@observer
export class RouteStopInfo extends Component {
    static propTypes = {
        routeStop: PropTypes.instanceOf(RouteStop).isRequired,
        firstRouteStop: PropTypes.instanceOf(RouteStop),
        orderedArrivalDatetimeFrom: PropTypes.object,
        orderedArrivalDatetimeUntil: PropTypes.object,
        onClick: PropTypes.func,
        active: PropTypes.bool,
        className: PropTypes.string,
    };

    render() {
        const { routeStop, active, className } = this.props;

        return (
            <RouteStopInfoContainer active={active} className={className} onClick={this.props.onClick}>
                <IconSideBase>
                    <ActivityTypeIcon type={routeStop.type} />
                </IconSideBase>
                <ContentSide>
                    <ActivityInfoCity
                        city={routeStop.location.city}
                        country={routeStop.location.country}
                    />
                    <span>{routeStop.timeDelta.asMinutes() > 0 ? '+' : ''}{routeStop.timeDelta.format('h:mm', { trim: false })}</span>
                    <span>{this.renderOrderedArrivalDatetimeFrom()}</span>
                </ContentSide>
            </RouteStopInfoContainer>
        );
    }

    renderOrderedArrivalDatetimeFrom = () => {
        const { firstRouteStop, orderedArrivalDatetimeFrom, routeStop } = this.props;

        if (!moment.isMoment(orderedArrivalDatetimeFrom)) {
            return '';
        }

        if (firstRouteStop === routeStop) {
            return orderedArrivalDatetimeFrom.format(DATETIME_MINI_FORMAT);
        }

        return orderedArrivalDatetimeFrom.clone().add(routeStop.timeDelta).format(DATETIME_MINI_FORMAT);
    }
}

@observer
export class From extends Component {
    static propTypes = {
        activity: PropTypes.object.isRequired,
    };

    render() {
        const { activity } = this.props;
        return (
            <Container>
                <ActivityInfoCity
                    city={activity.location.city}
                    country={activity.location.country}
                />
            </Container>
        );
    }
}

@observer
export class To extends Component {
    static propTypes = {
        activity: PropTypes.object.isRequired,
    };

    render() {
        const { activity } = this.props;
        const now = moment();

        return (
            <Container>
                <ContentSide>
                    <ActivityInfoCity
                        city={activity.location.city}
                        country={activity.location.country}
                    />
                    <ActivityInfoTimes
                        activity={activity}
                        now={now}
                        showAsap={false}
                    />
                    <ActivityInfoStatus activity={activity} now={now} />
                </ContentSide>
            </Container>
        );
    }
}
