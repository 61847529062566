import { observable, action, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Truck } from './Truck';
import { Activity } from './Activity';
import { Declaration } from './Declaration';
import { DriverCertification } from './Certification';

export const TYPE_TEMPERATURE_PRINT = 'temperature print';
export const TYPE_TACHO_CALIBRATION = 'tacho calibration';
export const TYPE_TUV = 'tuv';
export const TYPE_CMR = 'cmr';
export const TYPE_REGISTRATION_DOCUMENT = 'registration document';
export const STATUS_REJECTED_BY_CUSTOMER = 'rejected by customer';

export class Document extends Model {
    static backendResourceName = 'document';

    static types = [
        TYPE_CMR,
        'delivery note',
        'cargo manifest',
        'pallet note',
        'weight ticket',
        'commercial document',
        'fcdn',
        TYPE_TEMPERATURE_PRINT,
        'wash ticket',
        'receipt',
        TYPE_TACHO_CALIBRATION,
        TYPE_TUV,
        'other',
		't1',
		'ex a',
		'eur1',
		TYPE_REGISTRATION_DOCUMENT,
        'declaration',
    ];

    @observable id = null;

    @observable file = null;
    @observable _file = null; // actual file blob

    @observable pdfFile = null;
    @observable _pdfFile = null; // actual file blob

    @observable status = '';
    @observable type = '';
    @observable scannedAt = null;
    @observable mailedToCustomerAt = null;
    @observable deleted = false;
    @observable rejectReason = '';

    @observable replacedByDocument = null;

    relations() {
        return {
            truck: Truck,
            activity: Activity,
            declaration: Declaration,
            driverCertification: DriverCertification,
        };
    }

    casts() {
        return {
            type: Casts.enum(this.constructor.types),
            mailedToCustomerAt: Casts.datetime,
            scannedAt: Casts.datetime,
        };
    }

    openFileInWindow(activityId) {
        if (activityId) {
            window.open(`${process.env.REACT_APP_CY_FRONTEND_API_BASE_URL}activity/${activityId}/all_documents/`, '_blank');
        } else {
            window.open(`${window.location.origin}${this.file}`, '_blank');
        }
    }

    reset() {
        return this.wrapPendingRequestCount(
            this.api.patch('/document/file/reset/', { ids: [this.id] }).then(() => {
                this.file += '1';
            })
        );

    }

    rotate(angle) {
        return this.wrapPendingRequestCount(
            this.api.patch('document/file/rotate/', {
                ids: [this.id],
                angle,
            }).then(() => {
                this.file += '1';
            })
        );
    }

    brighten(brightness) {
        return this.wrapPendingRequestCount(
            this.api.patch('document/file/brighten/', {
                ids: [this.id],
                brightness,
            }).then(() => {
                this.file += '1';
            })
        );
    }

    sharpen(sharpness) {
        return this.wrapPendingRequestCount(
            this.api.patch('document/file/sharpen/', {
                ids: [this.id],
                sharpness,
            }).then(() => {
                this.file += '1';
            })
        );
    }

    crop(crop) {
        return this.api.patch('/document/file/crop/', {
            ids: [this.id],
            x_1: Math.round(crop.x),
            x_2: Math.round(crop.x + crop.width),
            y_1: Math.round(crop.y),
            y_2: Math.round(crop.y + crop.height),
        }).then(() => {
            this.file += '1';
        });
    }

    // Browser caches the file, so we need to trick it by changing file name
    fetchForceFileRefresh() {
        return this.fetch().then(() => {
            this.file += Date.now().toString();
        });
    }

    saveFiles() {
        const file = this._file;
        if (!file) {
            return Promise.resolve();
        }
        const data = new FormData();
        data.append('file', file, file.name);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        return this.api.post(`${this.url}file/`, data, {
            headers,
        }).then(response => {
            this.file = response.data.file;
        });
    }

    savePdfFiles() {
        const file = this._pdfFile;
        if (!file) {
            return Promise.resolve();
        }
        const data = new FormData();
        data.append('file', file, file.name);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        return this.api.post(`${this.url}pdf_file/`, data, {
            headers,
        }).then(response => {
            this.pdfFile = response.data.pdf_file;
        });
    }

    rejectByEmployee() {
        return this.wrapPendingRequestCount(
            this.api.post('/document/reject_employee/',{
                ids: [this.id],
                reason: this.rejectReason,
            })
        )
    }

    approve() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}approve/`)
        );
    }
}
export class DocumentStore extends Store {

    Model = Document;
    static backendResourceName = 'document';
    @observable rejectedByEmployeeReason = '';

    findByType(type) {
        return this.find(d => d.type === type);
    }

    wasScanRequestSent(type) {
        return !!this.find(d => d.type === type && d.status === 'scan requested' && d.replacedByDocument == null);
    }

    @action
    chooseDocument(doc) {
        // Only don't add the document if already have this one
        if (!this.get(doc.id)) {
            this.add(doc.toJS());
        }
    }
}

export class PublicDocument extends Document {
    @observable _rejected = false;
    @observable _rejectionUuid = '';

    @computed get rejectByCustomerFileUrl() {
        return `/api/document/${this.id}/detail/file/${this._rejectionUuid}/`;
    }

    rejectByCustomer() {
        return this.wrapPendingRequestCount(
            this.api.post(`/document/reject/${this._rejectionUuid}/`, {
                ids: [this.id],
                reason: this.rejectReason,
            }).then(() => this._rejected = true)
        );
    }
}

/**
 * Special store to list documents for customer using UUID.
 */
export class PublicDocumentStore extends DocumentStore {
    Model = PublicDocument;

    constructor(options = {}) {
        const rejectionUuid = options.rejectionUuid;
        delete options.rejectionUuid;

        super(options);

        this.rejectionUuid = rejectionUuid;
    }

    _newModel(model = null) {
        const m = super._newModel(model);

        m._rejectionUuid = this.rejectionUuid;

        return m;
    }

    url() {
        return `${super.url()}list/${this.rejectionUuid}/`;
    }
}

export class DocumentOrderedByTypeStore extends DocumentStore {
    comparator = 'type';
}