import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { CostImport } from './CostImport';
import { result } from 'lodash';
import { RoadCost } from './RoadCost'
import { FuelCost } from './FuelCost'

export class CostImportWarning extends Model {
    static backendResourceName = 'cost_import_warning';

    @observable id = null;
    @observable level = '';
    @observable lineNumber = 0;
    @observable field = '';
    @observable message = '';

    relations() {
        return {
            costImport: CostImport,
            fuelCost: FuelCost,
            roadCost: RoadCost,
        };
    }
}

export class CostImportWarningStore extends Store {
    static backendResourceName = 'cost_import_warning';

    Model = CostImportWarning;

    deleteAllCostInvoiceWarnings(costInvoiceId) {
        return this.api.post(result(this, 'url') + 'delete_all_warnings/', {
            'cost_invoice': costInvoiceId,
        });
    }
}
