import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Truck } from './Truck';
import { User } from './User';

export const STATUS_BUDGETED = 'budgeted';
export const STATUS_ORDERED = 'ordered';
export const STATUS_OPERATIONAL = 'operational';
export const STATUS_NON_OPERATIONAL = 'nonoperational';
export const STATUS_SCHOOLING = 'schooling';
export const STATUS_PRE_EXIT = 'pre-exit';
export const STATUS_EXIT = 'exit';
export const STATUS_HOLIDAY = 'holiday';
export const STATUS_THIRD_PARTY_RENT = 'third-party-rent';
export const STATUSES = [
    STATUS_BUDGETED,
    STATUS_ORDERED,
    STATUS_OPERATIONAL,
    STATUS_NON_OPERATIONAL,
    STATUS_SCHOOLING,
    STATUS_EXIT,
    STATUS_PRE_EXIT,
    STATUS_HOLIDAY,
    STATUS_THIRD_PARTY_RENT
];

export class TruckStatusChange extends Model {
    static backendResourceName = 'truck_status_change';

    @observable id = null;
    @observable status = '';
    @observable reason = '';
    @observable date = null;
    @observable createdAt = null;
    @observable deleted = false;

    casts() {
        return {
            date: Casts.date,
            createdAt: Casts.date,
        };
    }

    relations() {
        return {
            truck: Truck,
            createdBy: User,
        };
    }
}


export class TruckStatusChangeStore extends Store {
    Model = TruckStatusChange;
    static backendResourceName = 'truck_status_change';
}
