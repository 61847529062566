import { observable } from 'mobx';
import { Model, Store, Casts, FileField } from 'store/Base';

export class ContractDocument extends Model {
    static backendResourceName = 'contract_document';

    @observable id = null;
    @observable title = '';
    @observable file = new FileField({ name: 'file' });
    @observable deleted = false;
    @observable createdAt = null;
    @observable updatedAt = null;


    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            file: Casts.file,
        };
    }
}

export class ContractDocumentStore extends Store {
    Model = ContractDocument;
    static backendResourceName = 'contract_document';
}
