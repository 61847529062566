import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { FormQuestion } from './Question';

export class FormQuestionChoiceOption extends Model {
    static backendResourceName = 'form_question_choice_option';

    @observable id = null;
    @observable body = '';

    relations() {
        return {
            formQuestion: FormQuestion,
        };
    }
}

export class FormQuestionChoiceOptionStore extends Store {
    Model = FormQuestionChoiceOption;
    static backendResourceName = 'form_question_choice_option';
}
