import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Store, Model } from 'store/Base';
import { Form, Dropdown } from 'semantic-ui-react';
import { getErrorsFromProps, ErrorLabel } from 'spider/semantic-ui/Form';
import { ACTION_DELAY } from 'helpers';
import { debounce } from 'lodash';

@observer
export default class RemoteSelect extends Component {
    static propTypes = {
        label: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.any,
        model: PropTypes.instanceOf(Model),
        remoteStore: PropTypes.instanceOf(Store).isRequired,
        renderOption: PropTypes.func.isRequired,
        renderCell: PropTypes.func.isRequired,
        afterChange: PropTypes.func,
        disabled: PropTypes.bool,
    };

    @observable searchQuery = '';

    componentDidMount() {
        const { remoteStore } = this.props;

        remoteStore.fetch();
    }

    search = q => {
        const { remoteStore } = this.props;

        remoteStore.params.search = this.searchQuery;
        this.searchQuery = q;

        this.fetchDebounced();
    }

    fetchDebounced = debounce(() => {
        const { remoteStore } = this.props;

        remoteStore.fetch();
    }, ACTION_DELAY)

    selectModel = id => {
        const { remoteStore, afterChange, model, name } = this.props;
        const relatedModel = remoteStore.get(id);

        model.setInput(name, relatedModel);

        if (afterChange) {
            afterChange();
        }
    }

    getModelValue() {
        const { value, model, name } = this.props;
        return value || model[name];
    }


    // Stolen from spider/semantic-ui/Form
    renderErrors() {
        const errors = getErrorsFromProps(this.props);

        return errors.map((error, i) => (
            <ErrorLabel key={i}>
                {error}
            </ErrorLabel>
        ));
    }

    render() {
        const { label, remoteStore, renderOption, disabled, ...rest } = this.props;
        const modelValue = this.getModelValue();

        return (
            <Form.Field>
                {'label' in this.props && (
                    <label>{label}</label>
                )}
                <Dropdown
                    {...rest}
                    fluid
                    selection
                    search
                    selectOnNavigation={false}
                    icon="search"
                    options={remoteStore.filter(u => u.id !== modelValue.id).map(renderOption).concat([renderOption(modelValue)])}
                    value={modelValue.id}
                    noResultsMessage={null}
                    onChange={(e, { value }) => this.selectModel(value)}
                    onSearchChange={(e, { searchQuery }) => this.search(searchQuery)}
                    disabled={disabled || remoteStore.isLoading}
                    loading={remoteStore.isLoading}
                />
                {this.renderErrors()}
            </Form.Field>
        );
    }
}
