import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Incident } from '../Incident';
import { User } from '../User';

export const FOLLOWUP_ACTION_FILL_DATA = 'fill data';
export const FOLLOWUP_ACTION_ADD_AGENCY = 'add agency';
export const FOLLOWUP_ACTION_MAKE_CONTROL = 'make control';
export const FOLLOWUP_ACTION_CHECK_STATUS = 'check status';
export const FOLLOWUP_ACTION_FINAL_CONTROL = 'final control';
export const FOLLOWUP_ACTIONS = [FOLLOWUP_ACTION_FILL_DATA, FOLLOWUP_ACTION_ADD_AGENCY, FOLLOWUP_ACTION_MAKE_CONTROL, FOLLOWUP_ACTION_CHECK_STATUS, FOLLOWUP_ACTION_FINAL_CONTROL];

export class IncidentFollowup extends Model {
    static backendResourceName = 'incident_followup';

    @observable id = null;
    @observable description = null;
    @observable performedAt = null;
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            incident: Incident,
            createdBy: User,
            performedBy: User,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            performedAt: Casts.datetime,
        };
    }
}

export class IncidentFollowupStore extends Store {
    Model = IncidentFollowup;
    static backendResourceName = 'incident_followup';
}
