import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { DietRule } from './DietRule';
import { CalculationRuleDescriptionStore } from './CalculationRuleDescription';
import { DriverStatusChange } from './Driver/StatusChange';
import { CalculationRuleCountryStore } from './CalculationRuleCountry';
import { CalculationRuleContractTypeStore } from './CalculationRuleContractType';

export const DESCRIPTION = 'description';
export const LEVEL = 'level';
export const OPTIONAL_FIELDS = [DESCRIPTION, LEVEL];

export class CalculationRule extends Model {
    static backendResourceName = 'calculation_rule';

    @observable id = null;
    @observable name = '';
    @observable startDate = null;
    @observable expiryDate = null;
    @observable studentPerDay = 0;
    @observable statuses = [];
    @observable deleted = false;

    casts() {
        return {
            startDate: Casts.datetime,
            expiryDate: Casts.datetime,
        };
    }

    relations() {
        return {
            dietRule: DietRule,
            calculationRuleDescriptions: CalculationRuleDescriptionStore,
            calculationRuleCountries: CalculationRuleCountryStore,
            calculationRuleContractTypes: CalculationRuleContractTypeStore,
            driverStatusChange: DriverStatusChange
        };
    }
}

export class CalculationRuleStore extends Store {
    Model = CalculationRule;
    static backendResourceName = 'calculation_rule';
}
