import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { OrderConfirmationStore } from 'store/OrderConfirmation';
import { Accordion, Icon } from 'semantic-ui-react';
import HeaderRight from 'spider/semantic-ui/HeaderRight';
import { IconButton } from 'spider/semantic-ui/Button';
import RightDivider from 'spider/component/RightDivider';
import { TargetFile } from 'spider/semantic-ui/Target';

@observer
export default class OrderConfirmationCards extends Component {
    static propTypes = {
        orderConfirmationStore: PropTypes.instanceOf(OrderConfirmationStore).isRequired,
        className: PropTypes.string,
    };

    @observable activeIndex = null;

    toggleActive = index => {
        this.activeIndex = this.activeIndex === index ? -1 : index;
    }

    render() {
        const { orderConfirmationStore, className } = this.props;
        const nonDeletedOrderConfirmations = orderConfirmationStore.filter(oc => !oc.deleted);

        return (
            <div style={{ marginTop: '20px' }} className={className}>
                <HeaderRight content={t('activity.field.orderConfirmations.label')} store={nonDeletedOrderConfirmations}>
                    <IconButton data-test-add-order-confirmation
                        title={t('activity.field.orderConfirmations.add')}
                        name="add circle"
                        onClick={() => orderConfirmationStore.add({})}
                    />
                </HeaderRight>
                {nonDeletedOrderConfirmations.length > 0 && (
                    <Accordion fluid styled data-test-order-confirmations>
                        {nonDeletedOrderConfirmations.map((orderConfirmation, i) => {
                            if (orderConfirmation.deleted) {
                                return null;
                            }


                            return (
                                <React.Fragment key={orderConfirmation.id || orderConfirmation.cid}>
                                    <Accordion.Title
                                        style={{ display: 'flex', alignItems: 'center', color: 'unset' }}
                                        index={i}
                                        active={this.activeIndex === i}
                                        onClick={() => this.toggleActive(i)}
                                    >
                                        <Icon name="dropdown" style={{ visibility: 'unset' }} />

                                        #{i + 1}
                                        <RightDivider />
                                        {!orderConfirmation.file?.id && (
                                            <IconButton
                                                name="trash"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    orderConfirmationStore.remove(orderConfirmation)
                                                }}
                                            />
                                        )}
                                    </Accordion.Title>
                                    <Accordion.Content active={this.activeIndex === i}>
                                        <TargetFile
                                            target={orderConfirmation}
                                            name="file"
                                        />
                                    </Accordion.Content>
                                </React.Fragment>
                            );
                        })}
                    </Accordion>
                )}
            </div>
        );
    }
}
