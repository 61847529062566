export default `
{{customerId}} / {{trailerPlate}} {{status}} border customs in {{currentLocation}} at {{currentFinishedTime}}



{{customerId}} / {{trailerPlate}} {{status}} border customs in {{currentLocation}}

Time IN: {{currentArrivalTime}}
Time OUT: {{currentFinishedTime}}

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
