import { observable } from 'mobx';
import { Casts, Model, Store } from 'store/Base';
import { Activity } from './Activity';
import { EquipmentType } from './EquipmentType';

export const CHANGE_TARGET_TRAILER = 'trailer';
export const CHANGE_TARGET_ALLOCATION = 'allocation';
export const CHANGE_TARGETS = [CHANGE_TARGET_TRAILER, CHANGE_TARGET_ALLOCATION];

export const STATUS_DRAFT = 'draft';
export const STATUS_PENDING = 'pending';
export const STATUS_APPLIED = 'applied';
export const STATUSES = [STATUS_DRAFT, STATUS_PENDING, STATUS_APPLIED];

export class EquipmentChange extends Model {
    static backendResourceName = 'equipment_change';

    @observable id = null;
    @observable status = STATUS_DRAFT;
    @observable changeTarget = '';
    @observable load = 0;
    @observable unload = 0;
    @observable isCorrection = false;
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            activity: Activity,
            equipmentType: EquipmentType,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
         };
    }
}

export class EquipmentChangeStore extends Store {
    Model = EquipmentChange;
    static backendResourceName = 'equipment_change';
}
