export default `
{{customerId}} / {{trailerPlate}} {{status}} traffic jam in {{currentLocation}}



{{customerId}} / {{trailerPlate}} {{status}} traffic jam in {{currentLocation}} is: {{currentCommunicatedTime}}
Reason: Stuck in traffic jam area {{truckCurrentLocation}}

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
