import { observable } from 'mobx';
import { Model, Store, Casts } from '../Base';
import { Driver } from '../Driver'
import { User } from '../User'

export class DriverComment extends Model {
    static backendResourceName = 'driver_comment';

    @observable id = null;
    @observable comment = '';
    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            driver: Driver,
            createdBy: User,
        };
    }
}

export class DriverCommentStore extends Store {
    Model = DriverComment;
    static backendResourceName = 'driver_comment';
}
