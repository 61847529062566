import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Customer } from './Customer';
import { Activity, STATUS_ETA } from './Activity';
import { RouteStopStore } from './RouteStop';

export class Route extends Model {
    static backendResourceName = 'route';

    @observable id = null;
    @observable name = '';
    @observable appliedCount = 0;

    relations() {
        return {
            customer: Customer,
            stops: RouteStopStore,
        };
    }

    /**
     * Make sure first stop starts with 0:00.
     */
    fixOffset() {
        if (this.stops.length > 0) {
            const offsetTimeDelta = this.stops.at(0).timeDelta.clone();

            this.stops.forEach(stop => stop.setInput('timeDelta', stop.timeDelta.subtract(offsetTimeDelta)));
        }
    }

    /**
     * data = {
     *     isAsap: false,
     *     orderedArrivalDatetimeFrom: moment(),
     *     orderedArrivalDatetimeUntil: moment(),
     * }
     */
    applyTo(allocation, firstActivityData) {
        this.__pendingRequestCount += 1;
        let lowestStopId = 0;
        const hasCurrentActivity = allocation.currentActivity;

        const data = {
            data: this.stops.map((stop, i) => {
                const stopData = stop.toActivity();

                stopData['id'] = -stop.id;
                stopData['allocation'] = allocation.id;
                stopData['order_reference'] = firstActivityData.orderReference;

                if (i === 0) {
                    stopData['is_asap'] = firstActivityData.isAsap;
                    stopData['ordered_arrival_datetime_from'] = firstActivityData.orderedArrivalDatetimeFrom.format();
                    stopData['ordered_arrival_datetime_until'] = firstActivityData.orderedArrivalDatetimeUntil.format();

                    if (!hasCurrentActivity) {
                        stopData['status'] = STATUS_ETA;
                        lowestStopId = -stop.id
                    }
                } else {
                    stopData['ordered_arrival_datetime_from'] = firstActivityData.orderedArrivalDatetimeFrom.clone().add(stop.timeDelta).format();
                    stopData['ordered_arrival_datetime_until'] = firstActivityData.orderedArrivalDatetimeUntil.clone().add(stop.timeDelta).format();
                }

                return stopData;
            }),
            with: {
                route: [{
                    id: this.id,
                    applied_count: ++this.appliedCount,
                }]
            }
        };

        const p = this.api.put('/activity/', data);
        p.catch(() => {}).then(() => this.__pendingRequestCount -= 1);

        return p.then(response => {
            // Send first activity if possible.
            const first = response.idmap.activity.find(a => a[0] === lowestStopId);

            if (!hasCurrentActivity && first) {
                allocation.maybeSendFirstActivity(new Activity({ id: first[1], status: STATUS_ETA }));
            }
        });
    }
}

export class RouteStore extends Store {
    Model = Route;
    static backendResourceName = 'route';
}
