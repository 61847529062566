import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { CalculationRule } from './CalculationRule';
import { DietRule } from './DietRule';
import { Driver } from './Driver';
import { DriverStatusChange } from './Driver/StatusChange';
import { storeWithHistory, modelWithHistory } from './History';

export class DriverCalculationRuleStudentSalary extends Model {
    static backendResourceName = 'driver_calculation_rule_student_salary';

    @observable id = null;
    @observable date = null;
    @observable amount = null;
    @observable approved = false;

    casts() {
        return {
            date: Casts.date,
        };
    }

    relations() {
        return {
            calculationRule: CalculationRule,
            driver: Driver,
            student: Driver,
            driverStatusChange: DriverStatusChange,
            dietRule: DietRule,
        };
    }
}

export class DriverCalculationRuleStudentSalaryStore extends Store {
    Model = DriverCalculationRuleStudentSalary;
    static backendResourceName = 'driver_calculation_rule_student_salary';
}
export class HistoryDriverCalculationRuleStudentSalaryStore extends storeWithHistory(DriverCalculationRuleStudentSalaryStore){};
export class HistoryDriverCalculationRuleStudentSalary extends modelWithHistory(DriverCalculationRuleStudentSalary){};
