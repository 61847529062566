import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { VerificationForm } from './Form';
import { FormQuestionStore } from '../Form/Question';

export class VerificationFormVersion extends Model {
    static backendResourceName = 'verification_form_version';

    @observable id = null;
    @observable draft = true;

    relations() {
        return {
            verificationForm: VerificationForm,
            questions: FormQuestionStore,
        };
    }

    publish() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}publish/`)
                .then(() => this.draft = false)
        );
    }
}

export class VerificationFormVersionStore extends Store {
    Model = VerificationFormVersion;
    static backendResourceName = 'verification_form_version';
}
