import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { CalculationRule } from './CalculationRule';

export const DESCRIPTION = 'description';
export const LEVEL = 'level';
export const OPTIONAL_FIELDS = [DESCRIPTION, LEVEL];

export class CalculationRuleCountry extends Model {
    static backendResourceName = 'calculation_rule_country';

    @observable id = null;
    @observable countries = [];
    @observable rate = 0;


    relations() {
        return {
            calculationRule: CalculationRule,
        };
    }
}

export class CalculationRuleCountryStore extends Store {
    Model = CalculationRuleCountry;
    static backendResourceName = 'calculation_rule_country';
}
