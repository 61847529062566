export default `
{{customerId}} / {{trailerPlate}} {{status}} homebase in {{currentLocation}}



{{customerId}} / {{trailerPlate}} {{status}} homebase in {{currentLocation}}

Reason:

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
