export default `
{{customerId}} / {{trailerPlate}} {{status}} {currentLocation}}



{{customerId}} / {{trailerPlate}} {{status}} {{currentLocation}}
Reason: Truck is waiting in line, trucks in front of him.

Time IN: {{currentArrivalTime}}

{{timezoneOffsetHoursWithCest}}

Best Regards,
{{userFullName}}
Boekestijn Transport Service
{{userEmailSignature}}
`;
