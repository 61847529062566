import { observable, action, computed } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { SERVER_DATETIME_FORMAT } from 'helpers';
import { Activity } from './Activity';

const REQUIRED_PARAMS = {
    include_meta: '',
    order_by: '-measured_at',
    limit: 200,
}

export class TruckPosition extends Model {
    static backendResourceName = 'truck_position';

    @observable id = null;
    @observable dataExternalID = null;
    @observable dataSource = null;
    @observable point = { lng: '', lat: '' };
    @observable truck = null;
    @observable measuredAt = null;
    @observable odometer = 0;
    @observable fuelLevel = null;

    // Filled in from Fleetvisor.
    @observable closestCity = '';
    @observable closestStreet = '';
    @observable closestCountry = '';

    @observable _address = '';
    @observable _street = '';
    @observable _city = '';
    @observable _country = '';
    @observable _prevLocationAddress = '';

    casts() {
        return {
            measuredAt: Casts.datetime,
        };
    }

    /**
     * Convert to something that a Loction can use. Assumes this truckPosition
     * is already geocoded.
     */
    toLocation() {
        return {
            address: `${this._street}, ${this._city}, ${this._country}`,
            city: this._city,
            country: this._country,
            point: {
                lat: this.point.lat,
                lng: this.point.lng,
            },
        };
    }

    @computed get closestAddress() {
        return `${this.closestStreet}, ${this.closestCity} (${this.closestCountry})`;
    }

    @computed get latLng() {
        return `${this.point.lat}, ${this.point.lng}`;
    }

    // {geocode-copy-pasta}
    geocode() {
        this.__pendingRequestCount += 1;

        return this.api
            .post('/location/geocode/',
                { address: `${this.point.lat}, ${this.point.lng}` },
                { skipRequestError: true }
            )
            .then(action(response => {
                this._city = response.data.city;
                this._address = response.data.address;
                this._street = response.data.street;
                this._country = response.data.country;
                this.__pendingRequestCount -= 1;

                return response;
            }))
            .catch(err => {
                this.__pendingRequestCount -= 1;
                throw err;
            });
    }
}

export class TruckPositionStore extends Store {
    Model = TruckPosition;
    static backendResourceName = 'truck_position';

    comparator(a, b) {
        if (a.measuredAt > b.measuredAt) {
            return -1;
        }

        if (a.measuredAt < b.measuredAt) {
            return 1;
        }

        return 0;
    }

    lastPosition() {
        if (this.length > 0) {
            return this.at(0);
        }
    }

    setupMap(truck, activity, fetchProps = {}) {
        this.params = { ...this.params, ...REQUIRED_PARAMS };

        if (truck.id) {
            this.params['.truck'] = truck.id;

            if (!activity) {
                return this.fetch(fetchProps);
            }

            // When an activity is given, try to more acurately show what the
            // truck has been doing for this specific activity.
            if (activity) {
                // Only show starting from this activity.
                if (activity.startKm) {
                    this.params['.odometer:gte'] = activity.startKm;
                }

                // When activity is finished, don't show driven route after it.
                if (activity.finishedDatetime) {
                    this.params['.measured_at:lte'] = activity.finishedDatetime.format(SERVER_DATETIME_FORMAT);
                    return this.fetch(fetchProps);
                }

                // It's possible that the driver has sent a message that he is finished, but the dispatcher didn't
                // update the activity yet. So lets try to find a finished time from the driver message.
                const temp = new Activity();
                temp.uuid = activity.uuid;

                temp.setStatusDatetime('finished', truck, true, fetchProps).then(() => {
                    if (temp.finishedDatetime) {
                        this.params['.measured_at:lte'] = temp.finishedDatetime.format(SERVER_DATETIME_FORMAT);
                    }

                    this.fetch(fetchProps).catch(() => {});
                }).catch(() => {});
            }
        } else {
            this.clear();
        }
    }

    /**
     * Fetch only newer positions and append.
     */
    fetchNew(...fetchProps) {
        const lastTruckPosition = this.lastPosition();

        if (this.isLoading) {
            return Promise.resolve();
        }

        if (lastTruckPosition) {
            const tempStore = new TruckPositionStore({
                params: {
                    '.truck': this.params['.truck'],
                    '.measured_at:gt': lastTruckPosition.measuredAt.format(SERVER_DATETIME_FORMAT),
                    ...REQUIRED_PARAMS,
                },
            });

            return tempStore.fetch(...fetchProps).then(() => {
                this.add(tempStore.toJS().filter(tp =>
                    // Measured at is not super reliable, so lets filter out
                    // already existing ones.
                    !this.get(tp.id)
                ));
            });
        }

        return Promise.reject();

        // If we're gonna hack, at least do it in a sustainable way. I'm not
        // gonna delete the old code, to show how it was done before. The old
        // way requires a lot of knowledge about mobx-spine internals. The
        // newer hack simply assumes that you know how to use mobx-spine.
        //
        // let lastMeasuredAt = null;
        // if (this.length > 0) {
        //     // note: this assumes the data is sorted -measuredAt
        //     lastMeasuredAt = this.at(0)
        //         .measuredAt
        //         .clone()
        //         .add(1, 'second')
        //         .utc()
        //         .format(SERVER_DATETIME_FORMAT);
        // }
        // const data = Object.assign(
        //     this.api.buildFetchStoreParams(this),
        //     this.params,
        //     {
        //         '.measured_at:gte': lastMeasuredAt,
        //     }
        // );
        // return this.api
        //     .fetchStore({
        //         url: this.url(),
        //         data,
        //     })
        //     .then(res => {
        //         // Ridiculous hack to force-add the new models to the start instead of the end of the store, since newest
        //         // positions always go first
        //         if (res.data.length > 0) {
        //             const newOnes = res.data.map(a => new this.Model(a));
        //             this.models.unshift(...newOnes);
        //         }
        //     });
    }
}

