import styled, { keyframes } from 'styled-components';

const trip = keyframes`
    0%{ background-position: 0% 50%; }
    50%{ background-position: 100% 50%; }
    100%{ background-position: 0% 50%; }
`;

export default styled.div`
    flex: 1;
    display: flex;

    background: linear-gradient(45deg, #539bb4, #85b8cb);
    background-size: 400% 400%;

    animation: ${trip} 60s ease infinite;
`;
